import { Component, Input, OnInit } from '@angular/core';
import { CellEvent, GridApi } from 'ag-grid-community';
import { PublicationsDatasource } from './publications.datasource';
import { Subject } from 'rxjs';
import { PublicationFilters } from '../../../../models/publications/filters/publication-filters-model';
import { isNilty } from '../../../../core/utils.service';
import { PublicationService } from '../../publication.service';
import { PendingTooltipComponent } from './custom-tooltips/pending-tooltip.component';
import { PublicationViewType } from '../publication-view-type';
import { ColumnDefinitionsService } from './column-definitions.service';
import { PublicationDto } from '../../../../models/publications/publication-dto-model';
import { MatDialog } from '@angular/material/dialog';
import { PublicationErrorsModalComponent } from './errors-modal/publication-errors-modal.component';
import { Supplier } from '../../../../models/supplier-model';
import { SupplierTooltipComponent } from './custom-tooltips/supplier-tooltip.component';
import { CatalogRecapModalComponent } from './catalog-recap-modal/catalog-recap-modal.component';
import { PriceRangeTooltipComponent } from './custom-tooltips/price-range-tooltip.component';
import { PublicationEditButtonComponent } from './publication-edit-button/publication-edit-button.component';
import { PublicationPriceDetailsModalComponent } from './publication-price-details-modal/publication-price-details-modal.component';
import { StatusTooltipComponent } from './custom-tooltips/status-tooltip.component';

@Component({
  selector: 'app-publications-list',
  templateUrl: './publications-list.component.html',
  styleUrls: ['./publications-list.component.scss'],
})
export class PublicationsListComponent implements OnInit {
  @Input() searchBy: string;
  @Input() selectedStoreCode: string;
  @Input() selectedBarcode: string;
  @Input() refreshPublications: Subject<PublicationFilters>;
  @Input() switchViewType: Subject<PublicationViewType>;

  dataSource: PublicationsDatasource;

  selectedViewType: PublicationViewType = PublicationViewType.PUBLICATION;

  suppliers: Supplier[];

  gridOptions = {
    enableBrowserTooltips: false,
  };

  frameworkComponents = {
    pendingTooltip: PendingTooltipComponent,
    supplierTooltip: SupplierTooltipComponent,
    priceRangeTooltip: PriceRangeTooltipComponent,
    editButtonComponent: PublicationEditButtonComponent,
    disableReasonTooltip: StatusTooltipComponent,
  };

  defaultColDef = {
    sortable: true,
    resizable: true,
    filter: false,
    filterParams: {
      suppressAndOrCondition: true,
      filterOptions: ['equals'],
    },
  };

  rowModelType = 'infinite';
  paginationPageSize = 15;
  infiniteInitialRowCount = 1;

  columnDefs: any[] = [];

  private gridApi: GridApi;

  constructor(
    private publicationService: PublicationService,
    private columnDefinitionsService: ColumnDefinitionsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.dataSource = new PublicationsDatasource(this.publicationService, this.searchBy === 'store');

    this.defaultColDef.sortable = this.dataSource.isByStore;
    if (this.dataSource.isByStore) {
      this.dataSource.selectedStoreCode = this.selectedStoreCode;
      this.dataSource.selectedBarcode = undefined;
      this.paginationPageSize = 15;
      localStorage.setItem('paginationPageSize', String(this.paginationPageSize));
    } else {
      this.dataSource.selectedStoreCode = undefined;
      this.dataSource.selectedBarcode = this.selectedBarcode;
      // In this case, all publications are returned at once. I couldn't find a better way to stop ag-grid from doing the calls for the next pages.
      // Note that these results will always be limited by the total number of PublicationRules, so 1000 should be pretty fine.
      this.paginationPageSize = 1000;
      localStorage.setItem('paginationPageSize', String(this.paginationPageSize));
    }

    this.refreshColumns();

    if (this.refreshPublications) {
      this.refreshPublications.subscribe((filters) => {
        if (this.dataSource.isByStore && !isNilty(filters.storeCode)) {
          this.selectedStoreCode = filters.storeCode;
          this.dataSource.selectedStoreCode = this.selectedStoreCode;
        } else if (!this.dataSource.isByStore && !isNilty(filters.barcode)) {
          this.selectedBarcode = filters.barcode;
          this.dataSource.selectedBarcode = this.selectedBarcode;
        }
        this.dataSource.filters = filters;
        if (this.gridApi) {
          this.gridApi.onFilterChanged();
        }
      });
    }

    if (this.switchViewType) {
      this.switchViewType.subscribe((vt) => {
        this.selectedViewType = vt;
        this.refreshColumns();
      });
    }
  }

  refreshColumns() {
    this.columnDefs = this.columnDefinitionsService.getColumns(this.dataSource.isByStore, this.selectedViewType);
    this.onFirstDataRendered({});

    if (this.gridApi) {
      this.gridApi.refreshCells();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.setDatasource(this.dataSource);
    this.gridApi.setHeaderHeight(56);
    this.gridApi.setGroupHeaderHeight(30);
    this.gridApi.sizeColumnsToFit();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFirstDataRendered(_) {
    setTimeout(() => {
      if (this.gridApi !== undefined) {
        this.gridApi.sizeColumnsToFit();
      }
    }, 0);
  }

  cellClicked(event: CellEvent) {
    if (event?.colDef?.colId === 'statusColumn') {
      this.openStatusDetails(event.data as PublicationDto);
    }
    if (event?.colDef?.colId === 'supplierColumn') {
      this.openCatalogItemRecap(event.data as PublicationDto);
    }
    if (['publication.newPrice', 'publication.publishedPrice', 'pending.priceToPublish'].indexOf(event?.colDef?.colId) !== -1) {
      this.openPublicationPriceDetails(event);
    }
  }

  private openStatusDetails(p: PublicationDto) {
    if (p.publication?.status === 'ERROR' && p.publication?.errors?.length > 0) {
      this.dialog.open(PublicationErrorsModalComponent, { data: p });
    }
  }

  private openCatalogItemRecap(p: PublicationDto) {
    this.dialog.open(CatalogRecapModalComponent, { data: p });
  }

  private openPublicationPriceDetails(event: CellEvent) {
    this.dialog.open(PublicationPriceDetailsModalComponent, { data: { event } });
  }
}
