import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Price')
export class Price {
  @JsonProperty('amount', Number, true) amount: number = undefined;
  @JsonProperty('currencyCode', String, true) currencyCode: string = undefined;
}

@JsonObject('PublicationBuyBox')
export class PublicationBuyBox {
  @JsonProperty('amount', Number, true) amount: number = undefined;
  @JsonProperty('currencyCode', String, true) currencyCode: string = undefined;
  @JsonProperty('sellerId', String, true) sellerId: string = undefined;
  @JsonProperty('sellerName', String, true) sellerName: string = undefined;
}

@JsonObject('CompetitionData')
export class CompetitionData {
  @JsonProperty('timeOfOfferChange', String, true) timeOfOfferChange: string = undefined;
  @JsonProperty('buyBoxPrice', Price, true) buyBoxPrice: Price = undefined;
  @JsonProperty('fbaLowestLandedPrice', Price, true) fbaLowestLandedPrice: Price = undefined;
  @JsonProperty('fbaLowestListingPrice', Price, true) fbaLowestListingPrice: Price = undefined;
  @JsonProperty('fbaLowestShipping', Price, true) fbaLowestShipping: Price = undefined;
  @JsonProperty('fbmLowestLandedPrice', Price, true) fbmLowestLandedPrice: Price = undefined;
  @JsonProperty('fbmLowestListingPrice', Price, true) fbmLowestListingPrice: Price = undefined;
  @JsonProperty('fbmLowestShipping', Price, true) fbmLowestShipping: Price = undefined;
  @JsonProperty('fbaAveragePrice', Price, true) fbaAveragePrice: Price = undefined;
  @JsonProperty('fbmAveragePrice', Price, true) fbmAveragePrice: Price = undefined;
  @JsonProperty('offerCount', Number, true) offerCount: number = undefined;
  @JsonProperty('buyBoxEligibleOffers', Number, true) buyBoxEligibleOffers: number = undefined;
  @JsonProperty('buyBoxWinner', String, true) buyBoxWinner: string = undefined;
  @JsonProperty('buyBoxWinnerName', String, true) buyBoxWinnerName: string = undefined;
}
