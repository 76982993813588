import { JsonObject, JsonProperty } from 'json2typescript';
import { PublicationRuleStore } from '../configuration/rules/publication-rule/publication-rule-store-model';
import { BaseEntity } from '../shared/base-entity-model';
import { PublicationPrice } from './publication-price-model';
import { MarketplaceMetadata } from './marketplace-metadata-model';
import { FeedHistoryEntry } from './feed-history-entry-model';
import { StatusChangeLogEntry } from './status-changelog-entry-model';
import { PublicationBuyBox } from './competition-data-model';

@JsonObject('PublicationError')
export class PublicationError {
  @JsonProperty('errorCode', String, true) errorCode: string = undefined;
  @JsonProperty('errorDescription', String, true) errorDescription: string = undefined;
  @JsonProperty('timestamp', String, true) timestamp: string = undefined;
}

/* eslint-disable */
export enum PUBLICATION_ERRORS {
  MISSING_PRODUCT_CATEGORY = 'MISSING_PRODUCT_CATEGORY',
  MISSING_STORE_FEES = 'MISSING_STORE_FEES',
  MISSING_MARKETPLACE_CATEGORY_FEE = 'MISSING_MARKETPLACE_CATEGORY_FEE',
  MISSING_LOGISTIC_PARTNER = 'MISSING_LOGISTIC_PARTNER',
}
/* eslint-enable */

@JsonObject('Publication')
export class Publication extends BaseEntity {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('barcode', String, true) barcode: string = undefined;
  @JsonProperty('store', PublicationRuleStore, true) store: PublicationRuleStore = undefined;
  @JsonProperty('supplierId', Number, true) supplierId: number = undefined;
  @JsonProperty('supplierCode', String, true) supplierCode: string = undefined;
  @JsonProperty('stockLocationId', Number, true) stockLocationId: number = undefined;
  @JsonProperty('stockLocationCode', String, true) stockLocationCode: string = undefined;
  @JsonProperty('newQuantity', Number, true) newQuantity: number = undefined;
  @JsonProperty('newPrice', PublicationPrice, true) newPrice: PublicationPrice = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('marketplaceStatus', String, true) marketplaceStatus: string = undefined;
  @JsonProperty('publishedQuantity', Number, true) publishedQuantity: number = undefined;
  @JsonProperty('publishedPrice', PublicationPrice, true) publishedPrice: PublicationPrice = undefined;
  @JsonProperty('errors', [PublicationError], true) errors: PublicationError[] = undefined;
  @JsonProperty('hasBeenCreatedOnMarketplace', Boolean, true) hasBeenCreatedOnMarketplace: boolean = undefined;
  @JsonProperty('category', String, true) category: string = undefined;
  @JsonProperty('brand', String, true) brand: string = undefined;
  @JsonProperty('title', String, true) title: string = undefined;

  @JsonProperty('disableReason', String, true) disableReason: string = undefined;

  @JsonProperty('marketplaceMetadata', MarketplaceMetadata, true) marketplaceMetadata: MarketplaceMetadata = undefined;

  @JsonProperty('isBuyBoxWinner', Boolean, true) isBuyBoxWinner: boolean = undefined;
  @JsonProperty('buyBoxPrice', PublicationBuyBox, true) buyBoxPrice: PublicationBuyBox = undefined;
}

@JsonObject('PublicationHistory')
export class PublicationHistory extends BaseEntity {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('publicationId', String, true) publicationId: string = undefined;
  @JsonProperty('feedHistory', [FeedHistoryEntry], true) feedHistory: FeedHistoryEntry[] = undefined;
  @JsonProperty('statusChangeLog', [StatusChangeLogEntry], true) statusChangeLog: StatusChangeLogEntry[] = undefined;
}
