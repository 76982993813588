import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetadataDocuments, ProductMetadataWithAllMetadata } from '@models/product-metadata-model';
import { ProductsService } from '../../products.service';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {
  barcode: string;
  product: ProductMetadataWithAllMetadata;

  openedImageSrc: string;
  allImages: string[] = [];

  documents: MetadataDocuments[];
  allDocumentsCount = 0;

  detailsLeft = {
    title: 'Title',
    authors: 'Authors',
    publisher: 'Publisher',
    brand: 'Brand',
    categoryTree: 'Category Tree',
    category: 'Category',
    description: 'Description',
    duty: 'Duty',
  };

  detailsRight = {
    exportHsCode: 'Export HS Code',
    heightCm: 'Height (cm)',
    importHsCode: 'Import HS Code',
    retailPrice: 'Retail Price',
    thicknessCm: 'Thickness (cm)',
    vat: 'VAT',
    weightKg: 'Weight (kg)',
    wholesalePrice: 'Wholesale Price',
    widthCm: 'Width (cm)',
  };

  constructor(private service: ProductsService, private route: ActivatedRoute, private location: Location) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.barcode = params['barcode'];

      this.service.getProductMetadataDetails(this.barcode).subscribe((resp: ProductMetadataWithAllMetadata) => {
        this.product = resp;
        const additionalImages = isNilty(this.product.postgresMetadataDto?.additionalImagesUrls)
          ? []
          : this.product.postgresMetadataDto?.additionalImagesUrls;
        this.allImages = [this.product.postgresMetadataDto?.mainImageUrl, ...additionalImages];

        this.documents = this.product?.postgresMetadataDto?.documents as MetadataDocuments[];
        this.documents?.forEach((it) => (this.allDocumentsCount += it.documents.length));
      });
    });
  }

  downloadFile(url: string) {
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.click();
  }

  viewImage(url: string) {
    this.openedImageSrc = url;
  }

  goBack() {
    this.location.back();
  }
}
