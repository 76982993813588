import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isNilty } from '@core/utils.service';
import { ZionStore } from '@models/publications/published-store-model';
import { Supplier } from '@models/supplier-model';
import * as moment from 'moment';
import { FeesConfigurationService } from '../../configuration/fees-configuration/fees-configuration.service';
import { SuppliersService } from '../../suppliers/suppliers.service';
import { PublicationService } from '../publication.service';

@Component({
  selector: 'app-publication-analytics',
  templateUrl: './publication-analytics.component.html',
  styleUrls: ['./publication-analytics.component.css'],
})
export class PublicationAnalyticsComponent implements OnInit {
  @Input() type: string;

  suppliers: Supplier[];
  stores: ZionStore[];

  paForm: FormGroup;

  pieChartData = undefined;
  lineChartData = [];
  barChartData = [];
  pieChartDetail = [];

  singleDateToggle = false;
  singleDate: boolean;

  constructor(
    private publicationService: PublicationService,
    private suppliersService: SuppliersService,
    private storeFeesService: FeesConfigurationService
  ) {}

  ngOnInit() {
    const today = new Date();
    const sixtyDaysAgo = new Date();
    sixtyDaysAgo.setDate(today.getDate() - 60);

    this.paForm = new FormGroup({
      supplier: new FormControl(),
      store: new FormControl('', Validators.required),
      range: new FormGroup({
        start: new FormControl<Date | null>(sixtyDaysAgo, Validators.required),
        end: new FormControl<Date | null>(today, Validators.required),
      }),
    });
    this.suppliersService.getAllSuppliers().subscribe((r: Supplier[]) => (this.suppliers = r));
    this.storeFeesService.findAllStores().subscribe((it: ZionStore[]) => (this.stores = it));
  }

  showResults() {
    const dateFrom = moment(this.paForm.value.range.start).format('DD/MM/YYYY');
    const dateTo = moment(this.paForm.value.range.end).format('DD/MM/YYYY');
    const supplierCodes = isNilty(this.paForm.value.supplier)
      ? undefined
      : typeof this.paForm.value.supplier === 'string'
      ? [this.paForm.value.supplier]
      : this.paForm.value.supplier;

    this.publicationService.getMetrics(supplierCodes, this.paForm.value.store, dateFrom, dateTo).subscribe((r: any[]) => {
      this.paForm.controls.supplier.setValue([]);
      this.paForm.controls.supplier.setValue(
        r.filter((it, index, self) => self.findIndex((t) => t.supplierCode === it.supplierCode) === index).map((it) => it.supplierCode)
      );

      this.pieChartData = r.filter((it) => it.date === dateFrom)[0]?.publicationInfo;
      this.lineChartData = r;
      this.barChartData = r;
    });
  }

  getDetail(data: any) {
    const height = window.innerHeight;
    this.pieChartDetail = data;
    setTimeout(() => {
      window.scrollTo({
        top: height,
        behavior: 'smooth',
      });
    }, 500);
  }
}
