import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PublicationRule } from '@models/configuration/rules/publication-rule/publication-rule-model';
import { PublicationRulesService } from '../publication-rules/publication-rules.service';
import { GenericErrorModalComponent } from '@shared/generic-error-modal/generic-error-modal.component';
import {
  RuleStockLocationSource,
  RuleSupplierSource,
  SelectableStockLocationSource,
  SelectableSupplierSource,
} from '@models/configuration/rules/publication-rule-source-model';
import { MarkusRule } from '@models/configuration/rules/markus-rule-model';
import { PowerSellerRulesService } from '../power-seller-rules/power-seller-rules.service';
import { Observable } from 'rxjs';
import { PowerSellerRule } from '@models/configuration/rules/power-seller-rule/power-seller-rule-model';

@Component({
  selector: 'app-rule-refresh-trigger-modal',
  templateUrl: './rule-refresh-trigger-modal.component.html',
  styleUrls: ['./rule-refresh-trigger-modal.component.css'],
})
export class RuleRefreshTriggerModalComponent implements OnInit {
  refreshType: 'FULL' | 'PARTIAL' = null;

  suppliers: SelectableSupplierSource[];
  stockLocations: SelectableStockLocationSource[];

  constructor(
    public dialogRef: MatDialogRef<RuleRefreshTriggerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MarkusRule,
    private publicationRulesService: PublicationRulesService,
    private powerSellerRuleService: PowerSellerRulesService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.suppliers = this.data.supplierSources
      .map((it) => ({ selected: false, ...it }))
      .sort((a, b) => (a.supplierCode > b.supplierCode ? 1 : -1));

    this.stockLocations = this.data.stockLocationSources
      .map((it) => ({ selected: false, ...it }))
      .sort((a, b) => (a.stockLocationCode > b.stockLocationCode ? 1 : -1));
  }

  confirm() {
    let observable: Observable<any>;
    if (this.refreshType === 'FULL') {
      observable = this.fullRefresh(this.data);
    } else if (this.refreshType === 'PARTIAL') {
      const selectedSuppliers = this.suppliers.filter((it) => it.selected);
      const selectedStockLocations = this.stockLocations.filter((it) => it.selected);

      if (selectedSuppliers.length === 0 && selectedStockLocations.length === 0) {
        this.dialog.open(GenericErrorModalComponent, { data: 'You must select at least one source to Refresh.' });
        return;
      }

      observable = this.partialRefresh(this.data, selectedSuppliers, selectedStockLocations);
    } else if (this.refreshType === 'BRUTE-FULL') {
      observable = this.fullBruteRefresh(this.data);
    } else if (this.refreshType === 'BRUTE-PARTIAL') {
      const selectedSuppliers = this.suppliers.filter((it) => it.selected);
      const selectedStockLocations = this.stockLocations.filter((it) => it.selected);

      if (selectedSuppliers.length === 0 && selectedStockLocations.length === 0) {
        this.dialog.open(GenericErrorModalComponent, { data: 'You must select at least one source to Refresh.' });
        return;
      }

      observable = this.partialBruteRefresh(this.data, selectedSuppliers, selectedStockLocations);
    } else {
      this.dialogRef.close();
      return;
    }

    observable.subscribe(() => this.dialogRef.close());
  }

  private fullRefresh(rule: MarkusRule): Observable<any> {
    console.log(rule);
    if (rule instanceof PublicationRule) {
      console.log('store');
      return this.publicationRulesService.refreshRule(this.data as PublicationRule);
    }
    if (rule instanceof PowerSellerRule) {
      console.log('powerSeller');
      return this.powerSellerRuleService.refreshRule(this.data as PowerSellerRule);
    }
  }

  private partialRefresh(
    rule: MarkusRule,
    selectedSuppliers: RuleSupplierSource[],
    selectedStockLocations: RuleStockLocationSource[]
  ): Observable<any> {
    if (rule instanceof PublicationRule) {
      return this.publicationRulesService.partiallyRefreshRule(this.data as PublicationRule, selectedSuppliers, selectedStockLocations);
    }
    if (rule instanceof PowerSellerRule) {
      return this.powerSellerRuleService.partiallyRefreshRule(this.data as PowerSellerRule, selectedSuppliers, selectedStockLocations);
    }
  }

  private fullBruteRefresh(rule: MarkusRule): Observable<any> {
    console.log(rule);
    console.log('store');
    return this.publicationRulesService.fullBruteRefresh(this.data as PublicationRule);
  }

  private partialBruteRefresh(
    rule: MarkusRule,
    selectedSuppliers: RuleSupplierSource[],
    selectedStockLocations: RuleStockLocationSource[]
  ): Observable<any> {
    return this.publicationRulesService.partialBruteRefresh(this.data as PublicationRule, selectedSuppliers, selectedStockLocations);
  }
}
