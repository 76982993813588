import { Component } from '@angular/core';
import { SoupConfigurationService } from './soup-configuration.service';

@Component({
  selector: 'app-soup-configuration',
  templateUrl: './soup-configuration.component.html',
  styleUrls: ['./soup-configuration.component.css'],
})
export class SoupConfigurationComponent {
  constructor(private supplierFeedsService: SoupConfigurationService) {}
}
