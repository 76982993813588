import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datePipe',
})
export class DatePipe implements PipeTransform {
  transform(value: string, conversionType: string): string {
    const parsedDate = new Date(Date.parse(value));
    switch (conversionType) {
      case 'date':
        return parsedDate.getDate().toString();
      case 'month':
        return parsedDate.toLocaleString('eng', { month: 'short' });
      case 'hour':
        return `${parsedDate.getHours()}:${parsedDate.getMinutes()}`;
      default:
        return `${parsedDate.toDateString()} - ${parsedDate.getHours()}:${parsedDate.getMinutes()}`;
    }
  }
}
