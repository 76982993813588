import { Component, OnInit } from '@angular/core';
import { isNilty } from '@core/utils.service';
import { ActivatedRoute } from '@angular/router';
import { PowerSellerRule } from '@models/configuration/rules/power-seller-rule/power-seller-rule-model';
import { PowerSellerRulesService } from '../power-seller-rules.service';
import { Location } from '@angular/common';
import { RuleRefreshTriggerModalComponent } from '../../rule-refresh-trigger-modal/rule-refresh-trigger-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-power-seller-rule-edit',
  templateUrl: './power-seller-rule-edit.component.html',
  styleUrls: ['./power-seller-rule-edit.component.scss'],
})
export class PowerSellerRuleEditComponent implements OnInit {
  editMode = true;
  currentRuleId: string = undefined;
  currentRule: PowerSellerRule;

  constructor(
    private route: ActivatedRoute,
    private powerSellerRuleService: PowerSellerRulesService,
    private location: Location,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (isNilty(params['id'])) {
        this.editMode = false;
        this.currentRule = new PowerSellerRule();
      } else {
        this.editMode = true;
        this.currentRuleId = params['id'];
        this.getRule();
      }
    });
  }

  save() {
    this.powerSellerRuleService.savePowerSellerRule(this.currentRule).subscribe(() => {
      this.dialog
        .open(RuleRefreshTriggerModalComponent, {
          data: this.currentRule,
          disableClose: true,
        })
        .afterClosed()
        .subscribe(() => {
          this.back();
        });
    });
  }

  back() {
    this.location.back();
  }

  private getRule() {
    this.powerSellerRuleService.findPowerSellerRule(this.currentRuleId).subscribe((r) => (this.currentRule = r));
  }
}
