import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SuppliersService } from '../../../suppliers/suppliers.service';
import { Supplier } from '@models/supplier-model';
import { ProductsService } from '../../products.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GenericErrorModalComponent } from '@shared/generic-error-modal/generic-error-modal.component';
import { HelpModalComponent } from '@shared/help-modal/help-modal.component';

@Component({
  selector: 'app-import-product-images-modal',
  template: `
    <h1 mat-dialog-title>Import Product Images and Documents</h1>
    <div class="dialog-container">
      <div mat-dialog-content class="padding-bottom-20 padding-top-20">
        <div class="row">
          <div class="col padding-top-6">
            <button (click)="uploadFile()" class="btn-custom"><i class="fa fa-file margin-right-5"></i>Choose file</button>
            <button class="btn-custom smaller-icon-button margin-left-10" matTooltip="Instructions" (click)="showInstructions()">
              <i class="fa fa-question"></i>
            </button>
            <input #fileButton type="file" style="display: none" (change)="fileChanged($event)" />
          </div>

          <div class="col">
            <app-brandon-autocomplete
              label="Supplier*"
              [allData]="suppliers"
              filterKey="description"
              displayKey="description"
              valueKey="code"
              [(value)]="selectedSupplierCode"
            ></app-brandon-autocomplete>
          </div>

          <div class="row">
            <div class="col">
              <input class="file-name" disabled [value]="zipFile?.name" />
            </div>
          </div>
        </div>
      </div>
      <div class="row padding-top-20">
        <div class="col text-end">
          <button (click)="dialogRef.close()" class="btn-custom margin-right-10">Cancel</button>
          <button (click)="importFile()" class="btn-custom" [disabled]="!zipFile || !selectedSupplierCode">Confirm</button>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .dialog-container {
        width: 650px !important;
        height: fit-content !important;
        padding-bottom: 15px;
        padding-top: 15px;
      }

      mat-form-field {
        min-width: 90%;
      }
      .file-name {
        font-size: 16px;
        font-family: 'Titillium Web', sans-serif;
        font-weight: bold;
        width: 100%;
        padding: 8px;
      }
    `,
  ],
})
export class ImportProductImagesAndDocumentsModalComponent implements OnInit {
  @ViewChild('fileButton', { static: false }) fileButton: ElementRef;
  zipFile: File;

  suppliers: Supplier[] = [];
  selectedSupplierCode: string;

  fileTypes: { identifierName: string; allowedExtensions: string[]; requireLanguage: boolean }[] = [];

  constructor(
    public dialogRef: MatDialogRef<ImportProductImagesAndDocumentsModalComponent>,
    private dialog: MatDialog,
    private supplierService: SuppliersService,
    private productsService: ProductsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.supplierService.getAllSuppliers().subscribe((it) => (this.suppliers = it));
    this.productsService.getAvailableFileTypes().subscribe((r) => (this.fileTypes = r));
  }

  uploadFile() {
    this.fileButton.nativeElement.click();
  }

  fileChanged(event) {
    this.zipFile = event.target.files[0];
    if (!this.zipFile.name.endsWith('.zip')) {
      this.dialog.open(GenericErrorModalComponent, { data: 'You can only upload .zip files!' });
      this.zipFile = undefined;
    }
  }

  importFile() {
    this.productsService.importProductFilesZip(this.selectedSupplierCode, this.zipFile).subscribe(() => {
      this.snackBar.open('File uploaded.', 'CLOSE')._dismissAfter(2000);
      this.dialogRef.close();
    });
  }

  showInstructions() {
    this.dialog.open(HelpModalComponent, {
      data: {
        title: 'Product Images and documents ZIP Import',
        html: `
          The file must be a ZIP file containing images and documents for a supplier's products.
          <b>The fileName must always start with a valid EAN code, otherwise the file will be ignored.</b> \n

          The file names must have a specific format to be recognized.\n

          <b>FOR IMAGES:</b>
          Images can be .jpg or .png.
          <b>- MAIN IMAGE</b>: The file name must contain only the EAN or the EAN followed by "-MAIN" or ".MAIN". Examples: "0123456789123.jpg", "0123456789123-MAIN.jpg", "0123456789123.MAIN.jpg"
          <b>- ADDITIONAL IMAGE</b>: The file name must contain the EAN followed by a segment with some additional string. Examples: "0123456789123.IMG1.jpg", "0123456789123-NEW.jpg"

          <b>FOR DOCUMENTS:</b>
          Documents must be in .pdf format and require to specify a language. The file name must contain three segments, separated by a dash or a dot.
          <b>- EAN</b>: The file name must start with a valid EAN
          <b>- FILE TYPE</b>: Following the EAN, there should be the separator followed the fileType identifier, for example "-SAFETY_DATA_SHEET" or ".ENERGY_LABEL" (see below for allowed values)
          <b>- LANGUAGE</b>: Lastly, the file name must end with the same separator and a valid ISO2 language code ("-IT", ".EN", "-DE")\n\n
          Allowed File Types Identifiers:\n
          ${this.fileTypes.map((t) => t.identifierName).join(', ')}
          \n
          Valid fileNames examples: "0123456789123.SAFETY_DATA_SHEET.IT.pdf", "0123456789123-SAFETY_DATA_SHEET-IT.pdf", "0123456789123-ENERGY_LABEL-DE.pdf"
          `,
      },
    });
  }
}
