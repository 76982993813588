import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RolesService } from '../roles.service';

@Component({
  selector: 'app-roles-duplicate-modal',
  templateUrl: './roles-duplicate-modal.component.html',
  styleUrls: ['./roles-duplicate-modal.component.css'],
})
export class RolesDuplicateModalComponent implements OnInit {
  applications: string[] = [];
  applicationsFiltered: string[] = [];
  fromApp: string;
  rolesForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<RolesDuplicateModalComponent>,
    private rolesService: RolesService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.rolesForm = new FormGroup({
      from: new FormControl('', Validators.required),
      to: new FormControl('', Validators.required),
    });
    this.rolesService.getApplications().subscribe((applications: string[]) => {
      this.applications = applications;
    });
  }

  onSelect(fromApp) {
    this.applicationsFiltered = this.applications.filter((a) => a !== fromApp);
  }

  onSubmit() {
    const toApp = this.rolesForm.controls.to.value;
    this.rolesService.duplicateRoles(this.fromApp, toApp).subscribe(() => {
      this.snackbar.open('Roles successfully duplicated.', 'CLOSE')._dismissAfter(3000);
      this.dialogRef.close(true);
    });
  }

  close() {
    this.dialogRef.close(false);
  }
}
