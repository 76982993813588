import { Component, OnInit } from '@angular/core';
import { PublicationsJobsService } from './publications-jobs.service';
import { PublicationsJobs } from '../../../../models/publications-jobs-model';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-publications-jobs',
  templateUrl: './publications-jobs.component.html',
  styleUrls: ['./publications-jobs.component.scss'],
})
export class PublicationsJobsComponent implements OnInit {
  dataSource: PublicationsJobs[];
  panelOpenState = false;

  constructor(private publicationsJobsService: PublicationsJobsService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.getRunningPublicationsEndpoints();
  }

  getRunningPublicationsEndpoints() {
    this.publicationsJobsService.getPublicationsJobsEndpoints().subscribe((resp) => {
      this.dataSource = resp.sort((a, b) => +new Date(b.startTime) - +new Date(a.startTime));
    });
  }
}
