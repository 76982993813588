import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AccountConnection } from 'src/app/models/configuration/sales-order-poll-configuration/account-connection-model';
import { SalesOrderPollConfiguration } from 'src/app/models/configuration/sales-order-poll-configuration/sales-order.poll-configuration';
import { isNilty } from '@core/utils.service';
import { AccountSelectModalComponent } from '../account-select-modal/account-select-modal.component';
import { SalesOrderPollConfigurationService } from '../sales-order-poll-configuration.service';

@Component({
  selector: 'app-sales-order-poll-configuration-edit',
  templateUrl: './sales-order-poll-configuration-edit.component.html',
  styleUrls: ['./sales-order-poll-configuration-edit.component.css'],
})
export class SalesOrderPollConfigurationEditComponent implements OnInit, OnDestroy {
  editMode: boolean;
  currentId: string;
  currentSalesOrderPollConfiguration: SalesOrderPollConfiguration = new SalesOrderPollConfiguration();
  destinations: string[];

  constructor(
    private salesOrderPollConfigurationService: SalesOrderPollConfigurationService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private location: Location
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (isNilty(params['id'])) {
        this.editMode = false;
        this.currentSalesOrderPollConfiguration = new SalesOrderPollConfiguration();
      } else {
        this.editMode = true;
        this.currentId = params['id'];
        this.getConfiguration();
      }
    });

    this.salesOrderPollConfigurationService.getDestinations().subscribe((destinations) => (this.destinations = destinations));
  }

  getConfiguration() {
    this.salesOrderPollConfigurationService
      .findSalesOrderPollConfigurationById(this.currentId)
      .subscribe((it) => (this.currentSalesOrderPollConfiguration = it));
  }

  save() {
    this.salesOrderPollConfigurationService
      .saveSalesOrderPollConfiguration(this.currentSalesOrderPollConfiguration)
      .subscribe(() => this.back());
  }

  back() {
    this.location.back();
  }

  ngOnDestroy() {
    this.salesOrderPollConfigurationService.rulePredicateFieldsCache.reset();
  }

  selectAccount() {
    this.dialog
      .open(AccountSelectModalComponent, { data: this.currentSalesOrderPollConfiguration.account })
      .afterClosed()
      .subscribe((s: AccountConnection) => {
        if (!isNilty(s)) {
          this.currentSalesOrderPollConfiguration.account = s;
          this.currentSalesOrderPollConfiguration.account = s;
          if (s.marketplaceConnectionType === 'VERTICAL') {
            this.currentSalesOrderPollConfiguration.destination = null;
          }
        }
      });
  }
}
