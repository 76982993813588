import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { Publication, PublicationError } from '../../../../../models/publications/publication-model';
import { isNilty } from '../../../../../core/utils.service';

@Component({
  selector: 'app-pending-tooltip-component',
  template: `
    <div class="custom-tooltip" *ngIf="showTooltip">
      <ng-container *ngIf="tooltipType === 'disableReason'">
        <p>{{ reason }}</p>
      </ng-container>

      <ng-container *ngIf="tooltipType === 'error'">
        <p><b>Number of errors: </b> {{ errors.length }} (click for details)</p>
        <p><b>Last Error</b></p>
        <div class="indented">
          <p><b>Code: </b>{{ last?.errorCode }}</p>
          <p class="error-description"><b>Description: </b>{{ last?.errorDescription }}</p>
          <p><b>Date: </b>{{ last?.timestamp }}</p>
        </div>
      </ng-container>
    </div>
  `,
  styles: [
    `
      :host {
        font-family: 'Titillium Web', sans-serif;
        position: absolute;
        width: fit-content;
        max-width: 500px;
        height: 70px;
        pointer-events: none;
        transition: opacity 0.6s;
      }
      p {
        overflow-wrap: break-word;
        white-space: pre-wrap;
      }
      :host.ag-tooltip-hiding {
        opacity: 0;
      }
      .indented {
        padding-left: 15px;
      }
      .error-description {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    `,
  ],
})
export class StatusTooltipComponent implements ITooltipAngularComp {
  showTooltip = false;
  tooltipType: 'disableReason' | 'error' = null;
  reason: string;
  errors: PublicationError[];
  last: PublicationError;

  private showDisableReason = ['BLACKLIST_PENDING', 'BLACKLISTED', 'DISABLE_PENDING', 'DISABLED'];
  private errorStatus = 'ERROR';

  agInit(params): void {
    const publication = params.value as Publication;
    if (this.showDisableReason.indexOf(publication.status) !== -1 && !isNilty(publication.disableReason)) {
      this.tooltipType = 'disableReason';
      this.reason = publication?.disableReason;
      this.showTooltip = true;
    } else if (publication.status === this.errorStatus) {
      this.tooltipType = 'error';

      this.errors = publication.errors;
      this.last = this.errors[this.errors.length - 1];

      this.showTooltip = true;
    }
  }
}
