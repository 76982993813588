import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { isNilty } from '@core/utils.service';
import { CatalogItem } from '@models/catalog-item-model';
import { CatalogItemFilters } from '@models/filters/catalog-item';
import { CatalogItemsService } from '../catalog-items.service';
import { MatDialog } from '@angular/material/dialog';
import { PricesModalComponent } from './price-modal/prices-modal.component';

@Component({
  selector: 'app-catalog-items-lists',
  templateUrl: './catalog-items-lists.component.html',
  styleUrls: ['./catalog-items-lists.component.scss'],
})
export class CatalogItemsListsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('catalogFile', { static: false }) catalogFile: ElementRef;
  currentFileUpload: File;

  resultsNumber: Observable<number>;
  dataSource = new MatTableDataSource<CatalogItem>([]);
  displayedColumns = [
    'productBarcodeValue',
    'productBarcodeType',
    'title',
    'supplier',
    'publisher',
    'sku',
    'internalSku',
    'status',
    'physicalQuantity',
    'availableQuantity',
    'prices',
    'purchasePrice',
    'discountedPrice',
    'discountPercentage',
    'discountCode',
    'vat',
    'availabilityCode',
    'countryOfOrigin',
    'updatedAt',
    'openStockItems',
  ];

  constructor(
    private catalogItemsService: CatalogItemsService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (isNilty(this.catalogItemsService.catalogItemFilters)) {
      this.catalogItemsService.catalogItemFilters = new CatalogItemFilters();
    } else {
      this.catalogItemsService.catalogItemFilters.pageSize = 10;
      this.catalogItemsService.catalogItemFilters.pageNumber = 0;
    }

    this.route.queryParams.subscribe((params) => {
      if (params['supplier']) {
        this.catalogItemsService.catalogItemFilters = { supplier: params['supplier'] } as CatalogItemFilters;
      }
      if (params['ean']) {
        this.catalogItemsService.catalogItemFilters = { productBarcodeValue: params['ean'] } as CatalogItemFilters;
      }
    });

    this.resultsNumber = this.catalogItemsService.resultsNumber;
    this.loadResults();
  }

  loadResults() {
    this.catalogItemsService.getFilteredCatalogItems(this.catalogItemsService.catalogItemFilters).subscribe((resp: CatalogItem[]) => {
      this.dataSource.data = resp;
    });
  }

  loadPage() {
    this.catalogItemsService.catalogItemFilters.pageSize = this.paginator.pageSize;
    this.catalogItemsService.catalogItemFilters.pageNumber = this.paginator.pageIndex;
    this.loadResults();
  }

  onSort() {
    this.catalogItemsService.catalogItemFilters.sortBy = this.sort.active;
    this.catalogItemsService.catalogItemFilters.sortDirection = this.sort.direction;
    this.loadResults();
  }

  onFilter() {
    this.paginator.pageIndex = 0;
    this.loadResults();
  }

  showDetails(row: CatalogItem) {
    this.router.navigate(['catalog-items', 'details', row.id]);
  }

  openStockItems(catalogItem: CatalogItem) {
    this.router.navigate(['stock-items'], { queryParams: { ean: catalogItem.productBarcode.value } });
  }

  hiddenQuantity() {
    return !(
      this.catalogItemsService.catalogItemFilters &&
      (!isNilty(this.catalogItemsService.catalogItemFilters.productBarcodeValue) ||
        !isNilty(this.catalogItemsService.catalogItemFilters.productBarcodeType) ||
        !isNilty(this.catalogItemsService.catalogItemFilters.supplier) ||
        !isNilty(this.catalogItemsService.catalogItemFilters.sku) ||
        !isNilty(this.catalogItemsService.catalogItemFilters.status))
    );
  }

  uploadCatalogFile() {
    this.catalogFile.nativeElement.click();
  }

  catalogFileChanged(event) {
    this.currentFileUpload = event.target.files[0];
    this.catalogItemsService.uploadCatalogItems(this.currentFileUpload).subscribe(() => {
      // Do nothing
    });
  }

  openPricesModal(catalogItem: CatalogItem, event: MouseEvent) {
    event.stopPropagation();
    this.dialog.open(PricesModalComponent, {
      width: '600px',
      data: { prices: catalogItem.prices },
    });
  }
}
