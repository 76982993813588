import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonsService } from '@shared/commons.service';
import { NetworkCategory } from '@models/network-category-model';
import { MarketplaceDto } from '@models/configuration/marketplace/marketplace-model';
import { StoreService } from '../../store-configuration/store.service';
import { MarketplaceCategoryFeesFilters } from '@models/configuration/marketplace/filters/category-fees-filters-model';
import { isNilty } from '@core/utils.service';
import { FeesConfigurationService } from '../fees-configuration.service';
import { MatTableDataSource } from '@angular/material/table';
import { MarketplaceCategoryFee } from '@models/configuration/marketplace/marketplace-category-fee-model';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GenericErrorModalComponent } from '@shared/generic-error-modal/generic-error-modal.component';
import { EditMarketplaceCategoryFeeModalComponent } from './edit-marketplace-category-fee-modal/edit-marketplace-category-fee-modal.component';

@Component({
  selector: 'app-marketplace-category-fees-configuration',
  templateUrl: './marketplace-category-fees-configuration.component.html',
  styleUrls: ['./marketplace-category-fees-configuration.component.scss'],
})
export class MarketplaceCategoryFeesConfigurationComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  displayedColumns = ['network', 'marketplace', 'category', 'closingFee', 'percentageFee', 'minimalFee', 'edit'];
  dataSource = new MatTableDataSource<MarketplaceCategoryFee>();
  resultsNumber: Observable<number>;

  networks: string[];

  marketplaces: MarketplaceDto[];
  allMarketplaces: MarketplaceDto[];

  networkCategories: NetworkCategory[];
  allNetworkCategories: NetworkCategory[];

  currentNetwork: string;
  filters = new MarketplaceCategoryFeesFilters();

  constructor(
    private commonsService: CommonsService,
    private storeConfigurationService: StoreService,
    private feesConfigurationService: FeesConfigurationService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.resultsNumber = this.feesConfigurationService.categoryFeesResultsNumber;
    this.commonsService.getNetworkCategories().subscribe((r) => {
      this.networkCategories = [...r];
      this.allNetworkCategories = [...r];
    });
    this.commonsService.getNetworks().subscribe((r) => (this.networks = r));
    this.storeConfigurationService.getMarketplaces().subscribe((r) => {
      this.marketplaces = [...r];
      this.allMarketplaces = [...r];
    });
  }

  selectedNetwork(n: string) {
    if (isNilty(n)) {
      this.marketplaces = [...this.allMarketplaces];
    } else {
      this.marketplaces = [...this.allMarketplaces.filter((it) => it.network === n)];
    }
  }

  selectedMarketplace(mId: number) {
    if (typeof mId !== 'number') return;
    this.currentNetwork = this.allMarketplaces.find((it) => it.id === mId).network;
    this.networkCategories = [...this.allNetworkCategories.filter((it) => it.network === this.currentNetwork)];
  }

  selectedCategory(c: string) {
    if (typeof c !== 'string') return;
    this.currentNetwork = this.allNetworkCategories.find((it) => it.serializedEnum === c).network;
    this.marketplaces = [...this.allMarketplaces.filter((it) => it.network === this.currentNetwork)];
  }

  loadPage() {
    this.filters.pageSize = this.paginator.pageSize;
    this.filters.pageNumber = this.paginator.pageIndex;
    this.search();
  }

  search() {
    if (isNilty(this.filters.marketplaceId) && isNilty(this.filters.networkCategory)) {
      this.dataSource.data = [];
    } else {
      this.feesConfigurationService.findMarketplaceCategoryFees(this.filters).subscribe((r) => (this.dataSource.data = r));
    }
  }

  reset() {
    this.currentNetwork = undefined;
    this.filters = new MarketplaceCategoryFeesFilters();
    this.dataSource.data = [];
  }

  edit(mcf: MarketplaceCategoryFee) {
    this.dialog
      .open(EditMarketplaceCategoryFeeModalComponent, { data: mcf })
      .afterClosed()
      .subscribe((r) => {
        if (r) {
          this.search();
        }
      });
  }

  create() {
    if (isNilty(this.filters.networkCategory) || isNilty(this.filters.marketplaceId)) {
      this.dialog.open(GenericErrorModalComponent, { data: 'Please select a Marketplace and a Category' });
      return;
    }

    this.feesConfigurationService.findMarketplaceCategoryFees(this.filters).subscribe((r) => {
      let mcf: MarketplaceCategoryFee;
      if (r.length === 0) {
        mcf = new MarketplaceCategoryFee();
        mcf.marketplace = this.marketplaces.find((it) => it.id === this.filters.marketplaceId);
        mcf.networkCategory = this.filters.networkCategory;
      } else {
        mcf = r[0];
      }
      this.edit(mcf);
    });
  }
}
