import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Permission')
export class Permission {
  @JsonProperty('id', String, false) id: string = undefined;
  @JsonProperty('name', String, false) name: string = undefined;
  @JsonProperty('application', String, false) application: string = undefined;
}

/* eslint-disable */
export enum Permissions {
  // Zion
  SupplierFeedConfiguration = 'supplier_feed_configuration',
  ZionAdministration = 'administration',

  // Markus
  StoreFeesConfiguration = 'store_fees_configuration',
  BlacklistManagement = 'blacklist_management',
  RestrictionsManagement = 'restrictions_management',
  PublicationRulesManagement = 'publication_rules_management',
  SalesOrdersConfiguration = 'sales_orders_configuration',
  PublicationsView = 'publications_view',
  MarkusAdministration = 'administration',

  // Ponch
  Administration = 'administration',
}
/* eslint-enable */
