import { Component, OnInit } from '@angular/core';
import { StoreService } from '../store.service';
import { AccountView, OwnerView, StoreView, StoreViewFilters } from '@models/configuration/marketplace/store-view-model';
import { StoreGroup } from '@models/configuration/marketplace/store-group-model';
import { MarketplaceDto, SellingPlatform } from '@models/configuration/marketplace/marketplace-model';
import { isNilty } from '@core/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { AddStoreComponent } from '../add-store/add-store.component';
import { Observable } from 'rxjs';
import { Permissions } from '@models/security/permission-model';
import { AuthorizationService } from '@security/authorization.service';
import { AccountConnectionsComponent } from './store-tree.component.ts/account-connections.component';
import { S3AssetsHandler } from '@core/assets-handler';

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.scss'],
})
export class StoreListComponent implements OnInit {
  MAX_SIZE = 12;

  owners: OwnerView[] = [];
  accounts: AccountView[] = [];
  storeGroups: StoreGroup[] = [];
  sellingPlatforms: SellingPlatform[] = [];
  marketplaces: MarketplaceDto[] = [];

  storesToShow: StoreView[] = [];
  hasNext = false;
  totalResults = 0;

  filters = new StoreViewFilters();

  hasAccountConfigurationPermission: Observable<boolean>;

  protected readonly S3AssetsHandler = S3AssetsHandler;

  private allStores: StoreView[] = [];
  private filteredStores: StoreView[] = [];
  private allAccounts: AccountView[] = [];
  private allMarketplaces: MarketplaceDto[] = [];

  constructor(private storeService: StoreService, private dialog: MatDialog, private authorizationService: AuthorizationService) {}

  ngOnInit() {
    this.initData();
    this.hasAccountConfigurationPermission = this.authorizationService.hasMarkusPermission(Permissions.MarkusAdministration);
  }

  selectedOwner(name: string) {
    if (this.filters.owner === name) {
      this.filters.owner = undefined;
    } else {
      this.filters.owner = name;
    }
    this.updateAccounts();
    this.searchStores();
  }

  selectedAccount(accountCode: string) {
    this.filters.accountCode = accountCode;
    this.searchStores();
  }

  selectedSellingPlatform(sellingPlatformName: string) {
    this.filters.sellingPlatform = sellingPlatformName;
    this.updateMarketplaces();
    this.searchStores();
  }

  selectedStoreGroup(storeGroupName: string) {
    this.filters.storeGroup = storeGroupName;
    this.searchStores();
  }

  selectedMarketplace(marketplaceName: string) {
    this.filters.marketplace = marketplaceName;
    this.searchStores();
  }

  searchStores(searchString?: string) {
    if (searchString) {
      this.filters.storeCodeOrName = searchString;
    }

    this.filteredStores = this.filterByOwnerAndAccount(
      this.filterBySellingPlatformAndMarketplace(this.filterByStoreGroup(this.filterByNameOrCode(this.allStores)))
    );

    this.storesToShow = this.filteredStores.slice(0, this.MAX_SIZE);
    this.totalResults = this.filteredStores.length;
    this.hasNext = this.totalResults > this.MAX_SIZE;
  }

  reset() {
    this.filters = new StoreViewFilters();
    this.updateAccounts();
    this.updateMarketplaces();
    this.searchStores();
  }

  createNewStore() {
    this.dialog
      .open(AddStoreComponent)
      .afterClosed()
      .subscribe(() => {
        this.reset();
        this.initData();
      });
  }

  viewConnections() {
    this.dialog.open(AccountConnectionsComponent);
  }

  private updateAccounts() {
    this.filters.accountCode = undefined;
    if (this.filters.owner) {
      this.accounts = [...this.allAccounts.filter((a) => a.owner.name === this.filters.owner)];
    } else {
      this.accounts = [...this.allAccounts];
    }
  }

  private updateMarketplaces() {
    this.filters.marketplace = undefined;
    if (this.filters.sellingPlatform) {
      this.marketplaces = [...this.allMarketplaces.filter((m) => m.sellingPlatform === this.filters.sellingPlatform)];
    } else {
      this.marketplaces = [...this.allMarketplaces];
    }
  }

  private filterByNameOrCode(stores: StoreView[]): StoreView[] {
    if (isNilty(this.filters.storeCodeOrName)) {
      return stores;
    }

    const strToSearch = this.filters.storeCodeOrName.toUpperCase();
    return stores.filter((s) => s.name.toUpperCase().includes(strToSearch) || s.code.includes(strToSearch));
  }

  private filterByStoreGroup(stores: StoreView[]): StoreView[] {
    if (isNilty(this.filters.storeGroup)) {
      return stores;
    }

    return stores.filter((s) => s.storeGroup?.name === this.filters.storeGroup);
  }

  private filterByOwnerAndAccount(stores: StoreView[]): StoreView[] {
    if (this.filters.accountCode) {
      return [...stores.filter((s) => this.filters.accountCode === s.account.code)];
    } else if (this.filters.owner) {
      return [...stores.filter((s) => this.filters.owner === s.account.owner.name)];
    } else {
      return [...stores];
    }
  }

  private filterBySellingPlatformAndMarketplace(stores: StoreView[]): StoreView[] {
    if (this.filters.marketplace) {
      return [...stores.filter((s) => this.filters.marketplace === s.marketplace.name)];
    } else if (this.filters.sellingPlatform) {
      return [...stores.filter((s) => this.filters.sellingPlatform === s.marketplace.sellingPlatform.name)];
    } else {
      return [...stores];
    }
  }

  private initData() {
    this.storeService.findStoresForView().subscribe((r) => {
      this.allStores = r;
      this.filteredStores = [...this.allStores];
      this.searchStores();
    });

    this.storeService.findAllOwners().subscribe((r) => (this.owners = r));
    this.storeService.getAllAccounts().subscribe((r) => {
      this.allAccounts = r;
      this.updateAccounts();
    });
    this.storeService.getMarketplaces().subscribe((r) => {
      this.allMarketplaces = r;
      this.updateMarketplaces();
    });
    this.storeService.getSellingPlatforms().subscribe((r) => (this.sellingPlatforms = r));

    this.storeService.getAllStoreGroups().subscribe((r) => (this.storeGroups = r));
  }
}
