import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { S3AssetsHandler } from '@core/assets-handler';

@Component({
  selector: 'app-owner-icon',
  template: `
    <img
      #ownerIconImage
      [src]="imagePath"
      class="owner-icon"
      [ngClass]="{ 'hide-broken-image': failedImageLoading }"
      [matTooltip]="'Owner: ' + owner"
      [matTooltipHideDelay]="200"
    />

    <div *ngIf="failedImageLoading" [matTooltip]="'Owner: ' + owner" [matTooltipHideDelay]="200" class="owner-div">
      {{ owner.charAt(0).toUpperCase() }}
    </div>
  `,
  styles: [
    `
      .owner-icon {
        height: 30px;
        width: 30px;
        border-radius: 5px;
      }

      .owner-div {
        height: 30px;
        width: 30px;
        border-radius: 5px;
        border: solid 2px #495057;
        font-size: 16px;
        text-align: center;
        padding: 4px;
        font-weight: bold;
      }

      .hide-broken-image {
        display: none;
      }
    `,
  ],
})
export class OwnerIconComponent implements OnInit {
  @ViewChild('ownerIconImage', { static: true }) ownerIconImage: ElementRef;

  @Input() owner: string;
  imagePath: string;

  failedImageLoading = false;

  ngOnInit() {
    this.imagePath = S3AssetsHandler.getOwnerIcon(this.owner);

    this.ownerIconImage.nativeElement.addEventListener(
      'error',
      (event) => {
        if (!event.target) return;
        if (event.target instanceof HTMLImageElement) {
          this.failedImageLoading = true;
        }
      },
      true
    );
  }
}
