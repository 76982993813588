import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-publication-refresh-modal',
  templateUrl: './publication-refresh-modal.component.html',
  styleUrls: ['./publication-refresh-modal.component.css'],
})
export class PublicationRefreshModalComponent {
  constructor(public dialogRef: MatDialogRef<PublicationRefreshModalComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {}
}
