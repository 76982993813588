import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ProductWithTitle } from '@models/product/product-with-title-model';
import { isNilty } from '@core/utils.service';
import { PageEvent } from '@angular/material/paginator';
import { BarcodeRestrictionsFilters } from '@models/configuration/policies/restrictions/barcode-restrictions-filters-model';
import { BarcodeRestriction, RestrictionTarget } from '@models/configuration/policies/restrictions/restrictions-model';
import { RestrictionsService } from '../../restrictions.service';

@Component({
  selector: 'app-barcode-restrictions-list',
  templateUrl: './barcode-restrictions-list.component.html',
  styleUrls: ['./barcode-restrictions-list.component.scss'],
})
export class BarcodeRestrictionsListComponent implements OnInit {
  @Input() targets: RestrictionTarget[];
  @Input() product: ProductWithTitle;
  @Input() refresh: Subject<RestrictionTarget[] | ProductWithTitle>;

  filters: BarcodeRestrictionsFilters;
  allowPageNumber = 0;
  denyPageNumber = 0;

  barcodeAllowedPlatforms: BarcodeRestriction[] = [];
  barcodeDeniedPlatforms: BarcodeRestriction[] = [];

  allowResultsNumber: Subject<number> = new Subject<number>();
  denyResultsNumber: Subject<number> = new Subject<number>();

  constructor(private restrictionsService: RestrictionsService) {}

  ngOnInit(): void {
    this.restrictionsService.barcodeAllowRestrictionsResultNumber.subscribe((r) => this.allowResultsNumber.next(r));
    this.restrictionsService.barcodeDenyRestrictionsResultNumber.subscribe((r) => this.denyResultsNumber.next(r));

    if (!isNilty(this.refresh)) {
      this.refresh.subscribe((f) => {
        if (f instanceof ProductWithTitle) {
          this.product = f;
        } else {
          this.targets = f;
        }
        this.resetPaginators();
        this.search();
      });
    }
    this.search();
  }

  changeAllowPage(event: PageEvent) {
    this.allowPageNumber = event.pageIndex;
    this.search();
  }
  changeDenyPage(event: PageEvent) {
    this.denyPageNumber = event.pageIndex;
    this.search();
  }

  filter() {
    this.resetPaginators();
    this.search();
  }

  clear() {
    this.filters = new BarcodeRestrictionsFilters();
    this.resetPaginators();
    this.search();
  }

  search() {
    if (isNilty(this.filters)) {
      this.filters = new BarcodeRestrictionsFilters();
    }
    if (!isNilty(this.targets)) {
      this.filters.targets = this.targets;
    }
    if (!isNilty(this.product)) {
      this.filters.ean = this.product.barcode;
      this.filters.sku = this.product.sku;
      // TODO add marketplace barcode
    }

    const denyFilters = this.copyFilters();
    denyFilters.pageNumber = this.denyPageNumber;
    this.restrictionsService.findBarcodeDeniedPlatformsByFilters(denyFilters).subscribe((r) => {
      this.barcodeDeniedPlatforms = r;
    });

    const allowFilters = this.copyFilters();
    allowFilters.pageNumber = this.allowPageNumber;
    this.restrictionsService.findBarcodeAllowedPlatformsByFilters(allowFilters).subscribe((r) => {
      this.barcodeAllowedPlatforms = r;
    });
  }

  private resetPaginators() {
    this.allowPageNumber = 0;
    this.denyPageNumber = 0;
  }

  private copyFilters(): BarcodeRestrictionsFilters {
    const copy = new BarcodeRestrictionsFilters();
    copy.ean = this.filters.ean;
    copy.sku = this.filters.sku;
    copy.marketplaceBarcode = this.filters.marketplaceBarcode;
    copy.targets = this.filters.targets;
    copy.pageSize = this.filters.pageSize;
    return copy;
  }
}
