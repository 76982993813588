import { Component, ViewChild } from '@angular/core';
import {
  BarcodeRestriction,
  buildBarcodeRestriction,
  buildPredicatesRestriction,
  buildWordsRestriction,
  PredicatesRestriction,
  Restriction,
  RestrictionTarget,
  WordsRestriction,
} from '@models/configuration/policies/restrictions/restrictions-model';
import { RestrictionTargetType } from '@models/configuration/policies/restrictions/restriction-target-type';
import { PolicyTypes } from '@models/configuration/policies/policy-enums';
import { MatStepper } from '@angular/material/stepper';
import { isNilty } from '@core/utils.service';
import { RulePredicate } from '@models/configuration/rules/rule-predicate-model';
import { ListingSellingPlatform } from '@models/listings/listing-selling-platform-model';
import { RestrictionsService } from '../restrictions.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-insert-restriction',
  templateUrl: './insert-restriction.component.html',
  styleUrls: ['./insert-restriction.component.scss'],
})
export class InsertRestrictionComponent {
  @ViewChild(MatStepper, { static: false }) stepper: MatStepper;

  allowOrDeny: 'ALLOW' | 'DENY' = 'ALLOW';

  restrictionType: 'PRODUCT_RESTRICTION' | 'SALES_STRATEGY' = 'PRODUCT_RESTRICTION';

  selectedTargetType: RestrictionTargetType;
  selectedTargetIdentifier: string;

  restrictionTarget: RestrictionTarget;
  restrictionObject: BarcodeRestriction | WordsRestriction | PredicatesRestriction;

  selectedPolicyType: PolicyTypes;

  restrictionEditCompleted = false;

  notes: string;

  // Barcode Policy Data
  fileOrSingleBarcode: 'FILE' | 'SINGLE' = 'SINGLE';
  barcodeType: string;
  barcodeValue: string;

  // Predicates Policy Data
  predicates: RulePredicate[] = [];

  // Words Policy Data
  wordsField: string;
  words: string[] = [];

  // Selling Platforms
  sps: ListingSellingPlatform[] = [];

  protected readonly PolicyTypes = PolicyTypes;

  constructor(private restrictionsService: RestrictionsService) {}

  next() {
    switch (this.stepper.selected.label) {
      case 'Target and type':
        this.restrictionTarget = new RestrictionTarget(this.selectedTargetType, this.selectedTargetIdentifier);
        break;
      case 'Edit':
        if (this.fileOrSingleBarcode === 'FILE' && this.selectedPolicyType === PolicyTypes.BARCODE) {
          this.buildRestrictionObject();
        }
        break;
      case 'Selling Platforms':
        this.buildRestrictionObject();
        break;
      case 'Recap':
        this.saveRestriction();
        break;
    }
    this.stepper.next();
  }

  private buildRestrictionObject() {
    const wl = new Restriction(this.restrictionTarget, this.notes, this.selectedPolicyType, this.restrictionType);
    switch (this.selectedPolicyType) {
      case PolicyTypes.BARCODE:
        if (this.fileOrSingleBarcode === 'SINGLE') {
          this.restrictionObject = buildBarcodeRestriction(wl, this.barcodeType, this.barcodeValue);
        } else {
          // this.restrictionObject = new ImportBlacklistFile(bl, this.blacklistFile, this.barcodeType);
        }
        break;
      case PolicyTypes.PREDICATES:
        this.restrictionObject = buildPredicatesRestriction(wl, this.predicates);
        break;
      case PolicyTypes.WORDS:
        this.restrictionObject = buildWordsRestriction(wl, this.wordsField, this.words);
        break;
    }
    if (!isNilty(this.sps)) {
      if (this.allowOrDeny === 'ALLOW') {
        this.restrictionObject.setAllowed(this.sps);
      } else {
        this.restrictionObject.setDenied(this.sps);
      }
    }
  }

  private saveRestriction() {
    let obs: Observable<any>;
    switch (this.selectedPolicyType) {
      case PolicyTypes.BARCODE:
        if (this.fileOrSingleBarcode === 'SINGLE') {
          obs = this.restrictionsService.saveBarcodeRestriction(this.restrictionObject as BarcodeRestriction, this.allowOrDeny);
        } else {
          // obs = this.restrictionsService.importBlacklistFile(this.blacklistObject as ImportBlacklistFile);
        }
        break;
      case PolicyTypes.PREDICATES:
        obs = this.restrictionsService.savePredicatesRestrictions(this.restrictionObject as PredicatesRestriction, this.allowOrDeny);
        break;
      case PolicyTypes.WORDS:
        obs = this.restrictionsService.saveWordsRestrictions(this.restrictionObject as WordsRestriction, this.allowOrDeny);
        break;
    }

    if (obs) {
      obs.subscribe(() => this.reset());
    }
  }

  private reset() {
    this.selectedTargetType = undefined;
    this.selectedTargetIdentifier = undefined;
    this.restrictionTarget = undefined;

    this.restrictionEditCompleted = undefined;
    this.notes = undefined;
    this.barcodeType = undefined;
    this.barcodeValue = undefined;
    // this.blacklistFile = undefined;
    this.predicates = [];
    this.wordsField = undefined;
    this.words = [];

    this.sps = [];

    this.restrictionObject = undefined;
    this.stepper.reset();
  }
}
