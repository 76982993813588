import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isFalseOrNullOrUndefined',
})
export class FalseOrUndefinedPipe implements PipeTransform {
  transform(value: any): boolean {
    return value === false || value === null || value === undefined || value === '';
  }
}
