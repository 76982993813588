import { Component, Input, OnInit } from '@angular/core';
import { SoupConfigurationService } from '../../../soup-configuration.service';
import { MatDialog } from '@angular/material/dialog';
import { ItemFilter } from '../../../../../../models/configuration/soup-configuration/item-filter-model';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { isNilty } from 'src/app/core/utils.service';

@Component({
  selector: 'app-item-filters',
  templateUrl: './item-filters.component.html',
  styleUrls: ['./item-filters.component.css'],
})
export class ItemFiltersComponent implements OnInit {
  jsonFields: string[];
  filteredJsonFields: Observable<string[]>;
  jsonFieldsControl = new FormControl<string | string>('');

  itemFilters: ItemFilter[];

  constructor(private soupConfigurationService: SoupConfigurationService, private dialog: MatDialog) {}

  @Input() set _itemFilters(i: ItemFilter[]) {
    this.itemFilters = i;
  }

  ngOnInit(): void {
    this.soupConfigurationService.findValidFields().subscribe((f) => (this.jsonFields = f));

    this.filteredJsonFields = this.jsonFieldsControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : '';
        if (isNilty(this.jsonFields)) {
          return;
        }
        return name ? this._filter(name as string) : this.jsonFields.slice();
      })
    );
  }

  displayFn(field: string): string {
    return field || '';
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.jsonFields.filter((option) => option.toLowerCase().includes(filterValue));
  }

  addFilter() {
    this.itemFilters.unshift(new ItemFilter());
  }

  deleteFilter(index: number) {
    this.itemFilters.splice(index, 1);
  }
}
