import { JsonObject, JsonProperty } from 'json2typescript';
import { AccountConnection } from './account-connection-model';

@JsonObject('SalesOrderPollConfiguration')
export class SalesOrderPollConfiguration {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('account', AccountConnection, true) account: AccountConnection = undefined;
  @JsonProperty('cronExpression', String, true) cronExpression: string = undefined;
  @JsonProperty('destination', String, true) destination: string = undefined;
  @JsonProperty('active', Boolean, true) active = true;
  @JsonProperty('enabledStoreCodes', [String], true) enabledStoreCodes: string[] = undefined;
  @JsonProperty('pollOnlyFulfilledOrders', Boolean, true) pollOnlyFulfilledOrders = false;
}
