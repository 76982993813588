import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductFilters } from 'src/app/models/filters/product';
import { ProductWithMetadata } from 'src/app/models/product-with-metadata-model';
import { ProductsService } from '../products.service';
import { MatDialog } from '@angular/material/dialog';
import { AddProductComponent } from '../add-product/add-product.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonsService } from '@shared/commons.service';
import { SuppliersService } from '../../suppliers/suppliers.service';
import { Status } from '@models/status-model';
import { ImportProductImagesAndDocumentsModalComponent } from './import-product-images-modal/import-product-images-and-documents-modal.component';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProductsListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('networkCategoryFile', { static: false }) networkCategoryFile: ElementRef;
  @ViewChild('equivalenceFile') equivalenceFile: ElementRef;
  networkCategoryFileUpload: File;
  equivalenceFileUpload: File;

  resultsNumber: Observable<number>;
  dataSource = new MatTableDataSource<ProductWithMetadata>([]);
  displayedColumns = ['id', 'sku', 'barcode', 'title', 'brand', 'showDetails'];
  productFilters = new ProductFilters();
  selectedRow: ProductWithMetadata;
  productBarcodeTypes: Status[];
  networks: string[];
  private selectedNetworkForCategoryImport: string;

  constructor(
    private productsService: ProductsService,
    private supplierService: SuppliersService,
    private router: Router,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private commonService: CommonsService
  ) {}

  ngOnInit() {
    this.resultsNumber = this.productsService.resultsNumber;
    this.loadBarcodeTypes();

    this.commonService.getNetworks().subscribe((it) => (this.networks = it));

    this.loadResults();
  }

  loadBarcodeTypes() {
    this.supplierService.getProductBarcodeTypes().subscribe((s) => (this.productBarcodeTypes = s));
  }

  loadResults() {
    this.productsService.getFilteredProducts(this.productFilters).subscribe((resp: ProductWithMetadata[]) => {
      this.dataSource.data = resp;
    });
  }

  loadPage() {
    this.productFilters.pageSize = this.paginator.pageSize;
    this.productFilters.pageNumber = this.paginator.pageIndex;
    this.loadResults();
  }

  onSort() {
    this.productFilters.sortBy = this.sort.active;
    this.productFilters.sortDirection = this.sort.direction;
    this.loadResults();
  }

  onFilter() {
    this.paginator.pageIndex = 0;
    this.loadResults();
  }

  showDetails(row: ProductWithMetadata) {
    this.router.navigate(['products', 'details', row.barcode]);
  }

  showEquivalence() {
    this.router.navigate(['products/equivalence']);
  }

  toggle(row: ProductWithMetadata) {
    this.selectedRow = this.selectedRow === row ? null : row;
  }

  openProductImagesModal() {
    this.dialog.open(ImportProductImagesAndDocumentsModalComponent);
  }

  openNewProduct() {
    this.dialog.open(AddProductComponent);
  }

  uploadNetworkCategoriesFile(network: string) {
    this.selectedNetworkForCategoryImport = network;
    this.networkCategoryFile.nativeElement.click();
  }
  networkCategoriesFileChanged(event) {
    this.networkCategoryFileUpload = event.target.files[0];
    this.productsService
      .uploadNetworkCategoriesFile(this.networkCategoryFileUpload, this.selectedNetworkForCategoryImport)
      .subscribe(() => {
        this.snackbar.open('File uploaded.', 'CLOSE')._dismissAfter(2000);
        this.selectedNetworkForCategoryImport = undefined;
      });
  }

  importProductEquivalence() {
    this.equivalenceFile.nativeElement.click();
  }

  equivalenceFileChanged(event: any) {
    this.equivalenceFileUpload = event.target.files[0];
    if (this.equivalenceFileUpload.type !== 'text/csv') {
      this.snackbar.open('ERROR! Please, select an .csv file.', 'CLOSE')._dismissAfter(5000);
    } else {
      this.productsService.uploadEquivalenceProducts(this.equivalenceFileUpload).subscribe(() => {
        this.snackbar.open('File uploaded. ', 'CLOSE')._dismissAfter(3000);
        // this.loadResults();
      });
    }
  }
  filterProducts(filters: ProductFilters) {
    this.productFilters = filters;
    this.loadResults();
  }

  removeFilterKey(key: string) {
    delete this.productFilters[key];
    this.loadResults();
  }
}
