import { IDatasource, IGetRowsParams } from 'ag-grid-community';
import { PublicationService } from '../../publication.service';
import { PublicationFilters } from '../../../../models/publications/filters/publication-filters-model';
import { isNilty } from '../../../../core/utils.service';

export class PublicationsDatasource implements IDatasource {
  filters: PublicationFilters;

  isByStore: boolean;
  selectedStoreCode: string;
  selectedBarcode: string;

  private lastRow: number;

  constructor(private publicationService: PublicationService, isByStore: boolean) {
    this.publicationService.resultsNumber.subscribe((value) => {
      this.lastRow = value;
    });

    this.isByStore = isByStore;

    this.filters = new PublicationFilters();
    if (this.isByStore && !isNilty(this.publicationService.byStoreFilters)) {
      this.filters = this.publicationService.byStoreFilters;
      console.log(this.publicationService.byStoreFilters);
    }
    if (!this.isByStore && !isNilty(this.publicationService.byBarcodeFilters)) {
      this.filters = this.publicationService.byBarcodeFilters;
      console.log(this.publicationService.byBarcodeFilters);
    }
  }

  getRows(params: IGetRowsParams): void {
    const filters = this.addPaginationToFilters(params);
    if (this.isByStore) {
      this.publicationService.byStoreFilters = filters;
      this.publicationService.getPublicationsByStoreAndFilters(this.selectedStoreCode, filters).subscribe((data) => {
        let lastRow = -1;
        if (data.length <= params.endRow - params.startRow) {
          lastRow = this.lastRow;
        }
        params.successCallback(data, lastRow);
      });
    } else {
      this.publicationService.byBarcodeFilters = filters;
      this.publicationService.getPublicationsByProductBarcodeAndFilters(this.selectedBarcode, filters).subscribe((data) => {
        let lastRow = -1;
        if (data.length <= params.endRow - params.startRow) {
          lastRow = this.lastRow;
        }
        params.successCallback(data, lastRow);
      });
    }
  }

  private addPaginationToFilters(params: IGetRowsParams): PublicationFilters {
    this.filters.pageSize = params.endRow - params.startRow;
    this.filters.pageNumber = params.startRow / this.filters.pageSize;

    if (params.sortModel.length > 0) {
      const sort = params.sortModel[0];
      this.filters.sortBy = sort.colId.replace('publication.', '');
      this.filters.sortDirection = sort.sort;
    }

    return this.filters;
  }
}
