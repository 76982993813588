import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanIconPipe',
})
export class BooleanIconPipe implements PipeTransform {
  transform(value: boolean): string {
    return value ? 'fa fa-check' : 'fa fa-times sel-icon';
  }
}
