import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CreateNewStore')
export class CreateNewStore {
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('country', String, true) country: string = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('accountCode', String, true) accountCode: string = undefined;
  @JsonProperty('orderType', String, true) orderType: string = undefined;
  @JsonProperty('channelStoreName', String, true) channelStoreName: string = undefined;
  @JsonProperty('storeGroupName', String, true) storeGroupName: string = undefined;
  @JsonProperty('marketplaceName', String, true) marketplaceName: string = undefined;
}
