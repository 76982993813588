import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ShowJsonModalComponent } from './show-json-modal/show-json-modal.component';
import { CatalogFeedHistoryEntry } from '@models/catalog-item-history-model';

@Component({
  selector: 'app-catalog-item-feed-history',
  templateUrl: './catalog-item-feed-history.component.html',
  styleUrls: ['./catalog-item-feed-history.component.css'],
})
export class CatalogItemFeedHistoryComponent implements OnInit {
  @Input() feedHistory: CatalogFeedHistoryEntry[];

  dataSource = new MatTableDataSource<CatalogFeedHistoryEntry>([]);
  displayedColumns = ['feedUrl', 'importTimestamp', 'feed'];

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.dataSource.data = this.feedHistory;
  }

  showFullJson(feed: CatalogFeedHistoryEntry) {
    this.dialog.open(ShowJsonModalComponent, { data: feed });
  }
}
