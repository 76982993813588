import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  SupplierFeedReadingStatusFilters,
  supplierFeedReadingStatusFiltersMap,
} from '@models/configuration/administration/filters/supplier-feed-reading-status-filters.model';
import { MatChipsData } from 'src/app/models/mat-chips-data-model';

@Component({
  selector: 'app-soup-feeds-filters',
  templateUrl: './soup-feeds-filters.component.html',
  styleUrls: ['./soup-feeds-filters.component.scss'],
})
export class SoupFeedsFiltersComponent implements OnInit {
  @Input() soupFeeds;
  @Output() filterSFRS = new EventEmitter<SupplierFeedReadingStatusFilters>();
  filters = new SupplierFeedReadingStatusFilters();
  localFilters = new SupplierFeedReadingStatusFilters();
  openFilter = false;
  actualDistrNames = [];
  uniqueDistrNames = [];
  actualSuppliers = [];
  uniqueSuppliers = [];
  filtersMap: MatChipsData[] = [];
  filterForm: FormGroup;

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      distrName: new FormControl(),
      supplier: new FormControl(),
      error: new FormControl(),
      skip: new FormControl(),
    });
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
    this.soupFeeds.forEach((it) => {
      this.actualDistrNames.push(it.distrName);
      this.actualSuppliers.push(it.supplier);
    });
    this.uniqueDistrNames = this.actualDistrNames.filter((c, index) => this.actualDistrNames.indexOf(c) === index);
    this.uniqueSuppliers = this.actualSuppliers.filter((c, index) => this.actualSuppliers.indexOf(c) === index);
  }

  closeEvent() {
    this.openFilter = false;
  }

  resetFilters() {
    this.filters = new SupplierFeedReadingStatusFilters();
    this.filterForm.reset();
    this.updateFiltersMap();
    this.filterSFRS.emit(this.filters);
  }

  search() {
    this.updateFiltersMap();
    this.filters.distrName = this.localFilters.distrName;
    this.filters.supplier = this.localFilters.supplier;
    this.filters.importErrorMessage = this.localFilters.importErrorMessage;
    this.filters.skip = this.localFilters.skip;
    this.filterSFRS.emit(this.filters);
  }

  updateFiltersMap() {
    this.filtersMap = supplierFeedReadingStatusFiltersMap(
      this.filterForm.value.distrName,
      this.filterForm.value.supplier,
      this.filterForm.value.error,
      this.filterForm.value.skip
    );
  }
}
