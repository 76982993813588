import { Component } from '@angular/core';
import { PowerSellerRulesService } from '../power-seller-rules.service';
import { Observable } from 'rxjs';
import { PowerSellerRule } from '@models/configuration/rules/power-seller-rule/power-seller-rule-model';

@Component({
  selector: 'app-power-seller-rules-list',
  templateUrl: './power-seller-rules-list.component.html',
  styleUrls: ['./power-seller-rules-list.component.scss'],
})
export class PowerSellerRulesListComponent {
  allRules: Observable<PowerSellerRule[]>;

  constructor(private powerSellerRulesService: PowerSellerRulesService) {
    this.allRules = this.powerSellerRulesService.findAllPowerSellerRules();
  }
}
