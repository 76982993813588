import { Publication } from '../publications/publication-model';
import { ProductMetadata } from '../product-metadata-model';

export class ProductNetworkCategoryModalInput {
  sku: string;
  barcode: string;
  title: string;
  brand: string;

  constructor(p: Publication | ProductMetadata) {
    if (p instanceof Publication) {
      this.sku = p.sku;
      this.barcode = p.barcode;
      this.title = p.title;
      this.brand = p.brand;
    }
    if (p instanceof ProductMetadata) {
      this.sku = p.sku;
      this.barcode = p.productBarcode?.value;
      this.title = p.title;
      this.brand = p.brand?.name;
    }
  }
}
