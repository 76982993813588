import { JsonObject, JsonProperty } from 'json2typescript';
import { RulePredicate } from '../rule-predicate-model';

@JsonObject('CustomFulfilmentLatency')
export class CustomFulfilmentLatency {
  @JsonProperty('predicates', [RulePredicate], true) predicates: RulePredicate[] = [];
  @JsonProperty('fulfilmentLatency', Number, true) fulfilmentLatency: number = undefined;

  fulfilmentLatencyDescription: string;

  constructor() {
    this.fulfilmentLatency = null;
  }

  setDescription() {
    this.predicates.forEach((it) => it.setDescription());
    this.fulfilmentLatencyDescription = `Fulfilment latency: ${this.fulfilmentLatency} - ${this.predicates
      .map((it) => it.description)
      .join(' AND ')}`;
  }
}
