import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('RuleTypes')
export class RuleTypes {
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('valueType', String, true) valueType: string = undefined;
}

@JsonObject('ValidationRule')
export class ValidationRule {
  @JsonProperty('ruleType', String, true) ruleType: string = undefined;
  @JsonProperty('ruleValue', { value: String }, true) ruleValue: { value: string | number } = undefined;

  constructor(ruleType: string, ruleValue: { value: string | number }) {
    this.ruleType = ruleType;
    this.ruleValue = ruleValue;
  }
}

@JsonObject('ColumnWithPositionAndName')
export class ColumnWithPositionAndName {
  @JsonProperty('column', String, true) column: string = undefined;
  @JsonProperty('columnName', String, true) columnName: string = undefined;
  @JsonProperty('position', String, true) position: string = undefined;
  @JsonProperty('mandatory', Boolean, true) mandatory = false;
  @JsonProperty('validationRules', [ValidationRule], true) validationRules: ValidationRule[] = undefined;

  constructor(column: string, columnName: string, position: string) {
    this.column = column;
    this.columnName = columnName;
    this.position = position;
  }
}

@JsonObject('ExportMetadataTemplate')
export class ExportMetadataTemplate {
  @JsonProperty('templateName', String, true) templateName: string = undefined;
  @JsonProperty('columns', [ColumnWithPositionAndName], true) columns: ColumnWithPositionAndName[] = undefined;
}
