import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('RouteManagementInput')
export class RouteManagementInput {
  @JsonProperty('configurationId', String, false) configurationId: string;

  constructor(id: string) {
    this.configurationId = id;
  }
}
