import { Component, OnInit } from '@angular/core';
import { MarkusConfigurationService } from './markus-configuration.service';
import { ConfigurationTypes } from '../../../../models/configuration/administration/markus-configuration';

@Component({
  selector: 'app-markus-configuration',
  templateUrl: './markus-configuration.component.html',
  styleUrls: ['./markus-configuration.component.scss'],
})
export class MarkusConfigurationComponent implements OnInit {
  configurations: any[] = [];

  configurationTypes = ConfigurationTypes;

  selectedConfiguration: any = undefined;
  title: string = undefined;

  constructor(private markusConfigurationService: MarkusConfigurationService) {}

  ngOnInit(): void {
    this.load();
  }

  open(c: any) {
    this.selectedConfiguration = c;
    switch (this.selectedConfiguration.type) {
      case this.configurationTypes.REPRICING_SETTINGS: {
        this.title = 'Repricing settings';
        return;
      }
      case this.configurationTypes.MWS_THROTTLING_HANDLING: {
        this.title = 'MWS throttling settings';
        return;
      }
    }
  }

  discard() {
    this.load();
  }

  save() {
    const selectedType = this.selectedConfiguration.type;
    this.markusConfigurationService.save(this.selectedConfiguration).subscribe(() => {
      this.load(selectedType);
    });
  }

  private load(selectedType?: string) {
    this.selectedConfiguration = undefined;
    this.markusConfigurationService.getAll().subscribe((r) => {
      this.configurations = r;
      this.selectedConfiguration = this.configurations.find((it) => it.type === selectedType);
    });
  }
}
