import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNilty } from 'src/app/core/utils.service';
import { StockLocationsService } from '../../container/stock-locations/stock-locations.service';
import { StockLocation } from '../../models/stock-location-model';

@Pipe({
  name: 'stockLocationPipe',
})
export class StockLocationPipe implements PipeTransform {
  constructor(private stockLocationsService: StockLocationsService) {}

  transform(stockLocationId: number, key: string = 'code'): Observable<string> {
    if (isNilty(stockLocationId)) {
      return of(stockLocationId.toString());
    }

    return this.stockLocationsService.getAllStockLocations().pipe(
      map((stockLocations: StockLocation[]) => {
        const stockLocation = stockLocations.find((it) => it.id === stockLocationId);
        if (isNilty(stockLocation)) {
          return stockLocationId.toString();
        }
        switch (key) {
          case 'code':
            return stockLocation.code.toString();
          case 'name':
            return stockLocation.supplierName.toString();
        }
      })
    );
  }
}
