import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ListingSellingPlatform } from '@models/listings/listing-selling-platform-model';
import { CountriesService } from '@shared/countries.service';
import { SellingPlatform } from '@models/configuration/marketplace/marketplace-model';
import { StoreService } from '../../../../store-configuration/store.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { isNilty } from '@core/utils.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CreateSellingPlatformModalComponent } from '../../create-selling-platform-modal/create-selling-platform-modal.component';

@Component({
  selector: 'app-restriction-platforms-list',
  templateUrl: './restriction-platforms-list.component.html',
  styleUrls: ['./restriction-platforms-list.component.scss'],
})
export class RestrictionPlatformsListComponent implements OnInit {
  @ViewChild('newCountryInput', { static: true }) searchInput: ElementRef;

  @Input() sellingPlatforms: ListingSellingPlatform[];
  @Output() sellingPlatformsChange = new EventEmitter<ListingSellingPlatform[]>();

  allSellingPlatforms: SellingPlatform[] = [];

  newRestrictionPlatform: ListingSellingPlatform = new ListingSellingPlatform();

  isNewPlatformValid = false;
  isNewCountryCodeValid = true;

  constructor(
    private storeService: StoreService,
    private countriesService: CountriesService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.getSellingPlatforms();
  }

  ngOnInit() {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((countryCode: string) => {
        this.newRestrictionPlatform.country = countryCode;
        this.checkCountryValidity();
      });
  }

  selectedSP(sp: string) {
    this.newRestrictionPlatform.sellingPlatform = sp;
    this.checkValidity();
  }

  add() {
    const existing = this.sellingPlatforms.find(
      (it) => it.sellingPlatform === this.newRestrictionPlatform.sellingPlatform && it.country === this.newRestrictionPlatform.country
    );

    if (!isNilty(existing)) {
      if (isNilty(this.newRestrictionPlatform.sellingPlatform)) {
        delete this.newRestrictionPlatform.sellingPlatform;
      }
      if (isNilty(this.newRestrictionPlatform.country)) {
        delete this.newRestrictionPlatform.country;
      }
      this.snackBar
        .open(`${this.newRestrictionPlatform.sellingPlatform} - ${this.newRestrictionPlatform.country} is already present in the list`)
        ._dismissAfter(2000);
      return;
    }

    this.sellingPlatforms.push({ ...this.newRestrictionPlatform });
    this.newRestrictionPlatform = new ListingSellingPlatform();
    this.checkValidity();
  }

  remove(index: number) {
    this.sellingPlatforms.splice(index, 1);
  }

  newSellingPlatform() {
    this.dialog
      .open(CreateSellingPlatformModalComponent, { disableClose: true })
      .afterClosed()
      .subscribe((newSellingPlatformName: string) => {
        this.getSellingPlatforms();
        if (this.newRestrictionPlatform) {
          this.newRestrictionPlatform.sellingPlatform = newSellingPlatformName;
        }
      });
  }

  private getSellingPlatforms() {
    this.storeService.getSellingPlatforms().subscribe((r) => (this.allSellingPlatforms = r));
  }

  private checkCountryValidity() {
    if (isNilty(this.newRestrictionPlatform.country)) {
      this.isNewCountryCodeValid = true;
      this.checkValidity();
      return;
    }
    this.countriesService.isValidCountryCode(this.newRestrictionPlatform.country).subscribe((isValid) => {
      this.isNewCountryCodeValid = isValid;
      this.checkValidity();
    });
  }

  private checkValidity() {
    const isNullSP = isNilty(this.newRestrictionPlatform.sellingPlatform);
    const isNullCountry = isNilty(this.newRestrictionPlatform.country);

    this.isNewPlatformValid =
      (!isNullSP && isNullCountry) ||
      (isNullSP && !isNullCountry && this.isNewCountryCodeValid) ||
      (!isNullSP && !isNullCountry && this.isNewCountryCodeValid);
  }
}
