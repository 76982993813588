import { ITooltipAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { PendingPublication } from '../../../../../models/publications/pending-publication-model';
import { marketplaceOperationColorMap } from '../publication-list-utils';

@Component({
  selector: 'app-pending-tooltip-component',
  template: `
    <div class="custom-tooltip">
      <p>
        <b>Operation: </b><span class="operation" [ngStyle]="{ color: operationColor }">{{ pending.operation }}</span>
      </p>
      <p><b>Submitted: </b> <i [ngClass]="pending.submitted | booleanIconPipe"> </i></p>
      <p><b>Quantity: </b>{{ pending.quantityToPublish }}</p>
      <p><b>Price: </b>{{ pending.targetPrice }}</p>
      <p><b>Price range: </b>{{ pending.minimumPrice }} - {{ pending.maximumPrice }}</p>
      <p><b>Updated at: </b>{{ pending.updatedAt }}</p>
      <p *ngIf="pending.marketplaceFeedId"><b>Marketplace FeedId: </b>{{ pending.marketplaceFeedId }}</p>
    </div>
  `,
  styles: [
    `
      :host {
        font-family: 'Titillium Web', sans-serif;
        position: absolute;
        width: fit-content;
        max-width: 400px;
        height: 70px;
        pointer-events: none;
        transition: opacity 0.6s;
      }
      :host.ag-tooltip-hiding {
        opacity: 0;
      }
      .operation {
        font-weight: bold;
      }
    `,
  ],
})
export class PendingTooltipComponent implements ITooltipAngularComp {
  pending: PendingPublication;
  operationColor: string;

  agInit(params): void {
    this.pending = params.value as PendingPublication;
    this.operationColor = marketplaceOperationColorMap(this.pending.operation);
  }
}
