import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNilty } from '@core/utils.service';
import { ColumnWithPositionAndName } from '@models/export-metadata/metadata-export-model';

@Component({
  selector: 'app-export-select-column-position',
  template: `
    <div class="dialog-container" (keyup.enter)="confirm()">
      <div mat-dialog-content>
        <div class="row">
          <div class="col-12 center">
            <label for="position">Position for empty column {{ positionToEdit.column }}</label>
            <mat-form-field class="position-input">
              <input
                id="position"
                matInput
                [(ngModel)]="positionToEdit.position"
                [maxlength]="2"
                (ngModelChange)="validate($event)"
                oninput="this.value = this.value.toUpperCase()"
              />
            </mat-form-field>
          </div>

          <div class="col-12 center">
            <label for="name">Colum name </label>

            <mat-form-field style="margin-left: 50px;">
              <input id="name" matInput [(ngModel)]="this.data.column.columnName" oninput="this.value = this.value.toUpperCase()" />
            </mat-form-field>
          </div>
        </div>
        <div class="row description">
          <div *ngIf="error" class="col center error">{{ error }}</div>
          <div *ngIf="warn" class="col center warn">{{ warn }}</div>
          <div class="col center" *ngIf="!error && !warn">(press ENTER to confirm)</div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .dialog-container {
        font-family: 'Titillium Web', sans-serif;
        width: 400px !important;
      }

      .position-input {
        max-width: 35px;
        margin-left: 50px;
      }

      .description {
        font-size: 13px;
      }
      .error {
        color: red;
      }
      .warn {
        color: orange;
      }
    `,
  ],
})
export class EditPositionAndNameModalComponent implements OnInit {
  positionToEdit: ColumnWithPositionAndName;
  warn: string = undefined;
  error: string = undefined;

  private occupiedColumns: ColumnWithPositionAndName[] = [];
  private occupiedPosition: ColumnWithPositionAndName = undefined;
  private template: ColumnWithPositionAndName[];

  constructor(
    private dialogRef: MatDialogRef<EditPositionAndNameModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { column: ColumnWithPositionAndName; template: ColumnWithPositionAndName[] }
  ) {}

  ngOnInit() {
    this.positionToEdit = this.data.column;
    this.template = this.data.template;
    this.occupiedColumns = this.template.filter((it) => it.columnName !== this.positionToEdit.columnName);
  }

  validate(newValue: string) {
    if (isNilty(newValue)) return;

    const pattern = /^[A-ZA-Z]{1,2}$/;
    if (!pattern.test(newValue)) {
      this.setError('Position must be a letter');
      return;
    }

    this.occupiedPosition = this.occupiedColumns.find((it) => it.position === newValue);
    if (!isNilty(this.occupiedPosition)) {
      this.setWarn('Position is used by another column. Press ENTER to force it.');
      return;
    }

    this.unsetMessages();
  }

  confirm() {
    if (isNilty(this.positionToEdit.position)) return;
    if (!isNilty(this.error)) return;

    if (!isNilty(this.occupiedPosition)) {
      this.occupiedPosition.position = undefined;
    }

    this.dialogRef.close();
  }

  private setError(error: string) {
    this.warn = undefined;
    this.error = error;
  }

  private setWarn(warn: string) {
    this.warn = warn;
    this.error = undefined;
  }

  private unsetMessages() {
    this.warn = undefined;
    this.error = undefined;
    this.occupiedPosition = undefined;
  }
}
