import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AvailabilityHistoryEntry } from '@models/catalog-item-history-model';
import { isNilty } from '@core/utils.service';
import { CatalogItemsService } from '../../catalog-items.service';

@Component({
  selector: 'app-availability-history',
  templateUrl: './availability-history.component.html',
  styleUrls: ['./availability-history.component.css'],
})
export class AvailabilityHistoryComponent implements OnInit {
  @Input() availabilityHistory: AvailabilityHistoryEntry[];

  dataSource = new MatTableDataSource<AvailabilityHistoryEntry>([]);
  displayedColumns = ['feedUrl', 'importTimestamp', 'availability', 'quantity'];

  constructor(private catalogItemService: CatalogItemsService) {}

  ngOnInit() {
    this.dataSource.data = this.availabilityHistory;
  }

  getFeed(ah: AvailabilityHistoryEntry) {
    if (!isNilty(ah.fileUrl)) {
      return this.openLink(ah.fileUrl);
    }

    this.catalogItemService.getFeed(ah.feedId).subscribe((r) => {
      if (isNilty(r.fileUrl)) return;
      this.openLink(r.fileUrl);
    });
  }
  private openLink(url: string) {
    window.open(url);
  }
}
