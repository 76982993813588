import { JsonObject, JsonProperty } from 'json2typescript';
import { RuleStockLocationSource, RuleSupplierSource } from '@models/configuration/rules/publication-rule-source-model';
import { PublicationRule } from './publication-rule-model';

@JsonObject('BruteRefreshPublicationRequest')
export class BruteRefreshPublicationRequest {
  @JsonProperty('storeCode', String, true) storeCode: string = undefined;
  @JsonProperty('supplierCodes', [String], true) supplierCodes: string[] = [];
  @JsonProperty('stockLocationCodes', [String], true) stockLocationCodes: string[] = [];

  private constructor(ruleCode: string, supplierCodes: string[], stockLocationCodes: string[]) {
    this.storeCode = ruleCode;
    this.supplierCodes = supplierCodes;
    this.stockLocationCodes = stockLocationCodes;
  }

  public static fromPublicationRule(
    rule: PublicationRule,
    suppliers: RuleSupplierSource[],
    stockLocations: RuleStockLocationSource[]
  ): BruteRefreshPublicationRequest {
    const supplierCodes = suppliers.map((supplier) => supplier.supplierCode);
    const stockLocationCodes = stockLocations.map((stock) => stock.stockLocationCode);

    return new BruteRefreshPublicationRequest(rule.store.code, supplierCodes, stockLocationCodes);
  }
}
