import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cloneClass, isNilty } from '../../../../../../../core/utils.service';
import { ColumnsPair, ValuePair } from '../../../../../../../models/configuration/soup-configuration/file-properties-model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-columns-mapper-modal',
  templateUrl: './columns-mapper-modal.component.html',
  styleUrls: ['./columns-mapper-modal.component.css'],
})
export class ColumnsMapperModalComponent implements OnInit {
  newValuePair: ValuePair = new ValuePair();

  searchString = '';

  columnsPair: ColumnsPair;

  jsonFields: string[];
  filteredJsonFieldsFrom: Observable<string[]>;
  filteredJsonFieldsTo: Observable<string[]>;
  jsonFieldsFromControl = new FormControl<string | string>('');
  jsonFieldsToControl = new FormControl<string | string>('');

  displayedValues: ValuePair[];

  constructor(
    public dialogRef: MatDialogRef<ColumnsMapperModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { columns: ColumnsPair; jsonFields: string[] }
  ) {}

  ngOnInit() {
    this.columnsPair = cloneClass(this.data.columns);
    this.jsonFields = this.data.jsonFields;
    if (isNilty(this.columnsPair.values)) {
      this.columnsPair.values = [];
    }
    this.displayedValues = [...this.columnsPair.values];

    this.filteredJsonFieldsFrom = this.jsonFieldsFromControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : '';
        if (isNilty(this.jsonFields)) {
          return;
        }
        return name ? this._filter(name as string) : this.jsonFields.slice();
      })
    );

    this.filteredJsonFieldsTo = this.jsonFieldsToControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : '';
        if (isNilty(this.jsonFields)) {
          return;
        }
        return name ? this._filter(name as string) : this.jsonFields.slice();
      })
    );
  }

  displayFn(field: string): string {
    return field || '';
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.jsonFields.filter((option) => option.toLowerCase().includes(filterValue));
  }

  add() {
    if (!isNilty(this.newValuePair.fromValue) && !isNilty(this.newValuePair.toValue)) {
      this.columnsPair.values.push(this.newValuePair);
      this.newValuePair = new ValuePair();
      this.search(this.searchString);
    }
  }

  search(search: string) {
    this.displayedValues = this.columnsPair.values.filter(
      (it) =>
        it.fromValue.toUpperCase().indexOf(search.toUpperCase()) !== -1 || it.toValue.toUpperCase().indexOf(search.toUpperCase()) !== -1
    );
  }

  removeValue(index: number) {
    this.columnsPair.values.splice(index, 1);
    this.search(this.searchString);
  }
}
