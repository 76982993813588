import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { QuantityHistoryEntry } from '@models/catalog-item-history-model';
import { CatalogItemsService } from '../../catalog-items.service';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-quantity-history',
  templateUrl: './quantity-history.component.html',
  styleUrls: ['./quantity-history.component.css'],
})
export class QuantityHistoryComponent implements OnInit {
  @Input() quantityHistory: QuantityHistoryEntry[];

  dataSource = new MatTableDataSource<QuantityHistoryEntry>([]);
  displayedColumns = ['feedUrl', 'importTimestamp', 'quantity'];

  constructor(private catalogItemService: CatalogItemsService) {}

  ngOnInit(): void {
    this.dataSource.data = this.quantityHistory;
  }

  getFeed(qh: QuantityHistoryEntry) {
    if (!isNilty(qh.fileUrl)) {
      return this.openLink(qh.fileUrl);
    }

    this.catalogItemService.getFeed(qh.feedId).subscribe((r) => {
      if (isNilty(r.fileUrl)) return;
      this.openLink(r.fileUrl);
    });
  }
  private openLink(url: string) {
    window.open(url);
  }
}
