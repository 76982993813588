import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-select-column-position',
  template: `
    <div class="dialog-container">
      <div mat-dialog-content>
        <div class="row">
          <div class="col center">
            <app-brandon-autocomplete
              [label]="data.label"
              [allData]="data.allData"
              [(value)]="selected"
              [allowNullOption]="false"
            ></app-brandon-autocomplete>
          </div>
        </div>
        <div class="row description">
          <div class="col center" *ngIf="selected !== undefined">
            <button class="btn-custom" (click)="confirm()">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .dialog-container {
        font-family: 'Titillium Web', sans-serif;
        width: 400px !important;
      }

      .position-input {
        max-width: 35px;
        margin-left: 50px;
      }

      .description {
        font-size: 13px;
      }
      .error {
        color: red;
      }
      .warn {
        color: orange;
      }
    `,
  ],
})
export class GenericAutocompleteModalComponent {
  warn: string = undefined;
  error: string = undefined;

  selected: string;

  constructor(
    private dialogRef: MatDialogRef<GenericAutocompleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { label: string; allData: any[] }
  ) {}

  validate(newValue: string) {
    if (isNilty(newValue)) return;

    this.unsetMessages();
  }

  confirm() {
    this.dialogRef.close(this.selected);
  }

  private setError(error: string) {
    this.warn = undefined;
    this.error = error;
  }

  private setWarn(warn: string) {
    this.warn = warn;
    this.error = undefined;
  }

  private unsetMessages() {
    this.warn = undefined;
    this.error = undefined;
  }
}
