import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { isNilty } from '@core/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { HelpModalComponent } from '@shared/help-modal/help-modal.component';
import { RestrictionsService } from '../restrictions.service';
import { PredicatesRestriction, RestrictionTarget, WordsRestriction } from '@models/configuration/policies/restrictions/restrictions-model';
import { RestrictionTargetType } from '@models/configuration/policies/restrictions/restriction-target-type';
import { SearchRestrictionDto, RestrictionSearchType } from '@models/configuration/policies/restrictions/restrictions-utils';
import { PowerSeller } from '@models/configuration/rules/power-seller-rule/power-seller-model';

@Component({
  selector: 'app-restrictions-by-target',
  templateUrl: './restrictions-by-target.component.html',
  styleUrls: ['./restrictions-by-target.component.scss'],
})
export class RestrictionsByTargetComponent implements OnInit, OnDestroy {
  @Input() searchSubject: Subject<SearchRestrictionDto>;
  @Input() searchDto: SearchRestrictionDto;

  searchEvent = new Subject<RestrictionTarget[]>();

  targetSelectionType: 'ONLY_TARGET' | 'ALL_RELEVANT' = 'ALL_RELEVANT';

  targetsToSearch: RestrictionTarget[] = [];

  target: PowerSeller | string;
  predicatesWhitelists: PredicatesRestriction[] = [];
  wordsWhitelists: WordsRestriction[] = [];

  private subscriptions: Subscription[] = [];

  constructor(private restrictionsService: RestrictionsService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.search();

    if (!isNilty(this.searchSubject)) {
      this.subscriptions.push(
        this.searchSubject.subscribe((dto) => {
          this.searchDto = dto;
          this.search();
        })
      );
    }
    if (!isNilty(this.restrictionsService.refreshRestrictionsPageEvent)) {
      this.subscriptions.push(
        this.restrictionsService.refreshRestrictionsPageEvent.subscribe(() => {
          this.search();
        })
      );
    }
  }

  changedSelectionType(event: 'ONLY_TARGET' | 'ALL_RELEVANT') {
    this.targetSelectionType = event;
    this.search();
  }

  showHelp() {
    this.dialog.open(HelpModalComponent, {
      data: {
        title: 'Whitelist Targets',
        html: `
          Each Whitelist is targeted to an entity, which can be a Power seller or Global.\n
          Whitelist targeted to a Power Seller will be only applied to that Power Seller.
          Global blacklists will work for everything.\n
          <b>Only selected target</b>: will show only the restrictions targeted exactly to the selected target.
          <b>All relevant targets</b>: will show all the restrictions that will be applied to the selected target.
          `,
      },
    });
  }

  search() {
    if (isNilty(this.searchDto)) {
      return;
    }

    this.targetsToSearch = [];

    switch (this.searchDto.searchType) {
      case RestrictionSearchType.POWER_SELLER: {
        const ps = this.searchDto.entity as PowerSeller;
        this.targetsToSearch.push(this.buildPowerSeller(ps.code));
        if (this.targetSelectionType === 'ALL_RELEVANT') {
          this.targetsToSearch.push(this.buildGlobal());
        }
        break;
      }
      case RestrictionSearchType.GLOBAL: {
        this.targetsToSearch.push(this.buildGlobal());
        break;
      }
    }

    this.restrictionsService.findPredicatesRestrictionsByTargets(this.targetsToSearch).subscribe((r) => (this.predicatesWhitelists = r));
    this.restrictionsService.findWordsRestrictionsByTargets(this.targetsToSearch).subscribe((r) => {
      this.wordsWhitelists = r;
    });
    this.searchEvent.next(this.targetsToSearch);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((it) => it.unsubscribe());
  }

  private buildGlobal(): RestrictionTarget {
    return new RestrictionTarget(RestrictionTargetType.GLOBAL, RestrictionTargetType.GLOBAL);
  }
  private buildPowerSeller(id: string): RestrictionTarget {
    return new RestrictionTarget(RestrictionTargetType.POWER_SELLER, id);
  }
}
