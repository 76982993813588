import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ProductWithTitle')
export class ProductWithTitle {
  @JsonProperty('productId', Number, true) productId: number = undefined;
  @JsonProperty('barcodeId', Number, true) barcodeId: number = undefined;
  @JsonProperty('barcode', String, true) barcode: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('title', String, true) title: string = undefined;
  @JsonProperty('brand', String, true) brand: string = undefined;
}
