import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { EquivalenceProductsListComponent } from './equivalence-products-list/equivalence-products-list.component';
import { ProductsFiltersComponent } from './products-filters/products-filters.component';
import { ProductDetailsComponent } from './products-list/product-details/product-details.component';
import { ProductsListComponent } from './products-list/products-list.component';
import { ProductMetadataListComponent } from './products-list/products-metadata-list/products-metadata-list.component';
import { ProductSourceMetadataListComponent } from './products-list/products-metadata-list/products-source-metadata-list/products-source-metadata-list.component';
import { ProductsComponent } from './products.component';
import { ProductsService } from './products.service';
import { ImportProductImagesAndDocumentsModalComponent } from './products-list/import-product-images-modal/import-product-images-and-documents-modal.component';
import { NgOptimizedImage } from '@angular/common';
import { SourceMetadataDetailsComponent } from './products-list/product-details/source-metadata-details/source-metadata-details.component';

@NgModule({
  declarations: [
    ProductsComponent,
    ProductSourceMetadataListComponent,
    ProductMetadataListComponent,
    ProductsListComponent,
    ProductsFiltersComponent,
    EquivalenceProductsListComponent,
    ProductDetailsComponent,
    ImportProductImagesAndDocumentsModalComponent,
    SourceMetadataDetailsComponent,
  ],
  imports: [SharedModule, NgOptimizedImage],
  providers: [ProductsService, Location],
})
export class ProductsModule {}
