import { NgModule } from '@angular/core';
import { BlacklistsConfigurationComponent } from './blacklists-configuration.component';
import { BlacklistSearcherComponent } from './blacklist-searcher/blacklist-searcher.component';
import { SearchProductComponent } from './blacklist-searcher/search-product/search-product.component';
import { SearchStoreComponent } from './blacklist-searcher/search-store/search-store.component';
import { InsertBlacklistComponent } from './insert-blacklist/insert-blacklist.component';
import { SearchMarketplaceComponent } from './blacklist-searcher/search-marketplace/search-marketplace.component';
import { SearchNetworkComponent } from './blacklist-searcher/search-network/search-network.component';
import { SelectBlacklistTargetComponent } from './insert-blacklist/select-blacklist-target/select-blacklist-target.component';
import { LoadPolicyWordsModalComponent } from '../shared/edit-words-policy/load-policy-words-modal.component';
import { BlacklistsByTargetComponent } from './blacklists-by-target/blacklists-by-target.component';
import { BlacklistsByProductComponent } from './blacklists-by-product/blacklists-by-product.component';
import { BlacklistRowComponent } from './shared/blacklist-row.component';
import { BarcodeBlacklistsListComponent } from './shared/barcode-blacklists-list/barcode-blacklists-list.component';
import { BlacklistDetailsModalComponent } from './shared/blacklist-details-modal/blacklist-details-modal.component';
import { SolveBlacklistModalComponent } from './shared/blacklist-details-modal/solve-blacklist-modal.component';
import { PoliciesModule } from '../policies.module';

@NgModule({
  declarations: [
    BlacklistsConfigurationComponent,
    BlacklistSearcherComponent,
    SearchProductComponent,
    SearchStoreComponent,
    InsertBlacklistComponent,
    SearchMarketplaceComponent,
    SearchNetworkComponent,
    SelectBlacklistTargetComponent,
    LoadPolicyWordsModalComponent,
    BlacklistsByTargetComponent,
    BlacklistsByProductComponent,
    BlacklistRowComponent,
    BarcodeBlacklistsListComponent,
    BlacklistDetailsModalComponent,
    SolveBlacklistModalComponent,
  ],
  imports: [PoliciesModule],
  providers: [],
  exports: [SelectBlacklistTargetComponent, InsertBlacklistComponent],
})
export class BlacklistsModule {}
