import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('AccountConnection')
export class AccountConnection {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('marketplaceConnectionType', String, true) marketplaceConnectionType: string = undefined;
  @JsonProperty('redisKey', String, true) redisKey: string = undefined;
}
