import { Component, Input } from '@angular/core';
import { CustomProfit } from '@models/configuration/rules/publication-rule/custom-profit-model';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { GenericConfirmationModalComponent } from '@shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-rule-profits',
  templateUrl: './rule-profits.component.html',
  styleUrls: ['./rule-profits.component.css'],
})
export class RuleProfitsComponent {
  ruleProfits: CustomProfit[];
  sort = false;

  constructor(private dialog: MatDialog) {}

  @Input() set _ruleProfits(rm: CustomProfit[]) {
    this.ruleProfits = rm;
    this.ruleProfits.forEach((it) => it.setDescription());
  }

  addProfit() {
    this.ruleProfits.push(new CustomProfit());
  }

  confirm(m: CustomProfit, exp: MatExpansionPanel) {
    m.setDescription();
    exp.close();
  }

  removeProfit(index: number) {
    this.dialog
      .open(GenericConfirmationModalComponent, {
        data: `This will remove the custom profit and all its predicates.`,
      })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          this.ruleProfits.splice(index, 1);
        }
      });
  }

  toggleSorting(event: MatSlideToggleChange) {
    this.sort = event.checked;
  }

  drop(event) {
    moveItemInArray(this.ruleProfits, event.previousIndex, event.currentIndex);
  }
}
