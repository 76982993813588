import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { isNilty } from '@core/utils.service';
import { CatalogItemFilters, catalogItemFiltersMap } from '@models/filters/catalog-item';
import { MatChipsData } from '@models/mat-chips-data-model';
import { Status } from '@models/status-model';
import { Supplier } from '@models/supplier-model';
import { SuppliersService } from '../../../suppliers/suppliers.service';
import { CatalogItemsService } from '../../catalog-items.service';

@Component({
  selector: 'app-catalog-items-filters',
  templateUrl: './catalog-items-filters.component.html',
  styleUrls: ['./catalog-items-filters.component.css'],
})
export class CatalogItemsFiltersComponent implements OnInit {
  @Output() onFilter = new Subject<CatalogItemFilters>();

  filterForm: FormGroup;
  openFilter = false;

  filtersMap: MatChipsData[] = [];

  suppliers: Supplier[];

  productBarcodeTypes: Status[];
  status: string[];

  constructor(private catalogItemsService: CatalogItemsService, private suppliersService: SuppliersService) {}

  ngOnInit() {
    this.filterForm = new FormGroup({
      productBarcodeValue: new FormControl(),
      productBarcodeType: new FormControl(),
      supplier: new FormControl(),
      sku: new FormControl(),
      internalSku: new FormControl(),
      status: new FormControl(),
      quantityGreaterThan: new FormControl(),
      isPod: new FormControl(),
    });

    if (!isNilty(this.catalogItemsService.catalogItemFilters)) {
      this.filterForm.patchValue(this.catalogItemsService.catalogItemFilters);
      this.updateFiltersMap();
    }

    this.suppliersService.getAllSuppliers().subscribe((response) => {
      this.suppliers = response;
    });
    this.catalogItemsService.getStatus().subscribe((response) => (this.status = response));
    this.suppliersService.getProductBarcodeTypes().subscribe((response) => {
      this.productBarcodeTypes = response;
      this.setProductBarcodeType();
    });
  }

  setProductBarcodeType() {
    if (this.catalogItemsService.catalogItemFilters) {
      this.filterForm.controls['productBarcodeType'].patchValue(
        this.productBarcodeTypes.find((it) => it.code === this.catalogItemsService.catalogItemFilters.productBarcodeType)
      );
      this.updateFiltersMap();
    }
  }

  onSubmitFilter() {
    this.saveFiltersToCatalogItemService();
    this.updateFiltersMap();
    this.onFilter.next(this.catalogItemsService.catalogItemFilters);
  }

  resetFilters() {
    this.filterForm.reset();
    this.saveFiltersToCatalogItemService();
    this.updateFiltersMap();
    this.onFilter.next(this.catalogItemsService.catalogItemFilters);
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }

  saveFiltersToCatalogItemService() {
    this.catalogItemsService.catalogItemFilters.productBarcodeValue = this.filterForm.value.productBarcodeValue;
    this.catalogItemsService.catalogItemFilters.productBarcodeType = this.filterForm.value.productBarcodeType?.code;
    this.catalogItemsService.catalogItemFilters.supplier = this.filterForm.value.supplier;
    this.catalogItemsService.catalogItemFilters.supplierId = this.suppliers.find((it) => it.code === this.filterForm.value.supplier)?.id;
    this.catalogItemsService.catalogItemFilters.sku = this.filterForm.value.sku;
    this.catalogItemsService.catalogItemFilters.internalSku = this.filterForm.value.internalSku;
    this.catalogItemsService.catalogItemFilters.status = this.filterForm.value.status;
    this.catalogItemsService.catalogItemFilters.quantityGreaterThan = this.filterForm.value.quantityGreaterThan;
    this.catalogItemsService.catalogItemFilters.isPod = this.filterForm.value.isPod;
  }

  updateFiltersMap() {
    this.filtersMap = catalogItemFiltersMap(
      this.filterForm.value.productBarcodeValue,
      this.filterForm.value.productBarcodeType,
      this.filterForm.value.supplier,
      this.filterForm.value.sku,
      this.filterForm.value.internalSku,
      this.filterForm.value.status,
      this.filterForm.value.quantityGreaterThan,
      this.filterForm.value.isPod
    );
  }
}
