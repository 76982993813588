import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Supplier, SupplierDiscountCode } from 'src/app/models/supplier-model';
import { SuppliersService } from '../suppliers.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { GenericConfirmationModalComponent } from '../../../shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { AuthorizationService } from '../../../security/authorization.service';
import { Permissions } from '../../../models/security/permission-model';

@Component({
  selector: 'app-supplier-discount-codes-modal',
  templateUrl: './supplier-discount-codes-modal.component.html',
  styleUrls: ['./supplier-discount-codes-modal.component.css'],
})
export class SupplierDiscountCodesModalComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  @ViewChild('uploadCsv', { static: true }) uploadCsv: ElementRef;

  supplier = this.data;
  discounts: SupplierDiscountCode[];
  dataSource: MatTableDataSource<SupplierDiscountCode>;

  searchString: string;

  displayedColumns = ['discountCode', 'discountPercentage', 'createdAt'];

  hasSupplierFeedPermission: Observable<boolean>;

  constructor(
    public dialogRef: MatDialogRef<SupplierDiscountCodesModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: Supplier,
    private supplierService: SuppliersService,
    private dialog: MatDialog,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit(): void {
    this.reLoadDiscounts();

    this.hasSupplierFeedPermission = this.authorizationService.hasZionPermission(Permissions.SupplierFeedConfiguration);

    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        console.log('filtering');
        this.dataSource.data = this.discounts.filter((it) => it.discountCode.toUpperCase().indexOf(text.toUpperCase()) !== -1);
      });
  }

  reLoadDiscounts() {
    this.searchString = undefined;
    this.supplierService.getSupplierDiscounts(this.supplier.code).subscribe((r) => {
      this.discounts = r;
      this.dataSource = new MatTableDataSource<SupplierDiscountCode>(this.discounts);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  deleteAllDiscounts() {
    this.dialog
      .open(GenericConfirmationModalComponent, { data: `This will delete all discount codes for supplier ${this.supplier.code}` })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          this.supplierService.deleteDiscounts(this.supplier.code).subscribe(() => this.reLoadDiscounts());
        }
      });
  }

  loadNewDiscounts() {
    this.dialog
      .open(GenericConfirmationModalComponent, {
        data: `
      This will upsert the discounts found in the file on supplier ${this.supplier.code}, without deleting anything.
      If you need to reset the discounts, please delete them first.
      The csv template is 'DiscountCode;DiscountPercentage', without any quotes or special characters.
      `,
      })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          this.uploadCsv.nativeElement.click();
        }
      });
  }

  uploadDiscountCsv(event) {
    const currentFileUpload = event.target.files[0];

    this.supplierService.loadNewDiscounts(currentFileUpload, this.supplier.code).subscribe(() => {
      this.reLoadDiscounts();
    });
  }
}
