import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Supplier, InvoiceTo } from '@models/supplier-model';
import { SuppliersService } from '../suppliers.service';

@Component({
  selector: 'app-supplier-invoice-to-modal',
  templateUrl: './supplier-invoice-to-modal.component.html',
  styleUrls: ['./supplier-invoice-to-modal.component.css'],
})
export class SupplierInvoiceToModalComponent implements OnInit {
  supplier = this.data;
  invoicesByCountry: InvoiceTo[];
  dataSource: MatTableDataSource<InvoiceTo>;

  displayedColumns = ['country', 'owner', 'invoiceTo', 'billingName'];

  constructor(
    public dialogRef: MatDialogRef<SupplierInvoiceToModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: Supplier,
    private supplierService: SuppliersService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.supplierService.getInvoiceToByCountry(this.supplier.id).subscribe((r) => {
      this.invoicesByCountry = r;
      this.dataSource = new MatTableDataSource<InvoiceTo>(this.invoicesByCountry);
    });
  }
}
