import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SolveBlacklistInput } from '@models/configuration/policies/blacklist/solve-blacklist-input-model';

@Component({
  selector: 'app-generic-confirmation-modal',
  template: `
    <h1 mat-dialog-title></h1>
    <div class="dialog-container">
      <div mat-dialog-content>
        <div class="row">
          <div class="col">
            <b>You are about to delete the current blacklist.</b>
          </div>
        </div>
        <div class="row padding-top-30">
          <mat-form-field class="w-100">
            <label>You can add a note here</label>
            <textarea matInput [(ngModel)]="data.note" rows="3"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <div class="row w-100">
        <div class="col-md-12 text-center w-100">
          <button class="btn-custom margin-right-15" [mat-dialog-close]="true">Confirm</button>
          <button class="btn-custom" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .dialog-container {
        width: 600px !important;
        height: fit-content !important;
      }
    `,
  ],
})
export class SolveBlacklistModalComponent {
  constructor(public dialogRef: MatDialogRef<SolveBlacklistModalComponent>, @Inject(MAT_DIALOG_DATA) public data: SolveBlacklistInput) {}
}
