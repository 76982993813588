import { JsonObject, JsonProperty } from 'json2typescript';
import { Supplier } from './supplier-model';

@JsonObject('ZionBrand')
export class ZionBrand {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
}

@JsonObject('ZionProduct')
export class ZionProduct {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('ean', String, true) ean: string = undefined;
  @JsonProperty('title', String, true) title: string = undefined;
  @JsonProperty('brand', ZionBrand, true) brand: ZionBrand = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('category', String, true) category: string = undefined;
  @JsonProperty('updatedAt', String, true) updatedAt: string = undefined;
  @JsonProperty('internalSku', String, true) internalSku: string = undefined;
}
@JsonObject('ZionCatalogItem')
export class ZionCatalogItem {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('product', ZionProduct, true) product: ZionProduct = undefined;
  @JsonProperty('supplier', Supplier, true) supplier: Supplier = undefined;
  @JsonProperty('physicalQuantity', Number, true) physicalQuantity: number = undefined;
  @JsonProperty('availableQuantity', Number, true) availableQuantity: number = undefined;
  @JsonProperty('purchasePrice', Number, true) purchasePrice: number = undefined;
  @JsonProperty('discountedPrice', Number, true) discountedPrice: number = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('vat', Number, true) vat: number = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('pod', Boolean, true) pod: boolean = undefined;
  @JsonProperty('updatedAt', String, true) updatedAt: string = undefined;
}
