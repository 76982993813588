import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '@core/converter.service';
import { EnvironmentService } from '@core/environment/environment.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  BarcodeRestriction,
  PredicatesRestriction,
  Restriction,
  RestrictionTarget,
  WordsRestriction,
} from '@models/configuration/policies/restrictions/restrictions-model';
import { BarcodeRestrictionsFilters } from '@models/configuration/policies/restrictions/barcode-restrictions-filters-model';
import { isNilty } from '@core/utils.service';

@Injectable()
export class RestrictionsService {
  barcodeAllowRestrictionsResultNumber = new Subject<number>();
  barcodeDenyRestrictionsResultNumber = new Subject<number>();

  refreshRestrictionsPageEvent = new Subject<void>();

  constructor(private http: HttpClient, private converter: ConverterService, private environment: EnvironmentService) {}

  deleteAllowedPlatforms(type: string, id: string): Observable<any> {
    return this.http.delete(this.environment.getMarkusRestEndpoint('allowPlatformsRestriction') + `/${type}/${id}`);
  }
  deleteDeniedPlatforms(type: string, id: string): Observable<any> {
    return this.http.delete(this.environment.getMarkusRestEndpoint('deniedPlatformsRestriction') + `/${type}/${id}`);
  }

  saveBarcodeRestriction(b: BarcodeRestriction, allowOrDeny: 'ALLOW' | 'DENY'): Observable<BarcodeRestriction> {
    if (allowOrDeny === 'ALLOW') {
      this.validateAllowedPlatforms(b);
      return this.saveSingleRestriction(b, 'saveBarcodeAllowedPlatforms');
    }
    if (allowOrDeny === 'DENY') {
      this.validateDeniedPlatforms(b);
      return this.saveSingleRestriction(b, 'saveBarcodeDeniedPlatforms');
    }
  }
  savePredicatesRestrictions(b: PredicatesRestriction, allowOrDeny: 'ALLOW' | 'DENY'): Observable<PredicatesRestriction> {
    if (allowOrDeny === 'ALLOW') {
      this.validateAllowedPlatforms(b);
      return this.saveSingleRestriction(b, 'savePredicatesAllowedPlatforms');
    }
    if (allowOrDeny === 'DENY') {
      this.validateDeniedPlatforms(b);
      return this.saveSingleRestriction(b, 'savePredicatesDeniedPlatforms');
    }
  }
  saveWordsRestrictions(b: WordsRestriction, allowOrDeny: 'ALLOW' | 'DENY'): Observable<WordsRestriction> {
    if (allowOrDeny === 'ALLOW') {
      this.validateAllowedPlatforms(b);
      return this.saveSingleRestriction(b, 'saveWordsAllowedPlatforms');
    }
    if (allowOrDeny === 'DENY') {
      this.validateDeniedPlatforms(b);
      return this.saveSingleRestriction(b, 'saveWordsDeniedPlatforms');
    }
  }

  findBarcodeAllowedPlatformsByFilters(filters: BarcodeRestrictionsFilters): Observable<BarcodeRestriction[]> {
    return this.findBarcodeRestrictions(filters, 'barcodeAllowedPlatformsByFilters', 'ALLOW', this.barcodeAllowRestrictionsResultNumber);
  }
  findBarcodeDeniedPlatformsByFilters(filters: BarcodeRestrictionsFilters): Observable<BarcodeRestriction[]> {
    return this.findBarcodeRestrictions(filters, 'barcodeDeniedPlatformsByFilters', 'DENY', this.barcodeDenyRestrictionsResultNumber);
  }

  findWordsRestrictionsByTargets(targets: RestrictionTarget[]): Observable<WordsRestriction[]> {
    return forkJoin([this.wordsAllowedPlatformsObs(targets), this.wordsDeniedPlatformsObs(targets)]).pipe(
      map((allResults) => [...allResults[0], ...allResults[1]])
    );
  }
  findPredicatesRestrictionsByTargets(targets: RestrictionTarget[]): Observable<PredicatesRestriction[]> {
    return forkJoin([this.predicatesAllowedPlatformsObs(targets), this.predicatesDeniedPlatformsObs(targets)]).pipe(
      map((allResults) => [...allResults[0], ...allResults[1]])
    );
  }

  private wordsAllowedPlatformsObs(targets: RestrictionTarget[]): Observable<WordsRestriction[]> {
    return this.http
      .post(this.environment.getMarkusRestEndpoint('wordsAllowedPlatformsByTargets'), targets)
      .pipe(map((r: WordsRestriction[]) => r.map((it) => this.toWordsRestriction(it, 'ALLOW'))));
  }
  private wordsDeniedPlatformsObs(targets: RestrictionTarget[]): Observable<WordsRestriction[]> {
    return this.http
      .post(this.environment.getMarkusRestEndpoint('wordsDeniedPlatformsByTargets'), targets)
      .pipe(map((r: WordsRestriction[]) => r.map((it) => this.toWordsRestriction(it, 'DENY'))));
  }
  private toWordsRestriction(r: WordsRestriction, allowOrDeny: 'ALLOW' | 'DENY'): WordsRestriction {
    const converted: WordsRestriction = this.converter.fromJSONtoObj(r, WordsRestriction);
    converted.allowOrDeny = allowOrDeny;
    return converted;
  }

  private findBarcodeRestrictions(
    filters: BarcodeRestrictionsFilters,
    endpoint: string,
    allowOrDeny: 'ALLOW' | 'DENY',
    countSubject: Subject<number>
  ): Observable<BarcodeRestriction[]> {
    return this.http
      .post(this.environment.getMarkusRestEndpoint(endpoint), this.converter.fromObjtoJSON(filters), {
        observe: 'response',
      })
      .pipe(
        map((resp) => {
          countSubject.next(+resp.headers.get('Total-Length'));
          return resp.body;
        }),
        map((r: BarcodeRestriction[]) =>
          r.map((it) => {
            const converted = this.converter.fromJSONtoObj(it, BarcodeRestriction);
            converted.allowOrDeny = allowOrDeny;
            return converted;
          })
        )
      );
  }

  private predicatesAllowedPlatformsObs(targets: RestrictionTarget[]): Observable<PredicatesRestriction[]> {
    return this.http
      .post(this.environment.getMarkusRestEndpoint('predicatesAllowedPlatformsByTargets'), targets)
      .pipe(map((r: PredicatesRestriction[]) => r.map((it) => this.toPredicatesRestriction(it, 'ALLOW'))));
  }
  private predicatesDeniedPlatformsObs(targets: RestrictionTarget[]): Observable<PredicatesRestriction[]> {
    return this.http
      .post(this.environment.getMarkusRestEndpoint('predicatesDeniedPlatformsByTargets'), targets)
      .pipe(map((r: PredicatesRestriction[]) => r.map((it) => this.toPredicatesRestriction(it, 'DENY'))));
  }
  private toPredicatesRestriction(r: PredicatesRestriction, allowOrDeny: 'ALLOW' | 'DENY'): PredicatesRestriction {
    const converted: PredicatesRestriction = this.converter.fromJSONtoObj(r, PredicatesRestriction);
    converted.allowOrDeny = allowOrDeny;
    return converted;
  }

  private saveSingleRestriction = <T>(restriction: T, endpointKey: string): Observable<T> =>
    this.http.post(this.environment.getMarkusRestEndpoint(endpointKey), this.converter.fromObjtoJSON(restriction)).pipe(map((r: T) => r));

  private validateAllowedPlatforms(r: Restriction) {
    if (isNilty(r.allowedSellingPlatforms)) throw Error();
    r.deniedSellingPlatforms = undefined;
  }
  private validateDeniedPlatforms(r: Restriction) {
    if (isNilty(r.deniedSellingPlatforms)) throw Error();
    r.allowedSellingPlatforms = undefined;
  }
}
