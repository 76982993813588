import { JsonObject, JsonProperty } from 'json2typescript';
import { isNilty } from '../../core/utils.service';
import { MatChipsData } from '../mat-chips-data-model';
import { Pagination } from '../shared/pagination-model';
import { Status } from '../status-model';

@JsonObject('StockItemFilters')
export class StockItemFilters extends Pagination {
  @JsonProperty('productBarcodeValue', String, true) productBarcodeValue: string = undefined;
  @JsonProperty('productBarcodeType', String, true) productBarcodeType: string = undefined;
  @JsonProperty('stockLocation', String, true) stockLocation: string = undefined;
  @JsonProperty('supplier', String, true) supplier: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('internalSku', String, true) internalSku: string = undefined;
  @JsonProperty('hasQuantity', Boolean, true) hasQuantity: boolean = undefined;
}

export const stockItemFiltersMap = (
  productBarcodeValue: string,
  productBarcodeType: Status,
  stockLocation: string,
  supplier: string,
  sku: string,
  internalSku: string,
  hasQuantity: boolean
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(productBarcodeValue)) {
    map.push(new MatChipsData('Barcode Value', productBarcodeValue));
  }
  if (!isNilty(productBarcodeType)) {
    map.push(new MatChipsData('Barcode Type', productBarcodeType.description));
  }
  if (!isNilty(stockLocation)) {
    map.push(new MatChipsData('Stock Location', stockLocation));
  }
  if (!isNilty(supplier)) {
    map.push(new MatChipsData('Supplier', supplier));
  }
  if (!isNilty(sku)) {
    map.push(new MatChipsData('Sku', sku));
  }
  if (!isNilty(internalSku)) {
    map.push(new MatChipsData('Internal Sku', internalSku));
  }
  if (!isNilty(hasQuantity) && hasQuantity) {
    map.push(new MatChipsData('Quantity > 0', ''));
  }

  return map;
};
