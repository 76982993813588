import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PublicationDto } from '../../../../../models/publications/publication-dto-model';
import { PUBLICATION_ERRORS } from '../../../../../models/publications/publication-model';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../../../../security/authorization.service';
import { Permissions } from '../../../../../models/security/permission-model';
import { Observable } from 'rxjs';
import { GenericErrorModalComponent } from '../../../../../shared/generic-error-modal/generic-error-modal.component';
//eslint-disable-next-line
import { ProductNetworkCategoryModalComponent } from '../../../../../shared/product-network-category-modal/product-network-category-modal.component';
import { ProductNetworkCategoryModalInput } from '../../../../../models/product/product-network-category-modal-input';

@Component({
  selector: 'app-publication-errors-modal',
  templateUrl: './publication-errors-modal.component.html',
  styleUrls: ['./publication-errors-modal.component.scss'],
})
export class PublicationErrorsModalComponent implements OnInit {
  handledErrors = PUBLICATION_ERRORS;
  hasSupplierFeedConfiguration: Observable<boolean>;
  hasStoreFeesConfiguration: Observable<boolean>;

  constructor(
    public dialogRef: MatDialogRef<PublicationErrorsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: PublicationDto,
    private router: Router,
    private authorizationService: AuthorizationService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.hasSupplierFeedConfiguration = this.authorizationService.hasZionPermission(Permissions.SupplierFeedConfiguration);
    this.hasStoreFeesConfiguration = this.authorizationService.hasMarkusPermission(Permissions.StoreFeesConfiguration);
  }

  checkPermissions(permissionCheck: Observable<boolean>): boolean {
    if (permissionCheck) {
      return true;
    } else {
      this.dialog.open(GenericErrorModalComponent, { data: "You don't have the right permissions to access this feature." });
      return false;
    }
  }

  openSupplierEdit() {
    if (this.checkPermissions(this.authorizationService.hasZionPermission(Permissions.SupplierFeedConfiguration))) {
      this.router
        .navigate(['edit-supplier'], { queryParams: { supplier: this.data.publication.supplierCode } })
        .finally(() => this.dialogRef.close());
    }
  }

  openStoreFees() {
    if (this.checkPermissions(this.authorizationService.hasMarkusPermission(Permissions.StoreFeesConfiguration))) {
      this.router
        .navigate(['configuration/fees'], { queryParams: { index: 0, storeCode: this.data.publication.store.code } })
        .finally(() => this.dialogRef.close());
    }
  }

  openCategoryFees() {
    // TODO it's not possible to add categoryFees from the FE
  }

  editProductCategories() {
    this.dialog.open(ProductNetworkCategoryModalComponent, { data: new ProductNetworkCategoryModalInput(this.data.publication) });
  }
}
