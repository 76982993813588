import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { ProductBarcode } from './product-barcode-model';
import { ZionBrand } from './zion-catalog-item-model';

@JsonObject('ProductMetadata')
export class ProductMetadata {
  @JsonProperty('productBarcode', ProductBarcode, true) productBarcode: ProductBarcode = undefined;
  @JsonProperty('title', String, true) title: string = undefined;
  @JsonProperty('brand', ZionBrand, true) brand: ZionBrand = undefined;
  @JsonProperty('category', String, true) category: string = undefined;
  @JsonProperty('categoryTree', String, true) categoryTree: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('gbAmazonPrice', Number, true) gbAmazonPrice: number = undefined;
  @JsonProperty('gbNewPrice', Number, true) gbNewPrice: number = undefined;
  @JsonProperty('deAmazonPrice', Number, true) deAmazonPrice: number = undefined;
  @JsonProperty('deNewPrice', Number, true) deNewPrice: number = undefined;
  @JsonProperty('itAmazonPrice', Number, true) itAmazonPrice: number = undefined;
  @JsonProperty('itNewPrice', Number, true) itNewPrice: number = undefined;
  @JsonProperty('frAmazonPrice', Number, true) frAmazonPrice: number = undefined;
  @JsonProperty('frNewPrice', Number, true) frNewPrice: number = undefined;
  @JsonProperty('esAmazonPrice', Number, true) esAmazonPrice: number = undefined;
  @JsonProperty('esNewPrice', Number, true) esNewPrice: number = undefined;

  @JsonProperty('wholesalePrice', Number, true) wholesalePrice: number = undefined;
  @JsonProperty('retailPrice', Number, true) retailPrice: number = undefined;
  @JsonProperty('description', String, true) description: string = undefined;
  @JsonProperty('height', String, true) height: string = undefined;
  @JsonProperty('width', String, true) width: string = undefined;
  @JsonProperty('thickness', String, true) thickness: string = undefined;
  @JsonProperty('hsCode', String, true) hsCode: string = undefined;
}

@JsonObject('Money')
export class Money {
  @JsonProperty('amount', Number, true) amount: number = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
}

@JsonObject('SourceMetadataDto')
export class SourceMetadataDto {
  @JsonProperty('productBarcodeValue', String, true) productBarcodeValue: string = undefined;
  @JsonProperty('productBarcodeType', String, true) productBarcodeType: string = undefined;
  @JsonProperty('brand', String, true) brand: string = undefined;
  @JsonProperty('category', String, true) category: string = undefined;
  @JsonProperty('categoryTree', String, true) categoryTree: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('wholesalePrice', Money, true) wholesalePrice: Money = undefined;
  @JsonProperty('retailPrice', Money, true) retailPrice: Money = undefined;
  @JsonProperty('heightCm', Number, true) heightCm: number = undefined;
  @JsonProperty('widthCm', Number, true) widthCm: number = undefined;
  @JsonProperty('thicknessCm', Number, true) thicknessCm: number = undefined;
  @JsonProperty('weightKg', Number, true) weightKg: number = undefined;
  @JsonProperty('hsCode', String, true) hsCode: string = undefined;
  @JsonProperty('asin', String, true) asin: string = undefined;
  @JsonProperty('authors', String, true) authors: string = undefined;
  @JsonProperty('publisher', String, true) publisher: string = undefined;
  @JsonProperty('source', String, true) source: string = undefined;
  @JsonProperty('sourceId', String, true) sourceId: string = undefined;
  @JsonProperty('translatedMetadata', Any, true) translatedMetadata: any = undefined;
}

@JsonObject('TranslatedMetadata')
export class TranslatedMetadata {
  @JsonProperty('languageCode', String, true) languageCode: string = undefined;
  @JsonProperty('title', String, true) title: string = undefined;
  @JsonProperty('description', String, true) description: string = undefined;
  @JsonProperty('customMetadata', Any, true) customMetadata: any = undefined;
}

@JsonObject('ProductMetadataWithAllMetadata')
export class ProductMetadataWithAllMetadata {
  @JsonProperty('postgresMetadataDto', Any, true) postgresMetadataDto: any = undefined;
  @JsonProperty('mongoMetadataDto', [SourceMetadataDto], true) mongoMetadataDto: SourceMetadataDto[] = undefined;
}

export class ProductDocument {
  url: string;
  type: string;
}
export class MetadataDocuments {
  language: string;
  documents: ProductDocument[];
}
