import { NgModule } from '@angular/core';
import { NetworkCategoryPipe } from '@shared/pipes/network-category.pipe';
import { AgGridModule } from 'ag-grid-angular';
import { SharedModule } from '../../shared.module';
import { FeesConfigurationService } from '../configuration/fees-configuration/fees-configuration.service';
import { PublicationRulesService } from '../configuration/markus-rules/publication-rules/publication-rules.service';
import { BlacklistService } from '../configuration/policies/blacklists/blacklist.service';
import { PaBarChartComponent } from './publication-analytics/pa-bar-chart/pa-bar-chart.component';
import { PaLineChartComponent } from './publication-analytics/pa-line-chart/pa-line-chart.component';
import { PaPieChartComponent } from './publication-analytics/pa-pie-chart/pa-pie-chart.component';
import { PaPieDetailChartComponent } from './publication-analytics/pa-pie-detail-chart/pa-pie-detail-chart.component';
import { PublicationAnalyticsComponent } from './publication-analytics/publication-analytics.component';
import { PublicationService } from './publication.service';
import { PublicationsByBarcodeComponent } from './publications-by-barcode/publications-by-barcode.component';
import { SearchByBarcodeComponent } from './publications-by-barcode/search-by-barcode/search-by-barcode.component';
import { PublicationsByStoreComponent } from './publications-by-store/publications-by-store.component';
import { SearchByStoreComponent } from './publications-by-store/search-by-store/search-by-store.component';
import { PublicationsComponent } from './publications.component';
import { AnomalouslyPricedModalComponent } from './shared/publications-filters-chips/anomalously-priced/anomalously-priced-modal.component';
import { PublicationsFiltersChipsComponent } from './shared/publications-filters-chips/publications-filters-chips.component';
import { PublicationsFiltersComponent } from './shared/publications-filters/publications-filters.component';
import { CatalogRecapModalComponent } from './shared/publications-list/catalog-recap-modal/catalog-recap-modal.component';
import { ColumnDefinitionsService } from './shared/publications-list/column-definitions.service';
import { PendingTooltipComponent } from './shared/publications-list/custom-tooltips/pending-tooltip.component';
import { PriceRangeTooltipComponent } from './shared/publications-list/custom-tooltips/price-range-tooltip.component';
import { StatusTooltipComponent } from './shared/publications-list/custom-tooltips/status-tooltip.component';
import { SupplierTooltipComponent } from './shared/publications-list/custom-tooltips/supplier-tooltip.component';
import { PublicationErrorsModalComponent } from './shared/publications-list/errors-modal/publication-errors-modal.component';
import { FeedHistoryModalComponent } from './shared/publications-list/feed-history-modal/feed-history-modal.component';
import { ParentHistoryModalComponent } from './shared/publications-list/parent-history-modal/parent-history-modal.component';
import { BlacklistPublicationModalComponent } from './shared/publications-list/publication-edit-button/modals/blacklist-publication-modal.component';
import { PublicationEditButtonComponent } from './shared/publications-list/publication-edit-button/publication-edit-button.component';
import { PublicationGridCellFormatter } from './shared/publications-list/publication-grid-cell-formatter';
import { PublicationPriceDetailsModalComponent } from './shared/publications-list/publication-price-details-modal/publication-price-details-modal.component';
import { PublicationsListComponent } from './shared/publications-list/publications-list.component';
import { StatusChangelogModalComponent } from './shared/publications-list/status-changelog-modal/status-changelog-modal.component';
import { PromoPriceComponent } from './promo-price/promo-price.component';
import { PromoPriceFiltersComponent } from './promo-price/promo-price-filters/promo-price-filters.component';

@NgModule({
  declarations: [
    PublicationsComponent,
    PublicationsByBarcodeComponent,
    PublicationsByStoreComponent,
    SearchByStoreComponent,
    PublicationsListComponent,
    PublicationsFiltersComponent,
    PublicationsFiltersChipsComponent,
    SearchByBarcodeComponent,
    PendingTooltipComponent,
    PublicationErrorsModalComponent,
    SupplierTooltipComponent,
    CatalogRecapModalComponent,
    PriceRangeTooltipComponent,
    PublicationEditButtonComponent,
    BlacklistPublicationModalComponent,
    ParentHistoryModalComponent,
    FeedHistoryModalComponent,
    StatusChangelogModalComponent,
    PublicationPriceDetailsModalComponent,
    StatusTooltipComponent,
    AnomalouslyPricedModalComponent,
    PublicationAnalyticsComponent,
    PaLineChartComponent,
    PaPieChartComponent,
    PaBarChartComponent,
    PaPieDetailChartComponent,
    PromoPriceComponent,
    PromoPriceFiltersComponent,
  ],
  imports: [SharedModule, AgGridModule],
  providers: [
    PublicationService,
    PublicationGridCellFormatter,
    ColumnDefinitionsService,
    NetworkCategoryPipe,
    PublicationRulesService,
    BlacklistService,
    FeesConfigurationService,
  ],
})
export class PublicationsModule {}
