import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('MetadataPriorityEntries')
export class MetadataPriorityEntries {
  @JsonProperty('priority', Number, true) priority: number = undefined;
  @JsonProperty('sourceId', String, true) sourceId: string = undefined;
  @JsonProperty('sourceType', String, true) sourceType: string = undefined;

  constructor(sourceType: string, actualPriority: number) {
    this.sourceType = sourceType;
    this.priority = actualPriority;
  }
}

@JsonObject('MetadataPriority')
export class MetadataPriority {
  @JsonProperty('field', String, true) field: string = undefined;
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('metadataPriorityEntries', [MetadataPriorityEntries], true) metadataPriorityEntries: MetadataPriorityEntries[] = [];

  constructor(field: string) {
    this.field = field;
  }
}
