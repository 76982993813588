import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Publication } from '@models/publications/publication-model';
import { Blacklist, BlacklistTarget, buildBarcodeBlacklist } from '@models/configuration/policies/blacklist/blacklist-model';
import { BlacklistReason } from '@models/configuration/policies/blacklist/blacklist-reason-model';
import { BlacklistTargetTypes } from '@models/configuration/policies/blacklist/blacklists-enums-model';
import { isNilty } from '@core/utils.service';
import { BlacklistService } from '../../../../../configuration/policies/blacklists/blacklist.service';
import { Observable } from 'rxjs';
import { AuthorizationService } from '@security/authorization.service';
import { formatDate } from '@angular/common';
import { GenericConfirmationModalComponent } from '@shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { BarcodeTypes, PolicyTypes } from '@models/configuration/policies/policy-enums';

@Component({
  selector: 'app-blacklist-publication-modal',
  templateUrl: 'blacklist-publication-modal.component.html',
  styles: [
    `
      .dialog-container {
        height: fit-content !important;
        min-width: 600px;
      }

      .barcode-radio-group {
        display: flex;
        flex-direction: column;
        margin: 15px 0;
        align-items: flex-start;
      }

      .barcode-radio-button {
        margin: 5px;
      }
    `,
  ],
})
export class BlacklistPublicationModalComponent {
  publication: Publication;

  availableTargets: BlacklistTarget[] = [];
  target: BlacklistTarget;
  reason: BlacklistReason;

  date = new Date();
  dateString: string;

  notes: string;

  barcodes: { type: string; barcode: string }[] = [];
  selectedBarcode: { type: string; barcode: string };

  blacklistReasons: Observable<BlacklistReason[]>;

  private readonly email: string;

  constructor(
    public dialogRef: MatDialogRef<BlacklistPublicationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Publication,
    private blacklistService: BlacklistService,
    private authorizationService: AuthorizationService,
    private matDialog: MatDialog
  ) {
    this.blacklistReasons = this.blacklistService.getBlacklistReasons();

    this.publication = this.data;
    this.availableTargets = [
      new BlacklistTarget(BlacklistTargetTypes.STORE, this.publication.store.code),
      new BlacklistTarget(BlacklistTargetTypes.MARKETPLACE, this.publication.store.marketplaceName),
      new BlacklistTarget(BlacklistTargetTypes.NETWORK, this.publication.store.networkType),
      new BlacklistTarget(BlacklistTargetTypes.GLOBAL, 'GLOBAL'),
    ];

    this.barcodes = [
      { type: BarcodeTypes.SKU, barcode: this.publication.sku },
      { type: BarcodeTypes.EAN, barcode: this.publication.barcode },
    ];
    if (!isNilty(this.publication.marketplaceMetadata?.marketplaceBarcode)) {
      this.barcodes.push({
        type: BarcodeTypes.MARKETPLACE_BARCODE,
        barcode: this.publication.marketplaceMetadata?.marketplaceBarcode,
      });
    }

    this.target = this.availableTargets[0];
    this.selectedBarcode = this.barcodes[0];

    this.email = this.authorizationService.currentUserMail;
  }

  formatDate(date: Date) {
    this.dateString = formatDate(date, 'dd/MM/yyyy', 'en-US') + ' 12:00';
  }

  confirm() {
    this.matDialog
      .open(GenericConfirmationModalComponent, { data: '' })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          const blacklist = new Blacklist(this.target, this.reason, this.email, this.dateString, this.notes, PolicyTypes.BARCODE);

          this.blacklistService
            .saveSingleBarcodeBlacklist(buildBarcodeBlacklist(blacklist, this.selectedBarcode.type, this.selectedBarcode.barcode))
            .subscribe(() => this.dialogRef.close());
        }
      });
  }
}
