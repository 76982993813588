import { JsonObject, JsonProperty } from 'json2typescript';
import { SellingPlatform } from '@models/configuration/marketplace/marketplace-model';
import { IdAndName } from '@models/configuration/marketplace/id-and-name-model';
import { StoreGroup } from '@models/configuration/marketplace/store-group-model';

@JsonObject('MarketplaceView')
export class MarketplaceView {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('country', String, true) country: string = undefined;
  @JsonProperty('sellingPlatform', SellingPlatform, true) sellingPlatform: SellingPlatform = undefined;
}

@JsonObject('OwnerView')
export class OwnerView extends IdAndName {}

@JsonObject('AccountView')
export class AccountView {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('owner', OwnerView, true) owner: OwnerView = undefined;
}

@JsonObject('StoreView')
export class StoreView {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('country', String, true) country: string = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('orderType', String, true) orderType: string = undefined;
  @JsonProperty('channelStoreName', String, true) channelStoreName: string = undefined;
  @JsonProperty('marketplace', MarketplaceView, true) marketplace: MarketplaceView = undefined;
  @JsonProperty('account', AccountView, true) account: AccountView = undefined;
  @JsonProperty('storeGroup', StoreGroup, true) storeGroup: StoreGroup = undefined;
}

export class StoreViewFilters {
  storeCodeOrName: string;

  owner: string;
  accountCode: string;

  sellingPlatform: string;
  marketplace: string;

  storeGroup: string;
}
