import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Status } from 'src/app/models/status-model';
import { ConverterService } from '../../core/converter.service';
import { EnvironmentService } from '../../core/environment/environment.service';
import { SupplierFilters } from '../../models/filters/supplier';
import { Supplier, SupplierDiscountCode, InvoiceTo } from '../../models/supplier-model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SuppliersService {
  resultsNumber = new Subject<number>();
  suppliers: Supplier[];
  productBarcodeTypes: Status[];

  private gettingAllSuppliers = false;
  private allSuppliersSubject = new Subject<Supplier[]>();

  constructor(
    private converter: ConverterService,
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private snackBar: MatSnackBar
  ) {}

  getSupplierByCode(code: string): Observable<Supplier> {
    const filters = { code } as SupplierFilters;
    return this.getFilteredSuppliers(filters).pipe(map((resp: Supplier[]) => resp.find((supplier) => supplier.code === code)));
  }

  getFilteredSuppliers(filters: SupplierFilters): Observable<Supplier[]> {
    const code = filters.code ?? '';
    const pageIndex = filters.pageNumber ?? 0;
    const pageSize = filters.pageSize ?? 10;
    const sortBy = filters.sortBy ?? 'orderedOn';
    const sortDirection = filters.sortDirection ?? 'desc';

    const queryPath =
      '?code=' + code + '&page-index=' + pageIndex + '&page-size=' + pageSize + '&sort-by=' + sortBy + '&sort-direction=' + sortDirection;

    return this.http.get(this.environmentService.getRestEndpoint('suppliers') + queryPath, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: Supplier[]) => resp.map((r) => this.converter.fromJSONtoObj(r, Supplier)))
    );
  }

  getAllSuppliers(): Observable<Supplier[]> {
    if (this.suppliers) {
      return of(this.suppliers);
    }

    if (!this.gettingAllSuppliers) {
      this.gettingAllSuppliers = true;
      return this.http.get(this.environmentService.getRestEndpoint('suppliers') + '/all', { observe: 'response' }).pipe(
        map((resp: HttpResponse<Supplier[]>) => {
          this.suppliers = resp.body.map((r) => {
            const s: Supplier = this.converter.fromJSONtoObj(r, Supplier);
            s.initDescription();
            return s;
          });
          this.allSuppliersSubject.next(this.suppliers);
          return this.suppliers;
        })
      );
    } else {
      return this.allSuppliersSubject;
    }
  }

  getProductBarcodeTypes(): Observable<Status[]> {
    if (this.productBarcodeTypes) {
      return of(this.productBarcodeTypes);
    }

    return this.http
      .get(this.environmentService.getRestEndpoint('productBarcodes') + '/types', { observe: 'response' })
      .pipe(
        map((resp: HttpResponse<Status[]>) => (this.productBarcodeTypes = resp.body.map((r) => this.converter.fromJSONtoObj(r, Status))))
      );
  }

  getSupplierDiscounts(supplierCode: string): Observable<SupplierDiscountCode[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('supplierDiscounts') + supplierCode)
      .pipe(map((resp: SupplierDiscountCode[]) => resp.map((it) => this.converter.fromJSONtoObj(it, SupplierDiscountCode))));
  }

  deleteDiscounts(supplierCode: string): Observable<any> {
    return this.http.delete(this.environmentService.getRestEndpoint('supplierDiscounts') + supplierCode);
  }

  loadNewDiscounts(file: File, supplierCode: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    const path = this.environmentService.getRestEndpoint('supplierDiscounts') + supplierCode;

    return this.http.post(path, formData);
  }

  saveSupplier(supplier: Supplier): Observable<Supplier> {
    const body = this.converter.fromObjtoJSON(supplier);

    return this.http.post(this.environmentService.getRestEndpoint('suppliers'), body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => resp.body),
      map((resp: Supplier) => this.converter.fromJSONtoObj(resp, Supplier))
    );
  }

  uploadInvoiceInfo(file: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(this.environmentService.getRestEndpoint('supplierInvoice'), formData).pipe(
      map(() => {
        this.snackBar.open('File uploaded. ', 'CLOSE')._dismissAfter(3000);
      })
    );
  }

  getInvoiceToByCountry(supplierId: number): Observable<InvoiceTo[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('supplierInvoice') + supplierId)
      .pipe(map((i: any) => this.converter.fromJSONtoObj(i, InvoiceTo)));
  }
}
