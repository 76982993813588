import { isNilty } from '@core/utils.service';

const S3_ASSETS_BASE_URL = 'https://brandon-frontend-assets.s3.eu-west-1.amazonaws.com/';
const SHARED_ASSETS_FOLDER = 'shared/';

const assetsPaths = {
  flags: 'img/flags/',
  ownerIcons: 'img/owners/',
};

export class S3AssetsHandler {
  static mainLogo = S3_ASSETS_BASE_URL + SHARED_ASSETS_FOLDER + 'img/logo.png';

  static flagPlaceholder = S3_ASSETS_BASE_URL + SHARED_ASSETS_FOLDER + assetsPaths.flags + 'flag_placeholder.png';

  static getOwnerIcon(ownerName: string): string {
    const imageName = ownerName.toLowerCase() + '.png';

    if (isNilty(imageName)) return undefined;

    return S3_ASSETS_BASE_URL + SHARED_ASSETS_FOLDER + assetsPaths.ownerIcons + imageName;
  }
}
