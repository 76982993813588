import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService } from '@core/converter.service';
import { EnvironmentService } from '@core/environment/environment.service';
import { UkExchangeRates } from '@models/uk-exchange-rates-model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UkExchangeRatesService {
  exchangeRatesResultsNumber = new Subject<number>();

  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getUkExchangeRates(): Observable<UkExchangeRates[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('ukExchangeRates'))
      .pipe(map((resp: UkExchangeRates[]) => resp.map((it) => this.converter.fromJSONtoObj(it, UkExchangeRates))));
  }

  saveUkExchangeRates(obj: UkExchangeRates): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('ukExchangeRates'), obj);
  }
}
