import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService } from '@core/converter.service';
import { EnvironmentService } from '@core/environment/environment.service';
import { ExchangeRates, ExchangeRatesFilters } from '@models/exchange-rates-model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNilty } from 'src/app/core/utils.service';

@Injectable()
export class ExchangeRatesService {
  exchangeRatesResultsNumber = new Subject<number>();

  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getExchangeRatesEndpoints(): Observable<ExchangeRates[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('exchangeRates'))
      .pipe(map((resp: ExchangeRates[]) => resp.map((it) => this.converter.fromJSONtoObj(it, ExchangeRates))));
  }

  getExchangeRatesByFilters(filter: ExchangeRatesFilters): Observable<ExchangeRates[]> {
    const body = this.converter.fromObjtoJSON(filter);
    return this.http
      .post(this.environmentService.getRestEndpoint('exchangeRates'), body, { observe: 'response' })
      .pipe(
        map((resp: HttpResponse<any>) => {
          this.exchangeRatesResultsNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        })
      )
      .pipe(
        map((resp: any[]) => {
          if (!isNilty(resp)) {
            return resp.map((it) => this.converter.fromJSONtoObj(it, ExchangeRates));
          } else {
            return [];
          }
        })
      );
  }
}
