import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { PublicationRulesService } from '../publication-rules.service';
import { PublicationRule } from '@models/configuration/rules/publication-rule/publication-rule-model';
import { MatDialog } from '@angular/material/dialog';
import { StoreSelectModalComponent } from './store-select-modal/store-select-modal.component';
import { PublicationRuleStore } from '@models/configuration/rules/publication-rule/publication-rule-store-model';
import { isNilty } from '@core/utils.service';
import { GenericConfirmationModalComponent } from '@shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { RuleRefreshTriggerModalComponent } from '../../rule-refresh-trigger-modal/rule-refresh-trigger-modal.component';

@Component({
  selector: 'app-publication-rules-edit',
  templateUrl: './publication-rule-edit.component.html',
  styleUrls: ['./publication-rule-edit.component.css'],
})
export class PublicationRuleEditComponent implements OnInit, OnDestroy {
  editMode: boolean;

  currentRuleId: string;
  currentRule: PublicationRule = new PublicationRule();

  selectedStore = new EventEmitter<void>();

  constructor(
    private publicationRulesService: PublicationRulesService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (isNilty(params['id'])) {
        this.editMode = false;
        this.currentRule = new PublicationRule();
      } else {
        this.editMode = true;
        this.currentRuleId = params['id'];
        this.getRule();
      }
    });
  }

  getRule() {
    this.publicationRulesService.findRuleById(this.currentRuleId).subscribe((it) => {
      this.currentRule = it;
      console.log(this.currentRule);
    });
  }

  selectStore() {
    // Loading the fields when initializing the parent component. Children will receive the cached values.
    this.publicationRulesService.getRulePredicateFields().subscribe();

    this.dialog
      .open(StoreSelectModalComponent, { data: this.currentRule.store })
      .afterClosed()
      .subscribe((s: PublicationRuleStore) => {
        if (!isNilty(s)) {
          this.currentRule.store = s;

          this.selectedStore.emit();
        }
      });
  }

  save() {
    this.currentRule.customProfits = this.currentRule.customProfits.filter((it) => it.predicates.length > 0);
    this.publicationRulesService.saveRule(this.currentRule).subscribe(() => {
      if (this.currentRule.active) {
        this.dialog
          .open(RuleRefreshTriggerModalComponent, {
            data: this.currentRule,
            disableClose: true,
          })
          .afterClosed()
          .subscribe(() => {
            this.back();
          });
      } else {
        this.dialog
          .open(GenericConfirmationModalComponent, {
            data: 'This Rule is now inactive. Do you want to start a job to delete all linked publications from the Marketplace?',
          })
          .afterClosed()
          .subscribe((r: boolean) => {
            if (r) {
              this.publicationRulesService.unpublishRule(this.currentRule).subscribe(() => this.back());
            } else {
              this.back();
            }
          });
      }
    });
  }

  back() {
    this.location.back();
  }

  ngOnDestroy() {
    this.publicationRulesService.rulePredicateFieldsCache.reset();
  }
}
