import { Component, Input, OnInit } from '@angular/core';
import {
  RuleOrderType,
  RuleSourceSkuType,
  RuleStockLocationSource,
  RuleSupplierSource,
} from '@models/configuration/rules/publication-rule-source-model';
import { SuppliersService } from '../../../../../../suppliers/suppliers.service';
import { isNilty } from '@core/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericConfirmationModalComponent } from '@shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { StockLocation } from '@models/stock-location-model';
import { StockLocationsService } from '../../../../../../stock-locations/stock-locations.service';
import { Supplier } from '@models/supplier-model';
import { Router } from '@angular/router';
import { MarginParameters } from '@models/configuration/rules/pricing-strategy-model';

@Component({
  selector: 'app-stock-location-sources',
  templateUrl: './stock-location-sources.component.html',
  styleUrls: ['./stock-location-sources.component.scss'],
})
export class StockLocationSourcesComponent implements OnInit {
  ruleSources: RuleStockLocationSource[] = [];
  filteredRuleSources: RuleStockLocationSource[] = [];

  filterKey: string;

  suppliers: Supplier[] = [];

  stockLocations: StockLocation[] = [];

  ruleSourceTypes = Object.keys(RuleSourceSkuType);

  ruleOrderTypes = Object.keys(RuleOrderType);

  isPowerSeller = false;

  constructor(
    private suppliersService: SuppliersService,
    private dialog: MatDialog,
    private stockLocationService: StockLocationsService,
    private router: Router
  ) {}

  @Input() set _stockLocationSources(r: RuleStockLocationSource[]) {
    this.powerSeller();
    this.ruleSources = r;
    this.initSources();
  }

  ngOnInit() {
    this.suppliersService.getAllSuppliers().subscribe((r) => {
      this.suppliers = r;
    });

    this.stockLocationService.getAllStockLocations().subscribe((r) => {
      this.stockLocations = r;
      this.initSources();
    });
  }

  powerSeller() {
    this.isPowerSeller = this.router.url.includes('power-seller-rules');
  }

  add() {
    const newSource = new RuleStockLocationSource();
    this.ruleSources.push(newSource);
    this.filteredRuleSources.push(newSource);
  }

  addCustomPricing(source: RuleSupplierSource) {
    source.pricingParameters = new MarginParameters();
  }
  removeCustomPricing(source: RuleSupplierSource) {
    source.pricingParameters = undefined;
  }

  filterSources(string?: string) {
    const sorted = this.sortedSources();
    if (isNilty(string)) {
      this.filteredRuleSources = sorted;
    } else {
      this.filteredRuleSources = sorted.filter((it) => it.stockLocationCode.toUpperCase().indexOf(string.toUpperCase()) !== -1);
    }
  }

  selectedSource(event: number, source: RuleStockLocationSource) {
    this.assignDescription(source, event);
  }
  selectedActualSupplier(event: number, source: RuleStockLocationSource) {
    if (isNilty(event)) {
      source.actualSupplierCode = undefined;
      source.actualSupplierId = undefined;
    } else {
      source.actualSupplierId = event;
      source.actualSupplierCode = this.suppliers.find((it) => it.id === event)?.code;
    }
    this.validateActualSupplier(source);
  }

  removeSource(source: RuleStockLocationSource) {
    this.dialog
      .open(GenericConfirmationModalComponent, {
        data: `This will remove the Source for ${source.stockLocationCode} and all its predicates.`,
      })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          const index = this.ruleSources.findIndex((it) => it.stockLocationCode === source.stockLocationCode);
          this.ruleSources.splice(index, 1);
          this.filterSources(this.filterKey);
        }
      });
  }

  private initSources() {
    if (isNilty(this.ruleSources || isNilty(this.stockLocations))) {
      return;
    }
    this.ruleSources.forEach((it) => {
      if (!isNilty(it.stockLocationId)) {
        this.assignDescription(it, it.stockLocationId);
      }
    });
    this.filterSources();
  }

  private assignDescription(source: RuleStockLocationSource, stockLocationId: number) {
    const s = this.stockLocations.find((it) => it.id === stockLocationId);
    if (!isNilty(s)) {
      source.stockLocationCode = s.code;
    }
  }

  private sortedSources(): RuleStockLocationSource[] {
    return [...this.ruleSources].sort((i, j) => (i.stockLocationCode > j.stockLocationCode ? 1 : -1));
  }

  private validateActualSupplier(updatedSource: RuleStockLocationSource) {
    if (
      this.ruleSources.filter(
        (s) => s.actualSupplierId === updatedSource.actualSupplierId && s.stockLocationId === updatedSource.stockLocationId
      ).length > 1
    ) {
      updatedSource.valid = false;
      updatedSource.error = `This stockLocation/actualSupplier combination already exist in another source`;
    } else {
      updatedSource.valid = true;
      updatedSource.error = undefined;
    }
  }
}
