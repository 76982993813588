import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  MarginParameters,
  MarkupParameters,
  PricingParameters,
  PricingStrategy,
  RetailPriceParameters,
} from '@models/configuration/rules/pricing-strategy-model';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-pricing-strategy',
  templateUrl: './pricing-strategy.component.html',
  styleUrls: ['./pricing-strategy.component.scss'],
})
export class PricingStrategyComponent {
  @Output() pricingParametersChange = new EventEmitter<PricingParameters>();

  parameters: PricingParameters;
  pricingStrategies = Object.keys(PricingStrategy);

  protected readonly PricingStrategy = PricingStrategy;

  @Input() set pricingParameters(p: PricingParameters) {
    this.parameters = { ...p };
  }

  selectedPricingStrategy(strategy: PricingStrategy) {
    if (isNilty(this.parameters)) return;

    if (this.parameters.pricingStrategy === strategy) return;
    switch (strategy) {
      case PricingStrategy.Margin:
        this.parameters = new MarginParameters(this.parameters);
        break;
      case PricingStrategy.Markup:
        this.parameters = new MarkupParameters(this.parameters);
        break;
      case PricingStrategy.RetailPrice:
        this.parameters = new RetailPriceParameters();
        break;
      default:
        break;
    }
    this.pricingParametersChange.emit(this.parameters);
  }

  update() {
    this.pricingParametersChange.emit(this.parameters);
  }
}
