import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  RuleOperator,
  RulePredicate,
  RulePredicateField,
  RulePredicateFieldsOutput,
} from '@models/configuration/rules/rule-predicate-model';
import { PublicationRulesService } from '../../publication-rules.service';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-predicates-edit',
  templateUrl: './predicates-edit.component.html',
  styleUrls: ['./predicates-edit.component.scss'],
})
export class PredicatesEditComponent {
  @Input() editMode = true;
  @Output() predicatesChanged = new EventEmitter<void>();

  predicates: RulePredicate[] = [];
  fieldsOutput: RulePredicateFieldsOutput[] = [];

  fields: RulePredicateField[];

  constructor(private publicationRulesService: PublicationRulesService) {}

  @Input() set _predicates(p: RulePredicate[]) {
    this.predicates = p ? p : [];
    this.getFields();
  }

  getFields() {
    this.publicationRulesService.getRulePredicateFields().subscribe((r) => {
      this.fieldsOutput = r;
      this.fields = r.map((it) => it.field);
      this.predicates.forEach((p) => {
        this.setPredicateField(p.field.fieldName, p);
        p.setDescription();
      });
    });
  }

  addPredicate() {
    const p = new RulePredicate();
    p.field = new RulePredicateField();
    p.operator = new RuleOperator();
    p.isEdit = true;
    this.predicates.push(p);
  }

  setPredicateField(fieldName: string, predicate: RulePredicate) {
    const selectedField = this.fieldsOutput.find((it) => it.field.fieldName === fieldName);
    predicate.field = selectedField.field;
    predicate.availableOperators = selectedField.operators;
  }

  setPredicateOperator(operatorName: string, predicate: RulePredicate) {
    predicate.operator = predicate.availableOperators.find((it) => it.name === operatorName);
  }

  confirmPredicate(predicate: RulePredicate) {
    predicate.setDescription();
    predicate.isEdit = false;
    this.predicatesChanged.emit();
  }

  deletePredicate(predicate: RulePredicate) {
    const index = this.predicates.findIndex((it) => it === predicate);
    this.predicates.splice(index, 1);
    this.predicatesChanged.emit();
  }

  validateStringsList(target: string, predicate: RulePredicate) {
    predicate.target = target
      .split(',')
      .map((it) => it.trim())
      .filter((it) => !isNilty(it))
      .join(',');
  }
}
