import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PublicationDto } from '../../../../models/publications/publication-dto-model';
import { CatalogItem } from '../../../../models/catalog-item-model';
import { PublicationService } from '../../../publications/publication.service';
import { PublicationFilters } from '../../../../models/publications/filters/publication-filters-model';
import { Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Publication } from '../../../../models/publications/publication-model';

@Component({
  selector: 'app-catalog-publications',
  templateUrl: './catalog-publications.component.html',
  styleUrls: ['./catalog-publications.component.css'],
})
export class CatalogPublicationsComponent implements OnInit {
  @Input() catalogItem: CatalogItem;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource = new MatTableDataSource<PublicationDto>([]);
  displayedColumns = ['store', 'status', 'publishedQuantity', 'publishedPrice', 'pendingOperation'];

  resultsNumber: Observable<number>;

  constructor(private publicationService: PublicationService, private router: Router) {}

  ngOnInit() {
    this.resultsNumber = this.publicationService.resultsNumber;
    this.dataSource.paginator = this.paginator;

    const filters = new PublicationFilters();
    filters.supplierId = this.catalogItem.supplier.id;
    this.publicationService
      .getPublicationsByProductBarcodeAndFilters(this.catalogItem.productBarcode.value, filters)
      .subscribe((p) => (this.dataSource.data = p));
  }

  openPublication(p: Publication) {
    this.publicationService.byBarcodeFilters = new PublicationFilters();
    this.publicationService.byBarcodeFilters.storeCode = p.store.code;
    this.publicationService.byBarcodeFilters.supplierId = p.supplierId;
    this.router.navigate(['publications'], { queryParams: { barcode: p.barcode, tab: 1 } });
  }
}
