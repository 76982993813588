import { JsonObject, JsonProperty } from 'json2typescript';
import { PublicationPrice } from './publication-price-model';

@JsonObject('FeedHistoryEntry')
export class FeedHistoryEntry {
  @JsonProperty('feedId', String, true) feedId: string = undefined;
  @JsonProperty('operation', String, true) operation: string = undefined;
  @JsonProperty('feedMarketplaceType', String, true) feedMarketplaceType: string = undefined;
  @JsonProperty('submittedAt', String, true) submittedAt: string = undefined;
  @JsonProperty('finishedAt', String, true) finishedAt: string = undefined;
  @JsonProperty('quantity', Number, true) quantity: number = undefined;
  @JsonProperty('price', PublicationPrice, true) price: PublicationPrice = undefined;
  @JsonProperty('fulfilmentLatency', Number, true) fulfilmentLatency: number = undefined;
}
