import { Component, OnInit } from '@angular/core';
import { BlacklistSearchType, SearchBlacklistDto } from '@models/configuration/policies/blacklist/blacklist-utils';
import { Observable, Subject } from 'rxjs';
import { Permissions } from '@models/security/permission-model';
import { AuthorizationService } from '@security/authorization.service';

@Component({
  selector: 'app-blacklists-configuration',
  templateUrl: './blacklists-configuration.component.html',
  styleUrls: ['./blacklists-configuration.component.scss'],
})
export class BlacklistsConfigurationComponent implements OnInit {
  BlacklistSearchType = BlacklistSearchType;
  searchDto: SearchBlacklistDto = undefined;

  doSearchSubject = new Subject<SearchBlacklistDto>();
  hasBlacklistManagement: Observable<boolean>;

  constructor(private authorizationService: AuthorizationService) {}

  ngOnInit() {
    this.hasBlacklistManagement = this.authorizationService.hasMarkusPermission(Permissions.BlacklistManagement);
  }

  search(payload: SearchBlacklistDto) {
    this.searchDto = payload;
    this.doSearchSubject.next(this.searchDto);
  }
}
