import { NgModule } from '@angular/core';
import { RestrictionsConfigurationComponent } from './restrictions-configuration.component';
import { PoliciesModule } from '../policies.module';
import { RestrictionsSearcherComponent } from './restrictions-searcher/restrictions-searcher.component';
import { InsertRestrictionComponent } from './insert-restriction/insert-restriction.component';
import { SearchPowerSellersComponent } from './search-power-sellers/search-power-sellers.component';
import { BlacklistsModule } from '../blacklists/blacklists.module';
import { SelectRestrictionTargetComponent } from './insert-restriction/select-restriction-target/select-restriction-target.component';
import { RestrictionPlatformsListComponent } from './insert-restriction/restriction-platforms-list/restriction-platforms-list.component';
import { CreateSellingPlatformModalComponent } from './create-selling-platform-modal/create-selling-platform-modal.component';
import { RestrictionsByTargetComponent } from './restrictions-by-target/restrictions-by-target.component';
import { RestrictionRowComponent } from './shared/restriction-row.component';
import { RestrictionDetailsModalComponent } from './shared/restriction-details-modal/restriction-details-modal.component';
import { BarcodeRestrictionsListComponent } from './shared/barcode-restrictions-list/barcode-restrictions-list.component';

@NgModule({
  declarations: [
    RestrictionsConfigurationComponent,
    RestrictionsSearcherComponent,
    InsertRestrictionComponent,
    SearchPowerSellersComponent,
    SelectRestrictionTargetComponent,
    RestrictionPlatformsListComponent,
    CreateSellingPlatformModalComponent,
    RestrictionsByTargetComponent,
    RestrictionRowComponent,
    RestrictionDetailsModalComponent,
    BarcodeRestrictionsListComponent,
  ],
  imports: [PoliciesModule, BlacklistsModule],
  providers: [],
})
export class RestrictionsModule {}
