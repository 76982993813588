import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { ProductBarcode } from './product-barcode-model';
import { BaseEntity } from './shared/base-entity-model';
import { Supplier } from './supplier-model';
import { CatalogItemPriceDto } from '@models/catalog-item-price';

@JsonObject('CatalogItem')
export class CatalogItem extends BaseEntity {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('productBarcode', ProductBarcode, true) productBarcode: ProductBarcode = undefined;
  @JsonProperty('supplier', Supplier, true) supplier: Supplier = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('physicalQuantity', Number, true) physicalQuantity: number = undefined;
  @JsonProperty('availableQuantity', Number, true) availableQuantity: number = undefined;
  @JsonProperty('purchasePrice', Number, true) purchasePrice: number = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('discountedPrice', Number, true) discountedPrice: number = undefined;
  @JsonProperty('discountPercentage', Number, true) discountPercentage: number = undefined;
  @JsonProperty('discountCode', String, true) discountCode: string = undefined;
  @JsonProperty('availabilityCode', String, true) availabilityCode: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('vat', Number, true) vat: number = undefined;
  @JsonProperty('title', String, true) title: string = undefined;
  @JsonProperty('unNumber', String, true) unNumber: string = undefined;

  @JsonProperty('salesRight', String, true) salesRight: string = undefined;
  @JsonProperty('salesRightExclude', String, true) salesRightExclude: string = undefined;
  @JsonProperty('countryOfOrigin', String, true) countryOfOrigin: string = undefined;
  @JsonProperty('publisher', String, true) publisher: string = undefined;
  @JsonProperty('commodity', Any, true) commodity: any = undefined;

  @JsonProperty('prices', [CatalogItemPriceDto], true) prices: CatalogItemPriceDto[] = [];

  showPrices = false;
  salesRightArray: string[];
  salesRightExcludeArray: string[];

  initSalesRightArrays() {
    this.salesRightArray = this.salesRight ? this.salesRight.split(',') : [];
    this.salesRightExcludeArray = this.salesRightExclude ? this.salesRightExclude.split(',') : [];
  }
}

export enum CatalogItemStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}
