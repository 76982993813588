import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'myPercentPipe',
})
export class MyPercentPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number, isDecimal: boolean = false): string {
    if (value == null) {
      return ' -- ';
    }
    return this.decimalPipe.transform(isDecimal ? value * 100 : value, '1.2-2') + ' %';
  }
}
