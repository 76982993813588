import { JsonObject, JsonProperty } from 'json2typescript';
import { Pagination } from '../../../shared/pagination-model';

@JsonObject('MarketplaceCategoryFeesFilters')
export class MarketplaceCategoryFeesFilters extends Pagination {
  @JsonProperty('marketplaceId', Number, true) marketplaceId: number = undefined;
  @JsonProperty('networkCategory', String, true) networkCategory: string = undefined;

  constructor() {
    super();
    this.pageSize = 20;
  }
}

@JsonObject('NetworkCategoryFeesFilters')
export class NetworkCategoryFeesFilters extends Pagination {
  @JsonProperty('network', String, true) network: string = undefined;
  @JsonProperty('networkCategory', String, true) networkCategory: string = undefined;

  constructor() {
    super();
    this.pageSize = 20;
  }
}
