import { JsonObject, JsonProperty } from 'json2typescript';
import { Role } from './role-model';

@JsonObject('User')
export class User {
  @JsonProperty('id', String, false) id: string = undefined;
  @JsonProperty('email', String, false) email: string = undefined;
  @JsonProperty('enabled', Boolean, false) enabled: boolean = undefined;
  @JsonProperty('roles', [Role], false) roles: Role[] = undefined;
}
