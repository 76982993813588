import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SuppliersService } from '../../suppliers/suppliers.service';
import { CommonsService } from '../../../shared/commons.service';
import { Supplier } from '../../../models/supplier-model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Status } from '../../../models/status-model';
import { ProductsService } from '../products.service';
import { StockLocation } from '../../../models/stock-location-model';
import { StockLocationsService } from '../../stock-locations/stock-locations.service';
import { NewProductRequest } from '../../../models/product/new-product-request';
import { ZionBrand } from '../../../models/zion-catalog-item-model';
import { Category } from 'src/app/models/category-model';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css'],
})
export class AddProductComponent implements OnInit {
  @Output() filterBrandsEv = new EventEmitter<ZionBrand>();
  @Output() filterCategoriesEv = new EventEmitter<Category>();
  @Output() filterStockLocationsEv = new EventEmitter<StockLocation>();
  @Output() filterSuppliersEv = new EventEmitter<Supplier>();
  suppliers: Supplier[];
  productBarcodeTypes: Status[];
  stockLocations: StockLocation[];
  categories: Category[];
  brands: ZionBrand[];
  productForm: FormGroup;
  filterBrands = new ZionBrand();
  filterCategories = new Category();
  filterStockLocations = new StockLocation();
  filterSuppliers = new Supplier();
  isFormValid = false;

  constructor(
    public dialogRef: MatDialogRef<AddProductComponent>,
    private suppliersService: SuppliersService,
    private stockLocationsService: StockLocationsService,
    private productsService: ProductsService,
    private commonService: CommonsService
  ) {}
  ngOnInit() {
    this.productForm = new FormGroup({
      barcode: new FormControl(null, Validators.required),
      price: new FormControl(null, Validators.required),
      discountedPurchasePrice: new FormControl(null, Validators.required),
      supplier: new FormControl(null, Validators.required),
      stockLocation: new FormControl(null, Validators.required),
      title: new FormControl(null),
      catalogItemSku: new FormControl(null),
      physicalQuantity: new FormControl(null),
      availableQuantity: new FormControl(null),
      brand: new FormControl(null),
      category: new FormControl(null),
    });

    this.commonService.getBrands().subscribe((r) => (this.brands = r));
    this.commonService.getCategories().subscribe((r) => (this.categories = r));
    this.stockLocationsService.getAllStockLocations().subscribe((r) => (this.stockLocations = r));
    this.suppliersService.getProductBarcodeTypes().subscribe((r) => (this.productBarcodeTypes = r));
    this.suppliersService.getAllSuppliers().subscribe((r) => (this.suppliers = r));

    this.productForm.valueChanges.subscribe(() => {
      this.isFormValid =
        this.productForm.value.price > 0 && this.productForm.value.discountedPurchasePrice > 0 && !isNilty(this.productForm.value.barcode);
    });
  }

  onSubmit() {
    this.filterStockLocationsEv.emit(this.filterStockLocations);
    this.filterCategoriesEv.emit(this.filterCategories);
    this.filterSuppliersEv.emit(this.filterSuppliers);
    this.filterBrandsEv.emit(this.filterBrands);

    const requestBody = new NewProductRequest();

    requestBody.supplierId = this.filterSuppliers.id;
    requestBody.stockLocationId = this.filterStockLocations.id;
    requestBody.categoryId = this.filterCategories.id;
    requestBody.brandId = this.filterBrands.id;
    requestBody.barcode = this.productForm.value.barcode;
    requestBody.price = this.productForm.value.price;
    requestBody.discountedPurchasePrice = this.productForm.value.discountedPurchasePrice;
    requestBody.title = this.productForm.value.title;
    requestBody.catalogItemSku = this.productForm.value.catalogItemSku;
    requestBody.categoryId = this.productForm.value.category;
    requestBody.physicalQuantity = this.productForm.value.physicalQuantity;
    requestBody.availableQuantity = this.productForm.value.availableQuantity;

    this.productsService.addProduct(requestBody).subscribe(() => {
      this.dialogRef.close(true);
    });
  }

  close() {
    this.dialogRef.close(false);
  }
}
