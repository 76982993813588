import { Component, Inject } from '@angular/core';
import { PublicationService } from '../../../publication.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Publication } from '@models/publications/publication-model';

@Component({
  selector: 'app-anomalously-priced-modal',
  templateUrl: './anomalously-priced-modal.component.html',
})
export class AnomalouslyPricedModalComponent {
  constructor(
    public dialogRef: MatDialogRef<AnomalouslyPricedModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: Publication,
    private publicationService: PublicationService
  ) {}

  onUploadClicked(): void {
    const fileInput = document.getElementById('file') as HTMLInputElement;
    fileInput.click();
  }

  uploadAnomalouslyPricedFile(event) {
    const currentFileUpload = event.target.files[0];
    this.publicationService.loadAnomalouslyPricedFile(currentFileUpload).subscribe();
  }
}
