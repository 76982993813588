import { BarcodeTypes } from '@models/configuration/policies/policy-enums';

export enum BlacklistTargetTypes {
  STORE = 'STORE',
  MARKETPLACE = 'MARKETPLACE',
  NETWORK = 'NETWORK',
  GLOBAL = 'GLOBAL',
}

export const getAllowedBlacklistBarcodeTypes = (targetType: string): string[] => {
  if (targetType !== BlacklistTargetTypes.STORE && targetType !== BlacklistTargetTypes.MARKETPLACE) {
    // A MARKETPLACE_BARCODE blacklist makes sense only for Marketplaces and Stores.
    return Object.keys(BarcodeTypes).filter((it) => it !== 'MARKETPLACE_BARCODE');
  } else {
    return Object.keys(BarcodeTypes);
  }
};
