import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PromoPriceFilters, promoPriceFiltersMap } from '@models/promo-price-model';
import { Subject } from 'rxjs';
import { StoreService } from 'src/app/container/configuration/store-configuration/store.service';
import { PromoPriceService } from '../promo-price.service';
import { isNilty } from '@core/utils.service';
import { ZionStore } from '@models/publications/published-store-model';

@Component({
  selector: 'app-promo-price-filters',
  templateUrl: './promo-price-filters.component.html',
  styleUrls: ['./promo-price-filters.component.css'],
})
export class PromoPriceFiltersComponent implements OnInit {
  @Output() onFilter = new Subject<PromoPriceFilters>();

  filterForm: FormGroup;

  public openFilter = false;
  filters: PromoPriceFilters;
  filtersMap = [];

  stores: ZionStore[] = [];

  constructor(private promoPriceService: PromoPriceService, private storeService: StoreService) {}

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      storeCode: new FormControl(),
      productBarcode: new FormControl(),
      range: new FormGroup({
        dateFrom: new FormControl(),
        dateTo: new FormControl(),
      }),
    });

    if (isNilty(this.promoPriceService.promoPriceFilters)) {
      this.promoPriceService.promoPriceFilters = new PromoPriceFilters();
    }

    this.filters = this.promoPriceService.promoPriceFilters;

    this.storeService.getAllStores().subscribe((resp: ZionStore[]) => (this.stores = resp));
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }

  onSubmitFilters() {
    this.updateFiltersMap();
    this.promoPriceService.promoPriceFilters.pageNumber = 0;
    this.onFilter.next(this.setPromoPriceFilters());
  }

  resetFilters() {
    this.filters = new PromoPriceFilters();
    this.promoPriceService.promoPriceFilters = this.filters;
    this.filterForm.reset();
    this.onSubmitFilters();
  }

  updateFiltersMap() {
    this.filtersMap = promoPriceFiltersMap(
      this.filterForm.value.storeCode,
      this.filterForm.value.productBarcode,
      this.filterForm.value.range.dateFrom,
      this.filterForm.value.range.dateTo
    );
  }

  setPromoPriceFilters() {
    this.filters.storeCode = isNilty(this.filterForm.value.storeCode) ? null : this.filterForm.value.storeCode;
    this.filters.barcodes = isNilty(this.filterForm.value.productBarcode) ? null : this.filterForm.value.productBarcode.split('\n');
    this.filters.dateFrom = this.filterForm.value.range.dateFrom;
    this.filters.dateTo = this.filterForm.value.range.dateTo;

    return this.filters;
  }
}
