import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonsService } from '../shared/commons.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css'],
})
export class ContainerComponent {
  constructor(private router: Router, private route: ActivatedRoute, private currencyService: CommonsService) {
    this.route.queryParamMap.subscribe((params) => {
      if (params.has('token')) {
        sessionStorage.setItem('token', params.get('token'));
        this.router.navigate([], {
          queryParams: {
            token: null,
          },
          queryParamsHandling: 'merge',
        });
      }
    });

    this.currencyService.getCurrencies().subscribe();
  }
}
