import { Component, Input, OnInit } from '@angular/core';
import { GenericSoupConfiguration, ROUTE_TYPES } from '../../../../../models/configuration/soup-configuration/soup-configuration-model';
import { SoupConfigurationService } from '../../soup-configuration.service';
import { SoupParser } from '../../../../../models/configuration/soup-configuration/soup-parser-model';
import { MatDialog } from '@angular/material/dialog';
import { SoupParserModalComponent } from './soup-parser-modal/soup-parser-modal.component';
import { isNilty } from '../../../../../core/utils.service';
import { FileProperties } from '../../../../../models/configuration/soup-configuration/file-properties-model';
import { ChunkedFeedRouteConfiguration } from '../../../../../models/configuration/soup-configuration/route-configurations/chunked-feed-route-configuration-model';
import { MatSelectChange } from '@angular/material/select';
import { FtpRouteConfiguration } from '../../../../../models/configuration/soup-configuration/route-configurations/ftp-route-configuration-model';
import { B2bRouteConfiguration } from '../../../../../models/configuration/soup-configuration/route-configurations/b2b-route-configuration-model';
import { HttpRouteConfiguration } from '../../../../../models/configuration/soup-configuration/route-configurations/http-route-configuration-model';
import { AwsSftpConfiguration } from '../../../../../models/configuration/soup-configuration/route-configurations/aws-sftp-configuration-model';

@Component({
  selector: 'app-soup-route-configuration',
  templateUrl: './soup-route-configuration.component.html',
  styleUrls: ['./soup-route-configuration.component.css'],
})
export class SoupRouteConfigurationComponent implements OnInit {
  @Input() soupConfiguration: GenericSoupConfiguration;

  soupParsers: SoupParser[];
  selectedParser: SoupParser;

  routeTypes: string[] = Object.keys(ROUTE_TYPES);

  constructor(private soupConfigurationService: SoupConfigurationService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.soupConfigurationService.findSoupParsers().subscribe((resp: SoupParser[]) => {
      this.soupParsers = resp;
      this.selectedParser = this.soupParsers.find((it) => it.beanName === this.soupConfiguration.configuration.beanName);
    });
  }

  selectedRouteType(event: MatSelectChange) {
    switch (event.value) {
      case ROUTE_TYPES.FTP:
        this.soupConfiguration.configuration = new FtpRouteConfiguration(
          this.soupConfiguration.configuration.fileProperties,
          this.selectedParser
        );
        return;
      case ROUTE_TYPES.B2B:
        this.soupConfiguration.configuration = new B2bRouteConfiguration(
          this.soupConfiguration.configuration.fileProperties,
          this.selectedParser
        );
        return;
      case ROUTE_TYPES.HTTP:
        this.soupConfiguration.configuration = new HttpRouteConfiguration(
          this.soupConfiguration.configuration.fileProperties,
          this.selectedParser
        );
        return;
      case ROUTE_TYPES.AWS_SFTP:
        this.soupConfiguration.configuration = new AwsSftpConfiguration(
          this.soupConfiguration.configuration.fileProperties,
          this.selectedParser
        );
        return;
    }
  }

  changeParser() {
    this.dialog
      .open(SoupParserModalComponent, {
        disableClose: true,
        data: {
          parser: this.selectedParser,
          parsers: this.soupParsers,
        },
      })
      .afterClosed()
      .subscribe((parser: SoupParser) => {
        if (!isNilty(parser)) {
          this.selectedParser = parser;
          this.soupConfiguration.configuration.beanName = this.selectedParser.beanName;
          this.soupConfiguration.configuration.fileProperties = new FileProperties();
        }
      });
  }

  changedChunkedFlag(event: boolean) {
    if (event) {
      if (isNilty(this.soupConfiguration.configuration.chunkedFeedConfiguration)) {
        this.soupConfiguration.configuration.chunkedFeedConfiguration = new ChunkedFeedRouteConfiguration();
        this.soupConfiguration.configuration.chunkedFeedConfiguration.supplierHandlerClass = this.soupConfiguration.distrName;
      }
    }
  }
}
