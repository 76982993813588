import { PreloadingStrategy, Route } from '@angular/router';
import { flatMap } from 'rxjs/operators';
import { Observable, of, timer } from 'rxjs';
import { isDevMode } from '@angular/core';

export class AppPreloadingStrategy implements PreloadingStrategy {
  // eslint-disable-next-line @typescript-eslint/ban-types
  preload(route: Route, load: Function): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return !isDevMode() && route.data && route.data.preload ? timer(100).pipe(flatMap((_) => load())) : of(null);
  }
}
