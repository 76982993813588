import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ProductMetadata } from 'src/app/models/product-metadata-model';

@Component({
  selector: 'app-products-metadata-list',
  templateUrl: './products-metadata-list.component.html',
  styleUrls: ['./products-metadata-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProductMetadataListComponent implements OnInit {
  @Input() metadata: ProductMetadata[];

  dataSource = new MatTableDataSource<ProductMetadata>([]);
  displayedColumns = ['showDetails', 'barcodeType', 'barcodeValue', 'title', 'sku', 'brand', 'category'];
  selectedRow: ProductMetadata;

  ngOnInit(): void {
    this.dataSource.data = this.metadata;
  }

  toggle(row: ProductMetadata) {
    this.selectedRow = this.selectedRow === row ? null : row;
  }
}
