import { JsonObject, JsonProperty } from 'json2typescript';
import { MarketplaceDto } from './marketplace-model';

@JsonObject('CategoryFee')
export class CategoryFee {
  @JsonProperty('networkCategory', String, true) networkCategory: string = undefined;
  @JsonProperty('percentageFee', Number, true) percentageFee: number = undefined;
  @JsonProperty('minimalFee', Number, true) minimalFee: number = undefined;
  @JsonProperty('closingFee', Number, true) closingFee: number = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('id', String, true) id: string = undefined;
}

@JsonObject('MarketplaceCategoryFee')
export class MarketplaceCategoryFee extends CategoryFee {
  @JsonProperty('marketplace', MarketplaceDto, true) marketplace: MarketplaceDto = undefined;
}

@JsonObject('NetworkCategoryFee')
export class NetworkCategoryFee extends CategoryFee {
  @JsonProperty('network', String, true) network: string = undefined;
}
