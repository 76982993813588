import { Pipe, PipeTransform } from '@angular/core';
import { CommonsService } from '../commons.service';
import { isNilty } from '../../core/utils.service';
import { NetworkCategory } from '../../models/network-category-model';

@Pipe({
  name: 'networkCategoryPipe',
})
export class NetworkCategoryPipe implements PipeTransform {
  private categories: NetworkCategory[];

  constructor(private commonsService: CommonsService) {
    this.commonsService.getNetworkCategories().subscribe((r) => (this.categories = r));
  }

  transform(value: string): string {
    if (isNilty(value)) {
      return '';
    }
    return this.categories.find((it) => it.serializedEnum === value)?.description;
  }
}
