import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { Status } from '../status-model';

@JsonObject('PublicationPriceComponents')
export class PublicationPriceComponents {
  @JsonProperty('purchasePrice', Number, true) purchasePrice: number = undefined;
  @JsonProperty('fullPrice', Number, true) fullPrice: number = undefined;
  @JsonProperty('pricingParameters', Any, true) pricingParameters: any = undefined;
  @JsonProperty('vat', Number, true) vat: number = undefined;
  @JsonProperty('closingFee', Number, true) closingFee: number = undefined;
  @JsonProperty('packaging', Number, true) packaging: number = undefined;
  @JsonProperty('poShipmentCost', Number, true) poShipmentCost: number = undefined;
  @JsonProperty('handling', Number, true) handling: number = undefined;
  @JsonProperty('actualShipmentCost', Number, true) actualShipmentCost: number = undefined;
  @JsonProperty('shipmentClass', String, true) shipmentClass: string = undefined;
  @JsonProperty('percentageFee', Number, true) percentageFee: number = undefined;
  @JsonProperty('minimalFee', Number, true) minimalFee: number = undefined;
  @JsonProperty('sumShippingCosts', Boolean, true) sumShippingCosts: boolean = undefined;
  @JsonProperty('mandatoryPricingPolicy', String, true) mandatoryPricingPolicy: string = undefined;
  @JsonProperty('retailPrice', Number, true) retailPrice: number = undefined;
}
@JsonObject('PublicationPrice')
export class PublicationPrice {
  @JsonProperty('target', Number, true) target: number = undefined;
  @JsonProperty('minimum', Number, true) minimum: number = undefined;
  @JsonProperty('maximum', Number, true) maximum: number = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('repriced', Boolean, true) repriced: boolean = undefined;
  @JsonProperty('lastRepricing', String, true) lastRepricing: string = undefined;

  @JsonProperty('buyBoxDistance', Status, true) buyBoxDistance: Status = undefined;
  @JsonProperty('components', PublicationPriceComponents, true) components: PublicationPriceComponents = undefined;
  @JsonProperty('actualProfit', Number, true) actualProfit: number = undefined;
}
