import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Permissions } from 'src/app/models/security/permission-model';
import { SoupConfiguration } from 'src/app/models/soup-configuration-model';
import { AuthorizationService } from 'src/app/security/authorization.service';

@Component({
  selector: 'app-stock-locations-routes',
  templateUrl: './stock-locations-routes.component.html',
  styleUrls: ['./stock-locations-routes.component.css'],
  animations: [
    trigger('detailExpandRoutes', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class StockLocationsRoutesComponent implements OnInit {
  @Input() soupConfigurations: SoupConfiguration[];

  dataSource = new MatTableDataSource<SoupConfiguration>([]);
  displayedColumns = [
    'active',
    'distrName',
    'routeType',
    'lastStockUpdate',
    'chunkFiles',
    'addProduct',
    'fullFeed',
    'feedFrequency',
    'feedFrequencyExpected',
    'showDetails',
  ];
  selectedRow: SoupConfiguration;
  hasFeedConfiguration: Observable<boolean>;

  constructor(private router: Router, private authorizationService: AuthorizationService) {}

  ngOnInit(): void {
    this.dataSource.data = this.soupConfigurations;

    this.authorizationService.hasZionPermission(Permissions.SupplierFeedConfiguration).subscribe((supplierFeedConfiguration) => {
      if (supplierFeedConfiguration) {
        this.displayedColumns.splice(this.displayedColumns.length - 1, 0, 'open');
      }
    });
  }

  openSoupConfiguration(sc: SoupConfiguration) {
    this.router.navigate(['configuration', 'soup-configuration'], { queryParams: { distrName: sc.distrName } });
  }

  toggle(row: SoupConfiguration) {
    this.selectedRow = this.selectedRow === row ? null : row;
  }
}
