import { JsonObject, JsonProperty } from 'json2typescript';
import { isNilty } from '../../core/utils.service';

@JsonObject('PublicationStatusCount')
export class PublicationStatusCount {
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('count', String, true) count = '0';

  constructor(status, count) {
    this.status = status;
    this.count = isNilty(count) ? '0' : count;
  }
}
@JsonObject('PendingOperationCount')
export class PendingOperationCount {
  @JsonProperty('operation', String, true) operation: string = undefined;
  @JsonProperty('count', String, true) count = '0';

  constructor(operation, count) {
    this.operation = operation;
    this.count = isNilty(count) ? '0' : count;
  }
}

@JsonObject('PublicationsCounts')
export class PublicationsCounts {
  @JsonProperty('publicationsByStatus', [PublicationStatusCount], true) publicationsByStatus: PublicationStatusCount[] = [];
  @JsonProperty('pendingByOperation', [PendingOperationCount], true) pendingByOperation: PendingOperationCount[] = [];
}
