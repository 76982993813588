import { Component, Input, OnInit } from '@angular/core';
import { PublicationRule } from '@models/configuration/rules/publication-rule/publication-rule-model';
import { CommonsService } from '@shared/commons.service';
import { NetworkCategory } from '@models/network-category-model';
import { isNilty } from '@core/utils.service';
import { UpdateCriteria, UpdateCriterion } from '@models/configuration/rules/update-criteria-model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-rule-options-edit',
  templateUrl: './rule-options-edit.component.html',
  styleUrls: ['./rule-options-edit.component.css'],
})
export class RuleOptionsEditComponent implements OnInit {
  @Input() selectedStore: Observable<void>;

  rule: PublicationRule;
  networkCategories: NetworkCategory[];

  updateCriteriaTypes = [
    { type: 'ABSOLUTE_VARIATION', description: 'Update if the change is greater than the thresholdValue' },
    { type: 'PERCENTAGE_VARIATION', description: 'Update if the percentage change is greater than the thresholdValue' },
    { type: 'THRESHOLD', description: 'Update only if the thresholdValue is crossed (in both directions)' },
  ];

  private allNetworkCategories: NetworkCategory[];

  constructor(private commonsService: CommonsService) {
    this.commonsService.getNetworkCategories().subscribe((r) => {
      this.allNetworkCategories = r;
      this.getNetworkCategories();
    });
  }

  @Input() set _rule(r: PublicationRule) {
    this.rule = r;
    this.getNetworkCategories();
  }

  ngOnInit() {
    if (!isNilty(this.selectedStore)) {
      this.selectedStore.subscribe(() => this.getNetworkCategories());
    }
  }

  addQuantityUpdateCriteria() {
    if (isNilty(this.rule.updateCriteria)) {
      this.rule.updateCriteria = new UpdateCriteria();
    }
    this.rule.updateCriteria.quantityUpdateCriterion = new UpdateCriterion();
  }
  removeQuantityCriteria() {
    this.rule.updateCriteria.quantityUpdateCriterion = null;
  }
  addPriceUpdateCriteria() {
    if (isNilty(this.rule.updateCriteria)) {
      this.rule.updateCriteria = new UpdateCriteria();
    }
    this.rule.updateCriteria.priceUpdateCriterion = new UpdateCriterion();
  }
  removePriceCriteria() {
    this.rule.updateCriteria.priceUpdateCriterion = null;
  }

  private getNetworkCategories() {
    if (isNilty(this.rule) || isNilty(this.rule.store) || isNilty(this.allNetworkCategories)) {
      return;
    }
    this.networkCategories = this.allNetworkCategories.filter((it) => it.network === this.rule.store.networkType);
  }
}
