import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isNilty } from '../../../core/utils.service';

@Component({
  selector: 'app-fees-configuration',
  templateUrl: './fees-configuration.component.html',
  styleUrls: ['./fees-configuration.component.scss'],
})
export class FeesConfigurationComponent implements OnInit {
  selectedIndex: number;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((p) => {
      if (!isNilty(p['index'])) {
        this.selectedIndex = +p['index'];
      }
    });
  }
}
