import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { StockLocationsComponent } from './stock-locations.component';
import { StockLocationsRoutesComponent } from './stock-locations-routes/stock-locations-routes.component';
import { AddStockLocationComponent } from './add-stock-location/add-stock-location.component';
import { StockLocationsRoutesImportsComponent } from './stock-locations-routes/stock-locations-routes-imports/stock-locations-routes-imports.component';
import { StockLocationsService } from './stock-locations.service';
import { ImportStockLocationsService } from './import-stock-locations.service';

@NgModule({
  declarations: [StockLocationsComponent, StockLocationsRoutesComponent, StockLocationsRoutesImportsComponent, AddStockLocationComponent],
  imports: [SharedModule],
  providers: [StockLocationsService, ImportStockLocationsService],
})
export class StockLocationsModule {}
