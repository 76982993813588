import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter, fromDateToFiltersString } from './date-converter';
import { Money } from './product-metadata-model';
import { Pagination } from './shared/pagination-model';
import { MatChipsData } from './mat-chips-data-model';
import { isNilty } from '@core/utils.service';

@JsonObject('PromoPrice')
export class PromoPrice {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('storeCode', String, true) storeCode: string = undefined;
  @JsonProperty('barcode', String, true) barcode: string = undefined;
  @JsonProperty('startDate', String, true) startDate: Date = undefined;
  @JsonProperty('endDate', String, true) endDate: Date = undefined;
  @JsonProperty('price', Money, true) price: Money = undefined;
  @JsonProperty('fileUrl', String, true) fileUrl: string = undefined;
  @JsonProperty('loadingDate', String, true) loadingDate: Date = undefined;
  @JsonProperty('email', String, true) email: string = undefined;
}

@JsonObject('PromoPriceFilters')
export class PromoPriceFilters extends Pagination {
  @JsonProperty('storeCode', String, true) storeCode: string = undefined;
  @JsonProperty('barcodes', [String], true) barcodes: string[] = undefined;
  @JsonProperty('dateFrom', DateConverter, true) dateFrom: Date = undefined;
  @JsonProperty('dateTo', DateConverter, true) dateTo: Date = undefined;
}

export const promoPriceFiltersMap = (storeCode: string, barcodes: string, dateFrom: Date, dateTo: Date): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(storeCode)) {
    map.push(new MatChipsData('Store Code', storeCode));
  }
  if (!isNilty(barcodes)) {
    map.push(new MatChipsData('Product Barcode', barcodes));
  }
  if (!isNilty(dateFrom)) {
    map.push(new MatChipsData('Date From', fromDateToFiltersString(dateFrom)));
  }
  if (!isNilty(dateTo)) {
    map.push(new MatChipsData('Date To', fromDateToFiltersString(dateTo)));
  }
  return map;
};
