import { Component, EventEmitter, Output } from '@angular/core';
import { PowerSeller } from '@models/configuration/rules/power-seller-rule/power-seller-model';
import { SearchRestrictionDto, RestrictionSearchType } from '@models/configuration/policies/restrictions/restrictions-utils';

@Component({
  selector: 'app-restrictions-searcher',
  templateUrl: './restrictions-searcher.component.html',
  styleUrls: ['./restrictions-searcher.component.scss'],
})
export class RestrictionsSearcherComponent {
  @Output() selectedPowerSellerEvent = new EventEmitter<SearchRestrictionDto>();

  selectedPowerSeller: PowerSeller;

  panelExpanded = true;

  selected(p: PowerSeller) {
    this.selectedPowerSeller = p;
    this.selectedPowerSellerEvent.emit(new SearchRestrictionDto(RestrictionSearchType.POWER_SELLER, p));
    this.panelExpanded = closed;
  }
}
