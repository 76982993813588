import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '../../../../models/role-model';
import { Permission } from '../../../../models/security/permission-model';
import { GenericConfirmationModalComponent } from '../../../../shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { RolesService } from '../roles.service';

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.css'],
})
export class RoleEditComponent implements OnInit {
  applications: string[] = [];
  permissions: Permission[] = [];

  roleId: string;
  role = new Role();
  editMode = false;
  roleForm: FormGroup;

  constructor(private rolesService: RolesService, private route: ActivatedRoute, private router: Router, public dialog: MatDialog) {}

  ngOnInit() {
    const params = this.route.snapshot.params;
    this.roleId = params['id'];
    this.editMode = params['id'] != null;

    this.roleForm = new FormGroup({
      name: new FormControl('', Validators.required),
      application: new FormControl({ value: '', disabled: this.editMode }, Validators.required),
      permissions: new FormControl(null, Validators.required),
    });

    this.rolesService.getApplications().subscribe((applications: string[]) => {
      this.applications = applications;
    });

    if (this.editMode) {
      this.rolesService.getRole(this.roleId).subscribe((role: Role) => {
        this.role = role;

        this.rolesService.getPermissions(role.application).subscribe((permissions: Permission[]) => {
          this.permissions = permissions;
          this.changeFormValues(this.role);
        });
      });
    }
  }

  changeFormValues(role: Role) {
    this.roleForm.setValue({
      name: role.name,
      application: role.application,
      permissions: this.permissions.filter((permission) => role.permissions.some((rp) => rp.id === permission.id)),
    });
  }

  onCancel() {
    this.router.navigate(['configuration', 'roles']);
  }

  onSubmit() {
    if (!this.editMode) {
      this.role = new Role();
    }

    this.role.name = this.roleForm.controls.name.value;
    this.role.application = this.roleForm.controls.application.value;
    this.role.permissions = this.roleForm.controls.permissions.value;

    this.rolesService.saveRole(this.role).subscribe(() => {
      this.router.navigate(['configuration', 'roles']);
    });
  }

  onDelete() {
    const message = 'Deleting role ' + this.role.name;
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: message,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.rolesService.deleteRole(this.role.id).subscribe(() => {
          console.log('Role deleted.');
          this.router.navigate(['configuration', 'roles']);
        });
      } else {
        console.log('Role not deleted.');
      }
    });
  }

  loadPermissions(application: string) {
    return this.rolesService.getPermissions(application).subscribe((permissions: Permission[]) => {
      this.roleForm.patchValue({
        permissions: [],
      });
      this.permissions = permissions;
    });
  }
}
