import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Marketplace')
export class Marketplace {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('name', String, true) name: string = undefined;

  @JsonProperty('network', String, true) network: string = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('country', String, true) country: string = undefined;
}

@JsonObject('MarketplaceDto')
export class MarketplaceDto extends Marketplace {
  @JsonProperty('sellingPlatform', String, true) sellingPlatform: string = undefined;
}

@JsonObject('SellingPlatform')
export class SellingPlatform {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
}

@JsonObject('FullMarketplace')
export class FullMarketplace extends Marketplace {
  @JsonProperty('sellingPlatform', SellingPlatform, true) sellingPlatform: SellingPlatform = undefined;
}
