import { Component, Input } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CustomFulfilmentLatency } from '@models/configuration/rules/publication-rule/custom-fulfilment-latency-model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { GenericConfirmationModalComponent } from '@shared/generic-confirmation-modal/generic-confirmation-modal.component';

@Component({
  selector: 'app-rule-fulfilment-latencies',
  templateUrl: './rule-fulfilment-latencies.component.html',
  styleUrls: ['./rule-fulfilment-latencies.component.css'],
})
export class RuleFulfilmentLatenciesComponent {
  ruleFulfilmentLatencies: CustomFulfilmentLatency[];
  sort = false;

  constructor(private dialog: MatDialog) {}

  @Input() set _ruleFulfilmentLatencies(rfl: CustomFulfilmentLatency[]) {
    this.ruleFulfilmentLatencies = rfl;
    this.ruleFulfilmentLatencies.forEach((it) => it.setDescription());
  }

  addFulfilmentLatency() {
    this.ruleFulfilmentLatencies.push(new CustomFulfilmentLatency());
  }

  confirm(m: CustomFulfilmentLatency, exp: MatExpansionPanel) {
    m.setDescription();
    exp.close();
  }

  removeLatency(index: number) {
    this.dialog
      .open(GenericConfirmationModalComponent, {
        data: `This will remove the custom fulfilment latency and all its predicates.`,
      })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          this.ruleFulfilmentLatencies.splice(index, 1);
        }
      });
  }

  toggleSorting(event: MatSlideToggleChange) {
    this.sort = event.checked;
  }

  drop(event) {
    moveItemInArray(this.ruleFulfilmentLatencies, event.previousIndex, event.currentIndex);
  }
}
