import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AccountConnection } from 'src/app/models/configuration/sales-order-poll-configuration/account-connection-model';
import { AccountFilters } from 'src/app/models/configuration/sales-order-poll-configuration/account-filters-model';
import { SalesOrderPollConfigurationService } from '../sales-order-poll-configuration.service';

@Component({
  selector: 'app-account-select-modal',
  templateUrl: './account-select-modal.component.html',
  styleUrls: ['./account-select-modal.component.css'],
})
export class AccountSelectModalComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('filterInput', { static: true }) filterInput: ElementRef;

  selectedAccount: AccountConnection;
  resultsNumber = this.service.resultsNumber;
  accountsMatTable: MatTableDataSource<AccountConnection> = new MatTableDataSource<AccountConnection>();
  displayedColumns = ['select', 'name', 'code', 'marketplaceConnectionType'];
  filters = new AccountFilters();

  constructor(
    public dialogRef: MatDialogRef<AccountSelectModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: AccountConnection,
    private service: SalesOrderPollConfigurationService
  ) {}

  ngOnInit(): void {
    this.filters.pageSize = 5;
    this.loadAccounts();

    fromEvent(this.filterInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.filters.nameOrCode = text;
        this.loadAccounts();
      });
  }

  loadPage() {
    this.filters.pageSize = this.paginator.pageSize;
    this.filters.pageNumber = this.paginator.pageIndex;
    this.loadAccounts();
  }

  confirm() {
    this.dialogRef.close(this.selectedAccount);
  }

  selectAccount(a: AccountConnection) {
    this.selectedAccount = a;
  }

  private loadAccounts() {
    this.service.findAccountsByFilters(this.filters).subscribe((r) => (this.accountsMatTable.data = r));
  }
}
