import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountConnection } from 'src/app/models/configuration/sales-order-poll-configuration/account-connection-model';
import { AccountFilters } from 'src/app/models/configuration/sales-order-poll-configuration/account-filters-model';
import { SalesOrderPollConfiguration } from 'src/app/models/configuration/sales-order-poll-configuration/sales-order.poll-configuration';
import { SalesOrderPollConfigurationFilters } from 'src/app/models/configuration/sales-order-poll-configuration/sales-order.poll-configuration-filters-model';
import { ConverterService } from '../../../core/converter.service';
import { EnvironmentService } from '../../../core/environment/environment.service';
import { RulePredicateFieldsOutput } from '@models/configuration/rules/rule-predicate-model';
import { GenericArrayCache } from '../../../shared/service-cached-array';

@Injectable()
export class SalesOrderPollConfigurationService {
  resultsNumber = new Subject<number>();
  rulePredicateFieldsCache = new GenericArrayCache<RulePredicateFieldsOutput>(this.http, this.converterService, RulePredicateFieldsOutput);

  constructor(private converterService: ConverterService, private http: HttpClient, private environmentService: EnvironmentService) {}

  saveSalesOrderPollConfiguration(rule: SalesOrderPollConfiguration): Observable<SalesOrderPollConfiguration> {
    const body = this.converterService.fromObjtoJSON(rule);
    const path = this.environmentService.getMarkusRestEndpoint('saveSalesOrderPollConfiguration');
    return this.http
      .post(path, body)
      .pipe(map((resp: SalesOrderPollConfiguration) => this.converterService.fromJSONtoObj(resp, SalesOrderPollConfiguration)));
  }

  findSalesOrderPollConfigurationByFilters(filters: SalesOrderPollConfigurationFilters): Observable<SalesOrderPollConfiguration[]> {
    const body = this.converterService.fromObjtoJSON(filters);
    const path = this.environmentService.getMarkusRestEndpoint('salesOrderPollConfigurationByFilters');
    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: SalesOrderPollConfiguration[]) => resp.map((it) => this.converterService.fromJSONtoObj(it, SalesOrderPollConfiguration)))
    );
  }

  findSalesOrderPollConfigurationById(ruleId: string): Observable<SalesOrderPollConfiguration> {
    const path = this.environmentService.getMarkusRestEndpoint('salesOrderPollConfiguration') + '/' + ruleId;
    return this.http
      .get(path)
      .pipe(map((it: SalesOrderPollConfiguration) => this.converterService.fromJSONtoObj(it, SalesOrderPollConfiguration)));
  }

  getDestinations(): Observable<string[]> {
    return this.http
      .get(this.environmentService.getMarkusRestEndpoint('salesOrderPollConfiguration') + '/destinations')
      .pipe(map((r: string[]) => r));
  }

  findAccountsByFilters(filters: AccountFilters): Observable<AccountConnection[]> {
    const body = this.converterService.fromObjtoJSON(filters);
    const path = this.environmentService.getRestEndpoint('accounts');
    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: AccountConnection[]) => resp.map((it) => this.converterService.fromJSONtoObj(it, AccountConnection)))
    );
  }
}
