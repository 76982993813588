import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { getAllowedBlacklistBarcodeTypes } from '@models/configuration/policies/blacklist/blacklists-enums-model';
import { AuthorizationService } from '@security/authorization.service';
import { Permissions } from '@models/security/permission-model';
import { Observable } from 'rxjs';
import { PolicyTypes } from '@models/configuration/policies/policy-enums';
import {
  BarcodeRestriction,
  PredicatesRestriction,
  Restriction,
  WordsRestriction,
} from '@models/configuration/policies/restrictions/restrictions-model';
import { RestrictionsService } from '../../restrictions.service';
import { GenericConfirmationModalComponent } from '@shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { ListingSellingPlatform } from '@models/listings/listing-selling-platform-model';
import { GenericErrorModalComponent } from '@shared/generic-error-modal/generic-error-modal.component';

@Component({
  selector: 'app-restriction-details-modal',
  templateUrl: './restriction-details-modal.component.html',
  styleUrls: ['./restriction-details-modal.component.scss'],
})
export class RestrictionDetailsModalComponent implements OnInit {
  PolicyTypes = PolicyTypes;

  barcodeRestriction: BarcodeRestriction;
  wordsRestriction: WordsRestriction;
  predicatesRestriction: PredicatesRestriction;

  sellingPlatforms: ListingSellingPlatform[] = [];

  barcodeTypes: string[] = [];

  hasRestrictionsManagement: Observable<boolean>;

  editMode = false;

  constructor(
    public dialogRef: MatDialogRef<RestrictionDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Restriction,
    private restrictionsService: RestrictionsService,
    private dialog: MatDialog,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit() {
    this.hasRestrictionsManagement = this.authorizationService.hasMarkusPermission(Permissions.RestrictionsManagement);
    this.barcodeTypes = getAllowedBlacklistBarcodeTypes(this.data.type);

    switch (this.data.type) {
      case PolicyTypes.BARCODE:
        this.barcodeRestriction = this.data as BarcodeRestriction;
        break;
      case PolicyTypes.PREDICATES:
        this.predicatesRestriction = this.data as PredicatesRestriction;
        break;
      case PolicyTypes.WORDS:
        this.wordsRestriction = this.data as WordsRestriction;
        break;
    }

    if (this.data.allowOrDeny === 'ALLOW') {
      this.sellingPlatforms = this.data.allowedSellingPlatforms;
    } else {
      this.sellingPlatforms = this.data.deniedSellingPlatforms;
    }
  }

  deleteRestriction() {
    this.dialog
      .open(GenericConfirmationModalComponent, { data: 'You are about to delete this Restriction entry.' })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          const obs =
            this.data.allowOrDeny === 'ALLOW'
              ? this.restrictionsService.deleteAllowedPlatforms(this.data.type, this.data.id)
              : this.restrictionsService.deleteDeniedPlatforms(this.data.type, this.data.id);

          obs.subscribe(() => {
            this.restrictionsService.refreshRestrictionsPageEvent.next();
            this.dialogRef.close();
          });
        }
      });
  }

  save() {
    switch (this.data.type) {
      case PolicyTypes.BARCODE:
        this.dialog.open(GenericErrorModalComponent, { data: 'Cannot edit a BARCODE policy.' });
        break;
      case PolicyTypes.PREDICATES:
        this.restrictionsService
          .savePredicatesRestrictions(this.predicatesRestriction, this.data.allowOrDeny)
          .subscribe(() => this.refreshAndClose());
        break;
      case PolicyTypes.WORDS:
        this.restrictionsService
          .saveWordsRestrictions(this.wordsRestriction, this.data.allowOrDeny)
          .subscribe(() => this.refreshAndClose());
        break;
    }
    return;
  }

  private refreshAndClose() {
    this.restrictionsService.refreshRestrictionsPageEvent.next();
    this.dialogRef.close(true);
  }
}
