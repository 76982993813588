import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ChunkedFeedRouteConfiguration')
export class ChunkedFeedRouteConfiguration {
  @JsonProperty('supplierHandlerClass', String, true) supplierHandlerClass: string = undefined;
  @JsonProperty('chunkedFileType', String, true) chunkedFileType: string = undefined;
  @JsonProperty('supplierToken', String, true) supplierToken: string = undefined;
  @JsonProperty('encoding', String, true) encoding: string = undefined;

  @JsonProperty('chunkSize', Number, true) chunkSize = 1;

  @JsonProperty('largeFile', Boolean, true) largeFile: boolean = undefined;
  @JsonProperty('stepwise', Boolean, true) stepwise: boolean = undefined;
  @JsonProperty('binary', Boolean, true) binary: boolean = undefined;
  @JsonProperty('streamDownload', Boolean, true) streamDownload: boolean = undefined;
}
