import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PolicyTypes } from '@models/configuration/policies/policy-enums';

@Component({
  selector: 'app-select-policy-type',
  templateUrl: './select-policy-type.component.html',
  styleUrls: ['./select-policy-type.component.scss'],
})
export class SelectPolicyTypeComponent {
  @Output()
  selectedPolicyTypeChange = new EventEmitter<PolicyTypes>();
  @Input()
  selectedPolicyType: PolicyTypes;

  policyTypes = Object.keys(PolicyTypes);

  showTypeHelp = false;

  changeType(type: string) {
    this.selectedPolicyTypeChange.emit(PolicyTypes[type]);
  }
}
