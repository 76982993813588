import { HttpHeaders } from '@angular/common/http';

export const hideLoaderHeaders: () => HttpHeaders = () => new HttpHeaders({ 'Hide-Loader': 'true' });
export const isLoaderHidden: (headers: HttpHeaders) => boolean = (headers) => headers.get('Hide-Loader') === 'true';

export const isUndefined = (item: any): boolean => item === undefined;

export const isNull = (item: any): boolean => item === null;

/**
 * Permette di verificare se il parametro in input sia vuoto (stringa, array o oggetto)
 *
 * @param item
 * @returns true: se vuoto, false: altrimenti
 */
export const isEmpty = (item: any): boolean => {
  if (typeof item === 'string') {
    return item === '';
  } else if (typeof item === 'object') {
    if (Array.isArray(item)) {
      return item.length === 0;
    } else {
      return Object.keys(item).length === 0;
    }
  }
};

/**
 * Permette di verificare se il parametro in input sia nullo, undefined o vuoto (stringa, array o oggetto)
 *
 * @param item
 * @returns true: se nullo, undefined o vuoto, false: altrimenti
 */
export const isNilty = (item: any): boolean => isNull(item) || isUndefined(item) || isEmpty(item);

/**
 * Permette di verificare l'uguaglianza tra due oggetti
 *
 * @param obj: oggetto sorgente
 * @param obj1: oggetto da confrontare
 * @param {string[]} keyToCheck
 * @param {boolean} exclude
 * @returns {boolean} true: se sono uguali, false: altrimenti
 */

export const isEqual = (obj: any, obj1: any, keyToCheck: string[] = [], exclude = true): boolean => {
  let isequal = true;
  if (typeof obj === typeof obj1) {
    if (typeof obj === 'object' && !isNilty(obj)) {
      if (!Array.isArray(obj)) {
        for (const key of Object.keys(obj)) {
          if (obj1.hasOwnProperty(key)) {
            if (
              ((exclude === true && keyToCheck.indexOf(key) === -1) || (exclude === false && keyToCheck.indexOf(key) > -1)) &&
              isequal === true
            ) {
              isequal = isEqual(obj[key], obj1[key], keyToCheck, exclude);
            }
          } else {
            isequal = false;
          }
        }
      } else {
        if (obj.length === obj1.length) {
          obj.forEach((elem, index) => {
            if (isequal === true) {
              isequal = isEqual(elem, obj1[index], keyToCheck, exclude);
            }
          });
        } else {
          isequal = false;
        }
      }
    } else {
      isequal = obj === obj1;
    }
  } else {
    isequal = false;
  }
  return isequal;
};

/**
 * Permette di clonare un oggetto tipizzato
 *
 * @param objToClone: oggetto da clonare
 * @param classIntoClone: classe diversa dall'oggetto da clonare
 */
export const cloneClass = (objToClone: any, classIntoClone?: any): any => {
  let objCloned;
  if (!isNilty(objToClone)) {
    if (typeof objToClone === 'object') {
      if (!Array.isArray(objToClone)) {
        const classConstructor = !isNilty(classIntoClone) ? classIntoClone : objToClone.constructor;
        objCloned = new classConstructor();
        for (const key of Object.keys(objToClone)) {
          if (objCloned.hasOwnProperty(key)) {
            objCloned[key] = cloneClass(objToClone[key], classIntoClone);
          }
        }
      } else {
        objCloned = [];
        for (const elem of objToClone) {
          objCloned.push(cloneClass(elem, classIntoClone));
        }
      }
    } else {
      objCloned = objToClone;
    }
  }

  return objCloned;
};

const camelCaseRegex = new RegExp('^[a-zA-Z0-9_]+$', 'i');
export const isValidCamelCase = (str) => camelCaseRegex.test(str);

export const camelize = (str) =>
  str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase())).replace(/\s+/g, '');

export const uniqueArray = (arrayWithDuplicates: any[]): any[] =>
  arrayWithDuplicates.filter((value, index, self) => self.indexOf(value) === index);

export const caseInsensitiveStringMatch = (stringToMatch: string, searchString: string): boolean =>
  stringToMatch.toUpperCase().indexOf(searchString.toUpperCase()) !== -1;

export const anyStringInArrayMatches = (strings: string[], searchString: string): boolean => {
  for (const s of strings) {
    if (caseInsensitiveStringMatch(s, searchString)) {
      return true;
    }
  }
  return false;
};
