import { JsonObject, JsonProperty } from 'json2typescript';
import { BlacklistTarget } from './blacklist-model';

@JsonObject('BarcodeBlacklistFilters')
export class BarcodeBlacklistFilters {
  @JsonProperty('ean', String, true) ean: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('marketplaceBarcode', String, true) marketplaceBarcode: string = undefined;
  @JsonProperty('targets', [BlacklistTarget], true) targets: BlacklistTarget[] = undefined;
  @JsonProperty('reason', String, true) reason: string = undefined;
  @JsonProperty('pageNumber', Number, true) pageNumber = 0;
}
