import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EnvironmentService } from '@core/environment/environment.service';
import { Permissions } from '@models/security/permission-model';
import { AuthenticationService } from '@security/authentication.service';
import { AuthorizationService } from '@security/authorization.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  hasFeedConfiguration: Observable<boolean>;
  hasStoreFeesConfiguration: Observable<boolean>;
  hasBlacklistManagement: Observable<boolean>;
  hasPublicationRulesManagement: Observable<boolean>;
  canViewPublications: Observable<boolean>;
  subscription = new Subscription();
  currentMail = '';

  logoUrl: string = this.environmentService.logo;

  constructor(
    private authorizationService: AuthorizationService,
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private environmentService: EnvironmentService
  ) {}

  ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.subscription = this.authorizationService.getPermissions().subscribe((_) => {
      this.currentMail = this.authorizationService.currentUserMail;
    });
    this.hasFeedConfiguration = this.authorizationService.hasZionPermission(Permissions.SupplierFeedConfiguration);
    this.hasStoreFeesConfiguration = this.authorizationService.hasMarkusPermission(Permissions.StoreFeesConfiguration);
    this.canViewPublications = this.authorizationService.hasMarkusPermission(Permissions.PublicationsView);
    this.hasBlacklistManagement = this.authorizationService.hasMarkusPermission(Permissions.BlacklistManagement);
    this.hasPublicationRulesManagement = this.authorizationService.hasMarkusPermission(Permissions.PublicationRulesManagement);
  }

  refreshHomepage() {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  logout() {
    this.authenticationService.logout();
  }
}
