import { NgModule } from '@angular/core';
import { SoupConfigurationComponent } from './soup-configuration.component';
import { SoupConfigurationsListComponent } from './soup-configurations-list/soup-configurations-list.component';
import { SoupConfigurationEditComponent } from './soup-configuration-edit/soup-configuration-edit.component';
import { SoupSupplierConfigurationComponent } from './soup-configuration-edit/soup-supplier-configuration/soup-supplier-configuration.component';
import { SoupRouteConfigurationComponent } from './soup-configuration-edit/soup-route-configuration/soup-route-configuration.component';
import { FtpRouteConfigurationComponent } from './soup-configuration-edit/soup-route-configuration/route-types/ftp-route-configuration/ftp-route-configuration.component';
import { B2bRouteConfigurationComponent } from './soup-configuration-edit/soup-route-configuration/route-types/b2b-route-configuration/b2b-route-configuration.component';
import { HttpRouteConfigurationComponent } from './soup-configuration-edit/soup-route-configuration/route-types/http-route-configuration/http-route-configuration.component';
import { FilePropertiesComponent } from './soup-configuration-edit/soup-route-configuration/file-properties/file-properties.component';
import { SoupParserModalComponent } from './soup-configuration-edit/soup-route-configuration/soup-parser-modal/soup-parser-modal.component';
import { MapEditComponent } from './soup-configuration-edit/soup-route-configuration/map-edit/map-edit.component';
import { ChunkedRouteConfigurationComponent } from './soup-configuration-edit/soup-route-configuration/route-types/chunked-route-configuration/chunked-route-configuration.component';
import { SoupDestinationsConfigurationComponent } from './soup-configuration-edit/soup-destinations-configuration/soup-destinations-configuration.component';
import { SharedModule } from '../../../shared.module';
import { ConfigurationRoutingModule } from '../configuration-routing.module';
import { SoupConfigurationService } from './soup-configuration.service';
import { ColumnsMapperModalComponent } from './soup-configuration-edit/soup-route-configuration/file-properties/columns-mapper-modal/columns-mapper-modal.component';
import { AwsSftpRouteConfigurationComponent } from './soup-configuration-edit/soup-route-configuration/route-types/aws-sftp-route-configuration/aws-sftp-route-configuration.component';
import { ItemFiltersComponent } from './soup-configuration-edit/soup-route-configuration/item-filters/item-filters.component';

@NgModule({
  declarations: [
    SoupConfigurationComponent,
    SoupConfigurationsListComponent,
    SoupConfigurationEditComponent,
    SoupSupplierConfigurationComponent,
    SoupRouteConfigurationComponent,
    FtpRouteConfigurationComponent,
    B2bRouteConfigurationComponent,
    HttpRouteConfigurationComponent,
    FilePropertiesComponent,
    SoupParserModalComponent,
    MapEditComponent,
    ChunkedRouteConfigurationComponent,
    SoupDestinationsConfigurationComponent,
    ColumnsMapperModalComponent,
    AwsSftpRouteConfigurationComponent,
    ItemFiltersComponent,
  ],
  imports: [SharedModule, ConfigurationRoutingModule],
  providers: [SoupConfigurationService],
  exports: [MapEditComponent],
})
export class SoupConfigurationModule {}
