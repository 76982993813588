import { Pipe, PipeTransform } from '@angular/core';
import { CommonsService } from '../commons.service';
import { Currency } from '../../models/currency-model';
import { isNilty } from '../../core/utils.service';

@Pipe({
  name: 'myCurrencyCodePipe',
})
export class MyCurrencyCodePipe implements PipeTransform {
  private currencies: Currency[];

  constructor(private currencyService: CommonsService) {
    this.currencyService.getCurrencies().subscribe((r) => (this.currencies = r));
  }

  transform(value: string): string {
    if (isNilty(value)) {
      return '';
    }
    const symbol = this.currencies.find((it) => it.code === value);
    return symbol ? symbol?.symbol : value;
  }
}
