import { JsonObject, JsonProperty } from 'json2typescript';
import { MarketplaceDto } from '../configuration/marketplace/marketplace-model';
import { StoreGroup } from '@models/configuration/marketplace/store-group-model';

@JsonObject('ZionStore')
export class ZionStore {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('country', String, true) country: string = undefined;
  @JsonProperty('marketplace', MarketplaceDto, true) marketplace: MarketplaceDto = undefined;
  @JsonProperty('storeGroup', StoreGroup, true) storeGroup: StoreGroup = undefined;
  @JsonProperty('accountCode', String, true) accountCode: string = undefined;
  @JsonProperty('accountConnectionName', String, true) accountConnectionName: string = undefined;
}

export class PublishedStore {
  store: ZionStore;
  published: boolean;

  constructor(store: ZionStore, published: boolean) {
    this.store = store;
    this.published = published;
  }
}
