import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { StoreService } from '../../store.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { isNilty } from '@core/utils.service';
import { StoreGroup } from '@models/configuration/marketplace/store-group-model';

@Component({
  selector: 'app-new-store-group-modal',
  template: `
    <h1 mat-dialog-title></h1>
    <div class="dialog-container">
      <div mat-dialog-content>
        Create new Store Group:
        <br />
        <mat-form-field class="width-90per"
          ><input matInput placeholder="Name" #newNameInput [(ngModel)]="newStoreGroupName" />
          <mat-hint class="mat-error" *ngIf="!isNewNameValid && !(newStoreGroupName | isFalseOrNullOrUndefined)"
            >This Store Group already exists</mat-hint
          >
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions>
      <div class="row w-100">
        <div class="col-md-12 text-center w-100">
          <button class="btn-custom margin-right-15" (click)="save()" [disabled]="!isNewNameValid">Save</button>
          <button class="btn-custom" [mat-dialog-close]="null">Cancel</button>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .dialog-container {
        width: 600px !important;
        height: fit-content !important;
        padding-bottom: 15px;
        padding-top: 15px;
      }

      .mat-dialog-content {
        font: 500 20px/32px Roboto, 'Helvetica Neue', sans-serif !important;
      }

      .mat-dialog-actions {
        padding-top: 30px !important;
        padding-bottom: 20px !important;
      }

      .mat-error {
        color: red;
      }
    `,
  ],
})
export class NewStoreGroupModalComponent implements OnInit {
  @ViewChild('newNameInput', { static: true }) newNameInput: ElementRef;

  newStoreGroupName: string;
  storeGroups: StoreGroup[];
  isNewNameValid: boolean;

  constructor(public dialogRef: MatDialogRef<NewStoreGroupModalComponent>, private storeService: StoreService) {
    this.storeService.getAllStoreGroups().subscribe((r) => (this.storeGroups = r));
  }

  ngOnInit() {
    fromEvent(this.newNameInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(50),
        distinctUntilChanged()
      )
      .subscribe((newName: string) => {
        this.isNewNameValid =
          !isNilty(newName) && this.storeGroups.find((it) => it.name.toUpperCase() === newName.toUpperCase()) === undefined;
      });
  }

  save() {
    const newGroup = new StoreGroup();
    newGroup.name = this.newStoreGroupName;
    this.storeService.addStoreGroup(newGroup).subscribe(() => this.dialogRef.close(this.newStoreGroupName));
  }
}
