import { Component, EventEmitter, ViewChild } from '@angular/core';
import { PublicationFilters } from '../../../models/publications/filters/publication-filters-model';
import { PublicationDto } from '../../../models/publications/publication-dto-model';
import { MatSidenav } from '@angular/material/sidenav';
import { PublicationViewType } from '../shared/publication-view-type';
import { isNilty } from '../../../core/utils.service';

@Component({
  selector: 'app-publications-by-barcode',
  templateUrl: './publications-by-barcode.component.html',
  styleUrls: ['./publications-by-barcode.component.scss'],
})
export class PublicationsByBarcodeComponent {
  currentSelectedBarcode: string;

  filters = new PublicationFilters();

  publications: PublicationDto[];

  refreshTable = new EventEmitter<PublicationFilters>();
  onFocusControl = new EventEmitter<string>();
  onClearFilters = new EventEmitter();
  switchViewType = new EventEmitter<PublicationViewType>();

  sidenav: MatSidenav;

  @ViewChild('sidenav') set content(content: MatSidenav) {
    if (content) {
      this.sidenav = content;
    }
  }

  selectedBarcode(barcode: string) {
    this.currentSelectedBarcode = barcode;
    this.doRefreshTable();
  }

  filterPublications(filters: PublicationFilters) {
    if (!isNilty(filters.status)) {
      filters.onlyOnline = undefined;
    }
    this.filters = filters;
    this.doRefreshTable();
    if (this.sidenav) {
      this.sidenav.close();
    }
  }

  removeFilterKey(key: string) {
    delete this.filters[key];
    this.doRefreshTable();
  }

  editFilter(key: string) {
    this.onFocusControl.emit(key);
    if (this.sidenav) {
      this.sidenav.open();
    }
  }

  onOnlyOnlineChanged(onlyOnline: boolean) {
    this.filters.onlyOnline = onlyOnline;
    this.removeFilterKey('status');
  }

  doRefreshTable() {
    this.filters.barcode = this.currentSelectedBarcode;
    this.refreshTable.emit(this.filters);
  }

  clearFilters() {
    this.filters = new PublicationFilters();
    this.onClearFilters.emit();
  }
}
