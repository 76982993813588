import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterChipModel, PublicationFilters } from '../../../../models/publications/filters/publication-filters-model';
import { isNilty } from '../../../../core/utils.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { SuppliersService } from '../../../suppliers/suppliers.service';
import { Supplier } from '../../../../models/supplier-model';
import { PublicationService } from '../../publication.service';
import { PublicationViewType } from '../publication-view-type';
import { NetworkCategoryPipe } from '../../../../shared/pipes/network-category.pipe';
import { MatDialog } from '@angular/material/dialog';
import { GenericConfirmationModalComponent } from '../../../../shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnomalouslyPricedModalComponent } from './anomalously-priced/anomalously-priced-modal.component';

@Component({
  selector: 'app-publications-filters-chips',
  templateUrl: './publications-filters-chips.component.html',
  styleUrls: ['./publications-filters-chips.component.scss'],
})
export class PublicationsFiltersChipsComponent implements OnInit {
  @Output() toggleFilters = new EventEmitter<void>();
  @Output() onRemoveFilter = new EventEmitter<string>();
  @Output() onEditFilter = new EventEmitter<string>();
  @Output() onClearFilters = new EventEmitter<string>();
  @Output() onOnlyOnlineChanged = new EventEmitter<boolean>();

  @Output() onPublicationExport = new EventEmitter<void>();

  @Output() switchViewType = new EventEmitter<PublicationViewType>();

  @Input() filtersChanged: Observable<PublicationFilters>;

  @Input() searchBy: string;

  isByStore: boolean;

  opened = false;
  filtersChips: FilterChipModel[];

  suppliers: Supplier[];

  totalResults: BehaviorSubject<number>;

  selectedViewType: PublicationViewType;

  onlyOnline: boolean;

  private currentFilters: PublicationFilters;

  constructor(
    private supplierService: SuppliersService,
    private publicationService: PublicationService,
    private networkCategoryPipe: NetworkCategoryPipe,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  // Allows to access the enum class directly in the template
  public get viewType(): typeof PublicationViewType {
    return PublicationViewType;
  }

  ngOnInit() {
    this.selectedViewType = PublicationViewType.PUBLICATION;

    this.buildChipsFromFilters(new PublicationFilters());

    if (this.filtersChanged) {
      this.filtersChanged.subscribe((f) => {
        this.currentFilters = f;
        this.buildChipsFromFilters(f);
      });
    }

    this.supplierService.getAllSuppliers().subscribe((s) => (this.suppliers = s));

    this.totalResults = this.publicationService.resultsNumber;

    this.isByStore = this.searchBy === 'store';
  }

  toggle() {
    this.opened = !this.opened;
    this.toggleFilters.emit();
  }

  buildChipsFromFilters(filters: PublicationFilters) {
    this.filtersChips = [];
    if (filters.onlyPending) {
      this.filtersChips.push(new FilterChipModel('Only pending', undefined, 'onlyPending'));
    }
    if (this.searchBy !== 'store' && !isNilty(filters.storeCode)) {
      this.filtersChips.push(new FilterChipModel('Store', filters.storeCode, 'storeCode'));
    }
    if (this.searchBy !== 'barcode' && !isNilty(filters.sku)) {
      this.filtersChips.push(new FilterChipModel('SKU', filters.sku, 'sku'));
    }
    if (this.searchBy !== 'barcode' && !isNilty(filters.barcode)) {
      this.filtersChips.push(new FilterChipModel('Barcode', filters.barcode, 'barcode'));
    }
    if (!isNilty(filters.status)) {
      this.filtersChips.push(new FilterChipModel('Status', filters.status, 'status'));
    }
    if (!isNilty(filters.supplierId)) {
      this.filtersChips.push(
        new FilterChipModel('Supplier', this.suppliers.find((it) => it.id === filters.supplierId)?.code, 'supplierId')
      );
    }
    if (!isNilty(filters.marketplaceOperation)) {
      this.filtersChips.push(new FilterChipModel('Operation', filters.marketplaceOperation, 'marketplaceOperation'));
    }
    if (!isNilty(filters.category)) {
      this.filtersChips.push(new FilterChipModel('Category', this.networkCategoryPipe.transform(filters.category), 'category'));
    }
    if (!isNilty(filters.brand)) {
      this.filtersChips.push(new FilterChipModel('Brand', filters.brand, 'brand'));
    }
    if (!isNilty(filters.marketplaceBarcode)) {
      this.filtersChips.push(new FilterChipModel('Marketplace barcode', filters.marketplaceBarcode, 'marketplaceBarcode'));
    }
    if (!isNilty(filters.marketplaceFeedId)) {
      this.filtersChips.push(new FilterChipModel('Feed Id', filters.marketplaceFeedId, 'marketplaceFeedId'));
    }
    if (!isNilty(filters.errorCode)) {
      this.filtersChips.push(new FilterChipModel('Error', filters.errorCode, 'errorCode'));
    }
    if (!isNilty(filters.onlySubmitted)) {
      this.filtersChips.push(new FilterChipModel(filters.onlySubmitted ? 'Only submitted' : 'Only unsubmitted', '', 'onlySubmitted'));
    }

    this.onlyOnline = filters.onlyOnline;
    this.opened = false;
  }

  removeFilter(key: string) {
    this.opened = false;
    this.onRemoveFilter.emit(key);
  }

  editFilter(key: string) {
    this.opened = true;
    this.onEditFilter.emit(key);
  }

  clearFilters() {
    this.onClearFilters.next();
  }

  changeViewType(view: PublicationViewType) {
    this.switchViewType.emit(view);
  }

  onlyOnlineChanged(onlyOnline: boolean) {
    this.onlyOnline = onlyOnline;
    this.onOnlyOnlineChanged.emit(this.onlyOnline);
  }

  exportPublications() {
    this.dialog
      .open(GenericConfirmationModalComponent, { data: `This will export ${this.totalResults.value} publications.` })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          this.doExport();
        }
      });
  }

  uploadAnomalouslyPricedFile() {
    this.dialog.open(AnomalouslyPricedModalComponent);
  }

  private doExport() {
    console.log('Do export 2');
    this.publicationService
      .exportPublications(this.currentFilters.storeCode, this.currentFilters)
      .subscribe(() => this.snackBar.open('Request sent.', 'CLOSE')._dismissAfter(2000));
  }
}
