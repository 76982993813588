import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Permissions } from '@models/security/permission-model';
import { AuthorizationService } from '@security/authorization.service';
import { PowerSeller } from '@models/configuration/rules/power-seller-rule/power-seller-model';
import { SearchRestrictionDto } from '@models/configuration/policies/restrictions/restrictions-utils';

@Component({
  selector: 'app-blacklists-configuration',
  templateUrl: './restrictions-configuration.component.html',
  styleUrls: ['./restrictions-configuration.component.scss'],
})
export class RestrictionsConfigurationComponent implements OnInit {
  powerSellers: PowerSeller[] = [];

  hasRestrictionsManagement: Observable<boolean>;

  searchDto: SearchRestrictionDto = undefined;
  doSearchSubject = new Subject<SearchRestrictionDto>();

  constructor(private authorizationService: AuthorizationService) {}

  ngOnInit() {
    this.hasRestrictionsManagement = this.authorizationService.hasMarkusPermission(Permissions.RestrictionsManagement);
  }

  search(searchDto: SearchRestrictionDto) {
    this.searchDto = searchDto;
    this.doSearchSubject.next(this.searchDto);
  }
}
