import { Pipe, PipeTransform } from '@angular/core';
import { ListingSellingPlatform } from '@models/listings/listing-selling-platform-model';
import { isNilty } from '@core/utils.service';

@Pipe({
  name: 'listingSellingPlatformDescription',
})
export class ListingSellingPlatformDescriptionPipe implements PipeTransform {
  transform(value: ListingSellingPlatform): string {
    return [value.sellingPlatform, value.country].filter((it) => !isNilty(it)).join(' - ');
  }
}
