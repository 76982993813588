import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericImportTemplate, TemplateColumnWithPosition } from '@models/import/import-templates-model';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-select-column-position',
  template: `
    <div class="dialog-container" (keyup.enter)="confirm()">
      <div mat-dialog-content>
        <div class="row">
          <div class="col center">
            <label for="position">Position for {{ positionToEdit.column.name }}</label>
            <mat-form-field class="position-input">
              <input
                id="position"
                matInput
                [(ngModel)]="positionToEdit.position"
                [maxlength]="3"
                (ngModelChange)="validate($event)"
                oninput="this.value = this.value.toUpperCase()"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row description">
          <div *ngIf="error" class="col center error">{{ error }}</div>
          <div *ngIf="warn" class="col center warn">{{ warn }}</div>
          <div class="col center" *ngIf="!error && !warn">(press ENTER to confirm)</div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .dialog-container {
        font-family: 'Titillium Web', sans-serif;
        width: 400px !important;
      }

      .position-input {
        max-width: 35px;
        margin-left: 50px;
      }

      .description {
        font-size: 13px;
      }
      .error {
        color: red;
      }
      .warn {
        color: orange;
      }
    `,
  ],
})
export class SelectColumnPositionModalComponent implements OnInit {
  positionToEdit: TemplateColumnWithPosition;
  warn: string = undefined;
  error: string = undefined;

  private occupiedColumns: TemplateColumnWithPosition[] = [];
  private occupiedPosition: TemplateColumnWithPosition = undefined;
  private templateColumns: TemplateColumnWithPosition[];

  constructor(
    private dialogRef: MatDialogRef<SelectColumnPositionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { column: TemplateColumnWithPosition; template: GenericImportTemplate }
  ) {}

  ngOnInit() {
    this.positionToEdit = this.data.column;
    this.templateColumns = this.data.template.columns;
    this.occupiedColumns = this.templateColumns.filter((it) => it.column.name !== this.positionToEdit.column.name);
  }

  validate(newValue: string) {
    if (isNilty(newValue)) return;

    const pattern = /^[A-Z]{1,3}$/;
    if (!pattern.test(newValue)) {
      this.setError('Position must be a set of letters');
      return;
    }

    this.occupiedPosition = this.occupiedColumns.find((it) => it.position === newValue);
    if (!isNilty(this.occupiedPosition)) {
      this.setWarn('Position is used by another column. Press ENTER to force it.');
      return;
    }

    this.unsetMessages();
  }

  confirm() {
    if (isNilty(this.positionToEdit.position)) return;
    if (!isNilty(this.error)) return;

    if (!isNilty(this.occupiedPosition)) {
      this.occupiedPosition.position = undefined;
    }

    this.dialogRef.close();
  }

  private setError(error: string) {
    this.warn = undefined;
    this.error = error;
  }

  private setWarn(warn: string) {
    this.warn = warn;
    this.error = undefined;
  }

  private unsetMessages() {
    this.warn = undefined;
    this.error = undefined;
    this.occupiedPosition = undefined;
  }
}
