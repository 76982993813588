import { PowerSeller } from '@models/configuration/rules/power-seller-rule/power-seller-model';

export enum RestrictionSearchType {
  PRODUCT,
  POWER_SELLER,
  GLOBAL,
}

export class SearchRestrictionDto {
  searchType: RestrictionSearchType;
  entity: PowerSeller | string;

  constructor(searchType: RestrictionSearchType, entity: PowerSeller | string) {
    this.entity = entity;
    this.searchType = searchType;
  }
}
