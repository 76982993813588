import { Injectable } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { isNilty } from '../../../../core/utils.service';
import { PendingPublication } from '../../../../models/publications/pending-publication-model';
import { marketplaceOperationColorMap } from './publication-list-utils';

@Injectable()
export class PublicationGridCellFormatter {
  getPendingCell(params: ICellRendererParams): string {
    if (isNilty(params.value)) {
      return '';
    }

    const pending = params.value as PendingPublication;
    return `
         <span style="color: ${marketplaceOperationColorMap(pending.operation)}; padding-right: 1em;">
         <i class="fa ${pending.submitted ? 'fa-check' : 'fa-times'}" aria-hidden="true"></i>
            ${pending.operation}
         </span>`;
  }

  booleanColumn(params: ICellRendererParams): string {
    return `<i class="fa ${params.value ? 'fa-check' : 'fa-times sel-icon'}"> </i>`;
  }
}
