import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CatalogItemPriceDto')
export class CatalogItemPriceDto {
  @JsonProperty('countryCode', String)
  countryCode: string = undefined;

  @JsonProperty('purchasePrice', Number)
  purchasePrice: number = undefined;

  @JsonProperty('shippingPrice', Number, true)
  shippingPrice: number = undefined;

  @JsonProperty('currency', String, true)
  currency: string = undefined;
}
