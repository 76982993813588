import { Component, Input, OnInit } from '@angular/core';
import { SoupConfiguration } from '@models/configuration/soup-configuration/soup-configuration-model';
import { SoupConfigurationService } from '../../soup-configuration.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FtpEndpointsService } from '../../ftp-endpoints/ftp-endpoints.service';
import { FtpDestination, FtpEndpointConfiguration } from '@models/configuration/soup-configuration/ftp-destination-model';

@Component({
  selector: 'app-soup-destinations-configuration',
  templateUrl: './soup-destinations-configuration.component.html',
  styleUrls: ['./soup-destinations-configuration.component.css'],
})
export class SoupDestinationsConfigurationComponent implements OnInit {
  @Input() soupConfiguration: SoupConfiguration;

  clients: { active: boolean; client: string }[] = [];
  allFtpDestinations: FtpEndpointConfiguration[] = [];
  availableFtpDestinations: FtpEndpointConfiguration[] = [];

  constructor(private soupConfigurationService: SoupConfigurationService, private ftpEndpointsService: FtpEndpointsService) {}

  ngOnInit(): void {
    this.soupConfigurationService.findClients().subscribe((resp: string[]) => {
      resp.forEach((client) => {
        this.clients.push({
          active: this.soupConfiguration.destinationClients.findIndex((it) => it === client) !== -1,
          client,
        });
      });
    });
    this.ftpEndpointsService.getFtpEndpoints().subscribe((resp: FtpEndpointConfiguration[]) => {
      this.allFtpDestinations = resp;
      this.setAvailableFtpDestinations();
    });
  }

  checkedClient(event: MatCheckboxChange, client: string) {
    if (event.checked) {
      this.soupConfiguration.destinationClients.push(client);
    } else {
      this.soupConfiguration.destinationClients.splice(
        this.soupConfiguration.destinationClients.findIndex((it) => it === client),
        1
      );
    }
  }

  removeFtpDestination(index: number) {
    this.soupConfiguration.destinationFtps.splice(index, 1);
    this.setAvailableFtpDestinations();
  }

  addFtpDestination(ftpEndpoint: FtpEndpointConfiguration) {
    this.soupConfiguration.destinationFtps.push(new FtpDestination(this.soupConfiguration.supplierCode, ftpEndpoint));
    this.setAvailableFtpDestinations();
  }

  private setAvailableFtpDestinations() {
    this.availableFtpDestinations = this.allFtpDestinations.filter(
      (ftp) => this.soupConfiguration.destinationFtps.findIndex((it) => it.endpoint.partnerName === ftp.partnerName) === -1
    );
  }
}
