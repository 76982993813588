import { JsonObject, JsonProperty } from 'json2typescript';
import { RestrictionTarget } from '@models/configuration/policies/restrictions/restrictions-model';

@JsonObject('BarcodeRestrictionsFilters')
export class BarcodeRestrictionsFilters {
  @JsonProperty('ean', String, true) ean: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('marketplaceBarcode', String, true) marketplaceBarcode: string = undefined;
  @JsonProperty('targets', [RestrictionTarget], true) targets: RestrictionTarget[] = undefined;
  @JsonProperty('pageNumber', Number, true) pageNumber = 0;
  @JsonProperty('pageSize', Number, true) pageSize = 5;
}
