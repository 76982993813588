import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService } from '@core/converter.service';
import { EnvironmentService } from '@core/environment/environment.service';
import { PublishedSources } from '@models/configuration/published-sources-model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PublishedSourcesService {
  sources = new Subject<any>();

  constructor(private converterService: ConverterService, private http: HttpClient, private environmentService: EnvironmentService) {}

  getAllPublishedSources(): Observable<any> {
    const path = this.environmentService.getMarkusRestEndpoint('publishedSources');
    return this.http.get(path).pipe(map((resp: PublishedSources[]) => this.converterService.fromJSONtoObj(resp, PublishedSources)));
  }
}
