import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BlacklistTargetTypes } from '@models/configuration/policies/blacklist/blacklists-enums-model';
import { MatStepper } from '@angular/material/stepper';
import { AuthorizationService } from '@security/authorization.service';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { BlacklistReason } from '@models/configuration/policies/blacklist/blacklist-reason-model';
import {
  BarcodeBlacklist,
  Blacklist,
  BlacklistTarget,
  buildBarcodeBlacklist,
  buildPredicatesBlacklist,
  buildWordsBlacklist,
  ImportBlacklistFile,
  PredicatesBlacklist,
  WordsBlacklist,
} from '@models/configuration/policies/blacklist/blacklist-model';
import { BlacklistService } from '../blacklist.service';
import { isNilty } from '@core/utils.service';
import { RulePredicate } from '@models/configuration/rules/rule-predicate-model';
import { PolicyTypes } from '@models/configuration/policies/policy-enums';

@Component({
  selector: 'app-insert-blacklist',
  templateUrl: './insert-blacklist.component.html',
  styleUrls: ['./insert-blacklist.component.scss'],
})
export class InsertBlacklistComponent implements OnInit {
  @ViewChild(MatStepper, { static: false }) stepper: MatStepper;

  selectedTargetType: BlacklistTargetTypes;
  selectedTargetIdentifier: string;

  selectedBlacklistType: PolicyTypes;
  BlacklistTypes = PolicyTypes;

  blacklistEditCompleted = false;

  blacklistReasons: BlacklistReason[] = [];

  reason: BlacklistReason;
  email: string;
  blacklistDate = new Date();
  blacklistDateString: string;
  notes: string;

  // Barcode Blacklist Data
  fileOrSingleBarcode: 'FILE' | 'SINGLE' = undefined;
  barcodeType: string;
  barcodeValue: string;
  blacklistFile: File;

  // Predicates Blacklist Data
  predicates: RulePredicate[] = [];

  // Words Blacklist Data
  wordsField: string;
  words: string[] = [];

  blacklistTarget: BlacklistTarget;
  blacklistObject: BarcodeBlacklist | WordsBlacklist | PredicatesBlacklist | ImportBlacklistFile;

  constructor(private dialog: MatDialog, private authorizationService: AuthorizationService, private blacklistService: BlacklistService) {}

  ngOnInit() {
    this.formatDate(this.blacklistDate);

    this.email = this.authorizationService.currentUserMail;

    this.blacklistService.getBlacklistReasons().subscribe((r) => (this.blacklistReasons = r));
  }

  next() {
    switch (this.stepper.selected.label) {
      case 'Target and type':
        this.blacklistTarget = new BlacklistTarget(this.selectedTargetType, this.selectedTargetIdentifier);
        break;
      case 'Edit':
        if (this.fileOrSingleBarcode === 'FILE' && this.selectedBlacklistType === PolicyTypes.BARCODE) {
          this.buildBlacklistObject();
        }
        break;
      case 'Reason':
        this.buildBlacklistObject();
        break;
      case 'Recap':
        this.saveBlacklist();
        break;
    }
    this.stepper.next();
  }

  formatDate(date: Date) {
    this.blacklistDateString = formatDate(date, 'dd/MM/yyyy', 'en-US') + ' 12:00';
  }

  private buildBlacklistObject() {
    if (isNilty(this.email)) {
      // If you log directly in the Blacklist page, the email would be empty at this point, so I'll get it again
      this.email = this.authorizationService.currentUserMail;
    }
    const bl = new Blacklist(
      this.blacklistTarget,
      this.reason,
      this.email,
      this.blacklistDateString,
      this.notes,
      this.selectedBlacklistType
    );
    switch (this.selectedBlacklistType) {
      case PolicyTypes.BARCODE:
        if (this.fileOrSingleBarcode === 'SINGLE') {
          this.blacklistObject = buildBarcodeBlacklist(bl, this.barcodeType, this.barcodeValue);
        } else {
          this.blacklistObject = new ImportBlacklistFile(bl, this.blacklistFile, this.barcodeType);
        }
        break;
      case PolicyTypes.PREDICATES:
        this.blacklistObject = buildPredicatesBlacklist(bl, this.predicates);
        break;
      case PolicyTypes.WORDS:
        this.blacklistObject = buildWordsBlacklist(bl, this.wordsField, this.words);
        break;
    }
  }

  private saveBlacklist() {
    let obs: Observable<any>;
    switch (this.selectedBlacklistType) {
      case PolicyTypes.BARCODE:
        if (this.fileOrSingleBarcode === 'SINGLE') {
          obs = this.blacklistService.saveSingleBarcodeBlacklist(this.blacklistObject as BarcodeBlacklist);
        } else {
          obs = this.blacklistService.importBlacklistFile(this.blacklistObject as ImportBlacklistFile);
        }
        break;
      case PolicyTypes.PREDICATES:
        obs = this.blacklistService.saveSinglePredicatesBlacklist(this.blacklistObject as PredicatesBlacklist);
        break;
      case PolicyTypes.WORDS:
        obs = this.blacklistService.saveSingleWordsBlacklist(this.blacklistObject as WordsBlacklist);
        break;
    }

    if (obs) {
      obs.subscribe(() => this.reset());
    }
  }

  private reset() {
    this.blacklistEditCompleted = undefined;
    this.reason = undefined;
    this.email = undefined;
    this.blacklistDate = new Date();
    this.formatDate(this.blacklistDate);
    this.notes = undefined;
    this.barcodeType = undefined;
    this.barcodeValue = undefined;
    this.blacklistFile = undefined;
    this.predicates = undefined;
    this.wordsField = undefined;
    this.words = undefined;
    this.blacklistObject = undefined;
    this.stepper.reset();
  }
}
