import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { GenericSoupConfiguration, ROUTE_TYPES } from '../../../../models/configuration/soup-configuration/soup-configuration-model';
import { ActivatedRoute } from '@angular/router';
import { isNilty } from '../../../../core/utils.service';
import { SoupConfigurationService } from '../soup-configuration.service';
import { Location } from '@angular/common';
import { MatExpansionPanel } from '@angular/material/expansion';
import { FtpRouteConfiguration } from '../../../../models/configuration/soup-configuration/route-configurations/ftp-route-configuration-model';
import { HttpRouteConfiguration } from '../../../../models/configuration/soup-configuration/route-configurations/http-route-configuration-model';
import { B2bRouteConfiguration } from '../../../../models/configuration/soup-configuration/route-configurations/b2b-route-configuration-model';
import { MatDialog } from '@angular/material/dialog';
import { GenericErrorModalComponent } from '../../../../shared/generic-error-modal/generic-error-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GenericConfirmationModalComponent } from '../../../../shared/generic-confirmation-modal/generic-confirmation-modal.component';

@Component({
  selector: 'app-soup-configuration-edit',
  templateUrl: './soup-configuration-edit.component.html',
  styleUrls: ['./soup-configuration-edit.component.css'],
})
export class SoupConfigurationEditComponent implements OnInit, AfterViewInit {
  @ViewChild('destinationPanel') destinationPanel: MatExpansionPanel;
  @ViewChild('supplierPanel') supplierPanel: MatExpansionPanel;

  editMode: boolean;

  currentId: string;
  soupConfiguration: GenericSoupConfiguration;

  routeConfigurationErrors: string[] = [];

  private mandatoryFtpFields = ['host', 'port', 'user', 'password'];
  private mandatoryB2bFields = ['host', 'user', 'password'];
  private mandatoryHttpFields = ['host'];

  constructor(
    private route: ActivatedRoute,
    private soupConfigurationService: SoupConfigurationService,
    private location: Location,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (isNilty(params['id'])) {
        this.editMode = false;
        this.soupConfiguration = new GenericSoupConfiguration();
      } else {
        this.editMode = true;
        this.currentId = params['id'];
        this.getConfiguration();
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.editMode) {
      this.destinationPanel.open();
    } else {
      this.supplierPanel.open();
    }
  }

  save() {
    if (this.validateSupplier()) {
      this.routeConfigurationErrors = [];
      if (this.validateRoute()) {
        this.dialog
          .open(GenericConfirmationModalComponent, { data: 'This will update the configuration and restart the Route.' })
          .afterClosed()
          .subscribe((resp: boolean) => {
            if (resp) {
              this.soupConfigurationService.saveConfiguration(this.soupConfiguration).subscribe((conf: GenericSoupConfiguration) => {
                this.snackBar.open('The configuration has been saved.')._dismissAfter(3000);
                this.soupConfigurationService.updateRoute(conf.id).subscribe(() => {
                  this.location.back();
                });
              });
            }
          });
      } else {
        this.dialog.open(GenericErrorModalComponent, {
          data: this.routeConfigurationErrors,
        });
      }
    }
  }
  revert() {
    this.getConfiguration();
  }
  back() {
    this.location.back();
  }

  private getConfiguration() {
    this.soupConfigurationService.findSoupConfiguration(this.currentId).subscribe((resp: GenericSoupConfiguration) => {
      this.soupConfiguration = resp;
    });
  }

  private validateSupplier(): boolean {
    return (
      !isNilty(this.soupConfiguration.distrName) &&
      !isNilty(this.soupConfiguration.supplierCode) &&
      !isNilty(this.soupConfiguration.supplierName) &&
      !isNilty(this.soupConfiguration.stockLocationCode)
    );
  }

  private validateRoute(): boolean {
    if (isNilty(this.soupConfiguration.routeType)) {
      this.routeConfigurationErrors.push('RouteType must not be null.');
    } else {
      let errorString = '';
      switch (this.soupConfiguration.routeType) {
        case ROUTE_TYPES.FTP:
          errorString = this.validateFtp(errorString);
          break;
        case ROUTE_TYPES.B2B:
          errorString = this.validateB2b(errorString);
          break;
        case ROUTE_TYPES.HTTP:
          errorString = this.validateHttp(errorString);
          break;
        case ROUTE_TYPES.AWS_SFTP:
          break;
      }
      if (errorString !== '') {
        this.routeConfigurationErrors.push(errorString);
      }

      this.verifyFileProperties();

      if (
        this.soupConfiguration.configuration.multipleFile &&
        Object.keys(this.soupConfiguration.configuration.multipleFileMap).length === 0
      ) {
        this.routeConfigurationErrors.push(
          'If the Multiple File flag is true, you must configure at least one file in the Multiple Files map.'
        );
      }
      if (
        this.soupConfiguration.configuration.chunkedFeedConfiguration &&
        isNilty(this.soupConfiguration.configuration.chunkedFeedConfiguration.supplierHandlerClass)
      ) {
        this.routeConfigurationErrors.push('The handler class name is mandatory for a Chunked Configuration.');
      }
    }
    return this.routeConfigurationErrors.length === 0;
  }

  private validateFtp(errorString: string): string {
    const source = this.soupConfiguration.configuration as FtpRouteConfiguration;
    if (isNilty(source.sourceType)) {
      errorString = this.addSourceError(errorString, 'protocol');
    }
    return this.validateFields(this.mandatoryFtpFields, source, errorString);
  }
  private validateB2b(errorString: string): string {
    const source = this.soupConfiguration.configuration as B2bRouteConfiguration;
    return this.validateFields(this.mandatoryB2bFields, source, errorString);
  }
  private validateHttp(errorString: string): string {
    const source = this.soupConfiguration.configuration as HttpRouteConfiguration;
    if (isNilty(source.sourceType)) {
      errorString = this.addSourceError(errorString, 'protocol');
    }
    return this.validateFields(this.mandatoryHttpFields, source, errorString);
  }

  private validateFields(fields: string[], source: any, errorString: string): string {
    fields.forEach((field) => {
      if (isNilty(source[field])) {
        errorString = this.addSourceError(errorString, field);
      }
    });
    return errorString;
  }

  private verifyFileProperties() {
    const fileProperties = this.soupConfiguration.configuration.fileProperties;

    if (Object.keys(fileProperties.positions).length === 0) {
      this.routeConfigurationErrors.push('No positions configured, the output json will be empty.');
    }
    if (fileProperties.fileExtension === 'XML' && isNilty(fileProperties.root)) {
      this.routeConfigurationErrors.push('XML File requires a root parameter.');
    }
  }

  private addSourceError(errorString, field): string {
    if (errorString === '') {
      errorString = 'Error in Route Source, some mandatory parameters are not set: ' + field;
    } else {
      errorString += ', ' + field;
    }
    return errorString;
  }
}
