import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { isNilty } from 'src/app/core/utils.service';
import { StockLocation } from 'src/app/models/stock-location-model';
import { StockLocationFilters } from '@models/filters/stock-location';
import { MatDialog } from '@angular/material/dialog';
import { StockLocationsService } from './stock-locations.service';
import { AddStockLocationComponent } from './add-stock-location/add-stock-location.component';

@Component({
  selector: 'app-stock-locations',
  templateUrl: './stock-locations.component.html',
  styleUrls: ['./stock-locations.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class StockLocationsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  resultsNumber: Observable<number>;
  dataSource = new MatTableDataSource<StockLocation>([]);
  displayedColumns = [
    'code',
    'supplier',
    'name',
    'restrictOrderTypeTo',
    'lastStockUpdate',
    'totalItems',
    'totalQuantity',
    'lastTradingPartnerFeed',
    'returnStockLocationCode',
    'openStockItems',
    'showDetails',
  ];

  selectedRow: StockLocation;
  filters = new StockLocationFilters();

  constructor(
    private stockLocationsService: StockLocationsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['supplier']) {
        this.filters.code = params['supplier'];
      }
    });

    this.resultsNumber = this.stockLocationsService.resultsNumber;
    this.loadResults();
  }

  loadResults() {
    this.stockLocationsService.getFilteredStockLocations(this.filters).subscribe((resp: StockLocation[]) => {
      this.dataSource.data = resp;
    });
  }

  loadPage() {
    this.filters.pageSize = this.paginator.pageSize;
    this.filters.pageNumber = this.paginator.pageIndex;
    this.loadResults();
  }

  onSort() {
    this.filters.sortBy = this.sort.active;
    this.filters.sortDirection = this.sort.direction;
    this.loadResults();
  }

  search() {
    this.paginator.pageIndex = 0;
    this.filters.pageNumber = this.paginator.pageIndex;
    this.loadResults();
  }

  reset() {
    this.paginator.pageIndex = 0;
    this.filters.pageNumber = 0;
    this.filters.code = null;
    this.loadResults();
  }

  toggle(row: StockLocation) {
    this.selectedRow = this.selectedRow === row ? null : row;
  }

  openStockItems(stockLocation: StockLocation) {
    this.router.navigate(['stock-items'], { queryParams: { stockLocation: stockLocation.code } });
  }

  downloadFile(fileUrl: string) {
    if (!isNilty(fileUrl)) {
      window.open(fileUrl, '_blank');
    }
  }

  openNewStockLocation() {
    this.dialog.open(AddStockLocationComponent);
  }
}
