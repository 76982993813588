import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CatalogFeedHistoryEntry } from '@models/catalog-item-history-model';

@Component({
  selector: 'app-show-json-modal',
  templateUrl: './show-json-modal.component.html',
  styleUrls: ['./show-json-modal.component.css'],
})
export class ShowJsonModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ShowJsonModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: CatalogFeedHistoryEntry
  ) {}
}
