import { Component, Input } from '@angular/core';
import { RouteConfiguration } from '../../../../../../../models/configuration/soup-configuration/route-configuration-model';
import {
  HTTP_SOURCE_TYPES,
  HttpRouteConfiguration,
} from '../../../../../../../models/configuration/soup-configuration/route-configurations/http-route-configuration-model';

@Component({
  selector: 'app-http-route-configuration',
  templateUrl: './http-route-configuration.component.html',
  styleUrls: ['./http-route-configuration.component.css'],
})
export class HttpRouteConfigurationComponent {
  httpRouteConfiguration: HttpRouteConfiguration;
  httpSourceType: string[] = Object.keys(HTTP_SOURCE_TYPES);

  @Input() set _httpRouteConfiguration(c: RouteConfiguration) {
    this.httpRouteConfiguration = c as HttpRouteConfiguration;
  }
}
