import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColumnWithPositionAndName } from '@models/export-metadata/metadata-export-model';

@Component({
  selector: 'app-export-select-column-position',
  template: `
    <div class="dialog-container" (keyup.enter)="confirm()">
      <div mat-dialog-content>
        <div class="row">
          <div class="col center">
            <label for="position">Edit name for {{ columnToEdit.column }}</label>
            <br />
            <mat-form-field class="position-input">
              <input id="position" matInput [(ngModel)]="columnToEdit.columnName" oninput="this.value = this.value.toUpperCase()" />
            </mat-form-field>
          </div>
        </div>
        <div class="row description">
          <div class="col center" *ngIf="columnToEdit.columnName.length > 0">(press ENTER to confirm)</div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .dialog-container {
        font-family: 'Titillium Web', sans-serif;
        width: 400px !important;
      }

      .description {
        font-size: 13px;
      }
    `,
  ],
})
export class EditColumnNameModalComponent implements OnInit {
  columnToEdit: ColumnWithPositionAndName;

  constructor(
    private dialogRef: MatDialogRef<EditColumnNameModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { column: ColumnWithPositionAndName }
  ) {}

  ngOnInit() {
    this.columnToEdit = this.data.column;
  }

  confirm() {
    this.dialogRef.close();
  }
}
