import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { isNilty } from '@core/utils.service';
import { fromEvent, Subject } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-brandon-autocomplete',
  templateUrl: './brandon-autocomplete.component.html',
  styleUrls: ['./brandon-autocomplete.component.css'],
})
export class BrandonAutocompleteComponent implements OnInit {
  @ViewChild('filterInput', { static: true }) filterInput: ElementRef;
  @ViewChild('autocomplete', { static: true }) autocomplete: MatAutocomplete;

  @Input() cssClass: string;
  @Input() label: string;

  @Input() filterKey: string;
  @Input() displayKey: string;
  @Input() valueKey = '';

  @Input() autoActiveFirstOption = false;
  @Input() allowNullOption = true;
  @Input() disabled = false;

  @Input() isMulti = false;

  @Output() valueChange = new EventEmitter<any>();

  filteredData = new Subject<any[]>();

  selectedOptions: { name: string; code: string | number }[] = [];

  _value: any;
  private _allData: any[];
  private formControl: AbstractControl<any>;

  // Value of the control, i.e. all the selected options joined by ,
  @Input() set value(v: any) {
    this._value = v;
    if (isNilty(v)) {
      if (this.filterInput.nativeElement) this.filterInput.nativeElement.value = '';
      this.resetSelectedOptions();
    }
    this.setValuesAndFilter(this._allData);
    this.initSelectedOptions();
  }

  // List of all the options
  @Input() set allData(data: any[]) {
    this.setValuesAndFilter(data);
    setTimeout(() => {
      this.setValuesAndFilter(data);
      this.initSelectedOptions();
    }, 0);
  }

  // Form control
  @Input() set externalFormControl(fc: AbstractControl<any>) {
    this.formControl = fc;
    this.setExistingFormValue();
  }

  ngOnInit(): void {
    fromEvent(this.filterInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this._filter(text);
      });

    if (!isNilty(this.formControl)) {
      this.formControl.valueChanges.subscribe(() => {
        this._value = this.formControl.value;
        this.setExistingFormValue();
        this._filter();
        this.initSelectedOptions();
      });
    }
  }

  // A user selects an option
  selectionChange(event: MatAutocompleteSelectedEvent) {
    if (event.option.value === null) {
      this.selectedOptions = new Array<any>();
    } else {
      if (this.selectedOptions.some((it) => event.option.value === it.code)) return;
      if (this.isMulti) this.selectedOptions.push({ name: event.option.viewValue, code: event.option.value });
      else this.selectedOptions = [{ name: event.option.viewValue, code: event.option.value }];
    }

    this.filterInput.nativeElement.value = '';
    this.updateAutocompleteView();
    this.updateValueAndEmitChanges();
  }

  // Computes the label to be displayed. You can pass one or multiple parameters, separated by spaces ' '
  displayOption(option: any) {
    if (!isNilty(this.displayKey)) {
      if (!this.displayKey.includes(' ')) {
        return option[this.displayKey];
      } else {
        const displayArray = this.displayKey.split(' ');
        let displayString = '';
        displayArray.forEach((it, i) => {
          if (i !== 0) {
            displayString += ` - `;
          }
          displayString += `${option[it]}`;
        });
        return displayString;
      }
    } else return option;
  }

  // User removes an option
  remove(option: any): void {
    const index = this.selectedOptions.indexOf(option);

    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.updateAutocompleteView();
    }
    this.updateValueAndEmitChanges();
  }

  private initSelectedOptions() {
    if (isNilty(this._value) || isNilty(this._allData)) {
      this.selectedOptions = [];
      return;
    }
    if (this.isMulti) {
      this.selectedOptions = [];
      this._value.forEach((val) => {
        this.selectedOptions.push(this.toSelectedOption(this._allData.find((it) => it[this.valueKey] === val)) || { name: val, code: val });
      });
    } else {
      const item = this.toSelectedOption(this._allData.find((it) => it[this.valueKey] === this._value));
      this.selectedOptions = isNilty(this.valueKey) || isNilty(item) ? [{ name: this._value, code: this._value }] : [item];
    }
  }
  private toSelectedOption(option: any): { name: string; code: string | number } {
    if (isNilty(option)) return;
    return { name: option[this.displayKey], code: option[this.valueKey] };
  }

  private setValuesAndFilter(data: any[]) {
    this._allData = data;
    this.setExistingFormValue();
    this._filter();
  }

  private _filter(value?: string) {
    if (isNilty(this._allData)) {
      this.filteredData.next([]);
      return;
    }
    if (isNilty(value)) {
      this.filteredData.next(this._allData);
      if (value === '') {
        if (!isNilty(this.formControl)) {
          this.formControl.patchValue(this._value);
        }
        this.valueChange.emit(this._value);
      }
      return;
    }
    const filterValue = value.toLowerCase();
    if (isNilty(this.filterKey)) {
      this.filteredData.next(this._allData.filter((option) => option.toLowerCase().includes(filterValue)));
    } else {
      this.filteredData.next(this._allData.filter((option) => option[this.filterKey].toLowerCase().includes(filterValue)));
    }
  }

  private setExistingFormValue() {
    if (!isNilty(this.formControl) && !isNilty(this.formControl.value)) {
      this._value = this.formControl.value;
    }
  }

  private resetSelectedOptions() {
    setTimeout(() => {
      this.selectedOptions = [];
      this.resetAutocompleteView();
    }, 0);
  }

  private updateValueAndEmitChanges() {
    this._value = this.isMulti ? this.selectedOptions.map((it) => it.code) : this.selectedOptions[0]?.code;
    if (!isNilty(this.formControl)) {
      this.formControl.patchValue(this._value);
    }
    this.valueChange.emit(this._value);
  }
  private updateAutocompleteView() {
    this.autocomplete?.options?.forEach((autocompleteOption) => {
      const isSelected = !isNilty(this.selectedOptions?.find((it) => it.code === autocompleteOption.value));
      if (isSelected) {
        autocompleteOption.select();
      } else {
        autocompleteOption.deselect();
      }
    });
  }
  private resetAutocompleteView() {
    this.autocomplete?.options?.forEach((it) => it.deselect());
  }
}
