import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Role } from '../../../../models/role-model';
import { RolesService } from '../roles.service';
import { MatDialog } from '@angular/material/dialog';
import { RolesDuplicateModalComponent } from '../roles-duplicate-modal/roles-duplicate-modal.component';

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.css'],
})
export class RolesListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource: MatTableDataSource<Role>;
  displayedColumns = ['application', 'name', 'edit'];

  constructor(private rolesService: RolesService, private dialog: MatDialog) {}

  ngOnInit() {
    this.loadRoles();
  }

  loadRoles() {
    this.rolesService.getRoles().subscribe((roles: Role[]) => {
      this.dataSource = new MatTableDataSource(roles);
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = (data: Role, filter: string) =>
        data.name.toLowerCase().indexOf(filter) !== -1 ||
        data.application.toLowerCase().indexOf(filter) !== -1 ||
        data.permissions.some((p) => p.name.toLowerCase().indexOf(filter) !== -1);
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  onDuplicate() {
    this.dialog
      .open(RolesDuplicateModalComponent)
      .afterClosed()
      .subscribe(() => {
        this.loadRoles();
      });
  }
}
