import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { isNilty } from '@core/utils.service';
import { PieSeriesOption, TitleComponentOption } from 'echarts';
import { PieChart } from 'echarts/charts';
import { TitleComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

@Component({
  selector: 'app-pa-pie-detail-chart',
  templateUrl: './pa-pie-detail-chart.component.html',
  styleUrls: ['./pa-pie-detail-chart.component.css'],
})
export class PaPieDetailChartComponent implements AfterViewInit, OnChanges {
  @Input() data: any;

  @ViewChild('chartElem') chartElem: ElementRef;

  firstInit = false;

  ngAfterViewInit() {
    this.chartInit();
  }

  ngOnChanges() {
    if (this.firstInit && !isNilty(this.data)) this.chartInit();
  }

  chartInit() {
    this.firstInit = true;
    echarts.use([TitleComponent, PieChart, LabelLayout, CanvasRenderer]);

    type EChartsPieOption = echarts.ComposeOption<TitleComponentOption | PieSeriesOption>;

    const chartDom = this.chartElem.nativeElement;
    chartDom.style.height = this.data.length > 30 ? '750px' : '500px';
    chartDom.style.height = '750px';
    const myChart = echarts.init(chartDom);

    const option: EChartsPieOption = {
      series: [
        {
          type: 'pie',
          radius: [100, 150],
          top: '0%',
          height: '100%',
          left: 'center',
          width: '100%',
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 1,
            borderRadius: 3,
          },
          label: {
            alignTo: 'edge',
            formatter: `{name|{b}}: {value|{c}} `,
            minMargin: 5,
            edgeDistance: 10,
            lineHeight: 18,
            rich: {
              name: {
                fontSize: 14,
                color: '#00275c',
              },
              value: {
                fontSize: 14,
                color: '#000',
              },
            },
          },
          labelLine: {
            length: 15,
            length2: 0,
            maxSurfaceAngle: 80,
          },
          labelLayout: (params) => {
            const points = params.labelLinePoints as number[][];
            return {
              labelLinePoints: points,
            };
          },
          data: this.data.map((el) => ({ name: el.supplierCode, value: el.publicationInfo.published })),
        },
      ],
    };

    if (option) myChart.setOption(option);
  }
}
