import { JsonObject, JsonProperty } from 'json2typescript';
import { RouteConfiguration } from '../route-configuration-model';

@JsonObject('B2bRouteConfiguration')
export class B2bRouteConfiguration extends RouteConfiguration {
  @JsonProperty('host', String, true) host: string = undefined;
  @JsonProperty('user', String, true) user: string = undefined;
  @JsonProperty('password', String, true) password: string = undefined;
  @JsonProperty('timer', Number, true) timer: number = undefined;
}
