import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { StoreConfigurationComponent } from './store-configuration.component';
import { StoreListComponent } from './store-list.component.ts/store-list.component';
import { AccountConnectionsComponent } from './store-list.component.ts/store-tree.component.ts/account-connections.component';
import { ConnectionCredentialsModalComponent } from './store-list.component.ts/store-tree.component.ts/connection-credentials-modal/connection-credentials-modal.component';
import { AddStoreComponent } from './add-store/add-store.component';
import { OrderTypeTagComponent } from './order-type-tag.component';
import { NewMarketplaceModalComponent } from './add-store/new-marketplace-modal/new-marketplace-modal.component';
import { NewStoreGroupModalComponent } from './add-store/new-store-group-modal/new-store-group-modal.component';

@NgModule({
  declarations: [
    StoreConfigurationComponent,
    StoreListComponent,
    AccountConnectionsComponent,
    ConnectionCredentialsModalComponent,
    AddStoreComponent,
    OrderTypeTagComponent,
    NewMarketplaceModalComponent,
    NewStoreGroupModalComponent,
  ],
  imports: [SharedModule],
  providers: [],
  entryComponents: [ConnectionCredentialsModalComponent],
})
export class StoreConfigurationModule {}
