import { Component, Input, OnInit } from '@angular/core';
import { BlacklistService } from '../blacklist.service';
import { Subject } from 'rxjs';
import { BlacklistSearchType, SearchBlacklistDto } from '@models/configuration/policies/blacklist/blacklist-utils';
import { isNilty } from '@core/utils.service';
import { PublishedStore } from '@models/publications/published-store-model';
import { MarketplaceDto } from '@models/configuration/marketplace/marketplace-model';
import { BlacklistTargetTypes } from '@models/configuration/policies/blacklist/blacklists-enums-model';
import { BlacklistTarget, PredicatesBlacklist, WordsBlacklist } from '@models/configuration/policies/blacklist/blacklist-model';
import { MatDialog } from '@angular/material/dialog';
import { HelpModalComponent } from '@shared/help-modal/help-modal.component';

@Component({
  selector: 'app-blacklists-by-target',
  templateUrl: './blacklists-by-target.component.html',
  styleUrls: ['./blacklists-by-target.component.scss'],
})
export class BlacklistsByTargetComponent implements OnInit {
  @Input() searchSubject: Subject<SearchBlacklistDto>;
  @Input() searchDto: SearchBlacklistDto;

  searchEvent = new Subject<BlacklistTarget[]>();

  targetSelectionType: 'ONLY_TARGET' | 'ALL_RELEVANT' = 'ALL_RELEVANT';

  targetsToSearch: BlacklistTarget[] = [];

  target: PublishedStore | MarketplaceDto | string;
  predicatesBlacklists: PredicatesBlacklist[] = [];
  wordsBlacklists: WordsBlacklist[] = [];

  constructor(private blacklistService: BlacklistService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.search();

    if (!isNilty(this.searchSubject)) {
      this.searchSubject.subscribe((dto) => {
        this.searchDto = dto;
        this.search();
      });
    }
  }

  changedSelectionType(event: 'ONLY_TARGET' | 'ALL_RELEVANT') {
    this.targetSelectionType = event;
    this.search();
  }

  showHelp() {
    this.dialog.open(HelpModalComponent, {
      data: {
        title: 'Blacklist Targets',
        html: `
          Each Blacklist is targeted to an entity, which can be a Store, Marketplace, Network or Global.\n
          Blacklists targeted to a Store will be only applied to that Store, Marketplace ones will be applied to all the Stores published on that Marketplace and so on.
          Global blacklists will work for everything.\n
          <b>Only selected target</b>: will show only the blacklists targeted exactly to the selected target.
          <b>All relevant targets</b>: will show all the blacklists that will be applied to the selected target. For example, if Store BROOK_DE, published on Marketplace Amazon.de is selected,
          by choosing this option all blacklists targeted to Store BROOK_DE, Marketplace Amazon.de, Network Amazon Seller and Global will be shown.
          This is useful to check <em>every</em> blacklist that will be applied to a certain store all at once.
          `,
      },
    });
  }

  search() {
    if (isNilty(this.searchDto)) {
      return;
    }

    this.targetsToSearch = [];

    switch (this.searchDto.searchType) {
      case BlacklistSearchType.STORE: {
        const s = (this.searchDto.entity as PublishedStore).store;
        this.targetsToSearch.push(this.buildStore(s.code));
        if (this.targetSelectionType === 'ALL_RELEVANT') {
          this.targetsToSearch.push(this.buildMarketplace(s.marketplace.name));
          this.targetsToSearch.push(this.buildNetwork(s.marketplace.network));
          this.targetsToSearch.push(this.buildGlobal());
        }
        break;
      }
      case BlacklistSearchType.MARKETPLACE: {
        const m = this.searchDto.entity as MarketplaceDto;
        this.targetsToSearch.push(this.buildMarketplace(m.name));
        if (this.targetSelectionType === 'ALL_RELEVANT') {
          this.targetsToSearch.push(this.buildNetwork(m.network));
          this.targetsToSearch.push(this.buildGlobal());
        }
        break;
      }
      case BlacklistSearchType.NETWORK: {
        this.targetsToSearch.push(this.buildNetwork(this.searchDto.entity as string));
        if (this.targetSelectionType === 'ALL_RELEVANT') {
          this.targetsToSearch.push(this.buildGlobal());
        }
        break;
      }
      case BlacklistSearchType.GLOBAL: {
        this.targetsToSearch.push(this.buildGlobal());
        break;
      }
    }

    this.blacklistService.findPredicatesBlacklistsByTargets(this.targetsToSearch).subscribe((r) => (this.predicatesBlacklists = r));
    this.blacklistService.findWordsBlacklistsByTargets(this.targetsToSearch).subscribe((r) => (this.wordsBlacklists = r));
    this.searchEvent.next(this.targetsToSearch);
  }

  private buildGlobal(): BlacklistTarget {
    return new BlacklistTarget(BlacklistTargetTypes.GLOBAL, BlacklistTargetTypes.GLOBAL);
  }
  private buildNetwork(id: string): BlacklistTarget {
    return new BlacklistTarget(BlacklistTargetTypes.NETWORK, id);
  }
  private buildMarketplace(id: string): BlacklistTarget {
    return new BlacklistTarget(BlacklistTargetTypes.MARKETPLACE, id);
  }
  private buildStore(id: string): BlacklistTarget {
    return new BlacklistTarget(BlacklistTargetTypes.STORE, id);
  }
}
