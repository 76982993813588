import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SalesOrderPollConfiguration } from 'src/app/models/configuration/sales-order-poll-configuration/sales-order.poll-configuration';
import { SalesOrderPollConfigurationFilters } from 'src/app/models/configuration/sales-order-poll-configuration/sales-order.poll-configuration-filters-model';
import { SalesOrderPollConfigurationService } from '../sales-order-poll-configuration.service';

@Component({
  selector: 'app-sales-order-poll-configuration-list',
  templateUrl: './sales-order-poll-configuration-list.component.html',
  styleUrls: ['./sales-order-poll-configuration-list.component.css'],
})
export class SalesOrderPollConfigurationListComponent implements OnInit {
  @ViewChild('filterInput', { static: true }) filterInput: ElementRef;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  filters = new SalesOrderPollConfigurationFilters();

  dataSource = new MatTableDataSource<SalesOrderPollConfiguration>();
  resultsNumber: Subject<number> = this.salesOrderPollConfigurationService.resultsNumber;
  displayedColumns = ['accountName', 'accountCode', 'cronExpression', 'destination', 'pollOnlyFulfilledOrders', 'active', 'actions'];

  constructor(private salesOrderPollConfigurationService: SalesOrderPollConfigurationService) {}

  ngOnInit() {
    this.loadSalesOrderPollConfigurations();

    fromEvent(this.filterInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.filters.accountString = text;
        this.loadSalesOrderPollConfigurations();
      });
  }

  loadSalesOrderPollConfigurations() {
    this.salesOrderPollConfigurationService
      .findSalesOrderPollConfigurationByFilters(this.filters)
      .subscribe((it) => (this.dataSource.data = it));
  }

  loadPage() {
    this.filters.pageSize = this.paginator.pageSize;
    this.filters.pageNumber = this.paginator.pageIndex;
    this.loadSalesOrderPollConfigurations();
  }
}
