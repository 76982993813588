import { Component, Input, OnInit } from '@angular/core';
import { BarcodeBlacklist, PredicatesBlacklist, WordsBlacklist } from '@models/configuration/policies/blacklist/blacklist-model';
import { MatDialog } from '@angular/material/dialog';
import { BlacklistDetailsModalComponent } from './blacklist-details-modal/blacklist-details-modal.component';
import { PolicyTypes } from '@models/configuration/policies/policy-enums';

@Component({
  selector: 'app-blacklist-row',
  template: `
    <div class="or-row" *ngIf="index !== 0 && type === BlacklistTypes.PREDICATES">OR</div>
    <div class="spacing-row" *ngIf="index === 0 || type !== BlacklistTypes.PREDICATES"></div>
    <div class="blacklist-row-div d-flex" (click)="openBlacklistDetails()">
      <span class="target-id">
        <app-policy-target-type-icon [targetType]="blacklist?.target?.type"></app-policy-target-type-icon>
        <b>{{ blacklist?.target?.identifier }}</b>
      </span>
      <span class="blacklist-row-info name" [matTooltip]="blacklist?.name">{{ blacklist?.name }}</span>
      <span class="blacklist-row-info reason" [matTooltip]="blacklist?.reason?.name">{{ blacklist?.reason?.name }}</span>
      <span class="blacklist-row-info date">{{ blacklist?.date.substr(0, 10) }}</span>

      <span class="predicates center flex-fill" *ngIf="type === BlacklistTypes.PREDICATES">
        <ng-container *ngFor="let p of blacklist?.predicates; let predicateIndex = index">
          <b *ngIf="predicateIndex !== 0" class="and-text">AND</b>{{ p.description }}
        </ng-container>
      </span>
      <span class="predicates center flex-fill" *ngIf="type === BlacklistTypes.WORDS"> <b>Words: </b>{{ words }} </span>
      <span class="predicates center flex-fill" *ngIf="type === BlacklistTypes.BARCODE">
        <b>{{ blacklist.barcodeType }}</b> {{ blacklist.barcodeValue }}
      </span>
    </div>
  `,
  styleUrls: ['./blacklist-row.scss'],
})
export class BlacklistRowComponent implements OnInit {
  @Input() index: number;
  @Input() blacklist: PredicatesBlacklist | WordsBlacklist | BarcodeBlacklist;
  words: string;
  type: PolicyTypes;
  BlacklistTypes = PolicyTypes;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    if (this.blacklist instanceof PredicatesBlacklist) {
      this.type = PolicyTypes.PREDICATES;
      this.blacklist.predicates.forEach((it) => it.setDescription());
    } else if (this.blacklist instanceof WordsBlacklist) {
      this.type = PolicyTypes.WORDS;
      this.words = this.blacklist.words.slice(0, 20).join(', ');
      if (this.blacklist.words.length > 20) {
        this.words += '...';
      }
    } else {
      this.type = PolicyTypes.BARCODE;
    }
  }

  openBlacklistDetails() {
    this.dialog.open(BlacklistDetailsModalComponent, { data: this.blacklist });
  }
}
