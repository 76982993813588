import { JsonObject, JsonProperty } from 'json2typescript';
import { isNilty } from 'src/app/core/utils.service';
import { MatChipsData } from '../mat-chips-data-model';
import { Pagination } from '../shared/pagination-model';
import { Status } from '../status-model';

@JsonObject('ProductFilters')
export class ProductFilters extends Pagination {
  @JsonProperty('productBarcodeValue', String, true) productBarcodeValue: string = undefined;
  @JsonProperty('productBarcodeType', Status, true) productBarcodeType: Status = undefined;
  @JsonProperty('internalSku', String, true) internalSku: string = undefined;
  @JsonProperty('title', String, true) title: string = undefined;
  @JsonProperty('brand', Number, true) brand: string = undefined;
  @JsonProperty('category', String, true) category: string = undefined;
}

export const catalogItemFiltersMap = (
  productBarcodeValue: string,
  productBarcodeType: Status,
  internalSku: string,
  title: string,
  brand: string,
  category: string
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(productBarcodeValue)) {
    map.push(new MatChipsData('Barcode Value', productBarcodeValue));
  }
  if (!isNilty(productBarcodeType)) {
    map.push(new MatChipsData('Barcode Type', productBarcodeType.description));
  }
  if (!isNilty(internalSku)) {
    map.push(new MatChipsData('Internal Sku', internalSku));
  }
  if (!isNilty(title)) {
    map.push(new MatChipsData('Title', title));
  }
  if (!isNilty(brand)) {
    map.push(new MatChipsData('Brand', brand));
  }
  if (!isNilty(category)) {
    map.push(new MatChipsData('Category', category));
  }

  return map;
};
