import { Injectable } from '@angular/core';
import { ConverterService } from '@core/converter.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { EnvironmentService } from '@core/environment/environment.service';
import { RulePredicateFieldsOutput } from '@models/configuration/rules/rule-predicate-model';
import { map } from 'rxjs/operators';
import { PublicationRule } from '@models/configuration/rules/publication-rule/publication-rule-model';
import { PublicationRuleFilters } from '@models/configuration/rules/publication-rule/publication-rule-filters-model';
import { cachedArrayGet, GenericArrayCache } from '@shared/service-cached-array';
import { PartialRuleRefreshInput } from '@models/configuration/rules/publication-rule/partial-rule-refresh-input-model';
import { RuleStockLocationSource, RuleSupplierSource } from '@models/configuration/rules/publication-rule-source-model';
import { BruteRefreshPublicationRequest } from '@models/configuration/rules/publication-rule/brute-refresh-publication-request-model';

@Injectable()
export class PublicationRulesService {
  resultsNumber = new Subject<number>();
  allAccountsCache = new GenericArrayCache<string>(this.http, this.converterService);
  rulePredicateFieldsCache = new GenericArrayCache<RulePredicateFieldsOutput>(this.http, this.converterService, RulePredicateFieldsOutput);
  savedPublicationRuleFilters: PublicationRuleFilters;

  constructor(private converterService: ConverterService, private http: HttpClient, private environmentService: EnvironmentService) {}

  getAllAccountNames(): Observable<string[]> {
    return cachedArrayGet(this.allAccountsCache, this.environmentService.getRestEndpoint('allAccountsNames'));
  }

  getRulePredicateFields(): Observable<RulePredicateFieldsOutput[]> {
    return cachedArrayGet<RulePredicateFieldsOutput>(
      this.rulePredicateFieldsCache,
      this.environmentService.getMarkusRestEndpoint('rulePredicateFields')
    );
  }

  saveRule(rule: PublicationRule): Observable<PublicationRule> {
    console.log(rule);
    const body = this.converterService.fromObjtoJSON(rule);
    const path = this.environmentService.getMarkusRestEndpoint('savePublicationRule');
    return this.http.post(path, body).pipe(map((resp: PublicationRule) => this.converterService.fromJSONtoObj(resp, PublicationRule)));
  }

  findRulesByFilters(filters: PublicationRuleFilters): Observable<PublicationRule[]> {
    this.savedPublicationRuleFilters = { ...filters };
    const body = this.converterService.fromObjtoJSON(filters);
    const path = this.environmentService.getMarkusRestEndpoint('findRulesByFilters');
    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: PublicationRule[]) => resp.map((it) => this.converterService.fromJSONtoObj(it, PublicationRule)))
    );
  }

  findRuleById(ruleId: string): Observable<PublicationRule> {
    const path = this.environmentService.getMarkusRestEndpoint('publicationRules') + '/' + ruleId;
    return this.http.get(path).pipe(map((it: PublicationRule) => this.converterService.fromJSONtoObj(it, PublicationRule)));
  }

  unpublishRule(rule: PublicationRule): Observable<any> {
    const body = this.converterService.fromObjtoJSON(rule);
    const path = this.environmentService.getMarkusRestEndpoint('unpublishRule');
    return this.http.post(path, body);
  }

  refreshRule(rule: PublicationRule): Observable<any> {
    const body = this.converterService.fromObjtoJSON(rule);
    const path = this.environmentService.getMarkusRestEndpoint('refreshRule');
    return this.http.post(path, body);
  }

  partiallyRefreshRule(rule: PublicationRule, suppliers: RuleSupplierSource[], stockLocations: RuleStockLocationSource[]): Observable<any> {
    const body = this.converterService.fromObjtoJSON(PartialRuleRefreshInput.fromPublicationRule(rule, suppliers, stockLocations));
    const path = this.environmentService.getMarkusRestEndpoint('partiallyRefreshRule');
    return this.http.post(path, body);
  }

  fullBruteRefresh(rule: PublicationRule): Observable<any> {
    const body = this.converterService.fromObjtoJSON(BruteRefreshPublicationRequest.fromPublicationRule(rule, [], []));
    const path = this.environmentService.getMarkusRestEndpoint('fullBruteRefresh');
    return this.http.post(path, body);
  }

  partialBruteRefresh(rule: PublicationRule, suppliers: RuleSupplierSource[], stockLocations: RuleStockLocationSource[]): Observable<any> {
    const body = this.converterService.fromObjtoJSON(BruteRefreshPublicationRequest.fromPublicationRule(rule, suppliers, stockLocations));
    const path = this.environmentService.getMarkusRestEndpoint('partialBruteRefresh');
    return this.http.post(path, body);
  }
}
