import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BlacklistTarget } from '@models/configuration/policies/blacklist/blacklist-model';
import { isNilty } from '@core/utils.service';
import { RestrictionTargetType } from '@models/configuration/policies/restrictions/restriction-target-type';
import { RestrictionTarget } from '@models/configuration/policies/restrictions/restrictions-model';

@Component({
  selector: 'app-select-restriction-target',
  templateUrl: './select-restriction-target.component.html',
  styleUrls: ['./select-restriction-target.component.scss'],
})
export class SelectRestrictionTargetComponent {
  @Output()
  selectedBlacklistTarget = new EventEmitter<BlacklistTarget>();

  @Output()
  selectedTargetTypeChange = new EventEmitter<RestrictionTargetType>();
  @Output()
  selectedTargetIdentifierChange = new EventEmitter<string>();

  @Input()
  selectedTargetType: RestrictionTargetType;
  @Input()
  selectedTargetIdentifier: string;

  showTargetHelp = false;

  WhitelistTargetTypes = RestrictionTargetType;
  targetTypes = Object.keys(RestrictionTargetType);

  targetTypeChanged(type: string) {
    if (type === RestrictionTargetType.GLOBAL) {
      this.selectedTargetIdentifier = RestrictionTargetType.GLOBAL;
    } else {
      this.selectedTargetIdentifier = undefined;
    }
    this.selectedTargetTypeChange.emit(RestrictionTargetType[type]);
    this.selectedTargetIdentifierChange.emit(this.selectedTargetIdentifier);
    this.emitWhitelistTarget();
  }

  doSelectTarget(identifier: string) {
    this.selectedTargetIdentifier = identifier;
    this.selectedTargetIdentifierChange.emit(identifier);
    this.emitWhitelistTarget();
  }

  private emitWhitelistTarget() {
    if (!isNilty(this.selectedTargetType) && !isNilty(this.selectedTargetIdentifier)) {
      this.selectedBlacklistTarget.emit(new RestrictionTarget(this.selectedTargetType, this.selectedTargetIdentifier));
    }
  }
}
