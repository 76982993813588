import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConverterService } from '@core/converter.service';
import { EnvironmentService } from '@core/environment/environment.service';
import { Clients } from '@models/security/clients';
import { UserPermissions } from '@models/security/user-permissions-model';

@Injectable()
export class AuthorizationService {
  currentUserMail = '';
  zionPermissions: string[];
  markusPermissions: string[];
  ponchPermissions: string[];

  private zionPermissionsSubject = new Subject<string[]>();
  private markusPermissionsSubject = new Subject<string[]>();
  private ponchPermissionsSubject = new Subject<string[]>();

  private gettingPermissions = false;

  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getPermissions(client: Clients = Clients.ZION): Observable<string[]> {
    if (this.zionPermissions && this.markusPermissions) {
      switch (client) {
        case Clients.ZION:
          return of(this.zionPermissions);
        case Clients.MARKUS:
          return of(this.markusPermissions);
        case Clients.PONCH:
          return of(this.ponchPermissions);
      }
    }

    if (!this.gettingPermissions) {
      this.gettingPermissions = true;

      const zionCall = this.getPermissionsFromPonch(Clients.ZION).pipe(
        map((role: UserPermissions) => {
          this.currentUserMail = role.email;
          this.zionPermissions = role.permissions;
        })
      );
      const markusCall = this.getPermissionsFromPonch(Clients.MARKUS).pipe(
        map((role: UserPermissions) => {
          this.currentUserMail = role.email;
          this.markusPermissions = role.permissions;
        })
      );
      const ponchCall = this.getPermissionsFromPonch(Clients.PONCH).pipe(
        map((role: UserPermissions) => {
          this.currentUserMail = role.email;
          this.ponchPermissions = role.permissions;
        })
      );

      return forkJoin([zionCall, markusCall, ponchCall]).pipe(
        map(() => {
          this.zionPermissionsSubject.next(this.zionPermissions);
          this.markusPermissionsSubject.next(this.markusPermissions);
          this.ponchPermissionsSubject.next(this.ponchPermissions);
          this.gettingPermissions = false;

          switch (client) {
            case Clients.ZION:
              return this.zionPermissions;
            case Clients.MARKUS:
              return this.markusPermissions;
            case Clients.PONCH:
              return this.ponchPermissions;
          }
        })
      );
    } else {
      switch (client) {
        case Clients.ZION:
          return this.zionPermissionsSubject;
        case Clients.MARKUS:
          return this.markusPermissionsSubject;
        case Clients.PONCH:
          return this.ponchPermissionsSubject;
      }
    }
  }

  hasZionPermission(permission: string): Observable<boolean> {
    return this.getPermissions(Clients.ZION).pipe(map((p) => p.includes(permission)));
  }

  hasMarkusPermission(permission: string): Observable<boolean> {
    return this.getPermissions(Clients.MARKUS).pipe(map((p) => p.includes(permission)));
  }

  hasZionPermissions(permissions: string[]): Observable<boolean> {
    return this.getPermissions(Clients.ZION).pipe(map((p) => p.some((permission) => permissions.includes(permission))));
  }

  hasPonchPermission(permission: string): Observable<boolean> {
    return this.getPermissions(Clients.PONCH).pipe(map((p) => p.includes(permission)));
  }

  private getPermissionsFromPonch(client: string): Observable<UserPermissions> {
    return this.http
      .get(this.environmentService.getPonchRestEndpoint('userPermissions') + '?application=' + client, {})
      .pipe(map((resp: UserPermissions) => this.converter.fromJSONtoObj(resp, UserPermissions)));
  }
}
