import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { MarkusRule } from '@models/configuration/rules/markus-rule-model';
import { PowerSeller } from '@models/configuration/rules/power-seller-rule/power-seller-model';
import { UpdateCriteria } from '@models/configuration/rules/update-criteria-model';
import { BlacklistTarget } from '@models/configuration/policies/blacklist/blacklist-model';
import { MarginParameters } from '../pricing-strategy-model';

@JsonObject('PowerSellerRule')
export class PowerSellerRule extends MarkusRule {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('powerSeller', PowerSeller, true) powerSeller: PowerSeller = undefined;
  @JsonProperty('active', Boolean, true) active = false;
  @JsonProperty('powerSellerSyncActive', Boolean, true) powerSellerSyncActive = false;
  @JsonProperty('powerSellerSyncCronExpression', String, true) powerSellerSyncCronExpression: string = undefined;
  @JsonProperty('updateCriteria', UpdateCriteria, true) updateCriteria: UpdateCriteria = undefined;
  @JsonProperty('alertReceivers', String, true) alertReceivers: string = undefined;
  @JsonProperty('inheritBlacklistsFrom', [BlacklistTarget], true) inheritBlacklistsFrom: BlacklistTarget[] = [];

  @JsonProperty('pricingParameters', Any, true) pricingParameters: any;

  constructor() {
    super();
    this.pricingParameters = new MarginParameters();
  }
}
