import { Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { isNilty } from 'src/app/core/utils.service';
import { ImportStockLocationFilters } from 'src/app/models/filters/import-stock-location';
import { ImportStockLocation } from 'src/app/models/import-stock-location-model';
import { ImportStockLocationsService } from '../../import-stock-locations.service';

@Component({
  selector: 'app-stock-locations-routes-imports',
  templateUrl: './stock-locations-routes-imports.component.html',
  styleUrls: ['./stock-locations-routes-imports.component.css'],
})
export class StockLocationsRoutesImportsComponent {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @Input() distrName: string;

  dataSource = new MatTableDataSource<ImportStockLocation>([]);
  displayedColumns = [
    'importTimestamp',
    'importedItems',
    'importedQuantities',
    'invalidItems',
    'notFoundItems',
    'errorRows',
    'skippedRows',
    'missingFieldsRows',
    'missingFields',
    'errorPercentage',
    'availabilityPercentage',
    'fileUrl',
  ];
  loaded = false;
  filters = new ImportStockLocationFilters();
  resultsNumber = 0;

  constructor(private importStockLocationService: ImportStockLocationsService) {}

  @Input() set expanded(exp: boolean) {
    if (exp && !this.loaded) {
      this.filters.code = this.distrName;
      this.filters.pageSize = 5;
      this.importStockLocationService.getFilteredImportStockLocations(this.filters).subscribe((res) => {
        this.resultsNumber = res[0];
        this.dataSource.data = res[1];
        this.loaded = true;
      });
    }
  }

  loadPage() {
    this.filters.pageSize = this.paginator.pageSize;
    this.filters.pageNumber = this.paginator.pageIndex;

    this.importStockLocationService.getFilteredImportStockLocations(this.filters).subscribe((res) => {
      this.resultsNumber = res[0];
      this.dataSource.data = res[1];
    });
  }

  downloadFile(fileUrl: string) {
    if (!isNilty(fileUrl)) {
      window.open(fileUrl, '_blank');
    }
  }
}
