import { Component, Input } from '@angular/core';
import { RouteConfiguration } from '../../../../../../../models/configuration/soup-configuration/route-configuration-model';
import { AwsSftpConfiguration } from '../../../../../../../models/configuration/soup-configuration/route-configurations/aws-sftp-configuration-model';

@Component({
  selector: 'app-aws-sftp-route-configuration',
  templateUrl: './aws-sftp-route-configuration.component.html',
  styleUrls: ['./aws-sftp-route-configuration.component.css'],
})
export class AwsSftpRouteConfigurationComponent {
  awsSftpConfiguration: AwsSftpConfiguration;

  @Input() set _awsSftpRouteConfiguration(c: RouteConfiguration) {
    this.awsSftpConfiguration = c as AwsSftpConfiguration;
  }
}
