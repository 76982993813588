import { JsonObject, JsonProperty } from 'json2typescript';
import { Pagination } from '@models/shared/pagination-model';
import { MatChipsData } from '@models/mat-chips-data-model';
import { isNilty } from '@core/utils.service';

@JsonObject('SupplierFeedReadingStatusFilters')
export class SupplierFeedReadingStatusFilters extends Pagination {
  @JsonProperty('distrName', String, true) distrName: string = undefined;
  @JsonProperty('supplier', String, true) supplier: string = undefined;
  @JsonProperty('importErrorMessage', String, true) importErrorMessage: string = undefined;
  @JsonProperty('skip', Boolean, true) skip: boolean = undefined;

  constructor() {
    super();
    this.pageSize = 20;
  }
}
export const supplierFeedReadingStatusFiltersMap = (
  distrName: string,
  supplier: string,
  importErrorMessage: string,
  skip: string
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(distrName)) {
    map.push(new MatChipsData('Route', distrName));
  }
  if (!isNilty(supplier)) {
    map.push(new MatChipsData('Supplier', supplier));
  }
  if (!isNilty(importErrorMessage)) {
    map.push(new MatChipsData('Error', importErrorMessage));
  }
  if (!isNilty(skip)) {
    map.push(new MatChipsData('Skip', skip));
  }
  return map;
};
