import { Component, OnInit } from '@angular/core';
import { PublishedSources } from '@models/configuration/published-sources-model';
import { PublishedSourcesService } from './published-sources.service';

@Component({
  selector: 'app-published-sources',
  templateUrl: './published-sources.component.html',
  styleUrls: ['./published-sources.component.scss'],
})
export class PublishedSourcesComponent implements OnInit {
  publishedSources: PublishedSources[] = [];
  filteredPublishedSources: PublishedSources[] = [];
  filterSourceCode = '';
  filterRule = '';

  constructor(private service: PublishedSourcesService) {}

  ngOnInit(): void {
    this.service.getAllPublishedSources().subscribe((r) => {
      this.publishedSources = r.sort((i: { sourceCode: number }, j: { sourceCode: number }) => (i.sourceCode > j.sourceCode ? 1 : -1));
      this.filteredPublishedSources = r.sort((i: { sourceCode: number }, j: { sourceCode: number }) =>
        i.sourceCode > j.sourceCode ? 1 : -1
      );
      console.log(this.publishedSources);
    });
  }

  clearFilter() {
    this.filterSourceCode = '';
    this.filterRule = '';
    this.filterSources();
  }

  filterSources() {
    let sorted = this.sortedSources();

    if (this.filterSourceCode) {
      sorted = this.publishedSources.filter((it) => it.sourceCode.toUpperCase().indexOf(this.filterSourceCode.toUpperCase()) !== -1);
    } else {
      sorted = this.publishedSources;
    }
    if (this.filterRule) {
      sorted = sorted.filter((it) =>
        it.publishedIn.some((publishedIn) => publishedIn.ruleCode.toUpperCase().indexOf(this.filterRule.toUpperCase()) !== -1)
      );
    }

    this.filteredPublishedSources = sorted;
  }

  private sortedSources(): PublishedSources[] {
    return [...this.publishedSources].sort((i, j) => (i.sourceCode > j.sourceCode ? 1 : -1));
  }
}
