import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseEntity } from '@models/shared/base-entity-model';
import { RuleStockLocationSource, RuleSupplierSource } from '@models/configuration/rules/publication-rule-source-model';
import { RulePredicate } from '@models/configuration/rules/rule-predicate-model';

@JsonObject('MarkusRule')
export class MarkusRule extends BaseEntity {
  @JsonProperty('supplierSources', [RuleSupplierSource], true) supplierSources: RuleSupplierSource[] = [];
  @JsonProperty('stockLocationSources', [RuleStockLocationSource], true) stockLocationSources: RuleStockLocationSource[] = [];
  @JsonProperty('constraints', [RulePredicate], true) constraints: RulePredicate[] = [];
}
