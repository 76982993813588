import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImportStockLocation } from 'src/app/models/import-stock-location-model';
import { ConverterService } from '../../core/converter.service';
import { EnvironmentService } from '../../core/environment/environment.service';
import { ImportStockLocationFilters } from '../../models/filters/import-stock-location';

@Injectable()
export class ImportStockLocationsService {
  constructor(private converter: ConverterService, private http: HttpClient, private environmentService: EnvironmentService) {}

  getFilteredImportStockLocations(filters: ImportStockLocationFilters): Observable<[number, ImportStockLocation[]]> {
    const distrName = filters.code ?? '';
    const pageIndex = filters.pageNumber ?? 0;
    const pageSize = filters.pageSize ?? 10;

    const queryPath = '?distr-name=' + distrName + '&page-index=' + pageIndex + '&page-size=' + pageSize;

    return this.http.get(this.environmentService.getRestEndpoint('importStockLocations') + queryPath, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        const resultsNumber = +resp.headers.get('Total-Length');
        const body = resp.body.map((r) => this.converter.fromJSONtoObj(r, ImportStockLocation));
        return [resultsNumber, body];
      })
    );
  }
}
