import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConverterService } from '../../../core/converter.service';
import { EnvironmentService } from '../../../core/environment/environment.service';
import { RouteManagementInput } from '../../../models/configuration/soup-configuration/route-management-input-model';
import { SoupConfigurationFilters } from '../../../models/configuration/soup-configuration/soup-configuration-filters-model';
import {
  ROUTE_TYPES,
  SoupAwsSftpConfiguration,
  SoupB2bConfiguration,
  SoupConfiguration,
  SoupFtpConfiguration,
  SoupHttpConfiguration,
  SoupRoute,
} from '../../../models/configuration/soup-configuration/soup-configuration-model';
import { SoupParser } from '../../../models/configuration/soup-configuration/soup-parser-model';
import { cachedArrayGet, GenericArrayCache } from '../../../shared/service-cached-array';

@Injectable()
export class SoupConfigurationService {
  resultsNumber = new Subject<number>();

  private jsonFieldsCache = new GenericArrayCache<string>(this.http, this.converterService);
  private soupDestinationClientsCache = new GenericArrayCache<string>(this.http, this.converterService);
  private soupParsersCache = new GenericArrayCache<SoupParser>(this.http, this.converterService, SoupParser);

  constructor(private converterService: ConverterService, private http: HttpClient, private environmentService: EnvironmentService) {}

  findSoupParsers(): Observable<SoupParser[]> {
    return cachedArrayGet<SoupParser>(this.soupParsersCache, this.environmentService.getRestEndpoint('soupParsers'));
  }

  findValidFields(): Observable<string[]> {
    return cachedArrayGet<string>(this.jsonFieldsCache, this.environmentService.getRestEndpoint('soupValidFields'));
  }

  findClients(): Observable<string[]> {
    return cachedArrayGet<string>(this.soupDestinationClientsCache, this.environmentService.getRestEndpoint('soupDestinationClients'));
  }

  existsByDistrName(distrName: string): Observable<boolean> {
    return this.http
      .get(this.environmentService.getRestEndpoint('soupConfigurationExists') + '/' + distrName)
      .pipe(map((resp: boolean) => resp));
  }

  findSoupConfigurations(filters: SoupConfigurationFilters): Observable<SoupRoute[]> {
    const body = this.converterService.fromObjtoJSON(filters);
    return this.http.post(this.environmentService.getRestEndpoint('soupConfigurations'), body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: SoupRoute[]) => resp.map((it) => this.converterService.fromJSONtoObj(it, SoupRoute)))
    );
  }

  findSoupConfiguration(id: string): Observable<SoupConfiguration> {
    const path = this.environmentService.getRestEndpoint('soupConfigurations') + '/' + id;
    return this.http.get(path).pipe(map((resp: SoupConfiguration) => this.parseSoupConfiguration(resp)));
  }

  saveConfiguration(configuration: SoupConfiguration): Observable<SoupConfiguration> {
    const body = JSON.stringify(configuration);
    return this.http
      .post(this.environmentService.getRestEndpoint('saveSoupConfiguration') + '/' + configuration.routeType, body)
      .pipe(map((resp: SoupConfiguration) => this.parseSoupConfiguration(resp)));
  }

  loadNewEanPredicates(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    const path = this.environmentService.getRestEndpoint('eanPredicates');

    return this.http.post(path, formData);
  }

  startRoute(id: string): Observable<any> {
    const body = this.converterService.fromObjtoJSON(new RouteManagementInput(id));
    return this.http.post(this.environmentService.getRestEndpoint('startRoute'), body);
  }
  stopRoute(id: string): Observable<any> {
    const body = this.converterService.fromObjtoJSON(new RouteManagementInput(id));
    return this.http.post(this.environmentService.getRestEndpoint('stopRoute'), body);
  }
  updateRoute(id: string): Observable<any> {
    const body = this.converterService.fromObjtoJSON(new RouteManagementInput(id));
    return this.http.post(this.environmentService.getRestEndpoint('updateRoute'), body);
  }
  unlockRoute(distrName: string): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('unlockRoute'), distrName);
  }

  getsourceTypes(): Observable<string[]> {
    const path = this.environmentService.getRestEndpoint('sourceTypes') + 'soup-conf';
    return this.http.get<string[]>(path);
  }

  private parseSoupConfiguration(resp: SoupConfiguration): SoupConfiguration {
    switch (resp.routeType) {
      case ROUTE_TYPES.FTP:
        return this.converterService.fromJSONtoObj(resp, SoupFtpConfiguration);
      case ROUTE_TYPES.B2B:
        return this.converterService.fromJSONtoObj(resp, SoupB2bConfiguration);
      case ROUTE_TYPES.HTTP:
        return this.converterService.fromJSONtoObj(resp, SoupHttpConfiguration);
      case ROUTE_TYPES.AWS_SFTP:
        return this.converterService.fromJSONtoObj(resp, SoupAwsSftpConfiguration);
    }
  }
}
