import { Component, Input } from '@angular/core';
import {
  FTP_SOURCE_TYPES,
  FtpRouteConfiguration,
} from '../../../../../../../models/configuration/soup-configuration/route-configurations/ftp-route-configuration-model';
import { RouteConfiguration } from '../../../../../../../models/configuration/soup-configuration/route-configuration-model';

@Component({
  selector: 'app-ftp-route-configuration',
  templateUrl: './ftp-route-configuration.component.html',
  styleUrls: ['./ftp-route-configuration.component.css'],
})
export class FtpRouteConfigurationComponent {
  ftpRouteConfiguration: FtpRouteConfiguration;
  ftpSourceType: string[] = Object.keys(FTP_SOURCE_TYPES);

  @Input() set _ftpRouteConfiguration(c: RouteConfiguration) {
    this.ftpRouteConfiguration = c as FtpRouteConfiguration;
  }
}
