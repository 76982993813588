import { Any, JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('RuleOperator')
export class RuleOperator {
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('type', String, true) type: string = undefined;
  @JsonProperty('description', String, true) description: string = undefined;
}

@JsonObject('RulePredicateField')
export class RulePredicateField {
  @JsonProperty('fieldName', String, true) fieldName: string = undefined;
  @JsonProperty('type', String, true) type: string = undefined;
}

@JsonObject('RulePredicateFieldsOutput')
export class RulePredicateFieldsOutput {
  @JsonProperty('field', RulePredicateField, true) field: RulePredicateField = undefined;
  @JsonProperty('operators', [RuleOperator], true) operators: RuleOperator[] = undefined;
}

@JsonObject('RulePredicate')
export class RulePredicate {
  @JsonProperty('field', RulePredicateField, true) field: RulePredicateField = undefined;
  @JsonProperty('operator', RuleOperator, true) operator: RuleOperator = undefined;
  @JsonProperty('target', Any, true) target: any = undefined;
  @JsonProperty('negatePredicate', Boolean, true) negatePredicate = false;

  isEdit = false;
  availableOperators: RuleOperator[];

  description: string;
  setDescription() {
    let targetString: string;
    if (this.operator.name === 'IN') {
      const targets: string[] = this.target.split(',');
      targetString = '[' + targets.slice(0, 4).join(', ') + ' ...]';
    } else {
      targetString = this.target.substr(0, 100);
    }

    this.description = `${this.field.fieldName} ${this.negatePredicate ? 'not ' : ''}${this.operator.description} ${targetString}`;
  }
}
