import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CreateNewStockLocation')
export class CreateNewStockLocation {
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('supplierCode', String, true) supplierCode: string = undefined;
  @JsonProperty('logisticPartner', String, true) logisticPartner: string = undefined;
  @JsonProperty('restrictOrderTypeTo', String, true) restrictOrderTypeTo: string = undefined;
  @JsonProperty('returnStockLocationCode', String, true) returnStockLocationCode: string = undefined;
  @JsonProperty('enabledForReturns', Boolean, true) enabledForReturns: boolean = undefined;
}
