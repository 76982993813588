import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MetadataPriority, MetadataPriorityEntries } from '@models/configuration/metadata-priority-model';
import { GenericAutocompleteModalComponent } from '@shared/generic-autocomplete-modal/generic-autocomplete-modal.component';
import { GenericConfirmationModalComponent } from '@shared/generic-confirmation-modal/generic-confirmation-modal.component';

import { MetadataPriorityService } from './metadata-priority.service';

@Component({
  selector: 'app-metadata-priority',
  templateUrl: './metadata-priority.component.html',
  styleUrls: ['./metadata-priority.component.css'],
})
export class MetadataPriorityComponent implements OnInit {
  metadataPriority: MetadataPriority[];
  metadataFields: string[];
  metadataSources: string[];

  sort = false;

  constructor(private service: MetadataPriorityService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.service.getMetadataPriority().subscribe((r) => {
      this.metadataPriority = r;
    });
    this.service.getMetadataPriorityField().subscribe((r) => (this.metadataFields = r));
    this.service.getMetadataPrioritySource().subscribe((r) => (this.metadataSources = r));
  }

  addMeta() {
    const allData = this.metadataFields;
    this.metadataPriority.forEach((metadata) => {
      allData.splice(allData.indexOf(metadata.field), 1);
    });
    this.dialog
      .open(GenericAutocompleteModalComponent, {
        data: {
          label: 'Field',
          allData,
        },
      })
      .afterClosed()
      .subscribe((r) => {
        if (r) this.metadataPriority.push(new MetadataPriority(r));
      });
  }

  addEntry(metadata, index: number) {
    this.dialog
      .open(GenericAutocompleteModalComponent, {
        data: {
          label: 'Field',
          allData: this.metadataSources,
        },
      })
      .afterClosed()
      .subscribe((r) => {
        if (r)
          this.metadataPriority[index].metadataPriorityEntries.push(
            new MetadataPriorityEntries(r, this.metadataPriority[index].metadataPriorityEntries.length)
          );
      });
  }

  confirm(m: MetadataPriority, exp: MatExpansionPanel) {
    exp.close();
  }

  removeEntry(metaIndex: number, entryIndex: number) {
    this.dialog
      .open(GenericConfirmationModalComponent, {
        data: `This will remove the custom profits and all its predicates.`,
      })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          this.metadataPriority[metaIndex].metadataPriorityEntries.splice(entryIndex, 1);
        }
      });
  }

  removeField(metaIndex: number) {
    this.dialog
      .open(GenericConfirmationModalComponent, {
        data: `This will remove the custom profits and all its predicates.`,
      })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          this.metadataPriority.splice(metaIndex, 1);
        }
      });
  }

  toggleSorting(event: MatSlideToggleChange) {
    this.sort = event.checked;
  }

  drop(event, index: number) {
    moveItemInArray(this.metadataPriority[index].metadataPriorityEntries, event.previousIndex, event.currentIndex);
    this.setPriority(this.metadataPriority[index].metadataPriorityEntries);
  }

  save() {
    this.service.saveMetadataPriority(this.metadataPriority).subscribe();
  }

  private setPriority(entry: MetadataPriorityEntries[]) {
    entry.forEach((item, index) => {
      item.priority = index;
    });
  }
}
