import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdministrationComponent } from './administration/administration.component';
import { MarkusConfigurationComponent } from './administration/markus-configuration/markus-configuration.component';
import { PublicationsJobsComponent } from './administration/publications-jobs/publications-jobs.component';
import { SoupFeedsComponent } from './administration/soup-feeds/soup-feeds.component';
import { ConfigurationComponent } from './configuration.component';
import { DisabledCatalogsComponent } from './disabled-catalogs/disabled-catalogs.component';
import { ExchangeRatesComponent } from './exchange-rates/exchange-rates.component';
import { FeesConfigurationComponent } from './fees-configuration/fees-configuration.component';
import { PowerSellerRuleEditComponent } from './markus-rules/power-seller-rules/power-seller-rule-edit/power-seller-rule-edit.component';
import { PowerSellerRulesListComponent } from './markus-rules/power-seller-rules/power-seller-rules-list/power-seller-rules-list.component';
import { PowerSellerRulesComponent } from './markus-rules/power-seller-rules/power-seller-rules.component';
import { PublicationRuleEditComponent } from './markus-rules/publication-rules/publication-rule-edit/publication-rule-edit.component';
import { PublicationRulesListComponent } from './markus-rules/publication-rules/publication-rules-list/publication-rules-list.component';
import { PublicationRulesComponent } from './markus-rules/publication-rules/publication-rules.component';
import { MetadataPriorityComponent } from './metadata-priority/metadata-priority.component';
import { PermissionNewComponent } from './permissions/permission-new/permission-new.component';
import { PermissionsListComponent } from './permissions/permissions-list/permissions-list.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { BlacklistsConfigurationComponent } from './policies/blacklists/blacklists-configuration.component';
import { RestrictionsConfigurationComponent } from './policies/restrictions/restrictions-configuration.component';
import { PublishedSourcesComponent } from './published-sources/published-sources.component';
import { RoleEditComponent } from './roles/role-edit/role-edit.component';
import { RolesListComponent } from './roles/roles-list/roles-list.component';
import { RolesComponent } from './roles/roles.component';
import { SalesOrderPollConfigurationEditComponent } from './sales-order-poll-configuration/sales-order-poll-configuration-edit/sales-order-poll-configuration-edit.component';
import { SalesOrderPollConfigurationListComponent } from './sales-order-poll-configuration/sales-order-poll-configuration-list/sales-order-poll-configuration-list.component';
import { SalesOrderPollConfigurationComponent } from './sales-order-poll-configuration/sales-order-poll-configuration.component';
import { SellingCompetitorsComponent } from './selling-competitors/selling-competitors.component';
import { FtpEndpointsComponent } from './soup-configuration/ftp-endpoints/ftp-endpoints.component';
import { SoupConfigurationEditComponent } from './soup-configuration/soup-configuration-edit/soup-configuration-edit.component';
import { SoupConfigurationComponent } from './soup-configuration/soup-configuration.component';
import { SoupConfigurationsListComponent } from './soup-configuration/soup-configurations-list/soup-configurations-list.component';
import { StoreConfigurationComponent } from './store-configuration/store-configuration.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { UsersComponent } from './users/users.component';
import { CatalogImportExportComponent } from './catalog-import-export/catalog-import-export.component';
import { MetadataExportComponent } from './catalog-import-export/metadata-export/metadata-export.component';
import { GenericImportComponent } from './catalog-import-export/generic-import/generic-import.component';
import { CustomMetadataComponent } from './catalog-import-export/custom-metadata/custom-metadata.component';

const configurationRoutes: Routes = [
  {
    path: '',
    component: ConfigurationComponent,
    children: [
      { path: 'store-configuration', component: StoreConfigurationComponent },
      {
        path: 'sales-order-poll-configuration',
        component: SalesOrderPollConfigurationComponent,
        children: [
          { path: '', component: SalesOrderPollConfigurationListComponent, pathMatch: 'full' },
          { path: 'edit/:id', component: SalesOrderPollConfigurationEditComponent },
          { path: 'new', component: SalesOrderPollConfigurationEditComponent },
        ],
      },
      { path: 'fees', component: FeesConfigurationComponent },
      {
        path: 'publication-rules',
        component: PublicationRulesComponent,
        children: [
          { path: '', component: PublicationRulesListComponent, pathMatch: 'full' },
          { path: 'edit/:id', component: PublicationRuleEditComponent },
          { path: 'new', component: PublicationRuleEditComponent },
        ],
      },
      {
        path: 'power-seller-rules',
        component: PowerSellerRulesComponent,
        children: [
          { path: '', component: PowerSellerRulesListComponent, pathMatch: 'full' },
          { path: 'edit/:id', component: PowerSellerRuleEditComponent },
        ],
      },
      { path: 'blacklists', component: BlacklistsConfigurationComponent },
      { path: 'restrictions', component: RestrictionsConfigurationComponent },
      { path: 'selling-competitors', component: SellingCompetitorsComponent },
      { path: 'published-sources', component: PublishedSourcesComponent },
      { path: 'exchange-rates', component: ExchangeRatesComponent },
      { path: 'disabled-catalogs', component: DisabledCatalogsComponent },
      {
        path: 'soup-configuration',
        component: SoupConfigurationComponent,
        children: [
          { path: '', component: SoupConfigurationsListComponent, pathMatch: 'full' },
          { path: 'ftp-endpoints', component: FtpEndpointsComponent },
          { path: 'edit/:id', component: SoupConfigurationEditComponent },
          { path: 'new', component: SoupConfigurationEditComponent },
        ],
      },
      {
        path: 'users',
        component: UsersComponent,
        children: [
          { path: '', component: UsersListComponent, pathMatch: 'full' },
          { path: 'edit/:id', component: UserEditComponent },
          { path: 'new', component: UserEditComponent },
        ],
      },
      {
        path: 'roles',
        component: RolesComponent,
        children: [
          { path: '', component: RolesListComponent, pathMatch: 'full' },
          { path: 'edit/:id', component: RoleEditComponent },
          { path: 'new', component: RoleEditComponent },
        ],
      },
      {
        path: 'administration',
        component: AdministrationComponent,
        children: [
          { path: 'markus-configuration', component: MarkusConfigurationComponent, pathMatch: 'full' },
          { path: 'publications-jobs', component: PublicationsJobsComponent },
          { path: 'soup-feeds', component: SoupFeedsComponent, pathMatch: 'full' },
          { path: '', redirectTo: 'markus-configuration' },
        ],
      },
      {
        path: 'permissions',
        component: PermissionsComponent,
        children: [
          { path: '', component: PermissionsListComponent, pathMatch: 'full' },
          { path: 'new', component: PermissionNewComponent },
        ],
      },
      { path: 'metadata-priority', component: MetadataPriorityComponent },
      {
        path: 'import-export',
        component: CatalogImportExportComponent,
        children: [
          { path: 'export', component: MetadataExportComponent },
          { path: 'import', component: GenericImportComponent },
          { path: 'custom-metadata', component: CustomMetadataComponent },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(configurationRoutes)],
  exports: [RouterModule],
})
export class ConfigurationRoutingModule {}
