import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConverterService } from '../../../core/converter.service';
import { EnvironmentService } from '../../../core/environment/environment.service';
import { Role } from '../../../models/role-model';
import { User } from '../../../models/user.model';

@Injectable()
export class UsersService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getUsers(): Observable<User[]> {
    return this.http
      .get(this.environmentService.getPonchRestEndpoint('users'))
      .pipe(map((usersResp: User[]) => usersResp.map((user) => this.converter.fromJSONtoObj(user, User))));
  }

  getUser(id: string): Observable<User> {
    return this.http
      .get(this.environmentService.getPonchRestEndpoint('users') + '/' + id)
      .pipe(map((user: User) => this.converter.fromJSONtoObj(user, User)));
  }

  saveUser(user: User): Observable<boolean> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(user);
    return this.http.post(this.environmentService.getPonchRestEndpoint('users'), body, { headers }).pipe(map(() => true));
  }

  deleteUser(id: string): Observable<boolean> {
    return this.http.delete(this.environmentService.getPonchRestEndpoint('users') + '/' + id).pipe(map(() => true));
  }

  getRoles(): Observable<Role[]> {
    return this.http
      .get(this.environmentService.getPonchRestEndpoint('roles'))
      .pipe(map((rolesResp: Role[]) => rolesResp.map((role) => this.converter.fromJSONtoObj(role, Role))));
  }
}
