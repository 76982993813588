import { Component, Input } from '@angular/core';
import { RulePredicate } from '@models/configuration/rules/rule-predicate-model';

@Component({
  selector: 'app-rule-constraints',
  templateUrl: './rule-constraints.component.html',
  styleUrls: ['./rule-constraints.component.css'],
})
export class RuleConstraintsComponent {
  @Input() ruleConstraints: RulePredicate[];
}
