import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RuleTypes, ValidationRule } from '@models/export-metadata/metadata-export-model';

@Component({
  selector: 'app-edit-rule',
  template: `
    <div class="dialog-container position-relative">
      <div mat-dialog-content>
        <button
          class="bg-body btn-custom mat-tooltip-trigger ms-2 position-absolute smaller-icon-button text-black"
          style="right: -10px; transform: translateY(-2px); top: -10px;"
          matTooltip="Close"
          (click)="close()"
        >
          <i class="fa fa-close"></i>
        </button>
        <div class="row">
          <div class="col-12 center" *ngFor="let vr of data.validationRules; let i = index">
            <mat-form-field>
              <mat-label>Rule type</mat-label>
              <mat-select id="ruleType" [(ngModel)]="vr.ruleType">
                <mat-option *ngFor="let r of data.rules" [value]="r.name">{{ r.name.replaceAll('_', ' ') }} </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="position-input" style="width: 170px;">
              <mat-label>Rule value</mat-label>
              <input id="ruleValue" [type]="calculateType(vr.ruleType)" matInput [(ngModel)]="vr.ruleValue.value" />
            </mat-form-field>

            <button
              class="btn-custom smaller-icon-button ms-2"
              style="transform: translateY(-2px)"
              matTooltip="Delete rule"
              (click)="deleteRule(i)"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
        <div class="mt-1 text-center">
          <button class="btn-custom smaller-icon-button ms-2" style="transform: translateY(-2px)" matTooltip="Add rule" (click)="addRule()">
            <i class="fa fa-plus"></i>
          </button>
        </div>
        <div class="mt-1 text-end">
          <button class="btn-custom w-auto" [disabled]="calculateClosable()" (click)="confirm()">Confirm</button>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .dialog-container {
        font-family: 'Titillium Web', sans-serif;
        width: 400px !important;
      }

      .position-input {
        margin-left: 1rem;
      }

      .description {
        font-size: 13px;
      }
    `,
  ],
})
export class EditRuleModalComponent implements OnInit {
  originalValidationRules: ValidationRule[] = [];

  constructor(
    private dialogRef: MatDialogRef<EditRuleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { validationRules: ValidationRule[]; rules: RuleTypes[] }
  ) {}

  ngOnInit(): void {
    this.originalValidationRules = [...this.data.validationRules];
  }

  confirm() {
    this.deleteEmptyRules();
    this.dialogRef.close(this.data.validationRules);
  }

  close() {
    this.data.validationRules = this.originalValidationRules;
    this.confirm();
  }

  addRule() {
    this.data.validationRules.push(new ValidationRule('', { value: '' }));
  }

  deleteRule(i: number) {
    this.data.validationRules.splice(i, 1);
  }

  calculateType(ruleType: string) {
    return this.data.rules.find((r) => r.name === ruleType)?.valueType;
  }

  calculateClosable() {
    return false;
  }

  private deleteEmptyRules() {
    this.data.validationRules = this.data.validationRules.filter((r) => r.ruleType !== '' && r.ruleValue.value !== '');
  }
}
