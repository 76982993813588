import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GenericImportService } from './container/configuration/catalog-import-export/generic-import/generic-import.service';
import { AppPreloadingStrategy } from './app-loading-strategy';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContainerModule } from './container/container.module';
import { ConverterService } from './core/converter.service';
import { EnvironmentServiceProvider } from './core/environment/environment.service.provider';
import { MaterialModule } from './material.module';
import { AuthenticationService } from './security/authentication.service';
import { AuthorizationService } from './security/authorization.service';
import { CommonsService } from './shared/commons.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    ContainerModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [
    AuthenticationService,
    AppPreloadingStrategy,
    AuthorizationService,
    ConverterService,
    EnvironmentServiceProvider,
    HttpClientModule,
    CommonsService,
    GenericImportService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
