import { JsonObject, JsonProperty } from 'json2typescript';
import { RulePredicate } from '../rule-predicate-model';

@JsonObject('CustomShipmentClass')
export class CustomShipmentClass {
  @JsonProperty('predicates', [RulePredicate], true) predicates: RulePredicate[] = [];
  @JsonProperty('shipmentClass', String, true) shipmentClass: string = undefined;
  @JsonProperty('shipmentCost', Number, true) shipmentCost: number = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;

  shipmentClassDescription: string;

  constructor() {
    this.shipmentClass = null;
    this.shipmentCost = null;
  }

  setDescription() {
    this.predicates.forEach((it) => it.setDescription());
    this.shipmentClassDescription = `Shipment Class: ${this.shipmentClass} - ${this.predicates.map((it) => it.description).join(' AND ')}`;
  }
}
