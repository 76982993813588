import { JsonObject, JsonProperty } from 'json2typescript';
import { RouteConfiguration } from '../route-configuration-model';

/* eslint-disable */
export enum HTTP_SOURCE_TYPES {
  HTTP = 'HTTP',
  HTTPS = 'HTTPS',
}
/* eslint-enable */

@JsonObject('HttpRouteConfiguration')
export class HttpRouteConfiguration extends RouteConfiguration {
  @JsonProperty('sourceType', String, true) sourceType: string = undefined;

  @JsonProperty('host', String, true) host: string = undefined;
  @JsonProperty('timer', Number, true) timer: number = undefined;
}
