import { Component, OnInit } from '@angular/core';
import { SellingCompetitor } from '../../../models/configuration/selling-competitor-model';
import { SellingCompetitorsService } from './selling-competitors.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericConfirmationModalComponent } from '../../../shared/generic-confirmation-modal/generic-confirmation-modal.component';

@Component({
  selector: 'app-selling-competitors',
  templateUrl: './selling-competitors.component.html',
  styleUrls: ['./selling-competitors.component.scss'],
})
export class SellingCompetitorsComponent implements OnInit {
  searchText: string = undefined;

  competitors: SellingCompetitor[] = [];
  filteredCompetitors: SellingCompetitor[] = [];
  editingCompetitor: SellingCompetitor = undefined;

  constructor(private sellingCompetitorsService: SellingCompetitorsService, private matDialog: MatDialog) {}

  ngOnInit(): void {
    this.reload();
  }

  reload() {
    this.searchText = undefined;
    this.sellingCompetitorsService.findAll().subscribe((r) => {
      this.competitors = r;
      this.search(this.searchText);
    });
  }

  search(searchText: string) {
    if (!this.searchText) {
      this.filteredCompetitors = [...this.competitors];
    } else {
      this.filteredCompetitors = this.competitors.filter(
        (it) =>
          it.sellerId.toUpperCase().indexOf(searchText.toUpperCase()) !== -1 ||
          it.sellerName.toUpperCase().indexOf(searchText.toUpperCase()) !== -1
      );
    }
  }

  new() {
    this.editingCompetitor = new SellingCompetitor();
  }

  save() {
    this.sellingCompetitorsService.save(this.editingCompetitor).subscribe(() => {
      this.editingCompetitor = undefined;
      this.reload();
    });
  }
  cancel() {
    this.editingCompetitor = undefined;
  }

  edit(c: SellingCompetitor) {
    this.editingCompetitor = new SellingCompetitor();
    this.editingCompetitor.sellerId = c.sellerId;
    this.editingCompetitor.sellerName = c.sellerName;
    this.editingCompetitor.isAmazon = c.isAmazon;
    this.editingCompetitor.id = c.id;
  }

  delete(c: SellingCompetitor) {
    this.matDialog
      .open(GenericConfirmationModalComponent, { data: `This will delete competitor ${c.sellerName}.` })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          this.editingCompetitor = undefined;
          this.sellingCompetitorsService.delete(c).subscribe(() => this.reload());
        }
      });
  }
}
