import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('FtpEndpointConfiguration')
export class FtpEndpointConfiguration {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('active', Boolean, true) active = false;

  @JsonProperty('partnerName', String, true) partnerName: string = undefined;
  @JsonProperty('protocol', String, true) protocol: string = undefined;
  @JsonProperty('host', String, true) host: string = undefined;
  @JsonProperty('port', String, true) port: string = undefined;
  @JsonProperty('user', String, true) user: string = undefined;
  @JsonProperty('password', String, true) password: string = undefined;
  @JsonProperty('path', String, true) path = '/';
}

@JsonObject('FtpDestination')
export class FtpDestination {
  @JsonProperty('endpoint', FtpEndpointConfiguration, true) endpoint: FtpEndpointConfiguration = undefined;
  @JsonProperty('fileName', String, true) fileName: string = undefined;
  @JsonProperty('markup', Number, true) markup: number = undefined;

  constructor(supplierCode: string, endpoint: FtpEndpointConfiguration) {
    this.fileName = supplierCode;
    this.endpoint = endpoint;
  }
}
