import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PublishedStore } from '@models/publications/published-store-model';
import { MarketplaceDto } from '@models/configuration/marketplace/marketplace-model';
import { ProductWithTitle } from '@models/product/product-with-title-model';
import {
  BlacklistSearchType,
  SearchBlacklistDto,
  SearchTypeDefinition,
  searchTypeDefinitions,
} from '@models/configuration/policies/blacklist/blacklist-utils';

@Component({
  selector: 'app-blacklist-searcher',
  templateUrl: './blacklist-searcher.component.html',
  styleUrls: ['./blacklist-searcher.component.scss'],
})
export class BlacklistSearcherComponent implements OnInit {
  @Output()
  doSearch = new EventEmitter<SearchBlacklistDto>();

  searchTypes = searchTypeDefinitions;
  searchType: SearchTypeDefinition;

  selectedEntity: PublishedStore | ProductWithTitle | MarketplaceDto | string;
  searchedSearchType: BlacklistSearchType;

  BlacklistSearchType = BlacklistSearchType;

  panelExpanded = true;

  ngOnInit(): void {
    // TODO put back to 0 once product search is implemented
    this.searchType = this.searchTypes[1];
  }

  selectedSearch(entity: PublishedStore | ProductWithTitle | MarketplaceDto | string) {
    this.selectedEntity = entity;
    this.searchedSearchType = this.searchType.code;
    this.doSearch.emit(new SearchBlacklistDto(this.searchedSearchType, entity));
    this.panelExpanded = closed;
  }
}
