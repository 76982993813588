import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from './date-converter';

@JsonObject('ExchangeRates')
export class ExchangeRates {
  @JsonProperty('currency', String, false) currency: string = undefined;
  @JsonProperty('rate', Number, false) rate: number = undefined;
}

@JsonObject('ExchangeRatesFilters')
export class ExchangeRatesFilters {
  @JsonProperty('updatedAt', DateConverter, false) updatedAt: Date = undefined;
}
