import { PermissionsService } from './../permissions.service';
import { Component, OnInit, ViewChild } from '@angular/core';

import { Permission } from '@models/security/permission-model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-permissions-list',
  templateUrl: './permissions-list.component.html',
  styleUrls: ['./permissions-list.component.css'],
})
export class PermissionsListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  permissions: Permission[] = [];
  dataSource: MatTableDataSource<Permission>;
  displayedColumns = ['application', 'name', 'delete'];
  filter: string;

  constructor(private permissionsService: PermissionsService, private snackbar: MatSnackBar, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.loadPermissions();
  }

  loadPermissions() {
    this.permissionsService.getPermissions().subscribe((permissions: Permission[]) => {
      this.dataSource = new MatTableDataSource(permissions);
      this.dataSource.sort = this.sort;
      this.permissions = permissions;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  onDelete(id: string) {
    this.permissionsService.deletePermission(id).subscribe(() => {
      this.snackbar.open('Permission deleted.', 'CLOSE')._dismissAfter(3000);
      this.filter = null;
      this.loadPermissions();
    });
  }
}
