import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { RulePredicate } from './rule-predicate-model';

@JsonObject('PublicationRuleSource')
class PublicationRuleSource {
  @JsonProperty('type', String, true) type: string = undefined;
  @JsonProperty('constraintOverrideMode', String, true) constraintOverrideMode: 'ADD' | 'OVERRIDE' = 'ADD';
  @JsonProperty('constraints', [RulePredicate], true) constraints: RulePredicate[] = [];
  @JsonProperty('bufferQuantity', Number, true) bufferQuantity = 0;
  @JsonProperty('ruleSourceSkuType', String, true) ruleSourceSkuType: RuleSourceSkuType = RuleSourceSkuType.PRODUCT_SKU;
  @JsonProperty('fulfilmentLatency', Number, true) fulfilmentLatency: number = undefined;
  @JsonProperty('orderType', String, true) orderType: RuleOrderType = undefined;
  @JsonProperty('pricingParameters', Any, true) pricingParameters: any = undefined;

  valid = true;
  error: string;
}

@JsonObject('RuleSupplierSource')
export class RuleSupplierSource extends PublicationRuleSource {
  @JsonProperty('supplierId', Number, true) supplierId: number = undefined;
  @JsonProperty('supplierCode', String, true) supplierCode: string = undefined;
  @JsonProperty('pricingParameters', Any, true) pricingParameters: any = undefined;

  constructor() {
    super();
    this.type = RuleSourceType.SUPPLIER;
  }
}

@JsonObject('RuleStockLocationSource')
export class RuleStockLocationSource extends PublicationRuleSource {
  @JsonProperty('stockLocationId', Number, true) stockLocationId: number = undefined;
  @JsonProperty('stockLocationCode', String, true) stockLocationCode: string = undefined;
  @JsonProperty('actualSupplierId', Number, true) actualSupplierId: number = undefined;
  @JsonProperty('actualSupplierCode', String, true) actualSupplierCode: string = undefined;
  @JsonProperty('pricingParameters', Any, true) pricingParameters: any = undefined;

  constructor() {
    super();
    this.type = RuleSourceType.STOCK_LOCATION;
  }
}

export class SelectableSupplierSource extends RuleSupplierSource {
  selected = false;
}
export class SelectableStockLocationSource extends RuleStockLocationSource {
  selected = false;
}

/* eslint-disable */
export enum RuleSourceType {
  SUPPLIER = 'SUPPLIER',
  STOCK_LOCATION = 'STOCK_LOCATION',
}
export enum RuleSourceSkuType {
  PRODUCT_SKU = 'PRODUCT_SKU',
  CATALOG_ITEM_SKU = 'CATALOG_ITEM_SKU',
  STOCK_ITEM_SKU = 'STOCK_ITEM_SKU',
}

export enum RuleOrderType {
  BULK = 'BULK',
  DROP = 'DROP',
}
/* eslint-enable */
