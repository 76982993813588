import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SoupConfigurationFilters } from '../../../../models/configuration/soup-configuration/soup-configuration-filters-model';
import { ROUTE_TYPES, SoupConfiguration, SoupRoute } from '../../../../models/configuration/soup-configuration/soup-configuration-model';
import { GenericConfirmationModalComponent } from '../../../../shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { SoupConfigurationService } from '../soup-configuration.service';

@Component({
  selector: 'app-soup-configurations-list',
  templateUrl: './soup-configurations-list.component.html',
  styleUrls: ['./soup-configurations-list.component.css'],
})
export class SoupConfigurationsListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @ViewChild('uploadCsv', { static: true }) uploadCsv: ElementRef;

  filters = new SoupConfigurationFilters();

  resultsNumber: Observable<number>;
  dataSource = new MatTableDataSource<SoupRoute>([]);
  displayedColumns = [
    'active',
    'locked',
    'distrName',
    'supplierCode',
    'stockLocationCode',
    'supplierName',
    'routeType',
    'chunkFiles',
    'addProduct',
    'fullFeed',
    'custom',
    'external',
    'edit',
  ];

  routeTypes: string[] = Object.keys(ROUTE_TYPES);

  constructor(private soupConfigurationService: SoupConfigurationService, private dialog: MatDialog, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['distrName']) {
        this.filters.supplier = params['distrName'];
      }
    });

    this.resultsNumber = this.soupConfigurationService.resultsNumber;
    this.loadResults();
  }

  loadResults() {
    this.soupConfigurationService.findSoupConfigurations(this.filters).subscribe((resp: SoupRoute[]) => (this.dataSource.data = resp));
  }

  loadPage() {
    this.filters.pageSize = this.paginator.pageSize;
    this.filters.pageNumber = this.paginator.pageIndex;
    this.loadResults();
  }

  search() {
    this.filters.pageNumber = 0;
    this.loadResults();
  }

  reset() {
    this.filters = new SoupConfigurationFilters();
    this.loadResults();
  }

  start(conf: SoupConfiguration) {
    this.dialog
      .open(GenericConfirmationModalComponent, { data: 'Starting ' + conf.distrName + ' Route.' })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.soupConfigurationService.startRoute(conf.id).subscribe(() => {
            this.loadResults();
          });
        }
      });
  }

  stop(conf: SoupConfiguration) {
    this.dialog
      .open(GenericConfirmationModalComponent, { data: 'Stopping ' + conf.distrName + ' Route.' })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.soupConfigurationService.stopRoute(conf.id).subscribe(() => {
            this.loadResults();
          });
        }
      });
  }

  unlock(route: SoupRoute) {
    if (route.locked) {
      this.soupConfigurationService.unlockRoute(route.configuration.distrName).subscribe(() => {
        this.loadResults();
      });
    }
  }

  loadNewEanPredicates() {
    this.dialog
      .open(GenericConfirmationModalComponent, {
        data: `
      The file must be a ; separated csv and must contain the following columns:
       OperationTypes, PredicateTypes, FirstOperand, SecondOperand, Value.
      `,
      })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          this.uploadCsv.nativeElement.click();
        }
      });
  }

  uploadEanPredicateCsv(event) {
    const currentFileUpload = event.target.files[0];

    this.soupConfigurationService.loadNewEanPredicates(currentFileUpload).subscribe();
  }
}
