import { JsonObject, JsonProperty } from 'json2typescript';
import { isNilty } from 'src/app/core/utils.service';
import { MatChipsData } from '../mat-chips-data-model';
import { Pagination } from '../shared/pagination-model';
import { Status } from '../status-model';

@JsonObject('CatalogItemFilters')
export class CatalogItemFilters extends Pagination {
  @JsonProperty('productBarcodeValue', String, true) productBarcodeValue: string = undefined;
  @JsonProperty('productBarcodeType', String, true) productBarcodeType: string = undefined;
  @JsonProperty('supplier', String, true) supplier: string = undefined;
  @JsonProperty('supplierId', Number, true) supplierId: number = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('internalSku', String, true) internalSku: string = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('quantityGreaterThan', Number, true) quantityGreaterThan: number = undefined;
  @JsonProperty('isPod', Boolean, true) isPod: boolean = undefined;
}

export const catalogItemFiltersMap = (
  productBarcodeValue: string,
  productBarcodeType: Status,
  supplier: string,
  sku: string,
  internalSku: string,
  status: string,
  quantityGreaterThan: number,
  isPod: string
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(productBarcodeValue)) {
    map.push(new MatChipsData('Barcode Value', productBarcodeValue));
  }
  if (!isNilty(productBarcodeType)) {
    map.push(new MatChipsData('Barcode Type', productBarcodeType.description));
  }
  if (!isNilty(supplier)) {
    map.push(new MatChipsData('Supplier', supplier));
  }
  if (!isNilty(sku)) {
    map.push(new MatChipsData('Sku', sku));
  }
  if (!isNilty(internalSku)) {
    map.push(new MatChipsData('Internal Sku', internalSku));
  }
  if (!isNilty(status)) {
    map.push(new MatChipsData('Status', status));
  }
  if (!isNilty(quantityGreaterThan)) {
    map.push(new MatChipsData('Quantity Greater Than', quantityGreaterThan));
  }
  if (!isNilty(isPod)) {
    map.push(new MatChipsData('Is pod', isPod));
  }

  return map;
};
