import { Component, OnInit, ViewChild } from '@angular/core';
import { FtpEndpointsService } from './ftp-endpoints.service';
import { FtpEndpointConfiguration } from '@models/configuration/soup-configuration/ftp-destination-model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { FtpEndpointsModalComponent } from './ftp-endpoints-modal/ftp-endpoints-modal.component';
import { cloneClass, isNilty } from '@core/utils.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-ftp-endpoints',
  templateUrl: './ftp-endpoints.component.html',
  styleUrls: ['./ftp-endpoints.component.css'],
})
export class FtpEndpointsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource = new MatTableDataSource<FtpEndpointConfiguration>();

  displayedColumns = ['partnerName', 'active', 'protocol', 'host', 'user', 'edit'];

  constructor(private ftpEndpointsService: FtpEndpointsService, private dialog: MatDialog, private location: Location) {}

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.getFtpEndpoints();
  }

  getFtpEndpoints() {
    this.ftpEndpointsService.getFtpEndpoints().subscribe((resp: FtpEndpointConfiguration[]) => {
      this.dataSource.data = resp;
    });
  }

  edit(endpoint: FtpEndpointConfiguration) {
    this.openEditDialog(cloneClass(endpoint, FtpEndpointConfiguration));
  }

  new() {
    this.openEditDialog(new FtpEndpointConfiguration());
  }

  save(ftp: FtpEndpointConfiguration) {
    this.ftpEndpointsService.save(ftp).subscribe(() => {
      this.getFtpEndpoints();
    });
  }

  back() {
    this.location.back();
  }

  private openEditDialog(ftp: FtpEndpointConfiguration) {
    this.dialog
      .open(FtpEndpointsModalComponent, {
        data: { endpoint: ftp },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resp: FtpEndpointConfiguration) => {
        if (!isNilty(resp)) {
          this.save(resp);
        }
      });
  }
}
