import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { PublishedStore } from '@models/publications/published-store-model';
import { map } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { isNilty } from '@core/utils.service';
import { MatPaginator } from '@angular/material/paginator';
import { StoreService } from '../../../../store-configuration/store.service';

@Component({
  selector: 'app-search-store',
  templateUrl: './search-store.component.html',
  styleUrls: ['./search-store.component.scss'],
})
export class SearchStoreComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  @Output()
  selectedStore = new EventEmitter<PublishedStore>();

  currentSelectedStore: PublishedStore;

  searchString: string;

  allStores: PublishedStore[];
  storesDataSource = new MatTableDataSource<PublishedStore>();

  columns = ['name', 'code', 'marketplace', 'account', 'published'];

  constructor(private storeService: StoreService) {}

  ngOnInit(): void {
    this.storesDataSource.paginator = this.paginator;

    this.storeService.getPublishedStores(false).subscribe((r) => {
      this.allStores = r;
      this.search(this.searchString);
    });

    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(map((event: any) => event.target.value))
      .subscribe((value: string) => {
        this.searchString = value;
        this.search(value);
      });
  }

  search(value: string) {
    let filteredStores;
    if (isNilty(value)) {
      filteredStores = this.allStores;
    } else {
      filteredStores = this.allStores.filter(
        (s) =>
          s.store.code.toUpperCase().indexOf(value.toUpperCase()) !== -1 || s.store.name.toUpperCase().indexOf(value.toUpperCase()) !== -1
      );
    }
    this.storesDataSource.data = filteredStores;
  }

  selectStore(s) {
    this.selectedStore.next(s);
    this.currentSelectedStore = s;
  }
}
