import { Component, Input, OnInit } from '@angular/core';
import { isNilty } from '../../../../../../core/utils.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-map-edit',
  templateUrl: './map-edit.component.html',
  styleUrls: ['./map-edit.component.css'],
})
export class MapEditComponent implements OnInit {
  @Input() name: string;
  @Input() hasFixedKeys = true;

  map: any;

  existingKeys: string[];
  allowedFields: string[];

  availableKeys: string[];
  filteredAvailableKeys: Observable<string[]>;
  availableKeysControl = new FormControl<string | string>('');

  newKey: string;
  newValue: string;

  @Input() set _allowedFields(af: string[]) {
    this.allowedFields = af;
    if (!isNilty(this.existingKeys)) {
      this.setAvailableKeys();
    }
  }

  @Input() set _map(m: any) {
    this.map = m;
    this.existingKeys = Object.keys(this.map);
    if (!isNilty(this.allowedFields)) {
      this.setAvailableKeys();
    }
  }

  ngOnInit(): void {
    this.existingKeys = Object.keys(this.map);
    this.setAvailableKeys();

    this.filteredAvailableKeys = this.availableKeysControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : '';
        if (isNilty(this.availableKeys)) {
          return;
        }
        return name ? this._filter(name as string) : this.availableKeys.slice();
      })
    );
  }

  displayFn(field: string): string {
    return field || '';
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.availableKeys.filter((option) => option.toLowerCase().includes(filterValue));
  }

  delete(key: string) {
    this.existingKeys.splice(
      this.existingKeys.findIndex((it) => it === key),
      1
    );
    delete this.map[key];
    this.setAvailableKeys();
  }

  add() {
    this.map[this.newKey] = this.newValue;
    this.existingKeys.push(this.newKey);
    this.newKey = undefined;
    this.newValue = undefined;
    this.setAvailableKeys();
  }

  edit(key: string) {
    this.existingKeys.splice(
      this.existingKeys.findIndex((it) => it === key),
      1
    );
    this.newKey = key;
    this.newValue = this.map[key];
    this.setAvailableKeys();
  }

  setAvailableKeys() {
    this.availableKeys = this.allowedFields.filter((it) => this.existingKeys.findIndex((k) => k === it) === -1);
  }
}
