import { Injectable } from '@angular/core';
import { EnvironmentService } from '../core/environment/environment.service';

@Injectable()
export class AuthenticationService {
  constructor(private environmentService: EnvironmentService) {}

  login(): void {
    sessionStorage.removeItem('token');
    window.location.href = this.environmentService.ponchBaseApiUrl + 'login?callback=' + btoa(window.location.href) + '&application=ZION';
  }

  logout(): void {
    sessionStorage.removeItem('token');
    window.location.href = this.environmentService.ponchBaseApiUrl + 'logout?callback=' + btoa(window.location.href) + '&application=ZION';
  }

  getAuthorizationHeader(): string {
    const token = sessionStorage.getItem('token');
    if (!token) {
      this.login();
    }
    return token;
  }
}
