import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BlacklistTargetTypes, getAllowedBlacklistBarcodeTypes } from '@models/configuration/policies/blacklist/blacklists-enums-model';
import { BlacklistReason } from '@models/configuration/policies/blacklist/blacklist-reason-model';
import { isNilty } from '@core/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { HelpModalComponent } from '@shared/help-modal/help-modal.component';
import {
  getAllowedRestrictionBarcodeTypes,
  RestrictionTargetType,
} from '@models/configuration/policies/restrictions/restriction-target-type';

@Component({
  selector: 'app-edit-product-policy',
  templateUrl: './edit-product-policy.component.html',
  styleUrls: ['./edit-product-policy.component.scss'],
})
export class EditProductPolicyComponent implements OnInit {
  @Input() targetType: BlacklistTargetTypes | RestrictionTargetType;
  @Input() blacklistOrRestriction: 'restriction' | 'blacklist';

  @Input() barcodeType: string;
  @Output() barcodeTypeChange = new EventEmitter<string>();
  @Input() barcodeValue: string;
  @Output() barcodeValueChange = new EventEmitter<string>();
  @Input() fileOrSingleBarcode: 'FILE' | 'SINGLE';
  @Output() fileOrSingleBarcodeChange = new EventEmitter<'FILE' | 'SINGLE'>();
  @Input() blacklistFile: File;
  @Output() blacklistFileChange = new EventEmitter<File>();

  @Input() reasons: BlacklistReason[] = [];

  @Output() completed = new EventEmitter<boolean>();

  @ViewChild('blacklistFileUpload', { static: false }) fileElementRef: ElementRef;

  barcodeTypes: string[] = [];

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.fileOrSingleBarcode = 'SINGLE';
    this.fileOrSingleBarcodeChange.emit(this.fileOrSingleBarcode);

    if (this.blacklistOrRestriction === 'blacklist') {
      this.barcodeTypes = getAllowedBlacklistBarcodeTypes(this.targetType);
    } else {
      this.barcodeTypes = getAllowedRestrictionBarcodeTypes();
    }
  }

  checkCompleted() {
    setTimeout(() => {
      this.barcodeTypeChange.emit(this.barcodeType);
      this.barcodeValueChange.emit(this.barcodeValue);
      this.fileOrSingleBarcodeChange.emit(this.fileOrSingleBarcode);
      this.blacklistFileChange.emit(this.blacklistFile);

      if (this.fileOrSingleBarcode === 'FILE') {
        this.completed.emit(this.blacklistFile && !isNilty(this.barcodeType));
      } else {
        this.completed.emit(!isNilty(this.barcodeType) && !isNilty(this.barcodeValue));
      }
    }, 0);
  }

  uploadClick() {
    this.fileElementRef.nativeElement.click();
  }

  uploadFile(event) {
    this.blacklistFile = event.target.files[0];
    this.checkCompleted();
  }

  viewFileTemplate() {
    this.reasons = [
      { code: 'INTELLECTUAL_PROPERTY_INFRINGEMENT', name: 'Intellectual Property Infringement' },
      { code: 'PRODUCT_SAFETY_AND_COMPLIANCE', name: 'Product Safety&Compliance' },
      { code: 'INTELLECTUAL_PROPERTY_CLAIM', name: 'Intellectual Property Claim' },
      { code: 'PRODUCT_AUTHENTICITY_CLAIMS', name: 'Product Authenticity Claims' },
      { code: 'BID_POLICY_VIOLATIONS', name: 'Bid Policy Violations' },
      { code: 'VIOLATIONS_OF_TERMS_AND_CONDITIONS', name: 'Violations of terms and conditions' },
      { code: 'REVIEW_POLICY', name: 'Review policy' },
      { code: 'PRICE_VIOLATIONS', name: 'Price Violations' },
      { code: 'CUSTOMER_COMPLAINTS', name: 'Customer Complaints' },
      { code: 'POLICY_VIOLATION', name: 'Policy Violation' },
      { code: 'PRODUCT_CLAIMS', name: 'Product Claims' },
    ];

    this.dialog.open(HelpModalComponent, {
      data: {
        title: 'Blacklist import template',
        html: `
          The file must be a csv separated by <i>;</i> and having the following template:\n
          <div class="row">
            <div class="col-md-12 text-center background-whitesmoke" style="background-color: whitesmoke">BARCODE;BLACKLIST_REASON;BLACKLIST_DATE;NOTE</div>
          </div>

            <b class="text-primary">BARCODE</b>: The barcode will be validated based on the Barcode Type selected.\n
            <b class="text-primary">BLACKLIST_REASON</b>: Must be one of the allowed values (see below).\n
            <b class="text-primary">BLACKLIST_DATE</b>: Must have <i>DD/MM/YYYY</i> format

            <div class="row">
                <div class="col-md-12">
                    Allowed values for <b>BLACKLIST_REASON</b>:
                </div>
                <div class="col-md-12 padding-top-5 blacklist-reasons-list">${this.reasons.map((it) => it.code).join('\n')}</div>
            </div>
          `,
      },
    });
  }
}
