import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { FileProperties } from './file-properties-model';
import { ChunkedFeedRouteConfiguration } from './route-configurations/chunked-feed-route-configuration-model';
import { isNilty } from '../../../core/utils.service';
import { SoupParser } from './soup-parser-model';

@JsonObject('RouteConfiguration')
export class RouteConfiguration {
  @JsonProperty('beanName', String, true) beanName: string = undefined;
  @JsonProperty('zipFile', Boolean, true) zipFile = false;

  @JsonProperty('fileProperties', FileProperties, true) fileProperties: FileProperties = undefined;

  @JsonProperty('multipleFile', Boolean, true) multipleFile = false;
  @JsonProperty('multipleFileMap', Any, true) multipleFileMap: any = undefined;

  @JsonProperty('handleFileInChunks', Boolean, true) handleFileInChunks = false;
  @JsonProperty('chunkedFeedConfiguration', ChunkedFeedRouteConfiguration, true)
  chunkedFeedConfiguration: ChunkedFeedRouteConfiguration = undefined;

  constructor(fileProperties?: FileProperties, parser?: SoupParser) {
    this.fileProperties = isNilty(fileProperties) ? new FileProperties() : fileProperties;
    this.beanName = isNilty(parser) ? undefined : parser.beanName;
  }
}
