import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ProductMetadata } from 'src/app/models/product-metadata-model';

@Component({
  selector: 'app-products-source-metadata-list',
  templateUrl: './products-source-metadata-list.component.html',
  styleUrls: ['./products-source-metadata-list.component.css'],
})
export class ProductSourceMetadataListComponent implements OnInit {
  @Input() metadata: ProductMetadata[];

  dataSource = new MatTableDataSource<ProductMetadata>([]);
  displayedColumns = ['source', 'title', 'sku', 'brand', 'category'];

  ngOnInit(): void {
    this.dataSource.data = this.metadata;
  }
}
