import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SellingPlatform } from '@models/configuration/marketplace/marketplace-model';
import { StoreService } from '../../store.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateNewMarket } from '@models/configuration/marketplace/create-new-market';
import { Currency } from '@models/currency-model';
import { CreateSellingPlatformModalComponent } from '../../../policies/restrictions/create-selling-platform-modal/create-selling-platform-modal.component';
import { ListingSellingPlatform } from '@models/listings/listing-selling-platform-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonsService } from '@shared/commons.service';

@Component({
  selector: 'app-new-marketplace-modal',
  templateUrl: './new-marketplace-modal.component.html',
  styleUrls: ['./new-marketplace-modal.component.scss'],
})
export class NewMarketplaceModalComponent implements OnInit {
  newMarketplaceForm: FormGroup;
  newMarketplace = new CreateNewMarket();

  sellingPlatforms: SellingPlatform[] = [];
  newSellingPlatform: ListingSellingPlatform = new ListingSellingPlatform();

  networks: string[] = [];
  currencies: Currency[];
  countries: string[];

  selectedNetwork: string;
  selectedCountry: string;
  selectedCurrency = new Currency();

  constructor(
    public dialogRef: MatDialogRef<NewMarketplaceModalComponent>,
    private storeService: StoreService,
    private commonService: CommonsService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.storeService.getSellingPlatforms().subscribe((r) => (this.sellingPlatforms = r).sort((a, b) => (a.name > b.name ? 1 : -1)));
    this.commonService.getNetworks().subscribe((r) => (this.networks = r).sort((a, b) => (a > b ? 1 : -1)));
    this.commonService.getCurrencies().subscribe((r) => (this.currencies = r));
    this.storeService.getAllCountries().subscribe((r) => (this.countries = r.sort((a, b) => (a > b ? 1 : -1))));

    this.newMarketplaceForm = new FormGroup({
      nameCM: new FormControl(null, Validators.required),
      network: new FormControl(null, Validators.required),
      country: new FormControl(null),
      currency: new FormControl(null),
      sellingPlatform: new FormControl(null, Validators.required),
    });
  }

  createNewSellingPlatform($event) {
    $event.preventDefault();
    this.dialog
      .open(CreateSellingPlatformModalComponent, { disableClose: true })
      .afterClosed()
      .subscribe((newSellingPlatformName: string) => {
        this.storeService.getSellingPlatforms().subscribe((r) => (this.sellingPlatforms = r));
        if (this.newSellingPlatform) {
          this.newSellingPlatform.sellingPlatform = newSellingPlatformName;
          this.newMarketplaceForm.controls.sellingPlatform.patchValue(this.newSellingPlatform.sellingPlatform);
        }
      });
  }

  saveMarketplace() {
    this.newMarketplace.name = this.newMarketplaceForm.value.nameCM;
    this.newMarketplace.network = this.newMarketplaceForm.value.network;
    this.newMarketplace.country = this.newMarketplaceForm.value.country;
    this.newMarketplace.currency = this.newMarketplaceForm.value.currency;
    this.newMarketplace.sellingPlatform = this.newMarketplaceForm.value.sellingPlatform;

    this.storeService.addMarketplace(this.newMarketplace).subscribe(() => {
      this.storeService.clearMarketplacesCache();

      this.snackBar.open('Changes have been saved.', 'CLOSE')._dismissAfter(3000);

      this.dialogRef.close(this.newMarketplace.name);
    });
  }
}
