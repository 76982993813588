import { JsonObject, JsonProperty } from 'json2typescript';
import { SoupConfiguration } from './soup-configuration-model';

@JsonObject('StockLocation')
export class StockLocation {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('supplierCode', String, true) supplierCode: string = undefined;
  @JsonProperty('supplierName', String, true) supplierName: string = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('restrictOrderTypeTo', String, true) restrictOrderTypeTo: string = undefined;
  @JsonProperty('lastStockUpdate', String, true) lastStockUpdate: string = undefined;
  @JsonProperty('totalItems', Number, true) totalItems: number = undefined;
  @JsonProperty('totalAvailableItems', Number, true) totalAvailableItems: number = undefined;
  @JsonProperty('totalPodItems', Number, true) totalPodItems: number = undefined;
  @JsonProperty('totalQuantity', Number, true) totalQuantity: number = undefined;
  @JsonProperty('countryCode', String, true) countryCode: string = undefined;
  @JsonProperty('lastTradingPartnerFeedUrl', String, true) lastTradingPartnerFeedUrl: string = undefined;
  @JsonProperty('lastTradingPartnerFeedReceivedDate', String, true) lastTradingPartnerFeedReceivedDate: string = undefined;
  @JsonProperty('logisticPartner', String, true) logisticPartner: string = undefined;
  @JsonProperty('soupConfigurations', [SoupConfiguration], true) soupConfigurations: SoupConfiguration[] = undefined;
  @JsonProperty('returnStockLocationCode', String, true) returnStockLocationCode: string = undefined;
  @JsonProperty('enabledForReturns', Boolean, true) enabledForReturns: boolean = undefined;
}
