import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseEntity } from '../../shared/base-entity-model';
import { Account } from './account-model';
import { FullMarketplace, MarketplaceDto } from './marketplace-model';
import { StoreGroup } from './store-group-model';

@JsonObject('StoreWithoutMarketplace')
class StoreWithoutMarketplace extends BaseEntity {
  @JsonProperty('id', Number, true) id: number = undefined;

  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('country', String, true) country: string = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;

  @JsonProperty('account', Account, true) account: Account = undefined;
  @JsonProperty('storeGroup', StoreGroup, true) storeGroup: StoreGroup = undefined;
}

@JsonObject('Store')
export class Store extends StoreWithoutMarketplace {
  @JsonProperty('marketplace', MarketplaceDto, true) marketplace: MarketplaceDto = undefined;
}

@JsonObject('FullStore')
export class FullStore extends StoreWithoutMarketplace {
  @JsonProperty('marketplace', FullMarketplace, true) marketplace: FullMarketplace = undefined;
}
