import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-export-template-name',
  template: `
    <div class="dialog-container position-relative" (keyup.enter)="confirm()">
      <div mat-dialog-content>
        <button
          class="bg-body btn-custom mat-tooltip-trigger ms-2 position-absolute smaller-icon-button text-black"
          style="right: -10px; transform: translateY(-2px); top: -10px;"
          matTooltip="Erase all"
          (click)="close()"
        >
          <i class="fa fa-close"></i>
        </button>
        <div class="row">
          <div class="col center">
            <label for="templateName">Template name:</label>
            <mat-form-field class="position-input">
              <input id="templateName" matInput [(ngModel)]="data.newTemplateName" (ngModelChange)="validate($event)" />
            </mat-form-field>
          </div>
        </div>
        <div class="row description">
          <div class="col center">(press ENTER to confirm)</div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .dialog-container {
        font-family: 'Titillium Web', sans-serif;
        width: 400px !important;
      }

      .position-input {
        margin-left: 50px;
      }

      .description {
        font-size: 13px;
      }
      .error {
        color: red;
      }
      .warn {
        color: orange;
      }
    `,
  ],
})
export class ExportTemplateNameModalComponent {
  warn: string = undefined;
  error: string = undefined;

  constructor(
    private dialogRef: MatDialogRef<ExportTemplateNameModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { newTemplateName: string }
  ) {}

  validate(newValue: string) {
    if (isNilty(newValue)) return;

    const pattern = /^[A-ZA-Z]{1,2}$/;
    if (!pattern.test(newValue)) {
      this.setError('Position must be a letter');
      return;
    }

    this.unsetMessages();
  }

  confirm() {
    this.dialogRef.close(this.data.newTemplateName);
  }

  close() {
    this.dialogRef.close();
  }

  private setError(error: string) {
    this.warn = undefined;
    this.error = error;
  }

  private setWarn(warn: string) {
    this.warn = warn;
    this.error = undefined;
  }

  private unsetMessages() {
    this.warn = undefined;
    this.error = undefined;
  }
}
