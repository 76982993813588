import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '../core/converter.service';
import { EnvironmentService } from '../core/environment/environment.service';
import { Currency } from '../models/currency-model';
import { Observable } from 'rxjs';
import { NetworkCategory } from '../models/network-category-model';
import { ZionBrand } from '../models/zion-catalog-item-model';
import { map } from 'rxjs/operators';
import { cachedArrayGet, GenericArrayCache } from './service-cached-array';
import { Category } from '../models/category-model';
import { MeasureUnits } from '@models/measure-units-model';
import { cachedGet, GenericCache } from '@shared/service-cached';
import { PowerSeller } from '@models/configuration/rules/power-seller-rule/power-seller-model';
import { Language } from '@models/language-model';

@Injectable()
export class CommonsService {
  private measureUnitsCache = new GenericCache<MeasureUnits>(this.http, this.converter, MeasureUnits);
  private orderTypesCache = new GenericArrayCache<string>(this.http, this.converter);
  private currencyCache = new GenericArrayCache<Currency>(this.http, this.converter, Currency);
  private logisticPartnersCache = new GenericArrayCache<string>(this.http, this.converter);
  private networksCache = new GenericArrayCache<string>(this.http, this.converter);
  private networkCategoriesCache = new GenericArrayCache<NetworkCategory>(this.http, this.converter);
  private exclusionsCache = new GenericArrayCache<string>(this.http, this.converter);
  private categoryCache = new GenericArrayCache<Category>(this.http, this.converter, Category);
  private brandCache = new GenericArrayCache<ZionBrand>(this.http, this.converter, ZionBrand);
  private powerSellersCache = new GenericArrayCache<PowerSeller>(this.http, this.converter, PowerSeller);
  private languagesCache = new GenericArrayCache<Language>(this.http, this.converter, Language);

  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getLanguages(): Observable<Language[]> {
    return cachedArrayGet<Language>(this.languagesCache, this.environmentService.getRestEndpoint('languages'));
  }

  getOrderTypes(): Observable<string[]> {
    return cachedArrayGet<string>(this.orderTypesCache, this.environmentService.getRestEndpoint('orderTypes'));
  }

  getCurrencies(): Observable<Currency[]> {
    return cachedArrayGet<Currency>(this.currencyCache, this.environmentService.getRestEndpoint('currencies'));
  }

  getLogisticPartners(): Observable<string[]> {
    return cachedArrayGet<string>(this.logisticPartnersCache, this.environmentService.getRestEndpoint('getLogisticPartners'));
  }

  getNetworks(): Observable<string[]> {
    return cachedArrayGet<string>(this.networksCache, this.environmentService.getRestEndpoint('getNetworks'));
  }
  getNetworkCategories(): Observable<NetworkCategory[]> {
    return cachedArrayGet<NetworkCategory>(this.networkCategoriesCache, this.environmentService.getRestEndpoint('getNetworkCategories'));
  }

  getBrands(): Observable<ZionBrand[]> {
    return cachedArrayGet<ZionBrand>(this.brandCache, this.environmentService.getRestEndpoint('brands'));
  }

  searchBrands(searchString: string): Observable<ZionBrand[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('searchBrands') + '?searchString=' + searchString)
      .pipe(map((r: ZionBrand[]) => r.map((it) => this.converter.fromJSONtoObj(it, ZionBrand))));
  }

  getExclusions(): Observable<string[]> {
    return cachedArrayGet<string>(this.exclusionsCache, this.environmentService.getRestEndpoint('exclusions'));
  }

  getCategories(): Observable<Category[]> {
    return cachedArrayGet<Category>(this.categoryCache, this.environmentService.getRestEndpoint('categories'));
  }

  getMeasureUnits(): Observable<MeasureUnits> {
    return cachedGet<MeasureUnits>(this.measureUnitsCache, this.environmentService.getRestEndpoint('measureUnits'));
  }

  getAllPowerSellers(): Observable<PowerSeller[]> {
    return cachedArrayGet<PowerSeller>(this.powerSellersCache, this.environmentService.getMarkusRestEndpoint('powerSellers'));
  }
}
