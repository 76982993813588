import { AgRendererComponent } from 'ag-grid-angular';
import { Component, OnInit } from '@angular/core';
import { Publication } from '../../../../../models/publications/publication-model';
import { IAfterGuiAttachedParams } from 'ag-grid-community';
import { PublicationDto } from '../../../../../models/publications/publication-dto-model';
import { isNilty } from '../../../../../core/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { BlacklistPublicationModalComponent } from './modals/blacklist-publication-modal.component';
import { ParentHistoryModalComponent } from '../parent-history-modal/parent-history-modal.component';
import { PublicationsButtons } from './publications-buttons';
import { CatalogItemsService } from '../../../../catalog-items/catalog-items.service';
import { GenericConfirmationModalComponent } from '../../../../../shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GenericErrorModalComponent } from '../../../../../shared/generic-error-modal/generic-error-modal.component';
import { AuthorizationService } from '../../../../../security/authorization.service';
import { Observable } from 'rxjs';
import { Permissions } from '../../../../../models/security/permission-model';
import { PublicationService } from '../../../publication.service';
import { PublicationRefreshModalComponent } from './publication-refresh-modal/publication-refresh-modal.component';

@Component({
  selector: 'app-pending-tooltip-component',
  templateUrl: 'publication-edit-button.component.html',
  styleUrls: ['publication-edit-button.component.scss'],
})
export class PublicationEditButtonComponent implements AgRendererComponent, OnInit {
  publicationDto: PublicationDto;

  iconClass: string;
  onlineStatus: string;
  description = '';

  BUTTONS = PublicationsButtons;

  buttonsList: Map<string, boolean> = new Map(Object.keys(this.BUTTONS).map((it) => [this.BUTTONS[it], false]));
  hasActionsAvailable = false;
  hasBlacklistManagement: Observable<boolean>;

  constructor(
    private matDialog: MatDialog,
    private catalogItemsService: CatalogItemsService,
    private publicationService: PublicationService,
    private snackbar: MatSnackBar,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit() {
    this.hasBlacklistManagement = this.authorizationService.hasMarkusPermission(Permissions.BlacklistManagement);
  }

  agInit(params): void {
    if (isNilty(params.data)) {
      return;
    }
    this.publicationDto = params.data as PublicationDto;
    const status = this.publicationDto.publication.status;

    if (status === 'PUBLISHED') {
      this.iconClass = 'online';
      this.onlineStatus = 'Online';
    } else if (['NEW', 'CREATING', 'BLACKLISTED', 'DISABLED'].indexOf(status) !== -1) {
      this.iconClass = 'offline';
      this.onlineStatus = 'Offline';
    } else if (status === 'ERROR') {
      this.iconClass = 'error';
      this.onlineStatus = 'Error';
    } else if (['PENDING', 'BLACKLIST_PENDING', 'DISABLE_PENDING'].indexOf(status) !== -1) {
      this.iconClass = 'pending';
      this.onlineStatus = 'Online';
      this.description = '(pending update)';
    } else {
      this.iconClass = '';
      this.onlineStatus = 'Unrecognized status';
    }

    this.showButtons();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  afterGuiAttached(params?: IAfterGuiAttachedParams): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refresh(params: any): boolean {
    return false;
  }

  blacklistPublication() {
    this.matDialog.open(BlacklistPublicationModalComponent, { data: this.publicationDto?.publication });
  }

  refreshPublication() {
    this.matDialog
      .open(PublicationRefreshModalComponent, {
        data: `This will refresh the publication for SKU ${this.publicationDto.publication.sku}.
        Do you want to trigger a brute refresh (by also deleting the quantity and price) or just simply refresh?`,
      })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r !== undefined) {
          this.publicationService
            .refreshSinglePublication(this.publicationDto.publication, r)
            .subscribe(() => this.snackbar.open('The request has been sent.')._dismissAfter(3000));
        }
      });
  }

  disableCatalog() {
    this.matDialog
      .open(GenericConfirmationModalComponent, {
        data: `This will disable the catalog for supplier ${this.publicationDto.publication.supplierCode} and Barcode ${this.publicationDto.publication.barcode}.
      This product will not be published from this supplier on any marketplace anymore.`,
      })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const catalogToDisable = this.catalogItemsService
            .getCatalogItemBySupplierAndEan(this.publicationDto.publication.supplierCode, this.publicationDto.publication.barcode)
            .subscribe((c) => {
              if (!isNilty(c)) {
                this.catalogItemsService
                  .disableCatalogItem(c.id)
                  .subscribe(() => this.snackbar.open('The catalogItem has been disabled.')._dismissAfter(3000));
              } else {
                this.matDialog.open(GenericErrorModalComponent, {
                  data: `Could not find CatalogItem for supplier ${this.publicationDto.publication.supplierCode} and Barcode ${this.publicationDto.publication.barcode}`,
                });
              }
            });
        }
      });
  }

  openHistoryModal(p: Publication) {
    this.publicationService.getPublicationHistory(p.id, p.store.code).subscribe((r) => {
      this.matDialog.open(ParentHistoryModalComponent, {
        data: r,
        panelClass: 'parent-history-modal-component',
      });
    });
  }

  private showButtons() {
    const status = this.publicationDto?.publication?.status;

    this.buttonsList.set(this.BUTTONS.REFRESH_PUBLICATION, true);
    this.buttonsList.set(this.BUTTONS.DISABLE_CATALOG, true);
    this.buttonsList.set(this.BUTTONS.BLACKLIST, status !== 'BLACKLIST_PENDING' && status !== 'BLACKLISTED');

    // Looks if at least one button is available
    this.hasActionsAvailable = Array.from(this.buttonsList.values()).findIndex((it) => it) !== -1;
  }
}
