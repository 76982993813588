import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseEntity } from '@models/shared/base-entity-model';

@JsonObject('PowerSeller')
export class PowerSeller extends BaseEntity {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('productEquivalenceType', String, true) productEquivalenceType: string = undefined;
}
