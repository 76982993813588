import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('PublicationsJobs')
export class PublicationsJobs {
  @JsonProperty('startTime', String, false) startTime: string = undefined;
  @JsonProperty('updatedAt', String, false) updatedAt: string = undefined;
  @JsonProperty('filters', Object, false) filters: string = undefined;
  @JsonProperty('lastPageIds', Object, false) lastPageIds: string = undefined;
  @JsonProperty('totalRows', Number, false) totalRows: number = undefined;
}
