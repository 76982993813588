//eslint-disable-next-line
import { Observable, of, Subject } from 'rxjs';
import { isNilty } from '../core/utils.service';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '../core/converter.service';

export const cachedGet = <T>(cache: GenericCache<T>, endpoint: string): Observable<T> => {
  if (!isNilty(cache.cachedObject)) {
    return of(cache.cachedObject);
  }

  if (cache.getting) {
    return cache.subject;
  } else {
    cache.getting = true;
    return cache.http.get(endpoint).pipe(
      map((r: T) => {
        if (cache.clazz) {
          cache.cachedObject = cache.converter.fromJSONtoObj(r, cache.clazz);
        } else {
          cache.cachedObject = r;
        }
        cache.subject.next(cache.cachedObject);
        cache.getting = false;
        return cache.cachedObject;
      })
    );
  }
};

// Clazz is needed for the converter. If not provided, the converter will not be called.
// Clazz must not be provided for primitive types.
export class GenericCache<T> {
  clazz: any;
  http: HttpClient;
  converter: ConverterService;
  cachedObject: T;
  subject: Subject<T>;
  getting = false;

  constructor(http: HttpClient, converter: ConverterService, clazz?: any) {
    this.subject = new Subject<T>();
    this.clazz = clazz;
    this.http = http;
    this.converter = converter;
  }

  reset() {
    this.cachedObject = undefined;
    this.getting = false;
  }
}
