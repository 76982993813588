import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogItem, CatalogItemStatus } from '../../../../models/catalog-item-model';
import { CatalogItemsService } from '../../../catalog-items/catalog-items.service';

@Component({
  selector: 'app-disabled-catalogs-table',
  templateUrl: './disabled-catalogs-table.component.html',
  styleUrls: ['./disabled-catalogs-table.component.scss'],
})
export class DisabledCatalogsTableComponent {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @Output() changePage: EventEmitter<MatPaginator> = new EventEmitter<MatPaginator>();
  @Output() buttonPressed: EventEmitter<CatalogItem> = new EventEmitter<CatalogItem>();

  isActive: boolean;

  dataSource = new MatTableDataSource<CatalogItem>([]);
  displayedColumns = ['supplier', 'sku', 'productBarcodeValue', 'availableQuantity', 'purchasePrice', 'button'];

  constructor(private catalogItemsService: CatalogItemsService, private router: Router, private route: ActivatedRoute) {}

  @Input() set catalogs(c: CatalogItem[]) {
    this.dataSource.data = c;
  }
  @Input() set status(s: string) {
    this.isActive = s === CatalogItemStatus.ACTIVE;
  }

  loadPage() {
    this.changePage.next(this.paginator);
  }
}
