import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Supplier } from 'src/app/models/supplier-model';
import { SupplierFilters } from '../../models/filters/supplier';
import { SuppliersService } from './suppliers.service';
import { MatDialog } from '@angular/material/dialog';
import { SupplierDiscountCodesModalComponent } from './supplier-discount-codes/supplier-discount-codes-modal.component';
import { Permissions } from '../../models/security/permission-model';
import { AuthorizationService } from '../../security/authorization.service';
import { SupplierInvoiceToModalComponent } from './supplier-invoice-to-modal/supplier-invoice-to-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss'],
})
export class SuppliersComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @ViewChild('invoiceFile', { static: false }) invoiceFile: ElementRef;
  currentFileUpload: File;

  resultsNumber: Observable<number>;
  dataSource = new MatTableDataSource<Supplier>([]);
  displayedColumns = [
    'code',
    'name',
    'lastStockUpdate',
    'isDropShipper',
    'logisticPartner',
    'defaultDiscountPercentage',
    'overrideFeedDiscount',
    'insertNewProducts',
    'catalogItemHistorySize',
    'requestsMetadata',
    'requestsBookMetadata',
    'updateProductRestrictions',
    'hasOwnRestrictions',
    'currency',
    'mdActive',
    'mdDefaultQuantity',
    'defaultQuantity',
  ];

  filters = new SupplierFilters();

  constructor(
    private suppliersService: SuppliersService,
    private router: Router,
    private dialog: MatDialog,
    private authorizationService: AuthorizationService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.authorizationService.hasZionPermission(Permissions.SupplierFeedConfiguration).subscribe((it) => {
      if (it) {
        this.displayedColumns.push('edit');
      }
      this.displayedColumns.push('discountCodes', 'openStockLocations', 'openCatalogItems', 'invoiceTo');
    });

    this.resultsNumber = this.suppliersService.resultsNumber;
    this.loadResults();
  }

  loadResults() {
    this.suppliersService.getFilteredSuppliers(this.filters).subscribe((resp: Supplier[]) => {
      this.dataSource.data = resp;
    });
  }

  loadPage() {
    this.filters.pageSize = this.paginator.pageSize;
    this.filters.pageNumber = this.paginator.pageIndex;
    this.loadResults();
  }

  onSort() {
    this.filters.sortBy = this.sort.active;
    this.filters.sortDirection = this.sort.direction;
    this.loadResults();
  }

  search() {
    this.paginator.pageIndex = 0;
    this.filters.pageNumber = 0;
    this.loadResults();
  }

  reset() {
    this.paginator.pageIndex = 0;
    this.filters.pageNumber = 0;
    this.filters.code = null;
    this.loadResults();
  }

  openStockLocations(supplier: Supplier) {
    this.router.navigate(['stock-locations'], { queryParams: { supplier: supplier.code } });
  }

  openCatalogItems(supplier: Supplier) {
    this.router.navigate(['catalog-items'], { queryParams: { supplier: supplier.code } });
  }

  openDiscountCodes(supplier: Supplier) {
    this.dialog.open(SupplierDiscountCodesModalComponent, { data: supplier, autoFocus: false });
  }

  edit(supplier?: Supplier) {
    const path = supplier ? 'edit-supplier' : 'new-supplier';
    const params = supplier ? { queryParams: { supplier: supplier.code } } : {};
    this.router.navigate([path], params);
  }

  openInvoiceTo(supplier: Supplier) {
    this.dialog.open(SupplierInvoiceToModalComponent, { data: supplier, autoFocus: false });
  }

  importInvoice() {
    this.invoiceFile.nativeElement.click();
  }

  invoiceFileChanged(event) {
    this.currentFileUpload = event.target.files[0];
    if (this.currentFileUpload.type !== 'text/csv') {
      this.snackBar.open('ERROR! Please, select an .csv file.', 'CLOSE')._dismissAfter(5000);
    } else {
      this.suppliersService.uploadInvoiceInfo(this.currentFileUpload).subscribe(() => {
        this.snackBar.open('File uploaded. ', 'CLOSE')._dismissAfter(3000);
        this.loadResults();
      });
    }
  }
}
