import { JsonObject, JsonProperty } from 'json2typescript';
import { RouteConfiguration } from '../route-configuration-model';

/* eslint-disable */
export enum FTP_SOURCE_TYPES {
  FTP = 'FTP',
  SFTP = 'SFTP',
}
/* eslint-enable */

@JsonObject('FtpRouteConfiguration')
export class FtpRouteConfiguration extends RouteConfiguration {
  @JsonProperty('sourceType', String, true) sourceType: string = undefined;

  @JsonProperty('host', String, true) host: string = undefined;
  @JsonProperty('port', String, true) port: string = undefined;
  @JsonProperty('user', String, true) user: string = undefined;
  @JsonProperty('password', String, true) password: string = undefined;
  @JsonProperty('path', String, true) path: string = undefined;

  @JsonProperty('fileMask', String, true) fileMask: string = undefined;
  @JsonProperty('deleteFile', Boolean, true) deleteFile = true;

  @JsonProperty('cronExpression', String, true) cronExpression = '* * * ? * *';
  @JsonProperty('cacheFileName', Boolean, true) cacheFileName = false;
}
