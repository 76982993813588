import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenericImportComponent } from '../catalog-import-export/generic-import/generic-import.component';
import { Observable } from 'rxjs';
import { Permissions } from '../../../models/security/permission-model';
import { AuthorizationService } from '../../../security/authorization.service';

@Component({
  selector: 'app-configuration-menu',
  templateUrl: './configuration-menu.component.html',
  styleUrls: ['./configuration-menu.component.scss'],
})
export class ConfigurationMenuComponent implements OnInit {
  hasFeedConfiguration: Observable<boolean>;
  hasStoreFeesConfiguration: Observable<boolean>;
  hasPonchAdministration: Observable<boolean>;
  hasPublicationRulesManagement: Observable<boolean>;
  hasSalesOrdersConfiguration: Observable<boolean>;
  hasZionAdministration: Observable<boolean>;
  hasMarkusAdministration: Observable<boolean>;

  constructor(private authorizationService: AuthorizationService, private dialog: MatDialog) {}

  ngOnInit() {
    this.hasFeedConfiguration = this.authorizationService.hasZionPermission(Permissions.SupplierFeedConfiguration);
    this.hasStoreFeesConfiguration = this.authorizationService.hasMarkusPermission(Permissions.StoreFeesConfiguration);
    this.hasPonchAdministration = this.authorizationService.hasPonchPermission(Permissions.Administration);
    this.hasPublicationRulesManagement = this.authorizationService.hasMarkusPermission(Permissions.PublicationRulesManagement);
    this.hasSalesOrdersConfiguration = this.authorizationService.hasMarkusPermission(Permissions.SalesOrdersConfiguration);
    this.hasZionAdministration = this.authorizationService.hasZionPermission(Permissions.ZionAdministration);
    this.hasMarkusAdministration = this.authorizationService.hasMarkusPermission(Permissions.MarkusAdministration);

    window.onscroll = () => {
      if (document.documentElement.scrollTop < 78) {
        // Just for debug
        // console.log(document.documentElement.scrollTop);
        document.querySelector('aside').style.paddingTop = `${78 - document.documentElement.scrollTop}px`;
      } else {
        document.querySelector('aside').style.paddingTop = `0px`;
      }
    };
  }

  import() {
    this.dialog.open(GenericImportComponent, { disableClose: true, panelClass: 'parent-history-modal-component' });
  }
}
