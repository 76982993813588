import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UpdateCriterion')
export class UpdateCriterion {
  @JsonProperty('type', String, true) type: string = undefined;
  @JsonProperty('thresholdValue', Number, true) thresholdValue: number = undefined;
}

@JsonObject('UpdateCriteria')
export class UpdateCriteria {
  @JsonProperty('quantityUpdateCriterion', UpdateCriterion, true) quantityUpdateCriterion: UpdateCriterion = undefined;
  @JsonProperty('priceUpdateCriterion', UpdateCriterion, true) priceUpdateCriterion: UpdateCriterion = undefined;
}
