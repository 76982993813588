import { Component, OnInit } from '@angular/core';
import { Supplier } from '../../../models/supplier-model';
import { SuppliersService } from '../../suppliers/suppliers.service';
import { CatalogItemFilters } from '../../../models/filters/catalog-item';
import { CatalogItemsService } from '../../catalog-items/catalog-items.service';
import { CatalogItem, CatalogItemStatus } from '../../../models/catalog-item-model';
import { MatPaginator } from '@angular/material/paginator';
import { isNilty } from '../../../core/utils.service';

@Component({
  selector: 'app-disabled-catalogs',
  templateUrl: './disabled-catalogs.component.html',
  styleUrls: ['./disabled-catalogs.component.scss'],
})
export class DisabledCatalogsComponent implements OnInit {
  suppliers: Supplier[];
  filters = new CatalogItemFilters();

  disabledCatalogs: CatalogItem[];
  activeCatalogs: CatalogItem[];

  CATALOG_STATUS = CatalogItemStatus;

  constructor(private suppliersService: SuppliersService, private catalogItemsService: CatalogItemsService) {}

  ngOnInit() {
    this.suppliersService.getAllSuppliers().subscribe((r) => (this.suppliers = r));
  }

  search() {
    if (isNilty(this.filters.supplierId)) {
      return;
    }
    this.catalogItemsService
      .getFilteredCatalogItems(this.copyFilters(this.filters, this.CATALOG_STATUS.DISABLED), true)
      .subscribe((r) => (this.disabledCatalogs = r));

    this.catalogItemsService
      .getFilteredCatalogItems(this.copyFilters(this.filters, this.CATALOG_STATUS.ACTIVE), true)
      .subscribe((r) => (this.activeCatalogs = r));
  }

  reset() {
    this.filters = new CatalogItemFilters();
    this.activeCatalogs = [];
    this.disabledCatalogs = [];
  }

  changePage(paginator: MatPaginator, status: string) {
    const paginatedFilters = this.copyFilters(this.filters, status);
    paginatedFilters.pageNumber = paginator.pageIndex;
    paginatedFilters.pageSize = paginator.pageSize;

    this.catalogItemsService.getFilteredCatalogItems(paginatedFilters, true).subscribe((r) => {
      if (status === this.CATALOG_STATUS.ACTIVE) {
        this.activeCatalogs = r;
      } else if (status === this.CATALOG_STATUS.ACTIVE) {
        this.disabledCatalogs = r;
      }
    });
  }

  reactivate(c: CatalogItem) {
    this.catalogItemsService.activateCatalogItem(c.id).subscribe(() => this.search());
  }
  disable(c: CatalogItem) {
    this.catalogItemsService.disableCatalogItem(c.id).subscribe(() => this.search());
  }

  private copyFilters(filters: CatalogItemFilters, status: string): CatalogItemFilters {
    const newFilters = new CatalogItemFilters();
    newFilters.supplierId = filters.supplierId;
    newFilters.internalSku = filters.internalSku;
    newFilters.productBarcodeValue = filters.productBarcodeValue;
    newFilters.status = status;
    newFilters.sortBy = 'id';
    return newFilters;
  }
}
