import { StockLocationsService } from './../stock-locations.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Supplier } from '@models/supplier-model';
import { SuppliersService } from '../../suppliers/suppliers.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { CreateNewStockLocation } from '@models/create-new-stock-location';
import { CommonsService } from '@shared/commons.service';
import { StockLocation } from '@models/stock-location-model';

@Component({
  selector: 'app-add-stock-location',
  templateUrl: './add-stock-location.component.html',
  styleUrls: ['./add-stock-location.component.css'],
})
export class AddStockLocationComponent implements OnInit {
  @Output() filterSuppliersEv = new EventEmitter<Supplier>();
  @Output() filterReturnStockLocationEv = new EventEmitter<StockLocation>();
  suppliers: Supplier[];
  stockLocations: StockLocation[];
  logisticPartners: string[];
  stockLocForm: FormGroup;
  currentStockLoc = new CreateNewStockLocation();
  selectedSupplier = new Supplier();
  selectedReturnStockLocation = new StockLocation();
  orderTypes: string[] = [];
  requiredReturnStockLocation = false;
  isReturnSL = false;

  constructor(
    public dialogRef: MatDialogRef<AddStockLocationComponent>,
    private stockLocationsService: StockLocationsService,
    private suppliersService: SuppliersService,
    private commonService: CommonsService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.stockLocForm = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.pattern(/[A-Z0-9-]{4}/)]),
      name: new FormControl('', Validators.required),
      supplier: new FormControl('', Validators.required),
      logisticPartner: new FormControl(''),
      restrictOrderTypeTo: new FormControl(''),
      returnStockLocation: new FormControl(''),
      enabledForReturns: new FormControl(false),
    });

    this.suppliersService.getAllSuppliers().subscribe((suppliers: Supplier[]) => {
      this.suppliers = suppliers;
    });

    this.stockLocationsService.getAllReturnStockLocations().subscribe((stockLocations: StockLocation[]) => {
      this.stockLocations = stockLocations;
    });

    this.commonService.getLogisticPartners().subscribe((r) => (this.logisticPartners = r));
    this.commonService.getOrderTypes().subscribe((r) => (this.orderTypes = r));
  }

  onSubmit() {
    this.filterSuppliersEv.emit(this.selectedSupplier);
    this.filterReturnStockLocationEv.emit(this.selectedReturnStockLocation);

    this.currentStockLoc.code = this.stockLocForm.controls.code.value;
    this.currentStockLoc.name = this.stockLocForm.controls.name.value;
    this.currentStockLoc.supplierCode = this.selectedSupplier.code.toString();
    this.currentStockLoc.returnStockLocationCode = this.selectedReturnStockLocation.code
      ? this.selectedReturnStockLocation.code.toString()
      : null;
    this.currentStockLoc.logisticPartner =
      this.stockLocForm.controls.logisticPartner.value.length > 0 ? this.stockLocForm.controls.logisticPartner.value : null;
    this.currentStockLoc.restrictOrderTypeTo = this.stockLocForm.controls.restrictOrderTypeTo.value
      ? this.stockLocForm.controls.restrictOrderTypeTo.value
      : null;
    this.currentStockLoc.enabledForReturns = this.stockLocForm.controls.enabledForReturns.value;

    this.stockLocationsService.addStockLocation(this.currentStockLoc).subscribe(() => {
      this.snackbar.open('Changes have been saved.', 'CLOSE')._dismissAfter(3000);
      this.dialogRef.close(true);
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  checkReturnStockLocationRequired() {
    if (this.stockLocForm.controls.logisticPartner.value.toUpperCase() === 'TWS' && this.isReturnSL === false) {
      this.stockLocForm.controls.returnStockLocation.setValidators([Validators.required]);
      this.requiredReturnStockLocation = true;
    } else {
      this.stockLocForm.controls.returnStockLocation.setValidators([]);
      this.requiredReturnStockLocation = false;
    }

    this.stockLocForm.controls.returnStockLocation.updateValueAndValidity();
  }

  switchToggle() {
    this.isReturnSL = this.stockLocForm.controls.enabledForReturns.value;
    this.checkReturnStockLocationRequired();
  }
}
