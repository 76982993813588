import { Component, OnDestroy, OnInit } from '@angular/core';
import { PublicationService } from './publication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { isNilty } from '@core/utils.service';
import { PublishedStore } from '@models/publications/published-store-model';
import { StoreService } from '../configuration/store-configuration/store.service';

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.scss'],
})
export class PublicationsComponent implements OnInit, OnDestroy {
  selectedTab: number;

  publishedStores: PublishedStore[];

  constructor(
    private publicationService: PublicationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private storeConfigurationService: StoreService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (!isNilty(params['tab'])) {
        this.selectedTab = +params['tab'];
        this.publicationService.selectedSearchTab = this.selectedTab;
      } else {
        this.changeTab(this.publicationService.selectedSearchTab);
      }
    });

    this.storeConfigurationService.getPublishedStores().subscribe((r) => (this.publishedStores = r));
  }

  changeTab(tabIndex: number) {
    this.selectedTab = tabIndex;
    this.publicationService.selectedSearchTab = tabIndex;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { tab: this.selectedTab },
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy() {
    this.publicationService.byBarcodeFilters = undefined;
    this.publicationService.byStoreFilters = undefined;
  }
}
