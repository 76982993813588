import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CatalogItem } from '../../../../models/catalog-item-model';
import { isNilty } from '../../../../core/utils.service';

@Component({
  selector: 'app-sales-restrictions-modal',
  templateUrl: './sales-restrictions-modal.component.html',
  styleUrls: ['./sales-restrictions-modal.component.scss'],
})
export class SalesRestrictionsModalComponent implements OnInit {
  salesRight: string[];
  salesRightExclude: string[];

  searchField: string;

  constructor(public dialogRef: MatDialogRef<SalesRestrictionsModalComponent>, @Inject(MAT_DIALOG_DATA) public data: CatalogItem) {}

  ngOnInit() {
    this.data.initSalesRightArrays();
    this.salesRight = this.data.salesRightArray;
    this.salesRightExclude = this.data.salesRightExcludeArray;
    this.data.supplier.initDescription();
  }

  search(text: string) {
    if (isNilty(text)) {
      this.salesRight = this.data.salesRightArray;
      this.salesRightExclude = this.data.salesRightExcludeArray;
    } else {
      this.salesRight = this.data.salesRightArray.filter((it) => it.toUpperCase().indexOf(text.toUpperCase()) !== -1);
      this.salesRightExclude = this.data.salesRightExcludeArray.filter((it) => it.toUpperCase().indexOf(text.toUpperCase()) !== -1);
    }
  }
}
