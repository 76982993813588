import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '@core/converter.service';
import { EnvironmentService } from '@core/environment/environment.service';
import { SupplierFeedReadingStatus } from '@models/configuration/administration/supplier-feed-reading-status-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AdministrationService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  findPendingFeeds(): Observable<SupplierFeedReadingStatus[]> {
    return this.http.get(this.environmentService.getRestEndpoint('getSoupFeedsStatus')).pipe(
      map((resp: SupplierFeedReadingStatus[]) => {
        const convertedResp = resp.map((it) => this.converter.fromJSONtoObj(it, SupplierFeedReadingStatus));
        convertedResp.forEach((it) => it.sanitizeStatus());
        return convertedResp;
      })
    );
  }

  markFeedAsFinished(identifier: string): Observable<any> {
    return this.http.delete(this.environmentService.getRestEndpoint('getSoupFeedsStatus') + `/${identifier}`);
  }

  getAllSkipSupplierFeedKeys(): Observable<string[]> {
    return this.http.get(this.environmentService.getRestEndpoint('skipSupplierFeedsKeys')).pipe(map((r: string[]) => r));
  }

  setSkipSupplierFeed(feedIdentifier: string): Observable<any> {
    return this.http.put(this.environmentService.getRestEndpoint('skipSupplierFeedsKeys'), feedIdentifier);
  }
  removeSkipSupplierFeed(feedIdentifier: string): Observable<any> {
    return this.http.delete(this.environmentService.getRestEndpoint('skipSupplierFeedsKeys') + '/' + feedIdentifier);
  }
}
