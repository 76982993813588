/* eslint-disable */
import { JsonObject, JsonProperty } from 'json2typescript';
import { RouteConfiguration } from './configuration/soup-configuration/route-configuration-model';
import { ImportStockLocation } from './import-stock-location-model';

export enum ROUTE_TYPES {
  FTP = 'FTP',
  B2B = 'B2B',
  HTTP = 'HTTP',
}

@JsonObject('SoupConfigurationForStockLocations')
export class SoupConfiguration {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('active', Boolean, true) active: boolean = undefined;
  @JsonProperty('stockLocationCode', String, true) stockLocationCode: string = undefined;
  @JsonProperty('distrName', String, true) distrName: string = undefined;
  @JsonProperty('routeType', String, true) routeType: string = undefined;
  @JsonProperty('configuration', RouteConfiguration, true) configuration: RouteConfiguration = undefined;
  @JsonProperty('addProduct', Boolean, true) addProduct: boolean = undefined;
  @JsonProperty('fullFeed', Boolean, true) fullFeed: boolean = undefined;
  @JsonProperty('lastStockUpdate', String, true) lastStockUpdate: string = undefined;
  @JsonProperty('feedFrequencyExpected', Number, true) feedFrequencyExpected: number = undefined;
  @JsonProperty('feedFrequency', Number, true) feedFrequency: number = undefined;
}
