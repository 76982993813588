import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RulePredicate } from '@models/configuration/rules/rule-predicate-model';

@Component({
  selector: 'app-edit-predicates-policy',
  templateUrl: './edit-predicates-policy.component.html',
  styleUrls: ['./edit-predicates-policy.component.scss'],
})
export class EditPredicatesPolicyComponent implements OnInit {
  @Input() predicates: RulePredicate[];
  @Output() predicatesChange = new EventEmitter<RulePredicate[]>();

  @Output() completed = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.predicates = [];
  }

  checkPredicates() {
    this.predicatesChange.emit(this.predicates);
    this.completed.emit(this.predicates.length !== 0);
  }
}
