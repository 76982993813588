import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pending-tooltip-component',
  template: `
    <div class="dialog-container padding-top-10 padding-bottom-10">
      <div class="row">
        <div class="col">
          <h3>Paste a list of comma separated words</h3>
          <mat-form-field class="w-100">
            <textarea matInput rows="6" [(ngModel)]="text"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col center">
          <button class="btn-custom" [disabled]="text | isFalseOrNullOrUndefined" (click)="confirm()">Confirm</button>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        font-family: 'Titillium Web', sans-serif;
        width: fit-content;
        max-width: 400px;
        height: 70px;
      }
    `,
  ],
})
export class LoadPolicyWordsModalComponent {
  text: string;

  constructor(public dialogRef: MatDialogRef<LoadPolicyWordsModalComponent>) {}

  confirm() {
    const uniqueValues = new Set(this.text.toLowerCase().split(','));
    const sanitized = Array.from(uniqueValues)
      .map((it) => it.trim())
      .filter((it) => it !== '');
    this.dialogRef.close(sanitized);
  }
}
