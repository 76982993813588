import { JsonObject, JsonProperty } from 'json2typescript';
import { Pagination } from '../../../shared/pagination-model';

@JsonObject('StoreFilters')
export class StoreFilters extends Pagination {
  @JsonProperty('nameOrAccount', String, true) nameOrAccount: string = undefined;

  @JsonProperty('ownerId', Number, true) ownerId: number = undefined;
  @JsonProperty('accountId', Number, true) accountId: number = undefined;
  @JsonProperty('network', String, true) network: string = undefined;
  @JsonProperty('marketplaceId', Number, true) marketplaceId: number = undefined;
  @JsonProperty('storeGroupId', Number, true) storeGroupId: number = undefined;
}
