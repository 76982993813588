import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PublicationRuleStore,
  publicationRuleStoreFromFullStore,
} from '@models/configuration/rules/publication-rule/publication-rule-store-model';
import { StoreService } from '../../../../store-configuration/store.service';
import { FullStore, Store } from '@models/configuration/marketplace/store-model';
import { StoreFilters } from '@models/configuration/marketplace/filters/store-filters-model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-store-select-modal',
  templateUrl: './store-select-modal.component.html',
  styleUrls: ['./store-select-modal.component.css'],
})
export class StoreSelectModalComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('filterInput', { static: true }) filterInput: ElementRef;

  selectedStore: Store;
  resultsNumber = this.storeService.resultsNumber;
  storesMatTable: MatTableDataSource<FullStore> = new MatTableDataSource<FullStore>();
  displayedColumns = ['select', 'name', 'code', 'country', 'account', 'marketplaceName'];
  filters = new StoreFilters();

  constructor(
    public dialogRef: MatDialogRef<StoreSelectModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: PublicationRuleStore,
    private storeService: StoreService
  ) {}

  ngOnInit(): void {
    this.filters.pageSize = 5;
    this.loadStores();

    fromEvent(this.filterInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.filters.nameOrAccount = text;
        this.loadStores();
      });
  }

  loadPage() {
    this.filters.pageSize = this.paginator.pageSize;
    this.filters.pageNumber = this.paginator.pageIndex;
    this.loadStores();
  }

  confirm() {
    this.dialogRef.close(publicationRuleStoreFromFullStore(this.selectedStore));
  }

  selectStore(s: Store) {
    this.selectedStore = s;
  }

  private loadStores() {
    this.storeService.findByFilters(this.filters).subscribe((r) => (this.storesMatTable.data = r));
  }
}
