import { BarcodeTypes } from '@models/configuration/policies/policy-enums';

export enum RestrictionTargetType {
  POWER_SELLER = 'POWER_SELLER',
  GLOBAL = 'GLOBAL',
}

export const getAllowedRestrictionBarcodeTypes = (): string[] => {
  // For now, we don't allow to restrictions Marketplace barcodes.
  return Object.keys(BarcodeTypes).filter((it) => it !== 'MARKETPLACE_BARCODE');
};
