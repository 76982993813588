import { NgModule } from '@angular/core';
import { BlacklistService } from './blacklists/blacklist.service';
import { RestrictionsService } from './restrictions/restrictions.service';
import { EditPredicatesPolicyComponent } from './shared/edit-predicates-policy/edit-predicates-policy.component';
import { EditWordsPolicyComponent } from './shared/edit-words-policy/edit-words-policy.component';
import { EditProductPolicyComponent } from './shared/edit-product-policy/edit-product-policy.component';
import { SharedModule } from '../../../shared.module';
import { SelectPolicyTypeComponent } from './shared/select-policy-type/select-policy-type.component';
import { RestrictionTypeComponent } from './restrictions/shared/restriction-type.component';

@NgModule({
  declarations: [
    EditPredicatesPolicyComponent,
    EditWordsPolicyComponent,
    EditProductPolicyComponent,
    SelectPolicyTypeComponent,
    RestrictionTypeComponent,
  ],
  imports: [SharedModule],
  providers: [BlacklistService, RestrictionsService],
  exports: [
    EditPredicatesPolicyComponent,
    EditWordsPolicyComponent,
    EditProductPolicyComponent,
    SelectPolicyTypeComponent,
    SharedModule,
    RestrictionTypeComponent,
  ],
})
export class PoliciesModule {}
