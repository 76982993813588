import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MarketplaceDto } from '@models/configuration/marketplace/marketplace-model';
import { StoreService } from '../../../../store-configuration/store.service';
import { isNilty } from '@core/utils.service';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-search-marketplace',
  templateUrl: './search-marketplace.component.html',
  styleUrls: ['./search-marketplace.component.scss'],
})
export class SearchMarketplaceComponent implements OnInit {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  @Output()
  selectedMarketplace = new EventEmitter<MarketplaceDto>();

  currentSelectedMarketplace: MarketplaceDto;

  MAX_RESULTS = 20 - 1;

  searchString: string;

  allMarketplaces: MarketplaceDto[];
  filteredMarketplaces: MarketplaceDto[];

  constructor(private storeService: StoreService) {}

  ngOnInit(): void {
    this.storeService.getMarketplaces().subscribe((r) => {
      this.allMarketplaces = r;
      this.search(this.searchString);
    });

    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(map((event: any) => event.target.value))
      .subscribe((value: string) => {
        this.searchString = value;
        this.search(value);
      });
  }

  selectedSearch(m: MarketplaceDto) {
    this.selectedMarketplace.emit(m);
    this.currentSelectedMarketplace = m;
  }

  search(value: string) {
    if (isNilty(value)) {
      this.filteredMarketplaces = this.allMarketplaces.slice(0, this.MAX_RESULTS);
    } else {
      this.filteredMarketplaces = this.allMarketplaces
        .filter((m) => m.name.toUpperCase().indexOf(value.toUpperCase()) !== -1)
        .slice(0, this.MAX_RESULTS);
    }
  }
}
