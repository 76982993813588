import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PublicationHistory } from 'src/app/models/publications/publication-model';

@Component({
  selector: 'app-parent-history-modal',
  templateUrl: './parent-history-modal.component.html',
  styleUrls: ['./parent-history-modal.component.scss'],
})
export class ParentHistoryModalComponent implements OnInit {
  feedHistory = this.data?.feedHistory?.length !== 0;
  statusChangeLog = this.data?.statusChangeLog?.length !== 0;
  matDialogTitleH1;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: PublicationHistory
  ) {}

  checkMatDialogTitle = () => {
    if (this.feedHistory && this.statusChangeLog) {
      return 'History';
    } else if (this.feedHistory && !this.statusChangeLog) {
      return 'Feed History';
    } else if (!this.feedHistory && this.statusChangeLog) {
      return 'Status History';
    }
  };

  ngOnInit() {
    this.matDialogTitleH1 = this.checkMatDialogTitle();
  }
}
