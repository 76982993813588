/* eslint-disable */
export enum ConfigurationTypes {
  REPRICING_SETTINGS = 'REPRICING_SETTINGS',
  MWS_THROTTLING_HANDLING = 'MWS_THROTTLING_HANDLING',
}

export class MwsFeedThresholds {
  operation: string;
  minimalPageSize: number;
  maximalWaitMinutes: number;
}

export class MwsThrottlingSettings {
  enableFeedThresholds: boolean;
  feedMinimalThresholds: MwsFeedThresholds[];

  enableCountBasedOperationPriority: boolean;
  priorityMultipliers: any;
}
