import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { isNilty } from '@core/utils.service';
import { PieSeriesOption, TitleComponentOption } from 'echarts';
import { PieChart } from 'echarts/charts';
import { TitleComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

@Component({
  selector: 'app-pa-pie-chart',
  templateUrl: './pa-pie-chart.component.html',
  styleUrls: ['./pa-pie-chart.component.css'],
})
export class PaPieChartComponent implements AfterViewInit, OnChanges {
  @Input() data: any;

  @ViewChild('chartElem') chartElem: ElementRef;

  firstInit = false;

  ngAfterViewInit() {
    this.chartInit();
  }

  ngOnChanges() {
    if (this.firstInit && !isNilty(this.data)) this.chartInit();
  }

  chartInit() {
    this.firstInit = true;
    echarts.use([TitleComponent, PieChart, LabelLayout, CanvasRenderer]);

    type EChartsPieOption = echarts.ComposeOption<TitleComponentOption | PieSeriesOption>;

    const chartDom = this.chartElem.nativeElement;
    chartDom.style.height = '500px';
    const myChart = echarts.init(chartDom);

    const datas = [
      [
        { name: 'Published', value: this.data.published },
        { name: 'Not Published', value: this.data.notPublished },
        { name: 'New', value: this.data.new },
        { name: 'Creating', value: this.data.creating },
        { name: 'Disabled', value: this.data.disabled },
        { name: 'Blacklisted', value: this.data.blacklisted },
        { name: 'Error', value: this.data.error },
        { name: 'Pod Published', value: this.data.podPublished },
        { name: 'Pod Not Published', value: this.data.podNotPublished },
        { name: 'Errors', value: this.data.errors },
      ]
        .filter((it) => it.value !== 0)
        .map((it) => ({ name: it.name, value: it.value })),
    ];

    const option: EChartsPieOption = {
      series: datas.map((data) => {
        const top = 0;
        return {
          type: 'pie',
          radius: [100, 150],
          top: top + '%',
          height: '100%',
          left: 'center',
          width: '100%',
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 1,
            borderRadius: 3,
          },
          label: {
            alignTo: 'edge',
            formatter: `{name|{b}}\n{value|{c}} `,
            minMargin: 5,
            edgeDistance: 10,
            lineHeight: 18,
            rich: {
              name: {
                fontSize: 14,
                color: '#00275c',
              },
              value: {
                fontSize: 14,
                color: '#000',
              },
            },
          },
          labelLine: {
            length: 15,
            length2: 0,
            maxSurfaceAngle: 80,
          },
          labelLayout: (params) => {
            const points = params.labelLinePoints as number[][];
            return {
              labelLinePoints: points,
            };
          },
          data,
        };
      }),
    };

    if (option) myChart.setOption(option);
  }
}
