import { JsonObject, JsonProperty } from 'json2typescript';
import { Status } from './status-model';
import { Product } from './product-model';

@JsonObject('ProductBarcode')
export class ProductBarcode {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('value', String, true) value: string = undefined;
  @JsonProperty('barcodeType', Status, true) barcodeType: Status = undefined;
  @JsonProperty('product', Product, true) product: Product = undefined;
}
