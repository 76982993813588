import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { CommonsService } from '../commons.service';
import { Currency } from '../../models/currency-model';
import { isNilty } from '../../core/utils.service';

@Pipe({
  name: 'myCurrencyPipe',
})
export class MyCurrencyPipe implements PipeTransform {
  private currencies: Currency[];

  constructor(private decimalPipe: DecimalPipe, private currencyService: CommonsService) {
    this.currencyService.getCurrencies().subscribe((r) => (this.currencies = r));
  }

  transform(value: number, currencyCode?: string, comma = true): string {
    if (isNilty(value)) {
      return '';
    }
    const symbol = this.currencies.find((it) => it.code === currencyCode);
    return comma
      ? `${this.decimalPipe.transform(value, '1.2-2')} ${symbol ? symbol.symbol : ''}`
      : `${this.decimalPipe.transform(value, '1.2-2').toString().replace(/,/g, '')} ${symbol ? symbol.symbol : ''}`;
  }
}
