import { JsonObject, JsonProperty } from 'json2typescript';
import { RulePredicate } from '../rule-predicate-model';
import { RuleProfit } from './rule-profit-model';

@JsonObject('CustomProfit')
export class CustomProfit {
  @JsonProperty('predicates', [RulePredicate], true) predicates: RulePredicate[] = [];
  @JsonProperty('profit', RuleProfit, true) profit: RuleProfit = undefined;

  profitDescription: string;

  constructor() {
    this.profit = new RuleProfit();
  }

  setDescription() {
    this.predicates.forEach((it) => it.setDescription());
    this.profitDescription = `Target profit: ${this.profit.target} - ${this.predicates.map((it) => it.description).join(' AND ')}`;
  }
}
