import { Component, Input } from '@angular/core';
import { Restriction } from '@models/configuration/policies/restrictions/restrictions-model';

@Component({
  selector: 'app-restrictions-type',
  template: `
    <ng-container *ngIf="!editMode">
      <ng-container [ngSwitch]="restriction.restrictionType">
        <div class="spacing-row boxed red" *ngSwitchCase="PRODUCT_RESTRICTION">RESTRICTION</div>
        <div class="spacing-row boxed blue" *ngSwitchCase="SALES_STRATEGY">STRATEGY</div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="editMode">
      <mat-button-toggle-group [(ngModel)]="restriction.restrictionType">
        <mat-button-toggle [value]="SALES_STRATEGY">STRATEGY</mat-button-toggle>
        <mat-button-toggle [value]="PRODUCT_RESTRICTION">RESTRICTION</mat-button-toggle>
      </mat-button-toggle-group>
    </ng-container>
  `,
  styles: [
    `
      .boxed {
        display: inline;
        padding: 2px 6px !important;
        border-radius: 6px;
        border: 1px black solid;
        width: fit-content;
      }
      .red {
        background: #f9b269;
        color: white;
        border-color: white;
      }
      .blue {
        background: #70c6ff;
        color: white;
        border-color: white;
      }
    `,
  ],
})
export class RestrictionTypeComponent {
  @Input() restriction: Restriction;
  @Input() editMode = false;

  PRODUCT_RESTRICTION = 'PRODUCT_RESTRICTION';
  SALES_STRATEGY = 'SALES_STRATEGY';
}
