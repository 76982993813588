import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConverterService } from '../../../core/converter.service';
import { EnvironmentService } from '../../../core/environment/environment.service';
import { Role } from '../../../models/role-model';
import { Permission } from '../../../models/security/permission-model';

@Injectable()
export class RolesService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getRoles(): Observable<Role[]> {
    return this.http
      .get(this.environmentService.getPonchRestEndpoint('roles'))
      .pipe(map((rolesResp: Role[]) => rolesResp.map((role) => this.converter.fromJSONtoObj(role, Role))));
  }

  getRole(id: string): Observable<Role> {
    return this.http
      .get(this.environmentService.getPonchRestEndpoint('roles') + '/' + id)
      .pipe(map((role: Role) => this.converter.fromJSONtoObj(role, Role)));
  }

  saveRole(role: Role): Observable<boolean> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(role);
    return this.http.post(this.environmentService.getPonchRestEndpoint('roles'), body, { headers }).pipe(map(() => true));
  }

  deleteRole(id: string): Observable<boolean> {
    return this.http.delete(this.environmentService.getPonchRestEndpoint('roles') + '/' + id).pipe(map(() => true));
  }

  getPermissions(application: string): Observable<Permission[]> {
    return this.http
      .get(this.environmentService.getPonchRestEndpoint('permissions') + '?application=' + application)
      .pipe(
        map((permissionsResp: Permission[]) => permissionsResp.map((permission) => this.converter.fromJSONtoObj(permission, Permission)))
      );
  }

  getApplications(): Observable<string[]> {
    return this.http.get<string[]>(this.environmentService.getPonchRestEndpoint('applications'));
  }

  duplicateRoles(fromApplication: string, toApplication: string): Observable<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify({ fromApplication, toApplication });
    return this.http.post(this.environmentService.getPonchRestEndpoint('duplicateRoles'), body, { headers });
  }
}
