import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { CountriesService } from '@shared/countries.service';
import { SharedModule } from 'src/app/shared.module';
import { AdministrationComponent } from './administration/administration.component';
import { AdministrationService } from './administration/administration.service';
import { MarkusConfigurationComponent } from './administration/markus-configuration/markus-configuration.component';
import { MarkusConfigurationService } from './administration/markus-configuration/markus-configuration.service';
import { MwsThrottlingSettingsComponent } from './administration/markus-configuration/mws-throttling-settings/mws-throttling-settings.component';
import { RepricingSettingsComponent } from './administration/markus-configuration/repricing-settings/repricing-settings.component';
import { PublicationsJobsComponent } from './administration/publications-jobs/publications-jobs.component';
import { PublicationsJobsService } from './administration/publications-jobs/publications-jobs.service';
import { SoupFeedsFiltersComponent } from './administration/soup-feeds/soup-feeds-filters/soup-feeds-filters.component';
import { SoupFeedsComponent } from './administration/soup-feeds/soup-feeds.component';
import { CatalogImportExportComponent } from './catalog-import-export/catalog-import-export.component';
import { AddCustomMetadataFieldModalComponent } from './catalog-import-export/custom-metadata/add-custom-metadata-field/add-custom-metadata-field-modal.component';
import { CustomMetadataComponent } from './catalog-import-export/custom-metadata/custom-metadata.component';
import { CustomMetadataService } from './catalog-import-export/custom-metadata/custom-metadata.service';
import { EditCustomMetadataFieldModalComponent } from './catalog-import-export/custom-metadata/edit-custom-metadata-field/edit-custom-metadata-field-modal.component';
import { GenericImportComponent } from './catalog-import-export/generic-import/generic-import.component';
import { InsertTemplateNameModalComponent } from './catalog-import-export/generic-import/insert-template-name-component/insert-template-name-modal.component';
import { SelectColumnPositionModalComponent } from './catalog-import-export/generic-import/select-column-position-modal/select-column-position-modal.component';
import { MetadataExportSourceComponent } from './catalog-import-export/metadata-export/metadata-export-source/metadata-export-source.component';
import { EditColumnNameModalComponent } from './catalog-import-export/metadata-export/metadata-export-template/edit-column-name-modal/edit-column-name-modal.component';
import { EditPositionAndNameModalComponent } from './catalog-import-export/metadata-export/metadata-export-template/edit-position-name-modal/edit-position-name-modal.component';
import { EditRuleModalComponent } from './catalog-import-export/metadata-export/metadata-export-template/edit-rule-modal/edit-rule-modal.component';
import { ExportSelectColumnPositionModalComponent } from './catalog-import-export/metadata-export/metadata-export-template/export-select-column-position-modal/export-select-column-position-modal.component';
import { ExportTemplateNameModalComponent } from './catalog-import-export/metadata-export/metadata-export-template/export-template-name-modal/export-template-name-modal.component';
import { MetadataExportTemplateComponent } from './catalog-import-export/metadata-export/metadata-export-template/metadata-export-template.component';
import { MetadataExportUploadComponent } from './catalog-import-export/metadata-export/metadata-export-upload/metadata-export-upload.component';
import { MetadataExportComponent } from './catalog-import-export/metadata-export/metadata-export.component';
import { MetadataExportService } from './catalog-import-export/metadata-export/metadata-export.service';
import { ConfigurationMenuComponent } from './configuration-menu/configuration-menu.component';
import { ConfigurationRoutingModule } from './configuration-routing.module';
import { ConfigurationComponent } from './configuration.component';
import { DisabledCatalogsTableComponent } from './disabled-catalogs/disabled-catalogs-table/disabled-catalogs-table.component';
import { DisabledCatalogsComponent } from './disabled-catalogs/disabled-catalogs.component';
import { ExchangeRatesComponent } from './exchange-rates/exchange-rates.component';
import { ExchangeRatesService } from './exchange-rates/exchange-rates.service';
import { FeesConfigurationComponent } from './fees-configuration/fees-configuration.component';
import { FeesConfigurationService } from './fees-configuration/fees-configuration.service';
import { EditMarketplaceCategoryFeeModalComponent } from './fees-configuration/marketplace-category-fees-configuration/edit-marketplace-category-fee-modal/edit-marketplace-category-fee-modal.component';
import { MarketplaceCategoryFeesConfigurationComponent } from './fees-configuration/marketplace-category-fees-configuration/marketplace-category-fees-configuration.component';
import { EditNetworkCategoryFeeModalComponent } from './fees-configuration/network-category-fees-configuration/edit-network-category-fee-modal/edit-network-category-fee-modal.component';
import { NetworkCategoryFeesConfigurationComponent } from './fees-configuration/network-category-fees-configuration/network-category-fees-configuration.component';
import { EditStoreFeesComponent } from './fees-configuration/store-fees-configuration/edit-store-fees/edit-store-fees.component';
import { StoreFeesConfigurationComponent } from './fees-configuration/store-fees-configuration/store-fees-configuration.component';
import { MarkusRulesModule } from './markus-rules/markus-rules.module';
import { StoreSelectModalComponent } from './markus-rules/publication-rules/publication-rule-edit/store-select-modal/store-select-modal.component';
import { MetadataPriorityComponent } from './metadata-priority/metadata-priority.component';
import { MetadataPriorityService } from './metadata-priority/metadata-priority.service';
import { PermissionNewComponent } from './permissions/permission-new/permission-new.component';
import { PermissionsListComponent } from './permissions/permissions-list/permissions-list.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { BlacklistsModule } from './policies/blacklists/blacklists.module';
import { RestrictionsModule } from './policies/restrictions/restrictions.module';
import { PublishedSourcesComponent } from './published-sources/published-sources.component';
import { PublishedSourcesService } from './published-sources/published-sources.service';
import { RoleEditComponent } from './roles/role-edit/role-edit.component';
import { RolesDuplicateModalComponent } from './roles/roles-duplicate-modal/roles-duplicate-modal.component';
import { RolesListComponent } from './roles/roles-list/roles-list.component';
import { RolesComponent } from './roles/roles.component';
import { RolesService } from './roles/roles.service';
import { AccountSelectModalComponent } from './sales-order-poll-configuration/account-select-modal/account-select-modal.component';
import { SalesOrderPollConfigurationModule } from './sales-order-poll-configuration/sales-order-poll-configuration.module';
import { SellingCompetitorsComponent } from './selling-competitors/selling-competitors.component';
import { SellingCompetitorsService } from './selling-competitors/selling-competitors.service';
import { FtpEndpointsModalComponent } from './soup-configuration/ftp-endpoints/ftp-endpoints-modal/ftp-endpoints-modal.component';
import { FtpEndpointsComponent } from './soup-configuration/ftp-endpoints/ftp-endpoints.component';
import { FtpEndpointsService } from './soup-configuration/ftp-endpoints/ftp-endpoints.service';
import { SoupConfigurationModule } from './soup-configuration/soup-configuration.module';
import { StoreConfigurationModule } from './store-configuration/store-configuration.module';
import { UkExchangeRatesComponent } from './uk-exchange-rates/uk-exchange-rates.component';
import { UkExchangeRatesService } from './uk-exchange-rates/uk-exchange-rates.service';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { UsersComponent } from './users/users.component';
import { UsersService } from './users/users.service';

@NgModule({
  declarations: [
    ConfigurationComponent,
    ConfigurationMenuComponent,
    FtpEndpointsComponent,
    FtpEndpointsModalComponent,
    StoreFeesConfigurationComponent,
    EditStoreFeesComponent,
    FeesConfigurationComponent,
    MarketplaceCategoryFeesConfigurationComponent,
    StoreSelectModalComponent,
    AccountSelectModalComponent,
    EditMarketplaceCategoryFeeModalComponent,
    UsersComponent,
    UsersListComponent,
    UserEditComponent,
    RolesComponent,
    RolesListComponent,
    RoleEditComponent,
    DisabledCatalogsComponent,
    DisabledCatalogsTableComponent,
    NetworkCategoryFeesConfigurationComponent,
    EditNetworkCategoryFeeModalComponent,
    AdministrationComponent,
    SoupFeedsComponent,
    PublicationsJobsComponent,
    MarkusConfigurationComponent,
    RepricingSettingsComponent,
    MwsThrottlingSettingsComponent,
    ExchangeRatesComponent,
    UkExchangeRatesComponent,
    SellingCompetitorsComponent,
    SoupFeedsFiltersComponent,
    PublishedSourcesComponent,
    PermissionsComponent,
    PermissionsListComponent,
    PermissionNewComponent,
    RolesDuplicateModalComponent,
    MetadataPriorityComponent,
    CustomMetadataComponent,
    CatalogImportExportComponent,
    MetadataExportComponent,
    MetadataExportSourceComponent,
    MetadataExportTemplateComponent,
    ExportSelectColumnPositionModalComponent,
    EditColumnNameModalComponent,
    MetadataExportUploadComponent,
    ExportTemplateNameModalComponent,
    EditRuleModalComponent,
    EditPositionAndNameModalComponent,
    AddCustomMetadataFieldModalComponent,
    EditCustomMetadataFieldModalComponent,
    InsertTemplateNameModalComponent,
    GenericImportComponent,
    SelectColumnPositionModalComponent,
  ],
  imports: [
    SharedModule,
    ConfigurationRoutingModule,
    SoupConfigurationModule,
    StoreConfigurationModule,
    MarkusRulesModule,
    SalesOrderPollConfigurationModule,
    BlacklistsModule,
    RestrictionsModule,
    DragDropModule,
  ],
  providers: [
    FtpEndpointsService,
    FeesConfigurationService,
    UsersService,
    RolesService,
    AdministrationService,
    MarkusConfigurationService,
    ExchangeRatesService,
    UkExchangeRatesService,
    SellingCompetitorsService,
    PublicationsJobsService,
    PublishedSourcesService,
    CountriesService,
    MetadataPriorityService,
    CustomMetadataService,
    MetadataExportService,
  ],
  exports: [],
})
export class ConfigurationModule {}
