import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppPreloadingStrategy } from './app-loading-strategy';
import { CatalogItemsDetailsComponent } from './container/catalog-items/catalog-items-details/catalog-items-details.component';
import { CatalogItemsListsComponent } from './container/catalog-items/catalog-items-list/catalog-items-lists.component';
import { CatalogItemsComponent } from './container/catalog-items/catalog-items.component';
import { ContainerComponent } from './container/container.component';
import { EquivalenceProductsListComponent } from './container/products/equivalence-products-list/equivalence-products-list.component';
import { ProductDetailsComponent } from './container/products/products-list/product-details/product-details.component';
import { ProductsListComponent } from './container/products/products-list/products-list.component';
import { ProductsComponent } from './container/products/products.component';
import { PublicationsComponent } from './container/publications/publications.component';
import { StockItemsComponent } from './container/stock-items/stock-items.component';
import { StockLocationsComponent } from './container/stock-locations/stock-locations.component';
import { EditSupplierComponent } from './container/suppliers/edit-supplier.component';
import { SuppliersComponent } from './container/suppliers/suppliers.component';
import { ConfigurationModule } from './container/configuration/configuration.module';

const routes: Routes = [
  {
    path: '',
    component: ContainerComponent,
    children: [
      { path: 'suppliers', component: SuppliersComponent },
      { path: 'edit-supplier', component: EditSupplierComponent },
      { path: 'new-supplier', component: EditSupplierComponent },
      { path: 'stock-locations', component: StockLocationsComponent },
      { path: 'stock-items', component: StockItemsComponent },
      {
        path: 'catalog-items',
        component: CatalogItemsComponent,
        children: [
          { path: '', component: CatalogItemsListsComponent },
          { path: 'details/:id', component: CatalogItemsDetailsComponent },
        ],
      },
      {
        path: 'products',
        component: ProductsComponent,
        children: [
          { path: '', component: ProductsListComponent, pathMatch: 'full' },
          { path: 'details/:barcode', component: ProductDetailsComponent },
          { path: 'equivalence', component: EquivalenceProductsListComponent },
        ],
      },
      { path: 'publications', component: PublicationsComponent },
      {
        path: 'configuration',
        loadChildren: () => import('./container/configuration/configuration.module').then(() => ConfigurationModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: AppPreloadingStrategy })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
