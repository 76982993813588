import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('SupplierFeedReadingStatus')
export class SupplierFeedReadingStatus {
  @JsonProperty('distrName', String, true) distrName: string = undefined;
  @JsonProperty('identifier', String, true) identifier: string = undefined;
  @JsonProperty('fileName', String, true) fileName: string = undefined;
  @JsonProperty('startedBySoup', String, true) startedBySoup: string = undefined;
  @JsonProperty('finishedBySoup', String, true) finishedBySoup: string = undefined;
  @JsonProperty('startedByZion', String, true) startedByZion: string = undefined;
  @JsonProperty('readingStatus', String, true) readingStatus: string = undefined;
  @JsonProperty('totalMessages', Number, true) totalMessages: number = undefined;
  @JsonProperty('totalItems', Number, true) totalItems: number = undefined;
  @JsonProperty('importErrorMessage', String, true) importErrorMessage: string = undefined;
  @JsonProperty('skip', Boolean, true) skip: boolean = undefined;
  @JsonProperty('fullFeed', Boolean, true) fullFeed: boolean = undefined;
  @JsonProperty('supplier', String, true) supplier: string = undefined;

  sanitizedStatus: string;
  statusColor: string;

  sanitizeStatus() {
    this.sanitizedStatus = this.readingStatus.replace('_', ' ');
    switch (this.readingStatus) {
      case 'READING_FILE': {
        this.statusColor = 'blue';
        return;
      }
      case 'IN_QUEUE': {
        this.statusColor = 'orange';
        return;
      }
      case 'UPDATING_CATALOG': {
        this.statusColor = 'green';
        return;
      }
    }
  }
}
