import { Injectable } from '@angular/core';
import { ConverterService } from '../../../core/converter.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { EnvironmentService } from '../../../core/environment/environment.service';
import { StoreFeesFilters } from '../../../models/configuration/marketplace/filters/store-fees-filters-model';
import { StoreFeesConfiguration } from '../../../models/configuration/marketplace/store-fees-configuration-model';
import { map } from 'rxjs/operators';
import { ZionStore } from '../../../models/publications/published-store-model';
import { MarketplaceCategoryFee, NetworkCategoryFee } from '../../../models/configuration/marketplace/marketplace-category-fee-model';
import {
  MarketplaceCategoryFeesFilters,
  NetworkCategoryFeesFilters,
} from '../../../models/configuration/marketplace/filters/category-fees-filters-model';

@Injectable()
export class FeesConfigurationService {
  resultsNumber = new Subject<number>();
  categoryFeesResultsNumber = new Subject<number>();

  constructor(private converterService: ConverterService, private http: HttpClient, private environmentService: EnvironmentService) {}

  saveStoreFees(s: StoreFeesConfiguration): Observable<any> {
    return this.http.post(this.environmentService.getMarkusRestEndpoint('saveStoreFees'), this.converterService.fromObjtoJSON(s));
  }

  findByFilters(filters: StoreFeesFilters): Observable<StoreFeesConfiguration[]> {
    return this.http
      .post(this.environmentService.getMarkusRestEndpoint('findStoreFeesConfigurations'), this.converterService.fromObjtoJSON(filters), {
        observe: 'response',
      })
      .pipe(
        map((resp: HttpResponse<any>) => {
          this.resultsNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        }),
        map((r: StoreFeesConfiguration[]) => r.map((it) => this.converterService.fromJSONtoObj(it, StoreFeesConfiguration)))
      );
  }

  findAllStores(): Observable<ZionStore[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('findAll'))
      .pipe(map((r: ZionStore[]) => r.map((it) => this.converterService.fromJSONtoObj(it, ZionStore))));
  }

  findMarketplaceCategoryFees(filters: MarketplaceCategoryFeesFilters): Observable<MarketplaceCategoryFee[]> {
    return this.http
      .post(this.environmentService.getMarkusRestEndpoint('marketplaceCategoryFees'), this.converterService.fromObjtoJSON(filters), {
        observe: 'response',
      })
      .pipe(
        map((resp: HttpResponse<any>) => {
          this.categoryFeesResultsNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        }),
        map((r: MarketplaceCategoryFee[]) => r.map((it) => this.converterService.fromJSONtoObj(it, MarketplaceCategoryFee)))
      );
  }

  findNetworkCategoryFees(filters: NetworkCategoryFeesFilters): Observable<NetworkCategoryFee[]> {
    return this.http
      .post(this.environmentService.getMarkusRestEndpoint('networkCategoryFees'), this.converterService.fromObjtoJSON(filters), {
        observe: 'response',
      })
      .pipe(
        map((resp: HttpResponse<any>) => {
          this.categoryFeesResultsNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        }),
        map((r: NetworkCategoryFee[]) => r.map((it) => this.converterService.fromJSONtoObj(it, NetworkCategoryFee)))
      );
  }

  saveMarketplaceCategoryFees(cf: MarketplaceCategoryFee): Observable<any> {
    return this.http.post(
      this.environmentService.getMarkusRestEndpoint('saveMarketplaceCategoryFees'),
      this.converterService.fromObjtoJSON(cf)
    );
  }
  saveNetworkCategoryFees(cf: NetworkCategoryFee): Observable<any> {
    return this.http.post(
      this.environmentService.getMarkusRestEndpoint('saveNetworkCategoryFees'),
      this.converterService.fromObjtoJSON(cf)
    );
  }
}
