import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-repricing-settings',
  templateUrl: './repricing-settings.component.html',
  styleUrls: ['./repricing-settings.component.css'],
})
export class RepricingSettingsComponent implements OnInit {
  @Input()
  settings: any;

  ngOnInit(): void {
    console.log(this.settings);
  }
}
