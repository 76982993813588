import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@core/environment/environment.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConverterService } from '@core/converter.service';
import { PublicationEnumsOutput } from '@models/publications/publication-enums-output-model';
import { hideLoaderHeaders } from '@core/utils.service';
import { PublicationFilters } from '@models/publications/filters/publication-filters-model';
import { PublicationDto } from '@models/publications/publication-dto-model';
import { cachedGet, GenericCache } from '@shared/service-cached';
import { PublicationsCounts } from '@models/publications/publications-counts-model';
import { Publication, PublicationHistory } from '@models/publications/publication-model';
import { CompetitionData } from '@models/publications/competition-data-model';
import { Status } from '@models/status-model';
import { RefreshPublicationInputModel } from '@models/publications/refresh-publication-input-model';

@Injectable()
export class PublicationService {
  selectedSearchTab = 0;

  byStoreFilters: PublicationFilters;
  byBarcodeFilters: PublicationFilters;
  resultsNumber = new BehaviorSubject<number>(Number(localStorage.getItem('paginationPageSize')));

  tempFilters: PublicationFilters;

  private enums = new GenericCache<PublicationEnumsOutput>(this.http, this.converterService);

  constructor(private http: HttpClient, private converterService: ConverterService, private environmentService: EnvironmentService) {}

  getPublicationsEnums(): Observable<PublicationEnumsOutput> {
    return cachedGet<PublicationEnumsOutput>(this.enums, this.environmentService.getMarkusRestEndpoint('publicationsEnums'));
  }

  getPublicationsCountByStore(storeCode: string): Observable<PublicationsCounts> {
    return this.http
      .get(this.environmentService.getMarkusRestEndpoint('publicationCountByStore') + storeCode, { headers: hideLoaderHeaders() })
      .pipe(map((r) => this.converterService.fromJSONtoObj(r, PublicationsCounts)));
  }

  getPublicationsByStoreAndFilters(storeCode: string, publicationFilters: PublicationFilters): Observable<PublicationDto[]> {
    return this.http
      .post(
        this.environmentService.getMarkusRestEndpoint('publicationsByStore') + storeCode,
        this.converterService.fromObjtoJSON(publicationFilters),
        { observe: 'response' }
      )
      .pipe(
        map((resp) => {
          const getResultsLength = (respBody) => {
            return Object.keys(respBody).length;
          };

          const updateResultsNumber = (resultsNumber, value) => {
            if (resultsNumber.value === 0) {
              resultsNumber.next(value + 1);
            } else {
              resultsNumber.next(resultsNumber.value + value);
            }
          };

          const updateResultsNumberIfNotEmpty = (resultsNumber, respBody) => {
            const resultsLength = getResultsLength(respBody);

            if (resultsLength !== 0) {
              updateResultsNumber(resultsNumber, resultsLength);
            } else {
              resultsNumber.next(0);
            }
          };

          if (this.tempFilters === publicationFilters) {
            updateResultsNumberIfNotEmpty(this.resultsNumber, resp.body);
          } else {
            const resultsLength = getResultsLength(resp.body);
            if (resultsLength === Number(localStorage.getItem('paginationPageSize'))) {
              this.resultsNumber.next(resultsLength + 1);
            } else {
              this.resultsNumber.next(resultsLength);
            }
            this.tempFilters = publicationFilters;
          }

          return resp.body;
        }),
        map((r: PublicationDto[]) => r.map((it) => this.converterService.fromJSONtoObj(it, PublicationDto)))
      );
  }

  getPublicationsByProductBarcodeAndFilters(barcode: string, publicationFilters: PublicationFilters): Observable<PublicationDto[]> {
    return this.http
      .post(
        this.environmentService.getMarkusRestEndpoint('publicationsByBarcode') + barcode,
        this.converterService.fromObjtoJSON(publicationFilters),
        { observe: 'response' }
      )
      .pipe(
        map((resp) => {
          this.resultsNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        }),
        map((r: PublicationDto[]) => r.map((it) => this.converterService.fromJSONtoObj(it, PublicationDto)))
      );
  }

  getPublicationHistory(publicationId: string, storeCode: string): Observable<PublicationHistory> {
    return this.http
      .get(this.environmentService.getMarkusRestEndpoint('publications') + `/${storeCode}/history/${publicationId}`)
      .pipe(map((r: PublicationHistory) => this.converterService.fromJSONtoObj(r, PublicationHistory)));
  }

  refreshSinglePublication(p: Publication, bruteRefresh: boolean): Observable<any> {
    const body = new RefreshPublicationInputModel(p.sku, p.store.code, bruteRefresh);
    return this.http.post(this.environmentService.getMarkusRestEndpoint('refreshPublication'), this.converterService.fromObjtoJSON(body));
  }

  exportPublications(storeCode: string, filters: PublicationFilters): Observable<any> {
    return this.http.post(
      this.environmentService.getMarkusRestEndpoint('exportPublications') + storeCode,
      this.converterService.fromObjtoJSON(filters)
    );
  }

  getCompetitionData(publication: Publication): Observable<CompetitionData> {
    return this.http
      .get(
        this.environmentService.getMarkusRestEndpoint('competitionData') +
          '?marketplaceName=' +
          publication.store.marketplaceName +
          '&marketplaceBarcode=' +
          publication.marketplaceMetadata.marketplaceBarcode
      )
      .pipe(map((r: CompetitionData) => this.converterService.fromJSONtoObj(r, CompetitionData)));
  }

  getBuyBoxDistances(): Observable<Status[]> {
    return this.http
      .get(this.environmentService.getMarkusRestEndpoint('publications') + '/buy-box-distances')
      .pipe(map((r: Status[]) => r.map((it) => this.converterService.fromJSONtoObj(it, Status))));
  }

  loadAnomalouslyPricedFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    const path = this.environmentService.getMarkusRestEndpoint('publicationsAnomalouslyPriced');
    return this.http.post(path, formData);
  }

  getMetrics(supplierCodes: string[], storeCode: string, dateFrom: string, dateTo: string): Observable<any> {
    const path = this.environmentService.getMarkusRestEndpoint('publicationMetrics') + '/find-by-filters';
    const body = {
      supplierCode: supplierCodes,
      storeCode,
      dateFrom,
      dateTo,
    };

    return this.http.post(path, body).pipe(map((r: any) => r));
  }
}
