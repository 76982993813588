import { Component, Input, OnInit } from '@angular/core';
import { SuppliersService } from '../../../../../../suppliers/suppliers.service';
import { MatDialog } from '@angular/material/dialog';
import { isNilty } from '@core/utils.service';
import { RuleSupplierSource, RuleSourceSkuType, RuleOrderType } from '@models/configuration/rules/publication-rule-source-model';
import { Supplier } from '@models/supplier-model';
import { GenericConfirmationModalComponent } from '@shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { Router } from '@angular/router';
import { MarginParameters } from '@models/configuration/rules/pricing-strategy-model';

@Component({
  selector: 'app-supplier-sources',
  templateUrl: './supplier-sources.component.html',
  styleUrls: ['./supplier-sources.component.scss'],
})
export class SupplierSourcesComponent implements OnInit {
  ruleSources: RuleSupplierSource[] = [];
  filteredRuleSources: RuleSupplierSource[] = [];

  filterKey: string;

  suppliers: Supplier[] = [];

  ruleSourceTypes = Object.keys(RuleSourceSkuType);

  ruleOrderTypes = Object.keys(RuleOrderType);

  isPowerSeller = false;

  constructor(private suppliersService: SuppliersService, private dialog: MatDialog, private router: Router) {}

  @Input() set _supplierSources(r: RuleSupplierSource[]) {
    this.powerSeller();
    this.ruleSources = r;
    this.initSources();
  }

  ngOnInit() {
    this.suppliersService.getAllSuppliers().subscribe((r) => {
      this.suppliers = r;
      this.initSources();
    });
  }
  powerSeller() {
    this.isPowerSeller = this.router.url.includes('power-seller-rules');
  }

  add() {
    const newSource = new RuleSupplierSource();
    this.ruleSources.push(newSource);
    this.filteredRuleSources.push(newSource);
  }

  filterSources(string?: string) {
    const sorted = this.sortedSources();
    if (isNilty(string)) {
      this.filteredRuleSources = sorted;
    } else {
      this.filteredRuleSources = sorted.filter((it) => it.supplierCode.toUpperCase().indexOf(string.toUpperCase()) !== -1);
    }
  }

  selectedSource(event: number, source: RuleSupplierSource) {
    this.assignDescription(source, event);
    this.validate(event, source);
  }

  removeSource(source: RuleSupplierSource) {
    this.dialog
      .open(GenericConfirmationModalComponent, {
        data: `This will remove the Source for ${source.supplierCode} and all its predicates.`,
      })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          const index = this.ruleSources.findIndex((it) => it.supplierCode === source.supplierCode);
          this.ruleSources.splice(index, 1);
          this.filterSources(this.filterKey);
        }
      });
  }

  addCustomPricing(source: RuleSupplierSource) {
    source.pricingParameters = new MarginParameters();
  }
  removeCustomPricing(source: RuleSupplierSource) {
    source.pricingParameters = undefined;
  }

  private initSources() {
    if (isNilty(this.ruleSources || isNilty(this.suppliers))) {
      return;
    }
    this.ruleSources.forEach((it) => {
      if (!isNilty(it.supplierId)) {
        this.assignDescription(it, it.supplierId);
      }
    });
    this.filterSources();
  }

  private assignDescription(source: RuleSupplierSource, supplierId: number) {
    const s = this.suppliers.find((it) => it.id === supplierId);
    if (!isNilty(s)) {
      source.supplierCode = s.code;
    }
  }

  private sortedSources(): RuleSupplierSource[] {
    return [...this.ruleSources].sort((i, j) => (i.supplierCode > j.supplierCode ? 1 : -1));
  }

  private validate(newId: number, newSource: RuleSupplierSource) {
    if (this.ruleSources.find((s) => s.supplierId === newId)) {
      newSource.valid = false;
      newSource.error = `There is already a source with this supplier`;
    } else {
      newSource.valid = true;
      newSource.error = undefined;
    }
  }
}
