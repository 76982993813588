import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('SqsNotificationCredentials')
export class SqsNotificationCredentials {
  @JsonProperty('queueName', String, true) queueName: string = undefined;
  @JsonProperty('accountId', String, true) accountId: string = undefined;
  @JsonProperty('accessKey', String, true) accessKey: string = undefined;
  @JsonProperty('accessSecret', String, true) accessSecret: string = undefined;
  @JsonProperty('awsRegion', String, true) awsRegion = 'EU_WEST_1';
}

@JsonObject('ChannelEngineCredentials')
export class ChannelEngineCredentials {
  @JsonProperty('apiKey', String, true) apiKey: string = undefined;
  @JsonProperty('baseUrl', String, true) baseUrl: string = undefined;
}

@JsonObject('AbebooksCredentials')
export class AbebooksCredentials {
  @JsonProperty('username', String, true) username: string = undefined;
  @JsonProperty('token', String, true) token: string = undefined;
}

@JsonObject('TikTokCredentials')
export class TikTokCredentials {
  @JsonProperty('shopId', String, true) shopId: string = undefined;
  @JsonProperty('shopCipher', String, true) shopCipher: string = undefined;
  @JsonProperty('appKey', String, true) appKey: string = undefined;
  @JsonProperty('appSecret', String, true) appSecret: string = undefined;
  @JsonProperty('authCode', String, true) authCode: string = undefined;
}

@JsonObject('AmazonSellerCredentials')
export class AmazonSellerCredentials {
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('sellerId', String, true) sellerId: string = undefined;
  @JsonProperty('accessKey', String, true) accessKey: string = undefined;
  @JsonProperty('secretKey', String, true) secretKey: string = undefined;
  @JsonProperty('mwsToken', String, true) mwsToken: string = undefined;
  @JsonProperty('appName', String, true) appName: string = undefined;
  @JsonProperty('appVersion', String, true) appVersion = '1.0';
  @JsonProperty('endpoint', String, true) endpoint = 'https://mws-eu.amazonservices.com';
  @JsonProperty('reportCronExpression', String, true) reportCronExpression = '* * * ? * *';

  @JsonProperty('enablePushNotifications', Boolean, true) enablePushNotifications = false;
  @JsonProperty('sqsNotificationCredentials', SqsNotificationCredentials, true)
  sqsNotificationCredentials: SqsNotificationCredentials = undefined;

  @JsonProperty('spApiEndpoint', String, true) spApiEndpoint: string = undefined;
  @JsonProperty('lwaClientId', String, true) lwaClientId: string = undefined;
  @JsonProperty('lwaSecretKey', String, true) lwaSecretKey: string = undefined;
  @JsonProperty('lwaRefreshToken', String, true) lwaRefreshToken: string = undefined;
  @JsonProperty('iamUserAccessKey', String, true) iamUserAccessKey: string = undefined;
  @JsonProperty('iamUserSecretKey', String, true) iamUserSecretKey: string = undefined;
  @JsonProperty('enableSpApi', Boolean, true) enableSpApi = false;
}
