import { Pipe, PipeTransform } from '@angular/core';
import { isNilty } from '../../core/utils.service';

@Pipe({
  name: 'truncateText',
})
export class TruncateTextPipe implements PipeTransform {
  transform(value: string, maxLength: number = 50): any {
    if (isNilty(value)) {
      return ' -- ';
    }
    if (value.length <= maxLength) {
      return value;
    }
    return value.substr(0, maxLength) + '…';
  }
}
