import { Component, Input } from '@angular/core';
import { RouteConfiguration } from '../../../../../../../models/configuration/soup-configuration/route-configuration-model';
import { B2bRouteConfiguration } from '../../../../../../../models/configuration/soup-configuration/route-configurations/b2b-route-configuration-model';

@Component({
  selector: 'app-b2b-route-configuration',
  templateUrl: './b2b-route-configuration.component.html',
  styleUrls: ['./b2b-route-configuration.component.css'],
})
export class B2bRouteConfigurationComponent {
  b2bRouteConfiguration: B2bRouteConfiguration;

  @Input() set _b2bRouteConfiguration(c: RouteConfiguration) {
    this.b2bRouteConfiguration = c as B2bRouteConfiguration;
  }
}
