import { ITooltipAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { PublicationPrice } from '../../../../../models/publications/publication-price-model';

@Component({
  selector: 'app-pending-tooltip-component',
  template: `
    <div class="custom-tooltip">
      <span class="margin-right-5 margin-left-5">{{ price.minimum }}</span>
      -
      <span class="margin-right-5 margin-left-5"
        ><b>{{ price.target }}</b></span
      >
      -
      <span class="margin-right-5 margin-left-5">{{ price.maximum }}</span>
      <span class="margin-right-5 margin-left-5">{{ price.currency | myCurrencyCodePipe }}</span>
      <br />
      <div class="text-center">
        <span class="margin-right-5 margin-left-5">(Click to show details)</span>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        font-family: 'Titillium Web', sans-serif;
        position: absolute;
        width: fit-content;
        max-width: 400px;
        height: 70px;
        pointer-events: none;
        transition: opacity 0.6s;
      }
      :host.ag-tooltip-hiding {
        opacity: 0;
      }
      .operation {
        font-weight: bold;
      }
    `,
  ],
})
export class PriceRangeTooltipComponent implements ITooltipAngularComp {
  price: PublicationPrice;

  agInit(params): void {
    this.price = params.value as PublicationPrice;
  }
}
