import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonsService } from '@shared/commons.service';
import { NetworkCategory } from '@models/network-category-model';
import { StoreService } from '../../store-configuration/store.service';
import { NetworkCategoryFeesFilters } from '@models/configuration/marketplace/filters/category-fees-filters-model';
import { isNilty } from '@core/utils.service';
import { FeesConfigurationService } from '../fees-configuration.service';
import { MatTableDataSource } from '@angular/material/table';
import { NetworkCategoryFee } from '@models/configuration/marketplace/marketplace-category-fee-model';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GenericErrorModalComponent } from '@shared/generic-error-modal/generic-error-modal.component';
import { EditNetworkCategoryFeeModalComponent } from './edit-network-category-fee-modal/edit-network-category-fee-modal.component';

@Component({
  selector: 'app-network-category-fees-configuration',
  templateUrl: './network-category-fees-configuration.component.html',
  styleUrls: ['./network-category-fees-configuration.component.scss'],
})
export class NetworkCategoryFeesConfigurationComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  displayedColumns = ['network', 'category', 'closingFee', 'percentageFee', 'minimalFee', 'edit'];
  dataSource = new MatTableDataSource<NetworkCategoryFee>();
  resultsNumber: Observable<number>;

  networks: string[];

  networkCategories: NetworkCategory[];
  allNetworkCategories: NetworkCategory[];

  filters = new NetworkCategoryFeesFilters();

  constructor(
    private commonsService: CommonsService,
    private storeConfigurationService: StoreService,
    private feesConfigurationService: FeesConfigurationService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.resultsNumber = this.feesConfigurationService.categoryFeesResultsNumber;
    this.commonsService.getNetworkCategories().subscribe((r) => {
      this.networkCategories = [...r];
      this.allNetworkCategories = [...r];
    });
    this.commonsService.getNetworks().subscribe((r) => (this.networks = r));
  }

  selectedNetwork(n: string) {
    if (isNilty(n)) {
      this.networkCategories = [...this.allNetworkCategories];
    } else {
      this.networkCategories = [...this.allNetworkCategories.filter((it) => it.network === n)];
    }
  }

  selectedCategory(c: string) {
    if (typeof c !== 'string') return;
    this.filters.network = this.allNetworkCategories.find((it) => it.serializedEnum === c).network;
  }

  loadPage() {
    this.filters.pageSize = this.paginator.pageSize;
    this.filters.pageNumber = this.paginator.pageIndex;
    this.search();
  }

  search() {
    if (isNilty(this.filters.network) && isNilty(this.filters.networkCategory)) {
      this.dataSource.data = [];
    } else {
      this.feesConfigurationService.findNetworkCategoryFees(this.filters).subscribe((r) => (this.dataSource.data = r));
    }
  }

  reset() {
    this.filters.network = undefined;
    this.filters = new NetworkCategoryFeesFilters();
    this.dataSource.data = [];
  }

  edit(ncf: NetworkCategoryFee) {
    this.dialog
      .open(EditNetworkCategoryFeeModalComponent, { data: ncf })
      .afterClosed()
      .subscribe((r) => {
        if (r) {
          this.search();
        }
      });
  }

  create() {
    if (isNilty(this.filters.networkCategory) || isNilty(this.filters.network)) {
      this.dialog.open(GenericErrorModalComponent, { data: 'Please select a Marketplace and a Category' });
      return;
    }

    this.feesConfigurationService.findNetworkCategoryFees(this.filters).subscribe((r) => {
      let ncf: NetworkCategoryFee;
      if (r.length === 0) {
        ncf = new NetworkCategoryFee();
        ncf.network = this.filters.network;
        ncf.networkCategory = this.filters.networkCategory;
      } else {
        ncf = r[0];
      }
      this.edit(ncf);
    });
  }
}
