import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { isNilty } from '@core/utils.service';
import { LineChart, LineSeriesOption } from 'echarts/charts';
import { GridComponent, GridComponentOption } from 'echarts/components';
import * as echarts from 'echarts/core';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

@Component({
  selector: 'app-pa-line-chart',
  templateUrl: './pa-line-chart.component.html',
  styleUrls: ['./pa-line-chart.component.css'],
})
export class PaLineChartComponent implements AfterViewInit, OnChanges {
  @Input() data: any;

  @ViewChild('chartElem') chartElem: ElementRef;

  firstInit = false;

  ngAfterViewInit() {
    this.chartInit();
  }

  ngOnChanges() {
    if (this.firstInit && !isNilty(this.data)) this.chartInit();
  }

  chartInit() {
    this.firstInit = true;
    echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);

    type EChartsOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption>;

    const chartDom = this.chartElem.nativeElement;
    chartDom.style.height = '350px';
    const myChart = echarts.init(chartDom);

    const option: EChartsOption = {
      xAxis: {
        type: 'category',
        data: this.data.map((it) => it.date),
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: this.data.map((it) => it.publicationInfo.published),
          type: 'line',
        },
      ],
    };

    if (option) myChart.setOption(option);
  }
}
