import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Publication } from '../../../../../models/publications/publication-model';
import { fromEvent } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { FeedHistoryEntry } from '../../../../../models/publications/feed-history-entry-model';
import { MatPaginator } from '@angular/material/paginator';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { isNilty } from '../../../../../core/utils.service';
//eslint-disable-next-line

@Component({
  selector: 'app-feed-history-modal',
  templateUrl: './feed-history-modal.component.html',
  styleUrls: ['./feed-history-modal.component.scss'],
})
export class FeedHistoryModalComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  @Input()
  feedHistory: FeedHistoryEntry[];

  displayedColumns = ['feedId', 'operation', 'marketplaceType', 'submitted', 'finished', 'quantity', 'latency', 'price', 'repriced'];

  dataSource: MatTableDataSource<FeedHistoryEntry>;

  constructor(
    public dialogRef: MatDialogRef<FeedHistoryModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: Publication
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource<FeedHistoryEntry>(this.feedHistory);
    this.dataSource.paginator = this.paginator;

    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        if (!isNilty(text)) {
          this.dataSource.data = this.feedHistory.filter((it) => it.feedId.toUpperCase().indexOf(text.toUpperCase()) !== -1);
        } else {
          this.dataSource.data = this.feedHistory;
        }
      });
  }
}
