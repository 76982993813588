import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SoupParser } from '../../../../../../models/configuration/soup-configuration/soup-parser-model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { isNilty } from '../../../../../../core/utils.service';

@Component({
  selector: 'app-soup-parser-modal',
  templateUrl: './soup-parser-modal.component.html',
  styleUrls: ['./soup-parser-modal.component.css'],
})
export class SoupParserModalComponent implements OnInit {
  selectedParser: SoupParser;
  selectedParserBean: string;
  genericParsers: SoupParser[];
  customParsers: SoupParser[];

  constructor(
    public dialogRef: MatDialogRef<SoupParserModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      parser: SoupParser;
      parsers: SoupParser[];
    }
  ) {}

  ngOnInit(): void {
    this.selectedParser = this.data.parser;
    this.genericParsers = this.data.parsers.filter((it) => !it.custom);
    this.customParsers = this.data.parsers.filter((it) => it.custom);
    if (!isNilty(this.selectedParser)) {
      this.selectedParserBean = this.selectedParser.beanName;
    }
  }

  changeParser(event: MatCheckboxChange, parser: SoupParser) {
    if (event.checked) {
      this.selectedParser = parser;
      this.selectedParserBean = this.selectedParser.beanName;
    } else {
      this.selectedParser = undefined;
      this.selectedParserBean = undefined;
    }
  }
}
