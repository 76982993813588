import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConverterService } from '../../core/converter.service';
import { EnvironmentService } from '../../core/environment/environment.service';
import { StockItemFilters } from '../../models/filters/stock-item';
import { StockItem } from '../../models/stock-item-model';
import { StockLocation } from '../../models/stock-location-model';

@Injectable()
export class StockItemsService {
  resultsNumber = new Subject<number>();
  totalPhysicalQuantity = new Subject<number>();
  totalAvailableQuantity = new Subject<number>();
  stockItemFilters: StockItemFilters;

  stockLocations: StockLocation[];

  constructor(
    private converter: ConverterService,
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private snackBar: MatSnackBar
  ) {}

  getFilteredStockItems(filters: StockItemFilters): Observable<StockItem[]> {
    const productBarcodeValue = filters.productBarcodeValue ?? '';
    const productBarcodeType = filters.productBarcodeType ?? '';
    const stockLocation = filters.stockLocation ?? '';
    const supplier = filters.supplier ?? '';
    const sku = filters.sku ?? '';
    const internalSku = filters.internalSku ?? '';
    const hasQuantity = filters.hasQuantity ?? false;
    const pageIndex = filters.pageNumber ?? 0;
    const pageSize = filters.pageSize ?? 10;
    const sortBy = filters.sortBy ?? '';
    const sortDirection = filters.sortDirection ?? 'desc';

    const queryPath =
      '?productBarcodeValue=' +
      productBarcodeValue +
      '&productBarcodeType=' +
      productBarcodeType +
      '&stockLocation=' +
      stockLocation +
      '&supplier=' +
      supplier +
      '&sku=' +
      sku +
      '&internalSku=' +
      internalSku +
      '&hasQuantity=' +
      hasQuantity +
      '&page-index=' +
      pageIndex +
      '&page-size=' +
      pageSize +
      '&sort-by=' +
      sortBy +
      '&sort-direction=' +
      sortDirection;

    return this.http.get(this.environmentService.getRestEndpoint('stockItems') + queryPath, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        this.totalPhysicalQuantity.next(+resp.headers.get('Total-Physical-Quantity'));
        this.totalAvailableQuantity.next(+resp.headers.get('Total-Available-Quantity'));
        return resp.body;
      }),
      map((resp: StockItem[]) => resp.map((r) => this.converter.fromJSONtoObj(r, StockItem)))
    );
  }

  getAllStockLocations(): Observable<StockLocation[]> {
    if (this.stockLocations) {
      return of(this.stockLocations);
    }

    return this.http
      .get(this.environmentService.getRestEndpoint('stockLocations') + '/all', { observe: 'response' })
      .pipe(
        map(
          (resp: HttpResponse<StockLocation[]>) =>
            (this.stockLocations = resp.body.map((r) => this.converter.fromJSONtoObj(r, StockLocation)))
        )
      );
  }

  uploadStockItems(file: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(this.environmentService.getRestEndpoint('uploadStockItems'), formData).pipe(
      map(() => {
        this.snackBar.open('File uploaded. ', 'CLOSE')._dismissAfter(3000);
      })
    );
  }

  uploadStockItemsTransfer(file: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(this.environmentService.getRestEndpoint('uploadTransferStockItems'), formData).pipe(
      map(() => {
        this.snackBar.open('File uploaded. ', 'CLOSE')._dismissAfter(3000);
      })
    );
  }
}
