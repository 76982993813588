import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { isNilty } from '../../../../core/utils.service';
import { ProductWithTitle } from '../../../../models/product/product-with-title-model';
import { ProductsService } from '../../../products/products.service';

@Component({
  selector: 'app-search-by-barcode',
  templateUrl: './search-by-barcode.component.html',
  styleUrls: ['./search-by-barcode.component.scss'],
})
export class SearchByBarcodeComponent implements OnInit {
  @Output() selectedBarcodeChange = new EventEmitter<string>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  currentSelectedBarcode: string = undefined;
  currentProduct: ProductWithTitle;

  barcodeSearchString = '';

  productsDataSource = new MatTableDataSource<ProductWithTitle>();
  columns = ['barcode', 'sku', 'title'];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private productsService: ProductsService) {}

  ngOnInit() {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        if (!isNilty(text)) {
          this.productsService.searchBarcode(text).subscribe((p) => (this.productsDataSource.data = p));
        } else {
          this.productsDataSource.data = [];
        }
      });

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      const barcode = params['barcode'];
      if (!isNilty(barcode)) {
        this.selectedBarcode(barcode);
        this.productsService.searchBarcode(barcode).subscribe((p) => {
          if (p.length !== 0) {
            this.productsDataSource.data = p;
            this.currentProduct = p.find((it) => it.barcode === barcode);
          }
        });
      }
    });
  }

  selectedBarcode(barcode: string) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { barcode },
      queryParamsHandling: 'merge',
    });
    this.currentSelectedBarcode = barcode;
    this.currentProduct = this.productsDataSource.data.find((it) => it.barcode === barcode);
    this.selectedBarcodeChange.next(barcode);
  }

  changeSelectedBarcode() {
    this.currentSelectedBarcode = undefined;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { barcode: null },
      queryParamsHandling: 'merge',
    });
  }
}
