import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MetadataExportService } from '../metadata-export.service';
import { CommonsService } from '@shared/commons.service';
import { Language } from '@models/language-model';

@Component({
  selector: 'app-metadata-export-upload',
  templateUrl: './metadata-export-upload.component.html',
  styleUrls: ['./metadata-export-upload.component.css'],
})
export class MetadataExportUploadComponent {
  @Input() exportType: string;
  @Output() exportTypeChange = new EventEmitter<string>();

  @Output() exportTypeTrigger = new EventEmitter();

  @Input() exportFileName: string;
  @Output() exportFileNameChange = new EventEmitter<string>();

  @Input() targetLanguage: string;
  @Output() targetLanguageChange = new EventEmitter<string>();

  @Input() uploadRequestId: string;

  @Input() fileUploaded: boolean;
  @Output() fileUploadedChange = new EventEmitter<boolean>();

  file: File;

  languages: Language[] = [];

  nameConfirmed = false;
  oldFilename = '';
  editName = false;

  constructor(private metadataExportService: MetadataExportService, commonsService: CommonsService) {
    commonsService.getLanguages().subscribe((r) => (this.languages = r));
  }

  exportTypeSelected(value: string) {
    if (this.editName === true) this.resetFileName();

    this.exportFileName = this.exportFileName.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9-_.]/g, '');
    this.exportFileNameChange.emit(this.exportFileName);
    this.exportTypeChange.emit(value);
    this.exportTypeTrigger.emit();
    this.nameConfirmed = true;
  }

  targetLanguageChanged(event) {
    this.targetLanguageChange.emit(event);
  }

  fileLoaded(event) {
    this.file = event.target.files[0];
  }

  editFileName() {
    this.oldFilename = this.exportFileName;
    this.editName = true;
  }

  resetFileName() {
    this.editName = false;
    this.exportFileName = this.oldFilename;
  }

  confirmNewFileName() {
    this.exportFileNameChange.emit(this.exportFileName);
    this.editName = false;
    this.exportTypeSelected(this.exportType);
  }

  uploadFile() {
    this.metadataExportService.uploadFile(this.uploadRequestId, this.file).subscribe(() => {
      this.fileUploadedChange.emit(true);
    });
  }
}
