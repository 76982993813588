import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Role } from '../../../../models/role-model';
import { User } from '../../../../models/user.model';
import { GenericConfirmationModalComponent } from '../../../../shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit {
  mappedRoles: { application: string; roles: Role[] }[] = [];

  userId: string;
  user = new User();
  editMode = false;
  userForm: FormGroup;

  private allRoles: Role[];

  constructor(private usersService: UsersService, private route: ActivatedRoute, private router: Router, public dialog: MatDialog) {}

  ngOnInit() {
    this.userForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      enabled: new FormControl(false),
      roles: new FormControl(null, Validators.required),
    });

    this.route.params.subscribe((params: Params) => {
      this.userId = params['id'];
      this.editMode = params['id'] != null;
      this.usersService.getRoles().subscribe((roles: Role[]) => {
        this.allRoles = roles;
        this.buildMappedRoles();

        if (this.editMode) {
          this.usersService.getUser(this.userId).subscribe((user: User) => {
            this.user = user;
            this.changeFormValues(this.user);
          });
        }
      });
    });
  }

  changeFormValues(user: User) {
    this.userForm.setValue({
      email: user.email,
      enabled: user.enabled,
      roles: this.allRoles.filter((r) => user.roles.some((ur) => ur.id === r.id)),
    });
  }

  onCancel() {
    this.router.navigate(['configuration', 'users']);
  }

  onSubmit() {
    if (!this.editMode) {
      this.user = new User();
    }

    this.user.email = this.userForm.controls.email.value;
    this.user.enabled = this.userForm.controls.enabled.value;
    this.user.roles = this.userForm.controls.roles.value;

    this.usersService.saveUser(this.user).subscribe(() => {
      this.router.navigate(['configuration', 'users']);
    });
  }

  onDelete() {
    const message = 'Deleting user ' + this.user.email;
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: message,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.usersService.deleteUser(this.userId).subscribe(() => {
          console.log('User deleted.');
          this.router.navigate(['configuration', 'users']);
        });
      } else {
        console.log('User not deleted.');
      }
    });
  }

  private buildMappedRoles() {
    this.allRoles.forEach((role) => {
      const currentApplicationIndex = this.mappedRoles.findIndex((it) => it.application === role.application);
      if (currentApplicationIndex === -1) {
        this.mappedRoles.push({ application: role.application, roles: [role] });
      } else {
        this.mappedRoles[currentApplicationIndex].roles.push(role);
      }
    });
  }
}
