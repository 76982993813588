import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('RefreshPublicationInputModel')
export class RefreshPublicationInputModel {
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('storeCode', String, true) storeCode: string = undefined;
  @JsonProperty('bruteRefresh', Boolean, true) bruteRefresh: boolean = undefined;

  constructor(sku: string, store: string, bruteRefresh: boolean) {
    this.sku = sku;
    this.storeCode = store;
    this.bruteRefresh = bruteRefresh;
  }
}
