import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PolicyTypes } from '@models/configuration/policies/policy-enums';
import {
  BarcodeRestriction,
  PredicatesRestriction,
  WordsRestriction,
} from '@models/configuration/policies/restrictions/restrictions-model';
import { RestrictionDetailsModalComponent } from './restriction-details-modal/restriction-details-modal.component';
import { ListingSellingPlatformDescriptionPipe } from '@shared/pipes/listing-selling-platform-description.pipe';

@Component({
  selector: 'app-restrictions-row',
  template: `
    <div class="spacing-row"></div>
    <div
      class="blacklist-row-div d-flex"
      (click)="openBlacklistDetails()"
      [ngClass]="restriction.allowOrDeny === 'ALLOW' ? 'allow-row' : 'deny-row'"
    >
      <span class="icon-span">
        <i *ngIf="restriction.allowOrDeny === 'ALLOW'" class="fa fa-check-circle-o"></i>
        <i *ngIf="restriction.allowOrDeny === 'DENY'" class="fa fa-minus-circle"></i>
      </span>
      <span class="target-id">
        <app-policy-target-type-icon [targetType]="restriction?.target?.type"></app-policy-target-type-icon>
        <b>{{ restriction?.target?.identifier }}</b>
      </span>
      <span class="blacklist-row-info name" [matTooltip]="restriction?.name">{{ restriction?.name }}</span>
      <span class="blacklist-row-info date">{{ restriction?.date.substr(0, 10) }}</span>

      <span class="predicates center flex-fill" *ngIf="type === BlacklistTypes.PREDICATES">
        <ng-container *ngFor="let p of restriction?.predicates; let predicateIndex = index">
          <b *ngIf="predicateIndex !== 0" class="and-text">AND</b>{{ p.description }}
        </ng-container>
      </span>
      <span class="predicates center flex-fill" *ngIf="type === BlacklistTypes.WORDS"> <b>Words: </b>{{ words }} </span>
      <span class="predicates center flex-fill" *ngIf="type === BlacklistTypes.BARCODE">
        <b>{{ restriction.barcodeType }}</b> {{ restriction.barcodeValue }}
      </span>
      <span class="blacklist-row-info flex-fill">
        {{ sellingPlatforms }}
      </span>
      <span class="align-content-end">
        <app-restrictions-type [restriction]="restriction"></app-restrictions-type>
      </span>
    </div>
  `,
  styleUrls: ['./restriction-row.scss'],
})
export class RestrictionRowComponent implements OnInit {
  @Input() index: number;
  @Input() restriction: PredicatesRestriction | WordsRestriction | BarcodeRestriction;
  words: string;
  sellingPlatforms: string;
  type: PolicyTypes;
  BlacklistTypes = PolicyTypes;

  constructor(private dialog: MatDialog, private listingSellingPlatformDescriptionPipe: ListingSellingPlatformDescriptionPipe) {}

  ngOnInit() {
    if (this.restriction instanceof PredicatesRestriction) {
      this.type = PolicyTypes.PREDICATES;
      this.restriction.predicates.forEach((it) => it.setDescription());
    } else if (this.restriction instanceof WordsRestriction) {
      this.type = PolicyTypes.WORDS;
      this.words = this.restriction.words.slice(0, 20).join(', ');
      if (this.restriction.words.length > 20) {
        this.words += '...';
      }
    } else {
      this.type = PolicyTypes.BARCODE;
    }

    const sp =
      this.restriction.allowOrDeny === 'ALLOW' ? this.restriction.allowedSellingPlatforms : this.restriction.deniedSellingPlatforms;

    this.sellingPlatforms = sp
      .slice(0, 5)
      .map((it) => this.listingSellingPlatformDescriptionPipe.transform(it))
      .join(', ');
    if (sp.length > 5) {
      this.sellingPlatforms += '...';
    }
  }

  openBlacklistDetails() {
    this.dialog.open(RestrictionDetailsModalComponent, { data: this.restriction });
  }
}
