import { JsonObject, JsonProperty } from 'json2typescript';
import { MarketplaceConnection } from './marketplace-connection-model';

@JsonObject('Owner')
export class Owner {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
}

@JsonObject('Account')
export class Account {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('connection', MarketplaceConnection, true) connection: MarketplaceConnection = undefined;
  @JsonProperty('owner', Owner, true) Owner: string = undefined;
}
