import { PublishedStore } from '@models/publications/published-store-model';
import { ProductWithTitle } from '@models/product/product-with-title-model';
import { MarketplaceDto } from '../../marketplace/marketplace-model';

export enum BlacklistSearchType {
  PRODUCT,
  STORE,
  MARKETPLACE,
  NETWORK,
  GLOBAL,
}

export class SearchTypeDefinition {
  code: BlacklistSearchType;
  name: string;
  searchLabel: string;
  searchString: string;

  constructor(code: BlacklistSearchType, name: string, searchLabel: string) {
    this.code = code;
    this.name = name;
    this.searchLabel = searchLabel;
    this.searchString = '';
  }
}

export const searchTypeDefinitions = [
  new SearchTypeDefinition(BlacklistSearchType.PRODUCT, 'Product', 'SKU/Barcode'),
  new SearchTypeDefinition(BlacklistSearchType.STORE, 'Store', 'Store'),
  new SearchTypeDefinition(BlacklistSearchType.MARKETPLACE, 'Marketplace', 'Marketplace'),
  new SearchTypeDefinition(BlacklistSearchType.NETWORK, 'Network', 'Network'),
  new SearchTypeDefinition(BlacklistSearchType.GLOBAL, 'Global', ''),
];

export class SearchBlacklistDto {
  searchType: BlacklistSearchType;
  entity: PublishedStore | ProductWithTitle | MarketplaceDto | string;

  constructor(searchType: BlacklistSearchType, entity: PublishedStore | ProductWithTitle | MarketplaceDto | string) {
    this.entity = entity;
    this.searchType = searchType;
  }
}
