import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonsService } from '@shared/commons.service';

@Component({
  selector: 'app-search-network',
  templateUrl: './search-network.component.html',
  styleUrls: ['./search-network.component.scss'],
})
export class SearchNetworkComponent implements OnInit {
  @Output()
  selectedNetwork = new EventEmitter<string>();

  currentSelectedNetwork: string;

  networks: string[];

  constructor(private commonsService: CommonsService) {}

  ngOnInit(): void {
    this.commonsService.getNetworks().subscribe((r) => (this.networks = r));
  }

  selectNetwork(n: string) {
    this.selectedNetwork.emit(n);
    this.currentSelectedNetwork = n;
  }
}
