import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CatalogItem } from '@models/catalog-item-model';
import { CatalogItemsService } from '../catalog-items.service';
import { SalesRestrictionsModalComponent } from './sales-restrictions-modal/sales-restrictions-modal.component';
import { CatalogHistory } from '@models/catalog-item-history-model';

@Component({
  selector: 'app-catalog-items-details',
  templateUrl: './catalog-items-details.component.html',
  styleUrls: ['./catalog-items-details.component.scss'],
})
export class CatalogItemsDetailsComponent implements OnInit {
  catalogItemId: number;
  catalogItem: CatalogItem;
  catalogItemHistory: CatalogHistory;

  constructor(
    private catalogItemsService: CatalogItemsService,
    private route: ActivatedRoute,
    private location: Location,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.catalogItemId = params['id'];

      this.catalogItemsService.getCatalogItem(this.catalogItemId).subscribe((resp: CatalogItem) => {
        this.catalogItem = resp;
      });
      this.catalogItemsService.getCatalogHistory(this.catalogItemId).subscribe((resp: CatalogHistory) => (this.catalogItemHistory = resp));
    });
  }

  goBack() {
    this.location.back();
  }

  showSalesRestrictions() {
    this.dialog.open(SalesRestrictionsModalComponent, { data: this.catalogItem, autoFocus: false });
  }
}
