import { PublicationGridCellFormatter } from './publication-grid-cell-formatter';
import { singleNumericColumn, statusColorMap, statusCursor } from './publication-list-utils';
import { Injectable } from '@angular/core';
import { PublicationViewType } from '../publication-view-type';
import { isNilty } from '../../../../core/utils.service';
import { PublicationPrice } from '../../../../models/publications/publication-price-model';
import { MyCurrencyPipe } from '../../../../shared/pipes/currency.pipe';
import { NetworkCategoryPipe } from '../../../../shared/pipes/network-category.pipe';
import { Price } from '../../../../models/publications/competition-data-model';
import { PendingPublication } from '../../../../models/publications/pending-publication-model';

@Injectable()
export class ColumnDefinitionsService {
  private onlineColumn = {
    headerName: '',
    cellRenderer: 'editButtonComponent',
    cellStyle: { paddingLeft: '2px', paddingRight: '10px' },
    maxWidth: 70,
    minWidth: 70,
  };

  private productColumn = {
    headerName: 'Product (Zion)',
    children: [
      { headerName: 'Barcode', field: 'publication.barcode', minWidth: 150 },
      { headerName: 'SKU', field: 'publication.sku', minWidth: 120 },
      { headerName: 'Title', field: 'publication.title', minWidth: 150, columnGroupShow: 'open', tooltipField: 'publication.title' },
      { headerName: 'Brand', field: 'publication.brand', minWidth: 120, columnGroupShow: 'open' },
      {
        headerName: 'Category',
        field: 'publication.category',
        cellRenderer: this.getPrettyPrintedCategory.bind(this),
        minWidth: 160,
        columnGroupShow: 'open',
      },
    ],
  };

  private marketplaceMetadataColumn = {
    headerName: 'Marketplace',
    children: [
      { headerName: 'Barcode', field: 'publication.marketplaceMetadata.marketplaceBarcode', minWidth: 120 },
      {
        headerName: 'Title',
        field: 'publication.marketplaceMetadata.title',
        minWidth: 150,
        columnGroupShow: 'open',
        tooltipField: 'publication.marketplaceMetadata.title',
      },
      { headerName: 'Brand', field: 'publication.marketplaceMetadata.brand', minWidth: 120, columnGroupShow: 'open' },
    ],
  };

  private supplierColumn = {
    headerName: 'Supplier',
    children: [
      {
        headerName: 'Supplier',
        field: 'publication.supplierCode',
        cellStyle: { cursor: 'pointer' },
        minWidth: 100,
        maxWidth: 100,
        tooltipField: 'publication',
        tooltipComponent: 'supplierTooltip',
        colId: 'supplierColumn',
      },
      { headerName: 'Stock Location', field: 'publication.stockLocationCode', minWidth: 130, columnGroupShow: 'open' },
    ],
  };

  private storeColumn = {
    headerName: 'Store',
    children: [
      { headerName: 'Code', field: 'publication.store.code', minWidth: 130 },
      { headerName: 'Name', field: 'publication.store.name', columnGroupShow: 'open' },
      { headerName: 'Marketplace', field: 'publication.store.marketplaceName', columnGroupShow: 'open' },
      { headerName: 'Network', field: 'publication.store.networkType', columnGroupShow: 'open' },
    ],
  };

  private statusColumn = {
    headerName: 'Status',
    field: 'publication.status',
    cellStyle: this.statusCellStyle,
    minWidth: 120,
    tooltipField: 'publication',
    tooltipComponent: 'disableReasonTooltip',
    colId: 'statusColumn',
  };

  private pendingOperationColumn = {
    headerName: 'Pending',
    field: 'pending',
    cellStyle: { fontStyle: 'italic' },
    cellRenderer: this.formatter.getPendingCell,
    minWidth: 150,
    tooltipField: 'pending',
    tooltipComponent: 'pendingTooltip',
  };

  private publishedQuantityColumn = singleNumericColumn('Published Quantity', 'publication.publishedQuantity');
  private newQuantityColumn = singleNumericColumn('New Quantity', 'publication.newQuantity');
  private publishedPriceColumn = this.buildPriceRangeColumn('Published Price', 'publication.publishedPrice');
  private newPriceColumn = this.buildPriceRangeColumn('New Price', 'publication.newPrice');

  private pendingQuantityColumn = singleNumericColumn('Pending Quantity', 'pending.quantityToPublish');
  private pendingPriceColumn = this.buildPriceToPublishRangeColumn('Pending Price', 'pending');

  private submittedColumn = {
    headerName: 'Submitted',
    field: 'pending.submitted',
    minWidth: 50,
    cellRenderer: this.formatter.booleanColumn,
  };
  private marketplaceFeedIdColumn = { headerName: 'Feed Id', field: 'pending.marketplaceFeedId', minWidth: 100 };

  private updatedAtColumn = {
    headerName: 'Updated at',
    field: 'publication.updatedAt',
    tooltipField: 'publication.updatedAt',
    minWidth: 120,
  };
  private pendingUpdatedAtColumn = {
    headerName: 'Updated at',
    field: 'pending.updatedAt',
    tooltipField: 'pending.updatedAt',
    minWidth: 120,
  };

  private marketplaceStatusColumn = {
    headerName: 'Marketplace Status',
    field: 'publication.marketplaceStatus',
    tooltipField: 'publication.marketplaceStatus',
    minWidth: 160,
  };

  private isBuyBoxWinnerColumn = {
    headerName: 'Is Buy Box Winner',
    field: 'publication.isBuyBoxWinner',
    minWidth: 100,
    cellRenderer: this.formatter.booleanColumn,
  };

  private buyBoxPriceColumn = this.singleCompetitionPriceColumn('Buy Box Price', 'publication.buyBoxPrice');

  private publicationColumns: any[] = [
    this.marketplaceMetadataColumn,
    this.statusColumn,
    this.supplierColumn,
    this.publishedQuantityColumn,
    this.newQuantityColumn,
    this.publishedPriceColumn,
    this.newPriceColumn,
    this.pendingOperationColumn,
    this.updatedAtColumn,
    this.marketplaceStatusColumn,
    this.isBuyBoxWinnerColumn,
    this.buyBoxPriceColumn,
  ];

  private pendingColumns: any[] = [
    this.marketplaceMetadataColumn,
    this.pendingOperationColumn,
    this.pendingQuantityColumn,
    this.pendingPriceColumn,
    this.submittedColumn,
    this.statusColumn,
    this.publishedQuantityColumn,
    this.publishedPriceColumn,
    this.pendingUpdatedAtColumn,
    this.marketplaceFeedIdColumn,
  ];

  constructor(
    private formatter: PublicationGridCellFormatter,
    private myCurrencyPipe: MyCurrencyPipe,
    private networkCategoryPipe: NetworkCategoryPipe
  ) {}

  statusCellStyle(params) {
    return { color: statusColorMap(params.value), fontWeight: 'bold', cursor: statusCursor(params.value) };
  }

  getColumns(isByStore: boolean, viewType: PublicationViewType) {
    const columns = viewType === PublicationViewType.PUBLICATION ? [...this.publicationColumns] : [...this.pendingColumns];

    if (isByStore) {
      columns.unshift(this.onlineColumn, this.productColumn);
    } else {
      columns.unshift(this.onlineColumn, this.storeColumn);
    }
    return columns;
  }

  buildPriceRangeColumn(headerName: string, label: string) {
    return {
      headerName,
      type: 'numericColumn',
      children: [
        this.singlePriceColumn('Min', label, 'minimum', true),
        this.singlePriceColumn('Target', label, 'target', false),
        this.singlePriceColumn('Max', label, 'maximum', true),
      ],
    };
  }

  buildPriceToPublishRangeColumn(headerName: string, label: string) {
    return {
      headerName,
      type: 'numericColumn',
      children: [
        this.buildPendingPriceColumn('Min', label, 'minimumPrice', true),
        this.buildPendingPriceColumn('Target', label, 'targetPrice', false),
        this.buildPendingPriceColumn('Max', label, 'maximumPrice', true),
      ],
    };
  }

  private buildPendingPriceColumn(name: string, label: string, key: string, hidden: boolean) {
    const column = {
      headerName: name,
      field: label,
      type: 'numericColumn',
      cellRenderer: this.getPendingPriceWithCurrency.bind(this),
      cellRendererParams: { key },
      cellStyle: { cursor: 'pointer' },
      minWidth: 160,
      colId: label,
    };
    if (hidden) {
      column['columnGroupShow'] = 'open';
    }
    return column;
  }

  private singlePriceColumn(name: string, label: string, key: string, hidden: boolean) {
    const column = {
      headerName: name,
      field: label,
      type: 'numericColumn',
      cellRenderer: this.getPriceWithCurrency.bind(this),
      cellRendererParams: { key },
      cellStyle: { cursor: 'pointer' },
      minWidth: 160,
      tooltipField: label,
      tooltipComponent: 'priceRangeTooltip',
      colId: label,
    };
    if (hidden) {
      column['columnGroupShow'] = 'open';
    }
    return column;
  }

  private getPendingPriceWithCurrency(params): string {
    if (isNilty(params.value)) {
      return '--';
    }
    const pub = params.value as PendingPublication;
    return this.myCurrencyPipe.transform(pub[params.key], pub.currency);
  }

  private getPriceWithCurrency(params): string {
    if (isNilty(params.value)) {
      return '--';
    }
    const price = params.value as PublicationPrice;
    return this.myCurrencyPipe.transform(price[params.key], price.currency);
  }

  private getPrettyPrintedCategory(params): string {
    console.log('oooo');
    if (isNilty(params.value)) {
      return '--';
    }
    return this.networkCategoryPipe.transform(params.value as string);
  }

  private singleCompetitionPriceColumn(name: string, label: string) {
    const column = {
      headerName: name,
      field: label,
      type: 'numericColumn',
      cellRenderer: this.getCompetitionPriceWithCurrency.bind(this),
      cellStyle: { cursor: 'pointer' },
      minWidth: 160,
      tooltipField: label,
      tooltipComponent: 'priceRangeTooltip',
      colId: label,
    };
    return column;
  }

  private getCompetitionPriceWithCurrency(params): string {
    if (isNilty(params.value)) {
      return '--';
    }
    const price = params.value as Price;
    return this.myCurrencyPipe.transform(price.amount, price.currencyCode);
  }
}
