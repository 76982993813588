import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('MarketplaceMetadata')
export class MarketplaceMetadata {
  @JsonProperty('marketplaceName', String, true) marketplaceName: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('barcode', String, true) barcode: string = undefined;
  @JsonProperty('marketplaceBarcode', String, true) marketplaceBarcode: string = undefined;
  @JsonProperty('title', String, true) title: string = undefined;
  @JsonProperty('brand', String, true) brand: string = undefined;
  @JsonProperty('imageUrl', String, true) imageUrl: string = undefined;
  @JsonProperty('id', String, true) id: string = undefined;
}
