import { Component, Input } from '@angular/core';
import { ChunkedFeedRouteConfiguration } from '../../../../../../../models/configuration/soup-configuration/route-configurations/chunked-feed-route-configuration-model';

@Component({
  selector: 'app-chunked-route-configuration',
  templateUrl: './chunked-route-configuration.component.html',
  styleUrls: ['./chunked-route-configuration.component.css'],
})
export class ChunkedRouteConfigurationComponent {
  @Input() chunkedConfiguration: ChunkedFeedRouteConfiguration;

  zipFile: boolean;

  @Input() set _zipFile(z: boolean) {
    this.zipFile = z;
    if (this.zipFile) {
      this.chunkedConfiguration.largeFile = false;
    }
  }

  checkStreamDownload(streamDownload: boolean) {
    if (streamDownload) {
      this.chunkedConfiguration.stepwise = false;
    }
  }
}
