import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '../../../core/converter.service';
import { EnvironmentService } from '../../../core/environment/environment.service';
import { Observable } from 'rxjs';
import { SellingCompetitor } from '../../../models/configuration/selling-competitor-model';
import { map } from 'rxjs/operators';

@Injectable()
export class SellingCompetitorsService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  findAll(): Observable<SellingCompetitor[]> {
    return this.http
      .get(this.environmentService.getMarkusRestEndpoint('sellingCompetitors'))
      .pipe(
        map((r: SellingCompetitor[]) =>
          r.map((it) => this.converter.fromJSONtoObj(it, SellingCompetitor)).sort((a, b) => (a.sellerName > b.sellerName ? 1 : -1))
        )
      );
  }

  save(c: SellingCompetitor): Observable<any> {
    return this.http.post(this.environmentService.getMarkusRestEndpoint('sellingCompetitors'), this.converter.fromObjtoJSON(c));
  }

  delete(c: SellingCompetitor): Observable<any> {
    return this.http.delete(this.environmentService.getMarkusRestEndpoint('sellingCompetitors') + '/' + c.sellerId);
  }
}
