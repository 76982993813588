import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConnectionCredentialsModalComponent } from './connection-credentials-modal/connection-credentials-modal.component';
import { MarketplaceConnection } from '@models/configuration/marketplace/marketplace-connection-model';
import { StoreService } from '../../store.service';

@Component({
  selector: 'app-account-connections',
  templateUrl: './account-connections.component.html',
  styleUrls: ['./account-connections.component.scss'],
})
export class AccountConnectionsComponent implements OnInit {
  connections: MarketplaceConnection[];

  constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<AccountConnectionsComponent>, private storeService: StoreService) {}

  ngOnInit() {
    this.storeService.findMarketplaceConnections().subscribe((r) => (this.connections = r.sort((a, b) => (a.name > b.name ? 1 : -1))));
  }

  editConnection(connection: MarketplaceConnection) {
    this.dialog.open(ConnectionCredentialsModalComponent, { data: connection });
  }
}
