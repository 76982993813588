import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BarcodeBlacklist, Blacklist, PredicatesBlacklist, WordsBlacklist } from '@models/configuration/policies/blacklist/blacklist-model';
import { getAllowedBlacklistBarcodeTypes } from '@models/configuration/policies/blacklist/blacklists-enums-model';
import { BlacklistService } from '../../blacklist.service';
import { BlacklistReason } from '@models/configuration/policies/blacklist/blacklist-reason-model';
import { AuthorizationService } from '@security/authorization.service';
import { Permissions } from '@models/security/permission-model';
import { Observable } from 'rxjs';
import { SolveBlacklistModalComponent } from './solve-blacklist-modal.component';
import { SolveBlacklistInput } from '@models/configuration/policies/blacklist/solve-blacklist-input-model';
import { PolicyTypes } from '@models/configuration/policies/policy-enums';

@Component({
  selector: 'app-blacklist-edit-modal',
  templateUrl: './blacklist-details-modal.component.html',
  styleUrls: ['./blacklist-details-modal.component.scss'],
})
export class BlacklistDetailsModalComponent implements OnInit {
  BlacklistTypes = PolicyTypes;

  barcodeBlacklist: BarcodeBlacklist;
  wordsBlacklist: WordsBlacklist;
  predicatesBlacklist: PredicatesBlacklist;

  blacklistReasons: BlacklistReason[] = [];
  barcodeTypes: string[] = [];

  hasBlacklistManagement: Observable<boolean>;

  constructor(
    public dialogRef: MatDialogRef<BlacklistDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Blacklist,
    private blacklistService: BlacklistService,
    private dialog: MatDialog,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit() {
    this.hasBlacklistManagement = this.authorizationService.hasMarkusPermission(Permissions.BlacklistManagement);
    this.blacklistService.getBlacklistReasons().subscribe((r) => (this.blacklistReasons = r));
    this.barcodeTypes = getAllowedBlacklistBarcodeTypes(this.data.type);

    switch (this.data.type) {
      case PolicyTypes.BARCODE:
        this.barcodeBlacklist = this.data as BarcodeBlacklist;
        break;
      case PolicyTypes.PREDICATES:
        this.predicatesBlacklist = this.data as PredicatesBlacklist;
        break;
      case PolicyTypes.WORDS:
        this.wordsBlacklist = this.data as WordsBlacklist;
        break;
    }
  }

  deleteBlacklist() {
    const payload = new SolveBlacklistInput(this.data.id, this.data.type, '');
    this.dialog
      .open(SolveBlacklistModalComponent, { data: payload })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          this.blacklistService.solveBlacklist(payload).subscribe(() => this.dialogRef.close());
        }
      });
  }
}
