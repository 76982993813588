import { Component, Input } from '@angular/core';
import { BlacklistTarget } from '@models/configuration/policies/blacklist/blacklist-model';

@Component({
  selector: 'app-inherit-blacklists',
  templateUrl: './inherit-blacklists.component.html',
  styleUrls: ['./inherit-blacklists.component.scss'],
})
export class InheritBlacklistsComponent {
  @Input() targets: BlacklistTarget[];
  addMode = false;

  selectedNewTarget(event: BlacklistTarget) {
    if (this.targets.find((it) => it.type === event.type && it.identifier === event.identifier) === undefined) {
      this.targets.push(event);
      this.targets = this.targets.sort((a, b) => (a.type > b.type ? 1 : -1));
    }
    this.addMode = false;
  }

  removeTarget(i: number) {
    this.targets.splice(i, 1);
  }
}
