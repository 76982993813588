import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseEntity } from '../shared/base-entity-model';

@JsonObject('PendingPublication')
export class PendingPublication extends BaseEntity {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('publicationId', String, true) publicationId: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('barcode', String, true) barcode: string = undefined;
  @JsonProperty('storeCode', String, true) storeCode: string = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('targetPrice', Number, true) targetPrice: number = undefined;
  @JsonProperty('minimumPrice', Number, true) minimumPrice: number = undefined;
  @JsonProperty('maximumPrice', Number, true) maximumPrice: number = undefined;
  @JsonProperty('repriced', Boolean, true) repriced: boolean = undefined;
  @JsonProperty('quantityToPublish', Number, true) quantityToPublish: number = undefined;
  @JsonProperty('operation', String, true) operation: string = undefined;
  @JsonProperty('marketplaceFeedId', String, true) marketplaceFeedId: string = undefined;
  @JsonProperty('submitted', Boolean, true) submitted: boolean = undefined;
  @JsonProperty('category', String, true) category: string = undefined;
}
