import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('PublishedIn')
class PublishedIn {
  @JsonProperty('ruleCode', String, true) ruleCode: string = undefined;
  @JsonProperty('ruleType', String, true) ruleType: string = undefined;
}

@JsonObject('PublishedSources')
export class PublishedSources {
  @JsonProperty('sourceId', Number, true) sourceId: number = undefined;
  @JsonProperty('sourceCode', String, true) sourceCode: string = undefined;
  @JsonProperty('sourceType', String, true) sourceType: string = undefined;
  @JsonProperty('publishedIn', [PublishedIn], true) publishedIn: PublishedIn[] = [];
}
