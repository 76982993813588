import { NgModule } from '@angular/core';
import { LoaderComponent } from '@core/loader/loader.component';
import { LoaderService } from '@core/loader/loader.service';
import { SharedModule } from '../shared.module';
import { AvailabilityHistoryComponent } from './catalog-items/catalog-items-details/availability-history/availability-history.component';
import { CatalogItemFeedHistoryComponent } from './catalog-items/catalog-items-details/catalog-item-history/catalog-item-feed-history.component';
import { ShowJsonModalComponent } from './catalog-items/catalog-items-details/catalog-item-history/show-json-modal/show-json-modal.component';
import { CatalogItemsDetailsComponent } from './catalog-items/catalog-items-details/catalog-items-details.component';
import { CatalogPublicationsComponent } from './catalog-items/catalog-items-details/catalog-publications/catalog-publications.component';
import { PriceHistoryComponent } from './catalog-items/catalog-items-details/price-history/price-history.component';
import { QuantityHistoryComponent } from './catalog-items/catalog-items-details/quantity-history/quantity-history.component';
import { SalesRestrictionsModalComponent } from './catalog-items/catalog-items-details/sales-restrictions-modal/sales-restrictions-modal.component';
import { CatalogItemsFiltersComponent } from './catalog-items/catalog-items-list/catalog-items-filters/catalog-items-filters.component';
import { CatalogItemsListsComponent } from './catalog-items/catalog-items-list/catalog-items-lists.component';
import { CatalogItemsComponent } from './catalog-items/catalog-items.component';
import { CatalogItemsService } from './catalog-items/catalog-items.service';
import { StoreService } from './configuration/store-configuration/store.service';
import { ContainerComponent } from './container.component';
import { HeaderComponent } from './header/header.component';
import { AddProductComponent } from './products/add-product/add-product.component';
import { ProductsModule } from './products/products.module';
import { ProductsService } from './products/products.service';
import { PublicationsModule } from './publications/publications.module';
import { StockItemsFiltersComponent } from './stock-items/stock-items-filters/stock-items-filters.component';
import { StockItemsComponent } from './stock-items/stock-items.component';
import { StockItemsService } from './stock-items/stock-items.service';
import { ImportStockLocationsService } from './stock-locations/import-stock-locations.service';
import { StockLocationsModule } from './stock-locations/stock-locations.module';
import { StockLocationsService } from './stock-locations/stock-locations.service';
import { EditSupplierComponent } from './suppliers/edit-supplier.component';
import { SupplierDiscountCodesModalComponent } from './suppliers/supplier-discount-codes/supplier-discount-codes-modal.component';
import { SupplierInvoiceToModalComponent } from './suppliers/supplier-invoice-to-modal/supplier-invoice-to-modal.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { SuppliersService } from './suppliers/suppliers.service';

@NgModule({
  imports: [SharedModule, PublicationsModule, ProductsModule, StockLocationsModule],
  declarations: [
    ContainerComponent,
    LoaderComponent,
    HeaderComponent,
    SuppliersComponent,
    EditSupplierComponent,
    SupplierDiscountCodesModalComponent,
    StockItemsComponent,
    StockItemsFiltersComponent,
    CatalogItemsComponent,
    CatalogItemsListsComponent,
    CatalogItemsFiltersComponent,
    CatalogItemsDetailsComponent,
    CatalogItemFeedHistoryComponent,
    ShowJsonModalComponent,
    PriceHistoryComponent,
    QuantityHistoryComponent,
    AvailabilityHistoryComponent,
    CatalogPublicationsComponent,
    SalesRestrictionsModalComponent,
    AddProductComponent,
    SupplierInvoiceToModalComponent,
  ],
  providers: [
    LoaderService,
    SuppliersService,
    StockLocationsService,
    ImportStockLocationsService,
    StockItemsService,
    CatalogItemsService,
    ProductsService,
    StoreService,
  ],
})
export class ContainerModule {}
