import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../security/authentication.service';
import { LoaderService } from './loader/loader.service';
import { tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { GenericErrorModalComponent } from '../shared/generic-error-modal/generic-error-modal.component';
import { isLoaderHidden } from './utils.service';

@Injectable()
export class RESTInterceptor implements HttpInterceptor {
  requestNum = 0;

  constructor(private dialog: MatDialog, private authenticationService: AuthenticationService, private loaderService: LoaderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authHeader = this.authenticationService.getAuthorizationHeader();
    let finalReq = req;

    if (authHeader) {
      // Clone the request to add the new header.
      finalReq = req.clone({ headers: req.headers.set('Authorization', `Bearer ${authHeader}`) });
    }

    let timer;

    if (!isLoaderHidden(req.headers)) {
      this.requestNum++;
      timer = setTimeout(() => {
        this.showLoader();
      }, 0.1 * 1000);
    }

    if (environment.envName === 'mock') {
      finalReq = req.clone({ method: 'GET' });
    }

    return next.handle(finalReq).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (!isLoaderHidden(req.headers)) {
              this.requestNum--;
            }
            if (this.requestNum <= 0) {
              this.hideLoader();
              this.requestNum = 0;
            }
            clearTimeout(timer);
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.authenticationService.login();
              return;
            }

            if (err.error instanceof Blob) {
              err.error.text().then((error) => {
                this.showError(err.status, error);
              });
            } else {
              this.showError(err.status, err.error);
            }
          }
        }
      )
    );
  }

  private showError(status: number, error: string) {
    const errDialog = this.dialog.open(GenericErrorModalComponent, {
      width: '350px',
      data: error,
    });

    errDialog.afterClosed().subscribe(() => {
      this.hideLoader();
      this.requestNum = 0;
      if (status === 401) {
        location.reload();
      }
    });

    setTimeout(() => {
      this.hideLoader();
    }, 200);
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
