import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { isNilty } from 'src/app/core/utils.service';
import { Supplier } from 'src/app/models/supplier-model';
import { SuppliersService } from './suppliers.service';
import { CommonsService } from '../../shared/commons.service';
import { Currency } from '../../models/currency-model';

@Component({
  selector: 'app-suppliers',
  templateUrl: './edit-supplier.component.html',
  styleUrls: ['./edit-supplier.component.scss'],
})
export class EditSupplierComponent implements OnInit {
  supplier: Supplier;
  supplierForm: FormGroup;

  currencies: Currency[];
  logisticPartners: string[];
  exclusions: string[];
  newSupplier = false;

  constructor(
    private suppliersService: SuppliersService,
    private route: ActivatedRoute,
    private location: Location,
    private commonService: CommonsService
  ) {}

  ngOnInit(): void {
    this.supplierForm = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.pattern(/[A-Z0-9-]{4}/)]),
      name: new FormControl('', Validators.required),
      businessName: new FormControl(null),
      defaultDiscountPercentage: new FormControl(null),
      overrideFeedDiscount: new FormControl(false),
      insertNewProducts: new FormControl(true),
      sendsFullCatalog: new FormControl(true),
      catalogItemHistorySize: new FormControl(null),
      requestsMetadata: new FormControl(true),
      requestsBookMetadata: new FormControl(false),
      mdActive: new FormControl(null),
      mdDefaultQuantity: new FormControl(null),
      defaultQuantity: new FormControl(null),
      currency: new FormControl(null),

      salesRightExcludeToFind: new FormControl(null),
      salesRightExcludeToRemove: new FormControl(null),
      salesRightExcludeOnlyToRemove: new FormControl(null),

      logisticPartner: new FormControl(null),
      poShipmentCost: new FormControl(null),
      poShipmentCostForKg: new FormControl(null),
      dropShipper: new FormControl(null),
      trackPublicationMetrics: new FormControl(null),

      defaultCountryOfOrigin: new FormControl(null),
      updateProductRestrictions: new FormControl(false),
      hasOwnRestrictions: new FormControl(false),
    });

    this.route.queryParams.subscribe((params) => {
      const supplierCode = params['supplier'];
      if (supplierCode) {
        this.suppliersService.getSupplierByCode(supplierCode).subscribe((supplier: Supplier) => {
          this.supplier = supplier;
          this.updateForm();
        });
      } else {
        this.newSupplier = true;
        this.supplier = new Supplier();
      }
    });

    this.commonService.getCurrencies().subscribe((r) => (this.currencies = r));
    this.commonService.getLogisticPartners().subscribe((r) => (this.logisticPartners = r));
    this.commonService.getExclusions().subscribe((r) => (this.exclusions = r));
  }

  updateForm() {
    this.supplierForm.setValue({
      code: this.supplier.code,
      name: this.supplier.name,
      businessName: this.supplier.businessName ? this.supplier.businessName : null,
      defaultDiscountPercentage: this.supplier.defaultDiscountPercentage ? this.supplier.defaultDiscountPercentage : null,
      overrideFeedDiscount: !isNilty(this.supplier.overrideFeedDiscount) ? this.supplier.overrideFeedDiscount : false,
      insertNewProducts: !isNilty(this.supplier.insertNewProducts) ? this.supplier.insertNewProducts : true,
      sendsFullCatalog: !isNilty(this.supplier.sendsFullCatalog) ? this.supplier.sendsFullCatalog : true,
      catalogItemHistorySize: this.supplier.catalogItemHistorySize ? this.supplier.catalogItemHistorySize : null,
      requestsMetadata: !isNilty(this.supplier.requestsMetadata) ? this.supplier.requestsMetadata : true,
      requestsBookMetadata: !isNilty(this.supplier.requestsBookMetadata) ? this.supplier.requestsBookMetadata : false,
      mdActive: this.supplier.mdActive ? this.supplier.mdActive : null,
      mdDefaultQuantity: this.supplier.mdDefaultQuantity ? this.supplier.mdDefaultQuantity : null,
      defaultQuantity: this.supplier.defaultQuantity ? this.supplier.defaultQuantity : null,
      currency: this.supplier.currency ? this.supplier.currency : null,

      salesRightExcludeToFind: this.supplier.salesRightExcludeToFind ? this.supplier.salesRightExcludeToFind : null,
      salesRightExcludeToRemove: this.supplier.salesRightExcludeToRemove ? this.supplier.salesRightExcludeToRemove : null,
      salesRightExcludeOnlyToRemove: this.supplier.salesRightExcludeOnlyToRemove ? this.supplier.salesRightExcludeOnlyToRemove : null,

      logisticPartner: this.supplier.logisticPartner ? this.supplier.logisticPartner : null,
      poShipmentCost: this.supplier.poShipmentCost ? this.supplier.poShipmentCost : null,
      poShipmentCostForKg: this.supplier.poShipmentCostForKg ? this.supplier.poShipmentCostForKg : null,
      dropShipper: this.supplier.dropShipper ? this.supplier.dropShipper : false,
      trackPublicationMetrics: this.supplier.trackPublicationMetrics ? this.supplier.trackPublicationMetrics : false,

      defaultCountryOfOrigin: this.supplier.defaultCountryOfOrigin ? this.supplier.defaultCountryOfOrigin : null,
      updateProductRestrictions: this.supplier.updateProductRestrictions ? this.supplier.updateProductRestrictions : false,
      hasOwnRestrictions: this.supplier.hasOwnRestrictions ? this.supplier.hasOwnRestrictions : false,
    });
  }

  onCancel() {
    this.location.back();
  }

  onSubmit() {
    Object.keys(this.supplierForm.controls).map((key) => {
      const control = this.supplierForm.controls[key];
      this.supplier[key] = control.value;
    });

    this.suppliersService.saveSupplier(this.supplier).subscribe(() => {
      this.onCancel();
    });
  }
}
