import { Component, EventEmitter, Input, ViewChild } from '@angular/core';
import { PublishedStore, ZionStore } from '../../../models/publications/published-store-model';
import { PublicationFilters } from '../../../models/publications/filters/publication-filters-model';
import { PublicationDto } from '../../../models/publications/publication-dto-model';
import { MatSidenav } from '@angular/material/sidenav';
import { PublicationViewType } from '../shared/publication-view-type';
import { isNilty } from '../../../core/utils.service';

@Component({
  selector: 'app-publications-by-store',
  templateUrl: './publications-by-store.component.html',
  styleUrls: ['./publications-by-store.component.scss'],
})
export class PublicationsByStoreComponent {
  @Input() stores: PublishedStore[];
  currentSelectedStore: ZionStore;

  filters = new PublicationFilters();

  publications: PublicationDto[];

  refreshTable = new EventEmitter<PublicationFilters>();
  onFocusControl = new EventEmitter<string>();
  onClearFilters = new EventEmitter();
  onSearchStatus = new EventEmitter<string>();
  onSearchOperation = new EventEmitter<string>();
  switchViewType = new EventEmitter<PublicationViewType>();

  sidenav: MatSidenav;

  @ViewChild('sidenav') set content(content: MatSidenav) {
    if (content) {
      this.sidenav = content;
    }
  }

  selectedStore(store: PublishedStore) {
    this.currentSelectedStore = store.store;
    this.doRefreshTable();
  }

  filterPublications(filters: PublicationFilters) {
    if (!isNilty(filters.status)) {
      filters.onlyOnline = undefined;
    }
    this.filters = filters;
    this.doRefreshTable();
    if (this.sidenav) {
      this.sidenav.close();
    }
  }

  removeFilterKey(key: string) {
    delete this.filters[key];
    this.doRefreshTable();
  }

  editFilter(key: string) {
    this.onFocusControl.emit(key);
    if (this.sidenav) {
      this.sidenav.open();
    }
  }

  onOnlyOnlineChanged(onlyOnline: boolean) {
    this.filters.onlyOnline = onlyOnline;
    this.removeFilterKey('status');
  }

  doRefreshTable() {
    this.filters.storeCode = this.currentSelectedStore.code;
    setTimeout(() => this.refreshTable.emit(this.filters), 0);
  }

  clearFilters() {
    this.filters = new PublicationFilters();
    this.onClearFilters.emit();
  }

  searchStatus(s: string) {
    this.onSearchStatus.emit(s);
  }
  searchOperation(o: string) {
    this.onSearchOperation.emit(o);
  }
}
