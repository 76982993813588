import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PublicationDto } from '@models/publications/publication-dto-model';
import { ZionCatalogItem } from '@models/zion-catalog-item-model';
import { PublicationPrice, PublicationPriceComponents } from '@models/publications/publication-price-model';
import { CellEvent } from 'ag-grid-community';
import { PublicationService } from '../../../publication.service';
import { CompetitionData, Price } from 'src/app/models/publications/competition-data-model';
import * as echarts from 'echarts/core';
import { TitleComponent, TitleComponentOption } from 'echarts/components';
import { PieChart, PieSeriesOption } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { MyCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';
import { MyCurrencyCodePipe } from 'src/app/shared/pipes/currency-code.pipe';
import { MyPercentPipe } from 'src/app/shared/pipes/percent.pipe';
import { Currency } from 'src/app/models/currency-model';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { BarChart, BarSeriesOption } from 'echarts/charts';
import { PricingStrategy } from '@models/configuration/rules/pricing-strategy-model';

@Component({
  selector: 'app-publication-price-details-modal',
  templateUrl: './publication-price-details-modal.component.html',
  styleUrls: ['./publication-price-details-modal.component.scss'],
})
export class PublicationPriceDetailsModalComponent implements OnInit, AfterViewInit {
  @ViewChild('barChartElem') barChartElem: ElementRef;
  @ViewChild('pieChartElem') pieChartElem: ElementRef;

  ci: ZionCatalogItem;

  columnGroupName: string;
  sku: string;
  barcode: string;

  price: PublicationPrice;
  components: PublicationPriceComponents;
  competitionData: CompetitionData;

  buyBoxPrice: Price;
  distanceClass: string;

  currencies: Currency[];

  protected readonly PricingStrategy = PricingStrategy;
  private publicationDto: PublicationDto;

  constructor(
    public dialogRef: MatDialogRef<PublicationPriceDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { event: CellEvent },
    private publicationService: PublicationService,
    private myCurrencyPipe: MyCurrencyPipe,
    private myCurrencyCodePipe: MyCurrencyCodePipe,
    private myPercentPipe: MyPercentPipe
  ) {}

  chartBarInit() {
    echarts.use([GridComponent, BarChart, CanvasRenderer]);
    type EChartsBarOption = echarts.ComposeOption<GridComponentOption | BarSeriesOption>;

    const chartDom = this.barChartElem.nativeElement;
    chartDom.style.height = '350px';
    const myChart = echarts.init(chartDom);

    const labelOption = {
      show: true,
      rotate: 0,
      formatter: (params) =>
        `${params?.data?.name}:\n${params?.data?.value} ${this.myCurrencyCodePipe.transform(this.price.currency)} ${
          params?.data?.markup ? `\n\nMarkup:\n${params?.data?.markup}` : ''
        }`,
      textAlign: 'center',
      fontSize: 14,
      lineHeight: 18,
      rich: {
        name: {},
      },
    };

    const data = [
      {
        name: 'Min',
        value: this.price.minimum || 0,
      },
      {
        name: 'Target',
        value: this.price.target,
        profit: this.getTargetPriceParameter(),
        itemStyle: {
          color: 'green',
        },
      },
      {
        name: 'Max',
        value: this.price.maximum || 0,
      },
      {
        name: 'BuyBox',
        value: this.publicationDto?.publication?.buyBoxPrice?.amount || this.competitionData?.buyBoxPrice?.amount || 0,
        itemStyle: {
          color: '#ffd814',
        },
      },
    ].filter((it) => it.value !== 0);
    data.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));

    const option: EChartsBarOption = {
      xAxis: {
        type: 'category',
        show: false,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          label: labelOption,
          data,
          type: 'bar',
        },
      ],
    };

    if (option) myChart.setOption(option);
  }

  chartPieInit() {
    echarts.use([TitleComponent, PieChart, LabelLayout, CanvasRenderer]);

    type EChartsPieOption = echarts.ComposeOption<TitleComponentOption | PieSeriesOption>;

    const chartDom = this.pieChartElem.nativeElement;
    chartDom.style.height = '350px';
    const myChart = echarts.init(chartDom);

    const fixedCosts =
      this.components.actualShipmentCost +
      this.components.packaging +
      this.components.poShipmentCost +
      this.components.handling +
      this.components.closingFee;
    const profitValue = this.computeActualProfitValue();
    const vatValue = (this.components.purchasePrice + profitValue + fixedCosts) * this.components.vat;
    const feeValue = this.price.target * this.components.percentageFee;

    const datas = [
      [
        { name: 'Purchase Price', value: this.components.purchasePrice },
        { name: 'Actual shipping cost', value: this.components.actualShipmentCost },
        { name: 'Packaging', value: this.components.packaging },
        { name: 'Po Shipment Cost', value: this.components.poShipmentCost },
        { name: 'Handling', value: this.components.handling },
        { name: 'Closing Fee', value: this.components.closingFee },
        { name: 'Vat', value: vatValue },
        { name: 'Fee', value: feeValue },
        { name: 'Profit', value: profitValue },
      ]
        .filter((it) => it.value !== 0)
        .map((it) => ({ name: it.name, value: +this.myCurrencyPipe.transform(it.value, null, false) })),
    ];

    const option: EChartsPieOption = {
      series: datas.map((data) => {
        const top = 0;
        return {
          type: 'pie',
          radius: [100, 150],
          top: top + '%',
          height: '100%',
          left: 'center',
          width: '100%',
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 1,
            borderRadius: 3,
          },
          label: {
            alignTo: 'edge',
            formatter: `{name|{b}}\n{value|{c}} ${this.myCurrencyCodePipe.transform(this.price.currency)}`,
            minMargin: 5,
            edgeDistance: 10,
            lineHeight: 18,
            rich: {
              name: {
                fontSize: 14,
                color: '#00275c',
              },
              value: {
                fontSize: 14,
                color: '#000',
              },
            },
          },
          labelLine: {
            length: 15,
            length2: 0,
            maxSurfaceAngle: 80,
          },
          labelLayout: (params) => {
            const isLeft = params.labelRect.x < myChart.getWidth() / 2;
            const points = params.labelLinePoints as number[][];
            // Update the end point.
            points[2][0] = isLeft ? params.labelRect.x : params.labelRect.x + params.labelRect.width;

            return {
              labelLinePoints: points,
            };
          },
          data,
        };
      }),
    };

    if (option) myChart.setOption(option);
  }

  ngOnInit() {
    this.publicationDto = this.data.event?.data as PublicationDto;
    this.price = this.data.event?.value as PublicationPrice;
    this.components = this.price.components;
    this.sku = this.publicationDto.publication.sku;
    this.barcode = this.publicationDto.publication.barcode;

    this.columnGroupName = this.data.event?.column?.getOriginalParent()?.getColGroupDef().headerName;

    if (
      this.publicationDto.publication.store.networkType !== 'AMAZON_SELLER' ||
      this.publicationDto.publication.marketplaceMetadata == null
    ) {
      this.competitionData = null;
    } else {
      this.publicationService.getCompetitionData(this.publicationDto.publication).subscribe((data: CompetitionData) => {
        this.competitionData = data;

        this.buyBoxPrice = this.publicationDto.publication.buyBoxPrice || this.competitionData?.buyBoxPrice;
      });
    }

    switch (this.price.buyBoxDistance?.code) {
      case 'LESS_THEN_ZERO': {
        this.distanceClass = 'less-than-zero';
        break;
      }
      case 'ZERO_FIVE': {
        this.distanceClass = 'zero-five';
        break;
      }
      case 'FIVE_TEN': {
        this.distanceClass = 'five-ten';
        break;
      }
      case 'MORE_THEN_TEN': {
        this.distanceClass = 'more-than-ten';
        break;
      }
    }
  }

  ngAfterViewInit() {
    this.chartBarInit();
    if (this.price.components) {
      this.chartPieInit();
    }
  }

  private computeActualProfitValue() {
    if (this.components?.pricingParameters.pricingStrategy === PricingStrategy.RetailPrice) {
      return 0;
    }

    return this.price.actualProfit
      ? this.components.purchasePrice * this.price.actualProfit
      : this.components.purchasePrice * this.components?.pricingParameters?.target;
  }

  private getTargetPriceParameter() {
    return this.myPercentPipe.transform(this.price.actualProfit || this.components?.pricingParameters?.target, true);
  }
}
