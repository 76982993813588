import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdministrationService } from '../administration.service';
import { SupplierFeedReadingStatus } from '../../../../models/configuration/administration/supplier-feed-reading-status-model';
import { MatTableDataSource } from '@angular/material/table';
import { SupplierFeedReadingStatusFilters } from '@models/configuration/administration/filters/supplier-feed-reading-status-filters.model';
import { isNilty } from '@core/utils.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-soup-feeds',
  templateUrl: './soup-feeds.component.html',
  styleUrls: ['./soup-feeds.component.css'],
})
export class SoupFeedsComponent implements OnInit, OnDestroy {
  interval;
  dataSource: MatTableDataSource<SupplierFeedReadingStatus>;
  soupFeeds: SupplierFeedReadingStatus[];
  soupFilters = new SupplierFeedReadingStatusFilters();
  displayedColumns = [
    'skip',
    'status',
    'supplier',
    'distrName',
    'fileName',
    'startedBySoup',
    'finishedBySoup',
    'startedByZion',
    'fullFeed',
    'totalMessages',
    'totalItems',
    'actions',
  ];

  constructor(private administrationService: AdministrationService) {}

  ngOnInit(): void {
    this.getPendingFeeds();
    this.interval = setInterval(() => {
      this.refresh();
    }, 5000);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  refresh() {
    this.getPendingFeeds();
  }

  markAsFinished(f: SupplierFeedReadingStatus) {
    this.administrationService.markFeedAsFinished(f.identifier).subscribe(() => this.refresh());
  }

  skipFeed(feed: SupplierFeedReadingStatus) {
    this.administrationService.setSkipSupplierFeed(feed.identifier).subscribe(() => this.refresh());
  }
  dontSkipFeed(feed: SupplierFeedReadingStatus) {
    this.administrationService.removeSkipSupplierFeed(feed.identifier).subscribe(() => this.refresh());
  }

  applyFilter(filters: SupplierFeedReadingStatusFilters) {
    this.soupFilters = filters;
    this.filterPendingFeeds();
  }

  filterPendingFeeds() {
    let filteredDataSource = this.soupFeeds;
    if (!isNilty(this.soupFilters.distrName)) {
      filteredDataSource = this.soupFeeds.filter((it) => it.distrName === this.soupFilters.distrName);
    }
    if (!isNilty(this.soupFilters.supplier)) {
      filteredDataSource = filteredDataSource.filter((it) => it.supplier === this.soupFilters.supplier);
    }
    if (!isNilty(this.soupFilters.importErrorMessage)) {
      filteredDataSource = filteredDataSource.filter((it) =>
        this.soupFilters.importErrorMessage ? it.importErrorMessage : !it.importErrorMessage
      );
    }
    if (!isNilty(this.soupFilters.skip)) {
      filteredDataSource = filteredDataSource.filter((it) => it.skip === this.soupFilters.skip);
    }
    this.dataSource = new MatTableDataSource<SupplierFeedReadingStatus>(filteredDataSource);
  }

  private getPendingFeeds() {
    this.administrationService.findPendingFeeds().subscribe((r) => {
      this.dataSource = new MatTableDataSource<SupplierFeedReadingStatus>(r);
      this.soupFeeds = r;
      if (_.filter(this.soupFilters, Boolean).length > 0) this.filterPendingFeeds();
    });
  }
}
