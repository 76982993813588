import { Component, Input, OnInit } from '@angular/core';
import { MwsThrottlingSettings } from '../../../../../models/configuration/administration/markus-configuration';
import { MarkusConfigurationService } from '../markus-configuration.service';

@Component({
  selector: 'app-mws-throttling-settings',
  templateUrl: './mws-throttling-settings.component.html',
  styleUrls: ['./mws-throttling-settings.component.scss'],
})
export class MwsThrottlingSettingsComponent implements OnInit {
  settings: MwsThrottlingSettings;

  showHelp = false;
  operations: string[];

  constructor(private markusConfigurationService: MarkusConfigurationService) {}
  @Input() set _settings(c) {
    this.settings = c as MwsThrottlingSettings;
  }

  ngOnInit(): void {
    this.markusConfigurationService.getMarketplaceOperations().subscribe((r) => (this.operations = r.filter((it) => it !== 'UPDATE')));
  }
}
