import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-prices-modal',
  templateUrl: './prices-modal.component.html',
  styleUrls: ['./prices-modal.component.scss'],
})
export class PricesModalComponent {
  constructor(public dialogRef: MatDialogRef<PricesModalComponent>, @Inject(MAT_DIALOG_DATA) public data: { prices: any[] }) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
