import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@core/environment/environment.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class CountriesService {
  constructor(private http: HttpClient, private environmentService: EnvironmentService) {}

  isValidCountryCode(countryCode: string): Observable<boolean> {
    return this.http.get(this.environmentService.getRestEndpoint('isValidCountry') + countryCode).pipe(
      map((r: { countryCode: string; valid: boolean }) => {
        return r.valid;
      })
    );
  }
}
