import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('SolveBlacklistInput')
export class SolveBlacklistInput {
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('type', String, true) type: string = undefined;
  @JsonProperty('note', String, true) note: string = undefined;

  constructor(id: string, type: string, note: string) {
    this.id = id;
    this.type = type;
    this.note = note;
  }
}
