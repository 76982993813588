import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CreateNewMarket')
export class CreateNewMarket {
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('network', String, true) network: string = undefined;
  @JsonProperty('country', String, true) country: string = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('sellingPlatform', String, true) sellingPlatform: string = undefined;
}
