import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { isNilty } from 'src/app/core/utils.service';
import { ProductFilters, catalogItemFiltersMap } from 'src/app/models/filters/product';
import { Status } from 'src/app/models/status-model';
import { SuppliersService } from '../../suppliers/suppliers.service';
import { ProductsService } from '../products.service';
import { MatChipsData } from '@models/mat-chips-data-model';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-products-filters',
  templateUrl: './products-filters.component.html',
  styleUrls: ['./products-filters.component.scss'],
})
export class ProductsFiltersComponent implements OnInit {
  @Output() filterProducts = new EventEmitter<ProductFilters>();

  filters = new ProductFilters();
  productBarcodeTypes: Status[];
  filterForm: FormGroup;
  openFilter = false;
  filtersMap: MatChipsData[] = [];
  isEquivalence: boolean;

  constructor(private supplierService: SuppliersService, private productService: ProductsService, private router: Router) {}

  ngOnInit() {
    this.isEquivalence = this.router.url.includes('equivalence');
    this.filterForm = new FormGroup({
      productBarcodeValue: new FormControl(),
      productBarcodeType: new FormControl(),
      internalSku: new FormControl(),
      title: new FormControl(),
      brand: new FormControl(),
      category: new FormControl(),
    });

    this.loadBarcodeTypes();
    if (!isNilty(this.filters)) {
      this.filterForm.patchValue(this.filters);
      this.updateFiltersMap();
    }
  }

  search() {
    this.updateFiltersMap();
    this.filterProducts.emit(this.filters);
  }

  reset() {
    this.filters = new ProductFilters();
    this.filterForm.reset();
    this.updateFiltersMap();
    this.filterProducts.emit(this.filters);
  }

  loadBarcodeTypes() {
    this.supplierService.getProductBarcodeTypes().subscribe((s) => (this.productBarcodeTypes = s));
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeevent() {
    this.openFilter = false;
  }

  updateFiltersMap() {
    this.filtersMap = catalogItemFiltersMap(
      this.filterForm.value.productBarcodeValue,
      this.filterForm.value.productBarcodeType,
      this.filterForm.value.internalSku,
      this.filterForm.value.title,
      this.filterForm.value.brand,
      this.filterForm.value.category
    );
  }
}
