import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '@core/converter.service';
import { EnvironmentService } from '@core/environment/environment.service';
import { Observable } from 'rxjs';
import { PowerSellerRule } from '@models/configuration/rules/power-seller-rule/power-seller-rule-model';
import { map } from 'rxjs/operators';
import { isNilty } from '@core/utils.service';
import { RuleStockLocationSource, RuleSupplierSource } from '@models/configuration/rules/publication-rule-source-model';
import { PartialRuleRefreshInput } from '@models/configuration/rules/publication-rule/partial-rule-refresh-input-model';

@Injectable()
export class PowerSellerRulesService {
  constructor(private http: HttpClient, private converter: ConverterService, private environment: EnvironmentService) {}

  findAllPowerSellerRules(): Observable<PowerSellerRule[]> {
    return this.http.get(this.environment.getMarkusRestEndpoint('powerSellerRules')).pipe(
      map((r: PowerSellerRule[]) => {
        if (isNilty(r)) return [];
        return r.map((it) => this.converter.fromJSONtoObj(it, PowerSellerRule));
      })
    );
  }

  findPowerSellerRule(id: string): Observable<PowerSellerRule> {
    return this.http
      .get(this.environment.getMarkusRestEndpoint('powerSellerRules') + '/' + id)
      .pipe(map((r: PowerSellerRule) => this.converter.fromJSONtoObj(r, PowerSellerRule)));
  }

  savePowerSellerRule(p: PowerSellerRule): Observable<any> {
    return this.http.post(this.environment.getMarkusRestEndpoint('powerSellerRules'), this.converter.fromObjtoJSON(p));
  }

  refreshRule(rule: PowerSellerRule): Observable<any> {
    const body = this.converter.fromObjtoJSON(rule);
    const path = this.environment.getMarkusRestEndpoint('refreshPowerSellerRule');
    return this.http.post(path, body);
  }

  partiallyRefreshRule(rule: PowerSellerRule, suppliers: RuleSupplierSource[], stockLocations: RuleStockLocationSource[]): Observable<any> {
    const body = this.converter.fromObjtoJSON(PartialRuleRefreshInput.fromPowerSellerRule(rule, suppliers, stockLocations));
    const path = this.environment.getMarkusRestEndpoint('partiallyRefreshPowerSellerRule');
    return this.http.post(path, body);
  }
}
