import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StoreService } from '../store.service';
import { CreateNewStore } from '@models/configuration/marketplace/create-new-store';
import { CommonsService } from '@shared/commons.service';
import { Currency } from '@models/currency-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MarketplaceDto } from '@models/configuration/marketplace/marketplace-model';
import { StoreGroup } from '@models/configuration/marketplace/store-group-model';
import { NewMarketplaceModalComponent } from './new-marketplace-modal/new-marketplace-modal.component';
import { isNilty } from '@core/utils.service';
import { NewStoreGroupModalComponent } from './new-store-group-modal/new-store-group-modal.component';
import { AccountView } from '@models/configuration/marketplace/store-view-model';

@Component({
  selector: 'app-add-store',
  templateUrl: './add-store.component.html',
  styleUrls: ['./add-store.component.css'],
})
export class AddStoreComponent implements OnInit {
  storeForm: FormGroup;

  orderTypes: string[];
  currencies: Currency[];
  countries: string[];
  accounts: AccountView[] = [];
  storeGroups: StoreGroup[] = [];
  marketplaces: MarketplaceDto[];

  selectedMarketplaceName: string;

  selectedCountry: string;
  selectedCurrency = new Currency();
  selectedAccount: AccountView = new AccountView();
  selectedStoreGroupName: string;
  selectedOrderType: string;

  constructor(
    public dialogRef: MatDialogRef<AddStoreComponent>,
    private commonService: CommonsService,
    private storeService: StoreService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.storeForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      code: new FormControl(null, [Validators.required, Validators.pattern('[A-Z0-9-_]*')]),
      country: new FormControl(null, Validators.required),
      marketplace: new FormControl(null, Validators.required),
      currency: new FormControl(null, Validators.required),
      account: new FormControl(null, Validators.required),
      storeGroup: new FormControl(null),
      orderType: new FormControl(null, Validators.required),
      channelStoreName: new FormControl(null),
    });

    this.refreshMarketplaces();
    this.refreshStoreGroups();
    this.commonService.getOrderTypes().subscribe((r) => (this.orderTypes = r));
    this.commonService.getCurrencies().subscribe((r) => (this.currencies = r));
    this.storeService.getAllCountries().subscribe((r) => (this.countries = r.sort((a, b) => (a > b ? 1 : -1))));
    this.storeService.getAllAccounts().subscribe((r) => (this.accounts = r).sort((a, b) => (a.name > b.name ? 1 : -1)));
  }

  refreshMarketplaces(name?: string) {
    this.storeService.getMarketplaces().subscribe((r) => {
      this.marketplaces = r.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.selectedMarketplaceName = name;
      this.storeForm.controls.marketplace.patchValue(this.selectedMarketplaceName);
    });
  }

  refreshStoreGroups(name?: string) {
    this.storeService.getAllStoreGroups().subscribe((r) => {
      this.storeGroups = r.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.selectedStoreGroupName = name;
      this.storeForm.controls.storeGroup.patchValue(this.selectedStoreGroupName);
    });
  }

  onSubmit() {
    const requestBody = new CreateNewStore();

    requestBody.code = this.storeForm.value.code;
    requestBody.name = this.storeForm.value.name;
    requestBody.country = this.storeForm.value.country;
    requestBody.currency = this.storeForm.value.currency;
    requestBody.accountCode = this.storeForm.value.account;
    requestBody.storeGroupName = this.storeForm.value.storeGroup;
    requestBody.marketplaceName = this.selectedMarketplaceName;
    requestBody.orderType = this.selectedOrderType;
    requestBody.channelStoreName = this.storeForm.value.channelStoreName;

    this.storeService.addStore(requestBody).subscribe(() => {
      this.snackbar.open('Changes have been saved.', 'CLOSE')._dismissAfter(3000);
      this.dialogRef.close(true);
    });
  }

  addNewStoreGroup() {
    this.dialog
      .open(NewStoreGroupModalComponent)
      .afterClosed()
      .subscribe((storeGroupName: string) => {
        if (isNilty(storeGroupName)) return;
        this.refreshStoreGroups(storeGroupName);
      });
  }

  addNewMarketplace() {
    this.dialog
      .open(NewMarketplaceModalComponent)
      .afterClosed()
      .subscribe((marketplaceName: string) => {
        if (isNilty(marketplaceName)) return;
        this.refreshMarketplaces(marketplaceName);
      });
  }

  close() {
    this.dialogRef.close(false);
  }
}
