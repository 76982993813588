import { JsonObject, JsonProperty, Any } from 'json2typescript';

@JsonObject('CatalogFeedHistoryEntry')
export class CatalogFeedHistoryEntry {
  @JsonProperty('feedId', Number, true) feedId: number = undefined;
  @JsonProperty('importTimestamp', String, true) importTimestamp: string = undefined;
  @JsonProperty('feed', Any, true) feed: any = undefined;
  @JsonProperty('fileUrl', String, true) fileUrl: string = undefined;
}

@JsonObject('AvailabilityHistoryEntry')
export class AvailabilityHistoryEntry {
  @JsonProperty('available', Boolean, true) available: boolean = undefined;
  @JsonProperty('quantity', Number, true) quantity: number = undefined;
  @JsonProperty('feedId', Number, true) feedId: number = undefined;
  @JsonProperty('timestamp', String, true) timestamp: string = undefined;
  @JsonProperty('fileUrl', String, true) fileUrl: string = undefined;
}

@JsonObject('QuantityHistoryEntry')
export class QuantityHistoryEntry {
  @JsonProperty('quantity', Number, true) quantity: number = undefined;
  @JsonProperty('feedId', Number, true) feedId: number = undefined;
  @JsonProperty('timestamp', String, true) timestamp: string = undefined;
  @JsonProperty('fileUrl', String, true) fileUrl: string = undefined;
}

@JsonObject('PriceHistoryEntry')
export class PriceHistoryEntry {
  @JsonProperty('price', Number, true) price: number = undefined;
  @JsonProperty('discountedPrice', Number, true) discountedPrice: number = undefined;
  @JsonProperty('discountPercentage', Number, true) discountPercentage: number = undefined;
  @JsonProperty('discountCode', String, true) discountCode: string = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('feedId', Number, true) feedId: number = undefined;
  @JsonProperty('timestamp', String, true) timestamp: string = undefined;
  @JsonProperty('fileUrl', String, true) fileUrl: string = undefined;
}

@JsonObject('CatalogHistory')
export class CatalogHistory {
  @JsonProperty('catalogId', Number, true) catalogId: number = undefined;
  @JsonProperty('supplierCode', String, true) supplierCode: string = undefined;
  @JsonProperty('ean', String, true) ean: string = undefined;
  @JsonProperty('feedHistory', [CatalogFeedHistoryEntry], true) feedHistory: CatalogFeedHistoryEntry[] = undefined;
  @JsonProperty('availabilityHistory', [AvailabilityHistoryEntry], true) availabilityHistory: AvailabilityHistoryEntry[] = undefined;
  @JsonProperty('quantityHistory', [QuantityHistoryEntry], true) quantityHistory: QuantityHistoryEntry[] = undefined;
  @JsonProperty('priceHistory', [PriceHistoryEntry], true) priceHistory: PriceHistoryEntry[] = undefined;
}
