export const statusColorMap = (value) => {
  switch (value) {
    case 'NEW': {
      return '#1ca4ff';
    }
    case 'CREATING': {
      return '#87c5de';
    }
    case 'PENDING': {
      return '#a3de87';
    }
    case 'PUBLISHED': {
      return '#3d9d00';
    }
    case 'DISABLED': {
      return '#ffbf00';
    }
    case 'DISABLE_PENDING': {
      return '#ffa264';
    }
    case 'BLACKLISTED': {
      return 'black';
    }
    case 'BLACKLIST_PENDING': {
      return '#7e7e7e';
    }
    case 'ERROR': {
      return 'red';
    }
  }
};

export const statusCursor = (value) => {
  switch (value) {
    case 'ERROR': {
      return 'pointer';
    }
    default: {
      return 'auto';
    }
  }
};

export const marketplaceOperationColorMap = (value) => {
  switch (value) {
    case 'CREATE': {
      return '#87c5de';
    }
    case 'UPDATE': {
      return '#a3de87';
    }
    case 'UPDATE_PRICE': {
      return '#a3de87';
    }
    case 'UPDATE_QUANTITY': {
      return '#a3de87';
    }
    case 'DISABLE': {
      return '#ffa264';
    }
    case 'BLACKLIST': {
      return '#7e7e7e';
    }
    default: {
      return 'black';
    }
  }
};

export const singleNumericColumn = (headerName: string, field: string) => ({ headerName, field, type: 'numericColumn', minWidth: 160 });
