import { Component, OnInit, ViewChild } from '@angular/core';
import { StoreFeesConfiguration } from '@models/configuration/marketplace/store-fees-configuration-model';
import { StoreFeesFilters } from '@models/configuration/marketplace/filters/store-fees-filters-model';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { ZionStore } from '@models/publications/published-store-model';
import { cloneClass, isNilty } from '@core/utils.service';
import { CommonsService } from '@shared/commons.service';
import { Currency } from '@models/currency-model';
import { FeesConfigurationService } from '../fees-configuration.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-store-fees-configuration',
  templateUrl: './store-fees-configuration.component.html',
  styleUrls: ['./store-fees-configuration.component.scss'],
})
export class StoreFeesConfigurationComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  logisticPartners: string[];
  currencies: Currency[];

  stores: ZionStore[];
  storeFees: StoreFeesConfiguration[];

  filters = new StoreFeesFilters();

  resultsNumber: Observable<number>;

  selectedRow: StoreFeesConfiguration;

  displayedColumns = [
    'storeCode',
    'logisticPartner',
    'packaging',
    'poShipmentCost',
    'handling',
    'defaultActualShipmentCost',
    'shipmentClass',
    'edit',
  ];
  dataSource = new MatTableDataSource<StoreFeesConfiguration>();

  constructor(
    private storeFeesService: FeesConfigurationService,
    private commonsService: CommonsService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((p) => {
      if (!isNilty(p['storeCode'])) {
        this.filters.storeCode = p['storeCode'];
      }
    });

    this.resultsNumber = this.storeFeesService.resultsNumber;
    this.commonsService.getLogisticPartners().subscribe((it) => (this.logisticPartners = it));
    this.commonsService.getCurrencies().subscribe((it) => (this.currencies = it));
    this.storeFeesService.findAllStores().subscribe((it) => (this.stores = it));
    this.search();
  }

  search() {
    this.storeFeesService.findByFilters(this.filters).subscribe((it) => {
      this.storeFees = it;
      this.dataSource.data = this.storeFees;
    });
  }

  reset() {
    this.filters = new StoreFeesFilters();
    this.filters.pageSize = this.paginator.pageSize;
    this.filters.pageNumber = this.paginator.pageIndex;
    this.search();
  }

  newFeesConfiguration() {
    this.selectedRow = new StoreFeesConfiguration();
  }

  loadPage() {
    this.filters.pageSize = this.paginator.pageSize;
    this.filters.pageNumber = this.paginator.pageIndex;
    this.search();
  }

  onCancel() {
    this.selectedRow = undefined;
  }

  onSave(sc: StoreFeesConfiguration) {
    this.storeFeesService.saveStoreFees(sc).subscribe(() => {
      this.selectedRow = undefined;
      this.search();
    });
  }

  edit(sc: StoreFeesConfiguration) {
    this.selectedRow = cloneClass(sc);
  }
}
