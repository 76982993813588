import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNilty } from 'src/app/core/utils.service';
import { SuppliersService } from '../../container/suppliers/suppliers.service';
import { Supplier } from '../../models/supplier-model';

@Pipe({
  name: 'supplierPipe',
})
export class SupplierPipe implements PipeTransform {
  constructor(private suppliersService: SuppliersService) {}

  transform(supplierId: number, key: string = 'code'): Observable<string> {
    if (isNilty(supplierId)) {
      return of(supplierId.toString());
    }

    return this.suppliersService.getAllSuppliers().pipe(
      map((suppliers: Supplier[]) => {
        const supplier = suppliers.find((it) => it.id === supplierId);
        if (isNilty(supplier)) {
          return supplierId.toString();
        }
        switch (key) {
          case 'code':
            return supplier.code.toString();
          case 'name':
            return supplier.name.toString();
        }
      })
    );
  }
}
