import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Status } from 'src/app/models/status-model';
import { isNilty } from '@core/utils.service';
import { StockItemFilters, stockItemFiltersMap } from '@models/filters/stock-item';
import { MatChipsData } from '@models/mat-chips-data-model';
import { StockLocation } from '@models/stock-location-model';
import { Supplier } from '@models/supplier-model';
import { SuppliersService } from '../../suppliers/suppliers.service';
import { StockItemsService } from '../stock-items.service';

@Component({
  selector: 'app-stock-items-filters',
  templateUrl: './stock-items-filters.component.html',
  styleUrls: ['./stock-items-filters.component.css'],
})
export class StockItemsFiltersComponent implements OnInit {
  @Output() onFilter = new Subject<StockItemFilters>();
  filterForm: FormGroup;
  openFilter = false;

  filtersMap: MatChipsData[] = [];

  suppliers: Supplier[];
  productBarcodeTypes: Status[];
  stockLocations: StockLocation[];

  constructor(private stockItemsService: StockItemsService, private suppliersService: SuppliersService) {}

  ngOnInit() {
    this.filterForm = new FormGroup({
      productBarcodeValue: new FormControl(),
      productBarcodeType: new FormControl(),
      stockLocation: new FormControl(),
      supplier: new FormControl(),
      sku: new FormControl(),
      internalSku: new FormControl(),
      hasQuantity: new FormControl(),
    });

    if (!isNilty(this.stockItemsService.stockItemFilters)) {
      this.filterForm.patchValue(this.stockItemsService.stockItemFilters);
      this.updateFiltersMap();
    }

    this.suppliersService.getAllSuppliers().subscribe((response) => {
      this.suppliers = response;
    });
    this.suppliersService.getProductBarcodeTypes().subscribe((response) => {
      this.productBarcodeTypes = response;
      this.setProductBarcodeType();
    });
    this.stockItemsService.getAllStockLocations().subscribe((response) => {
      this.stockLocations = response;
    });
  }

  setProductBarcodeType() {
    if (this.stockItemsService.stockItemFilters) {
      this.filterForm.controls['productBarcodeType'].patchValue(
        this.productBarcodeTypes.find((it) => it.code === this.stockItemsService.stockItemFilters.productBarcodeType)
      );
      this.updateFiltersMap();
    }
  }

  onSubmitFilter() {
    this.saveFiltersToChargebackService();
    this.updateFiltersMap();
    this.onFilter.next(this.stockItemsService.stockItemFilters);
  }

  resetFilters() {
    this.filterForm.reset();
    this.saveFiltersToChargebackService();
    this.updateFiltersMap();
    this.onFilter.next(this.stockItemsService.stockItemFilters);
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeevent() {
    this.openFilter = false;
  }

  saveFiltersToChargebackService() {
    this.stockItemsService.stockItemFilters.productBarcodeValue = this.filterForm.value.productBarcodeValue;
    this.stockItemsService.stockItemFilters.productBarcodeType = this.filterForm.value.productBarcodeType?.code;
    this.stockItemsService.stockItemFilters.stockLocation = this.filterForm.value.stockLocation;
    this.stockItemsService.stockItemFilters.supplier = this.filterForm.value.supplier;
    this.stockItemsService.stockItemFilters.sku = this.filterForm.value.sku;
    this.stockItemsService.stockItemFilters.internalSku = this.filterForm.value.internalSku;
    this.stockItemsService.stockItemFilters.hasQuantity = this.filterForm.value.hasQuantity;
  }

  updateFiltersMap() {
    this.filtersMap = stockItemFiltersMap(
      this.filterForm.value.productBarcodeValue,
      this.filterForm.value.productBarcodeType,
      this.filterForm.value.stockLocation,
      this.filterForm.value.supplier,
      this.filterForm.value.sku,
      this.filterForm.value.internalSku,
      this.filterForm.value.hasQuantity
    );
  }
}
