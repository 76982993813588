import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MetadataPriorityEntries } from '@models/configuration/metadata-priority-model';
import { MetadataPriorityService } from 'src/app/container/configuration/metadata-priority/metadata-priority.service';

@Component({
  selector: 'app-metadata-export-source',
  templateUrl: './metadata-export-source.component.html',
  styleUrls: ['./metadata-export-source.component.css'],
})
export class MetadataExportSourceComponent implements OnInit {
  @Input() metadataPriorityEntries: MetadataPriorityEntries[];
  @Output() metadataPriorityEntriesChange = new EventEmitter<MetadataPriorityEntries[]>();

  metadataPrioritySources: string[];

  actualPriority = 0;

  constructor(private metadataPriorityService: MetadataPriorityService) {}

  ngOnInit() {
    this.metadataPriorityService.getMetadataPrioritySource().subscribe((r) => (this.metadataPrioritySources = r));
    this.newRow();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.metadataPriorityEntries, event.previousIndex, event.currentIndex);
  }

  deleteEntry(index: number) {
    this.metadataPriorityEntries.splice(index, 1);
  }

  newRow() {
    this.metadataPriorityEntries.push(new MetadataPriorityEntries('', this.actualPriority++));
  }
}
