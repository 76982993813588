import { JsonObject, JsonProperty } from 'json2typescript';

import { RulePredicate } from '@models/configuration/rules/rule-predicate-model';
import { BarcodePolicy, PredicatesPolicy, WordsPolicy } from '@models/configuration/policies/policies-model';
import { RestrictionTargetType } from '@models/configuration/policies/restrictions/restriction-target-type';
import { ListingSellingPlatform } from '@models/listings/listing-selling-platform-model';

@JsonObject('RestrictionTarget')
export class RestrictionTarget {
  @JsonProperty('type', String, true) type: string = undefined;
  @JsonProperty('identifier', String, true) identifier: string = undefined;

  description: string;

  constructor(type: RestrictionTargetType, identifier: string) {
    this.type = type;
    this.identifier = identifier;
    this.description = this.buildDescription();
  }

  buildDescription() {
    return this.type + (this.type !== RestrictionTargetType.GLOBAL ? ` ${this.identifier}` : '');
  }
}

@JsonObject('Restriction')
export class Restriction {
  @JsonProperty('target', RestrictionTarget, true) target: RestrictionTarget = undefined;
  @JsonProperty('restrictionType', String, true) restrictionType: string = undefined;
  @JsonProperty('date', String, true) date: string = undefined;
  @JsonProperty('notes', String, true) notes: string = undefined;
  @JsonProperty('type', String, true) type: string = undefined;
  @JsonProperty('id', String, true) id: string = undefined;

  @JsonProperty('allowedSellingPlatforms', [ListingSellingPlatform], true) allowedSellingPlatforms: ListingSellingPlatform[] = undefined;
  @JsonProperty('deniedSellingPlatforms', [ListingSellingPlatform], true) deniedSellingPlatforms: ListingSellingPlatform[] = undefined;

  allowOrDeny: 'ALLOW' | 'DENY';
  name: string;

  constructor(target: RestrictionTarget, notes: string, type: string, restrictionType: string) {
    this.target = target;
    this.notes = notes;
    this.type = type;
    this.restrictionType = restrictionType;
  }

  setAllowed(sp: ListingSellingPlatform[]) {
    this.allowOrDeny = 'ALLOW';
    this.deniedSellingPlatforms = undefined;
    this.allowedSellingPlatforms = sp;
  }

  setDenied(sp: ListingSellingPlatform[]) {
    this.allowOrDeny = 'DENY';
    this.deniedSellingPlatforms = sp;
    this.allowedSellingPlatforms = undefined;
  }
}

@JsonObject('BarcodeRestriction')
export class BarcodeRestriction extends Restriction implements BarcodePolicy {
  @JsonProperty('barcodeType', String, true) barcodeType: string = undefined;
  @JsonProperty('barcodeValue', String, true) barcodeValue: string = undefined;
}
export const buildBarcodeRestriction = (wl: Restriction, barcodeType: string, barcodeValue: string): BarcodeRestriction => {
  const bb = new BarcodeRestriction(wl.target, wl.notes, wl.type, wl.restrictionType);
  bb.barcodeType = barcodeType;
  bb.barcodeValue = barcodeValue;
  return bb;
};

@JsonObject('WordsRestriction')
export class WordsRestriction extends Restriction implements WordsPolicy {
  @JsonProperty('field', String, true) field: string = undefined;
  @JsonProperty('words', [String], true) words: string[] = undefined;
}
export const buildWordsRestriction = (wl: Restriction, field: string, words: string[]): WordsRestriction => {
  const wb = new WordsRestriction(wl.target, wl.notes, wl.type, wl.restrictionType);
  wb.field = field;
  wb.words = words;
  return wb;
};

@JsonObject('PredicatesRestriction')
export class PredicatesRestriction extends Restriction implements PredicatesPolicy {
  @JsonProperty('predicates', [RulePredicate], true) predicates: RulePredicate[] = undefined;
}
export const buildPredicatesRestriction = (wl: Restriction, predicates: RulePredicate[]): PredicatesRestriction => {
  const pb = new PredicatesRestriction(wl.target, wl.notes, wl.type, wl.restrictionType);
  pb.predicates = predicates;
  return pb;
};
