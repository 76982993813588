import { JsonObject, JsonProperty } from 'json2typescript';
import { ProductBarcode } from './product-barcode-model';
import { StockLocation } from './stock-location-model';

@JsonObject('StockItem')
export class StockItem {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('productBarcode', ProductBarcode, true) productBarcode: ProductBarcode = undefined;
  @JsonProperty('stockLocation', StockLocation, true) stockLocation: StockLocation = undefined;
  @JsonProperty('physicalQuantity', Number, true) physicalQuantity: number = undefined;
  @JsonProperty('availableQuantity', Number, true) availableQuantity: number = undefined;
  @JsonProperty('title', String, true) title: string = undefined;
  @JsonProperty('availabilityCode', String, true) availabilityCode: string = undefined;
  @JsonProperty('purchasePrice', Number, true) purchasePrice: number = undefined;
  @JsonProperty('purchasePriceCurrency', String, true) purchasePriceCurrency: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('actualSupplierCode', String, true) actualSupplierCode: string = undefined;
  @JsonProperty('createdAt', String, true) createdAt: string = undefined;
  @JsonProperty('updatedAt', String, true) updatedAt: string = undefined;
  @JsonProperty('updatedWithLastFeed', Boolean, true) updatedWithLastFeed: boolean = undefined;
  @JsonProperty('lastFeedUpdate', String, true) lastFeedUpdate: string = undefined;
}
