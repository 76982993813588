import { Component, Input, OnInit } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { GenericConfirmationModalComponent } from '@shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { CustomShipmentClass } from '@models/configuration/rules/publication-rule/custom-shipment-class-model';
import { CommonsService } from '@shared/commons.service';
import { Currency } from '@models/currency-model';

@Component({
  selector: 'app-rule-shipment-classes',
  templateUrl: './rule-shipment-classes.component.html',
  styleUrls: ['./rule-shipment-classes.component.css'],
})
export class RuleShipmentClassesComponent implements OnInit {
  ruleShipmentClasses: CustomShipmentClass[];
  sort = false;
  currencies: Currency[];

  constructor(private dialog: MatDialog, private commonsService: CommonsService) {}

  @Input() set _ruleShipmentClasses(rfl: CustomShipmentClass[]) {
    this.ruleShipmentClasses = rfl;
    this.ruleShipmentClasses.forEach((it) => it.setDescription());
  }

  ngOnInit() {
    this.commonsService.getCurrencies().subscribe((r) => (this.currencies = r));
  }

  addShipmentClass() {
    this.ruleShipmentClasses.push(new CustomShipmentClass());
  }

  confirm(m: CustomShipmentClass, exp: MatExpansionPanel) {
    m.setDescription();
    exp.close();
  }

  removeShipmentClass(index: number) {
    this.dialog
      .open(GenericConfirmationModalComponent, {
        data: `This will remove the custom shipment class and all its predicates.`,
      })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          this.ruleShipmentClasses.splice(index, 1);
        }
      });
  }

  toggleSorting(event: MatSlideToggleChange) {
    this.sort = event.checked;
  }

  drop(event) {
    moveItemInArray(this.ruleShipmentClasses, event.previousIndex, event.currentIndex);
  }
}
