import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../../../../core/environment/environment.service';
import { Observable } from 'rxjs';
import { PublicationEnumsOutput } from '../../../../models/publications/publication-enums-output-model';
import { cachedGet, GenericCache } from '../../../../shared/service-cached';
import { ConverterService } from '../../../../core/converter.service';
import { map } from 'rxjs/operators';

@Injectable()
export class MarkusConfigurationService {
  private enums = new GenericCache<PublicationEnumsOutput>(this.http, this.converterService);

  constructor(private http: HttpClient, private environmentService: EnvironmentService, private converterService: ConverterService) {}

  getAll(): Observable<any> {
    return this.http.get(this.environmentService.getMarkusRestEndpoint('markusConfiguration'));
  }

  save(c: any): Observable<any> {
    return this.http.post(this.environmentService.getMarkusRestEndpoint('markusConfiguration'), c);
  }

  getMarketplaceOperations(): Observable<string[]> {
    return cachedGet<PublicationEnumsOutput>(this.enums, this.environmentService.getMarkusRestEndpoint('publicationsEnums')).pipe(
      map((it: PublicationEnumsOutput) => it.marketplaceOperations)
    );
  }
}
