import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomMetaDataField, CustomMetadataImportConfiguration } from '@models/configuration/custom-metadata-field-model';
import { camelize, isNilty, isValidCamelCase } from '@core/utils.service';
import { CustomMetadataService } from '../custom-metadata.service';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-add-custom-metadata-field-modal',
  templateUrl: './add-custom-metadata-field-modal.component.html',
  styleUrls: ['./add-custom-metadata-field-modal.component.scss'],
})
export class AddCustomMetadataFieldModalComponent {
  newField = new CustomMetaDataField();

  similarFields: CustomMetaDataField[] = [];

  labelTouched = false;

  isLabelValid = true;

  types: ('STRING' | 'INT' | 'DOUBLE' | 'BOOLEAN')[] = ['STRING', 'INT', 'DOUBLE', 'BOOLEAN'];

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  constructor(
    public dialogRef: MatDialogRef<AddCustomMetadataFieldModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomMetaDataField[],
    private customMetadataService: CustomMetadataService,
    private dialog: MatDialog
  ) {}

  nameChanged(name: string) {
    this.autocompleteLabel(name);
    this.filterSimilarFields(name);
  }

  autocompleteLabel(name: string) {
    if (this.labelTouched) return;
    if (isNilty(this.newField.name)) {
      this.newField.label = undefined;
      return;
    }
    this.newField.label = camelize(name);
    this.checkLabelValidity();
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = input.value;
    this.newField.tags.push(value);
    input.value = '';
    this.filterSimilarFields();
  }
  removeTag(index: number) {
    this.newField.tags.splice(index, 1);
  }

  changedDataType(dataType: string) {
    if (dataType === 'STRING' || dataType === 'BOOLEAN') {
      this.newField.importConfiguration = undefined;
    }
    if (dataType === 'INT' || dataType === 'DOUBLE') {
      this.newField.importConfiguration = new CustomMetadataImportConfiguration();
    }
  }

  save() {
    this.checkLabelValidity();
    if (!this.isLabelValid) {
      return;
    }

    this.customMetadataService.save(this.newField).subscribe(() => this.dialogRef.close(true));
  }

  checkLabelValidity() {
    if (isNilty(this.newField.label)) this.isLabelValid = true;
    this.isLabelValid = isValidCamelCase(this.newField.label);
  }

  private filterSimilarFields(name?: string) {
    this.similarFields = [];
    this.filterSimilarFieldsByName(name ? name : this.newField.name);
    this.filterSimilarFieldsByTags(this.newField.tags);
  }

  private filterSimilarFieldsByName(name: string): CustomMetaDataField[] {
    if (isNilty(name)) {
      return [];
    }

    const words = name.split(' ').filter((it) => it.length > 3);

    if (words.length === 0) return [];

    const similarFields = [];

    words.forEach((word: string) => {
      this.customMetadataService.searchMatchingFields(this.data, word).forEach((field) => {
        this.addToSimilarFields(field);
      });
    });
    return similarFields;
  }

  private filterSimilarFieldsByTags(tags: string[]): CustomMetaDataField[] {
    if (tags.length === 0) return [];

    tags.forEach((tag) => {
      this.data.forEach((field) => {
        if (field.tags.indexOf(tag) !== -1) this.addToSimilarFields(field);
      });
    });
  }

  private addToSimilarFields(field: CustomMetaDataField) {
    if (this.similarFields.find((it) => it.label === field.label) === undefined) this.similarFields.push(field);
  }
}
