import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { ExchangeRates, ExchangeRatesFilters } from '@models/exchange-rates-model';
import { ExchangeRatesService } from './exchange-rates.service';

@Component({
  selector: 'app-exchange-rates',
  templateUrl: './exchange-rates.component.html',
  styleUrls: ['./exchange-rates.component.css'],
})
export class ExchangeRatesComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  exchangeRatesFilters: ExchangeRatesFilters;
  exchangeArray = [];

  filterForm: FormGroup;

  constructor(private exchangeRatesService: ExchangeRatesService) {}

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      updatedAt: new FormControl(new Date()),
    });
    this.search();
  }

  getExchangeRatesEndpoints() {
    this.exchangeRatesService.getExchangeRatesEndpoints().subscribe((resp: ExchangeRates[]) => {
      this.exchangeArray = resp;
    });
  }

  search() {
    this.exchangeRatesFilters = this.setExchangeRatesFilter();
    this.loadExchangeRates();
  }

  setExchangeRatesFilter(): ExchangeRatesFilters {
    const filters = new ExchangeRatesFilters();
    filters.updatedAt = this.filterForm.value.updatedAt ? this.filterForm.value.updatedAt : new Date();
    return filters;
  }

  private loadExchangeRates() {
    this.exchangeRatesService.getExchangeRatesByFilters(this.exchangeRatesFilters).subscribe((resp: ExchangeRates[]) => {
      this.exchangeArray = resp;
    });
  }
}
