/* eslint-disable */
import { JsonObject, JsonProperty } from 'json2typescript';
import { FtpDestination } from './ftp-destination-model';
import { RouteConfiguration } from './route-configuration-model';
import { B2bRouteConfiguration } from './route-configurations/b2b-route-configuration-model';
import { FtpRouteConfiguration } from './route-configurations/ftp-route-configuration-model';
import { HttpRouteConfiguration } from './route-configurations/http-route-configuration-model';
import { AwsSftpConfiguration } from './route-configurations/aws-sftp-configuration-model';
import { ItemFilter } from './item-filter-model';

export enum ROUTE_TYPES {
  FTP = 'FTP',
  B2B = 'B2B',
  HTTP = 'HTTP',
  AWS_SFTP = 'AWS_SFTP',
}

@JsonObject('SoupConfiguration')
export class SoupConfiguration {
  @JsonProperty('id', String, true) id: string = undefined;

  @JsonProperty('distrName', String, true) distrName: string = undefined;
  @JsonProperty('supplierCode', String, true) supplierCode: string = undefined;
  @JsonProperty('stockLocationCode', String, true) stockLocationCode: string = undefined;
  @JsonProperty('supplierName', String, true) supplierName: string = undefined;

  @JsonProperty('routeType', String, true) routeType: string = undefined;

  @JsonProperty('active', Boolean, true) active = true;

  @JsonProperty('destinationClients', [String], true) destinationClients: string[] = undefined;
  @JsonProperty('destinationFtps', [FtpDestination], true) destinationFtps: FtpDestination[] = undefined;

  @JsonProperty('applyDiscount', Boolean, true) applyDiscount = false;
  @JsonProperty('defaultDiscount', Number, true) defaultDiscount: number = undefined;
  @JsonProperty('discountPercentage', Boolean, true) discountPercentage: boolean = undefined;

  @JsonProperty('fullFeed', Boolean, true) fullFeed = false;
  @JsonProperty('addProduct', Boolean, true) addProduct = false;
  @JsonProperty('skipQuantity', Boolean, true) skipQuantity: boolean = undefined;
  @JsonProperty('custom', Boolean, true) custom = false;

  @JsonProperty('errorThreshold', Number, true) errorThreshold = 1.0;
  @JsonProperty('feedFrequencyExpected', Number, true) feedFrequencyExpected = 24;
  @JsonProperty('external', Boolean, true) external: boolean = undefined;

  @JsonProperty('findDiscount', Boolean, true) findDiscount = false;

  @JsonProperty('vatPercentageToRemove', Number, true) vatPercentageToRemove = 0.0;

  @JsonProperty('itemFilters', [ItemFilter], true) itemFilters: ItemFilter[] = [];

  @JsonProperty('findCountryOfOrigin', Boolean, true) findCountryOfOrigin = false;

  @JsonProperty('findPublisher', Boolean, true) findPublisher = false;

  @JsonProperty('estimateWeight', Boolean, true) estimateWeight = false;

  @JsonProperty('multiSupplier', Boolean, true) multiSupplier = false;

  @JsonProperty('defaultWeightUnit', String, true) defaultWeightUnit: string = undefined;
  @JsonProperty('defaultLengthUnit', String, true) defaultLengthUnit: string = undefined;

  @JsonProperty('metadataSource', Boolean, true) metadataSource = false;

  @JsonProperty('findDiscountByGLN', Boolean, true) findDiscountByGLN = false;

  @JsonProperty('sourceType', String, true) sourceType: string = undefined;

  @JsonProperty('sourceId', String, true) sourceId: string = undefined;

  constructor() {
    this.destinationClients = [];
    this.destinationFtps = [];
  }
}

@JsonObject('GenericSoupConfiguration')
export class GenericSoupConfiguration extends SoupConfiguration {
  @JsonProperty('configuration', RouteConfiguration, true) configuration: RouteConfiguration = undefined;

  constructor() {
    super();
    this.configuration = new RouteConfiguration();
  }
}

@JsonObject('SoupRoute')
export class SoupRoute {
  @JsonProperty('configuration', GenericSoupConfiguration, true) configuration: GenericSoupConfiguration = undefined;
  @JsonProperty('locked', Boolean, true) locked: boolean = undefined;
}

@JsonObject('SoupFtpConfiguration')
export class SoupFtpConfiguration extends GenericSoupConfiguration {
  @JsonProperty('configuration', FtpRouteConfiguration, true) configuration: FtpRouteConfiguration = undefined;
}
@JsonObject('SoupHttpConfiguration')
export class SoupHttpConfiguration extends GenericSoupConfiguration {
  @JsonProperty('configuration', HttpRouteConfiguration, true) configuration: HttpRouteConfiguration = undefined;
}
@JsonObject('SoupB2bConfiguration')
export class SoupB2bConfiguration extends GenericSoupConfiguration {
  @JsonProperty('configuration', B2bRouteConfiguration, true) configuration: B2bRouteConfiguration = undefined;
}
@JsonObject('SoupAwsSftpConfiguration')
export class SoupAwsSftpConfiguration extends GenericSoupConfiguration {
  @JsonProperty('configuration', AwsSftpConfiguration, true) configuration: AwsSftpConfiguration = undefined;
}
