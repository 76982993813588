import { Component, Input, OnInit } from '@angular/core';
import { SourceMetadataDto, TranslatedMetadata } from '@models/product-metadata-model';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-source-metadata-details',
  templateUrl: './source-metadata-details.component.html',
  styleUrls: ['./source-metadata-details.component.scss'],
})
export class SourceMetadataDetailsComponent implements OnInit {
  @Input() metadata: SourceMetadataDto;

  selectedTranslation: TranslatedMetadata;
  selectedLanguage: string;

  hasPricing: boolean;
  hasMeasures: boolean;
  hasCustomMetadata: boolean;

  ngOnInit() {
    if (this.metadata.translatedMetadata?.length > 0) {
      this.selectedTranslation = this.metadata.translatedMetadata[0];
      this.selectedLanguage = this.selectedTranslation.languageCode;
      this.hasCustomMetadata = Object.keys(this.selectedTranslation?.customMetadata).length > 0;
    }

    this.hasPricing = !isNilty(this.metadata.retailPrice || this.metadata.wholesalePrice);
    this.hasMeasures = !isNilty(this.metadata.weightKg || this.metadata.widthCm || this.metadata.thicknessCm || this.metadata.heightCm);
  }

  changeLanguage(language: string) {
    this.selectedTranslation = this.metadata.translatedMetadata.find((it: TranslatedMetadata) => it.languageCode === language);
    this.selectedLanguage = this.selectedTranslation.languageCode;
    this.hasCustomMetadata = Object.keys(this.selectedTranslation?.customMetadata).length > 0;
  }
}
