import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CustomMetadataImportConfiguration')
export class CustomMetadataImportConfiguration {
  @JsonProperty('allowZeroValues', Boolean, true) allowZeroValues = true;
  @JsonProperty('allowNegativeValues', Boolean, true) allowNegativeValues = false;
}

@JsonObject('CustomMetaDataField')
export class CustomMetaDataField {
  @JsonProperty('label', String, true) label: string = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('dataType', String, true) dataType: 'STRING' | 'INT' | 'DOUBLE' | 'BOOLEAN' = undefined;
  @JsonProperty('tags', [String], true) tags: string[] = [];
  @JsonProperty('importConfiguration', CustomMetadataImportConfiguration, true) importConfiguration: CustomMetadataImportConfiguration =
    undefined;
  @JsonProperty('mustBeTranslated', Boolean, true) mustBeTranslated = false;
  @JsonProperty('id', String, true) id: string = undefined;
}
