import { Any, JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ValuePair')
export class ValuePair {
  @JsonProperty('fromValue', String, true) fromValue: string = undefined;
  @JsonProperty('toValue', String, true) toValue: string = undefined;
}
@JsonObject('ColumnsPair')
export class ColumnsPair {
  @JsonProperty('fromColumn', String, true) fromColumn: string = undefined;
  @JsonProperty('toColumn', String, true) toColumn: string = undefined;
  @JsonProperty('useAsRegex', Boolean, true) useAsRegex: boolean = undefined;
  @JsonProperty('values', [ValuePair], true) values: ValuePair[] = undefined;
  constructor() {
    this.values = [];
  }
}

@JsonObject('FileProperties')
export class FileProperties {
  @JsonProperty('fileExtension', String, true) fileExtension: string = undefined;

  @JsonProperty('positions', Any, true) positions: any = undefined;
  @JsonProperty('alternativePositions', Any, true) alternativePositions: any = undefined;

  @JsonProperty('mandatoryFields', [String], true) mandatoryFields: string[] = undefined;

  @JsonProperty('sourceFileSeparator', String, true) sourceFileSeparator = ';';
  @JsonProperty('textDelimiter', String, true) textDelimiter = '';
  @JsonProperty('decimalSeparator', String, true) decimalSeparator = ' ';
  @JsonProperty('groupingSeparator', String, true) groupingSeparator = ' ';

  @JsonProperty('currencyConverter', Boolean, true) currencyConverter = false;
  @JsonProperty('root', String, true) root: string = undefined;

  @JsonProperty('columnsMap', [ColumnsPair], true) columnsMap: ColumnsPair[] = undefined;
  @JsonProperty('skipSeparator', String, true) skipSeparator = '';

  constructor() {
    this.positions = {};
    this.mandatoryFields = [];
    this.alternativePositions = {};
  }
}
