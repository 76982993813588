import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { UkExchangeRates } from '@models/uk-exchange-rates-model';
import * as moment from 'moment';
import { Moment } from 'moment';
import { UkExchangeRatesService } from './uk-exchange-rates.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-uk-exchange-rates',
  templateUrl: './uk-exchange-rates.component.html',
  styleUrls: ['./uk-exchange-rates.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class UkExchangeRatesComponent implements OnInit {
  exchangeArray = {};
  formVisible = false;
  filterForm = new FormGroup({
    newExchangeRate: new FormControl(),
    newMonthYear: new FormControl(moment()),
  });

  constructor(private ukExchangeRatesService: UkExchangeRatesService) {}

  ngOnInit(): void {
    this.getExchangeRatesEndpoints();
  }

  getExchangeRatesEndpoints() {
    this.ukExchangeRatesService.getUkExchangeRates().subscribe((resp: UkExchangeRates[]) => {
      const yearGroups = {};

      resp.forEach((obj) => {
        const monthYear = obj.monthYear;
        const groupKey = monthYear.slice(-2);

        if (!yearGroups[groupKey]) {
          yearGroups[groupKey] = [];
        }

        yearGroups[groupKey].push(obj);
      });

      this.exchangeArray = yearGroups;
    });
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.filterForm.value.newMonthYear ?? moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.filterForm.controls.newMonthYear.setValue(ctrlValue);
    datepicker.close();
  }

  saveNewExchangeRate() {
    const ukExchangeRate: UkExchangeRates = { monthYear: '', exchangeRate: 0 };
    ukExchangeRate.monthYear = this.filterForm.value.newMonthYear.format('MMYY');
    ukExchangeRate.exchangeRate = this.filterForm.value.newExchangeRate;
    this.ukExchangeRatesService.saveUkExchangeRates(ukExchangeRate).subscribe();
  }
}
