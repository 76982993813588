import { Component, EventEmitter, Output } from '@angular/core';
import { PowerSeller } from '@models/configuration/rules/power-seller-rule/power-seller-model';
import { CommonsService } from '@shared/commons.service';

@Component({
  selector: 'app-search-power-sellers',
  templateUrl: './search-power-sellers.component.html',
  styleUrls: ['./search-power-sellers.component.scss'],
})
export class SearchPowerSellersComponent {
  @Output() selectedPowerSellerEvent = new EventEmitter<PowerSeller>();

  powerSellers: PowerSeller[] = [];
  selectedPowerSeller: PowerSeller;

  constructor(private commonsService: CommonsService) {
    this.commonsService.getAllPowerSellers().subscribe((r) => (this.powerSellers = r));
  }

  select(p: PowerSeller) {
    this.selectedPowerSeller = p;
    this.selectedPowerSellerEvent.emit(p);
  }
}
