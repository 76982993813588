import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('NewProductRequest')
export class NewProductRequest {
  @JsonProperty('barcode', String, true) barcode: string = undefined;
  @JsonProperty('supplierId', Number, true) supplierId: number = undefined;
  @JsonProperty('stockLocationId', Number, true) stockLocationId: number = undefined;
  @JsonProperty('price', Number, true) price: number = undefined;
  @JsonProperty('discountedPurchasePrice', Number, true) discountedPurchasePrice: number = undefined;
  @JsonProperty('catalogItemSku', String, true) catalogItemSku: string = undefined;
  @JsonProperty('title', String, true) title: string = undefined;
  @JsonProperty('brandId', Number, true) brandId: number = undefined;
  @JsonProperty('categoryId', Number, true) categoryId: number = undefined;
  @JsonProperty('physicalQuantity', Number, true) physicalQuantity: number = undefined;
  @JsonProperty('availableQuantity', Number, true) availableQuantity: number = undefined;
}
