import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { PublicationFilters } from '@models/publications/filters/publication-filters-model';
import { Supplier } from '@models/supplier-model';
import { SuppliersService } from '../../../suppliers/suppliers.service';
import { PublicationService } from '../../publication.service';
import { Observable, Subject } from 'rxjs';
import { isNilty } from '@core/utils.service';
import { MatSelect } from '@angular/material/select';
import { ZionStore } from '@models/publications/published-store-model';
import { CommonsService } from '@shared/commons.service';
import { NetworkCategory } from '@models/network-category-model';
import { Store } from '@models/configuration/marketplace/store-model';
import { ZionBrand } from '@models/zion-catalog-item-model';
import { Status } from '@models/status-model';
import { StoreService } from '../../../configuration/store-configuration/store.service';

@Component({
  selector: 'app-publications-filters',
  templateUrl: './publications-filters.component.html',
  styleUrls: ['./publications-filters.component.scss'],
})
export class PublicationsFiltersComponent implements OnInit {
  @Input() onFocusControl: Observable<string>;
  @Input() onClearFilters: Observable<void>;

  @Input() onSearchStatus: Observable<string>;
  @Input() onSearchOperation: Observable<string>;

  @Input() searchBy: string;
  @Input() currentStore: Store;

  @Output() filterPublications = new EventEmitter<PublicationFilters>();

  @ViewChildren('controls') controls: QueryList<any>;

  filters = new PublicationFilters();

  suppliers: Supplier[];

  stores: ZionStore[];

  isByStore: boolean;

  publicationStatuses: string[];
  publicationErrors: string[];
  marketplaceOperations: string[];

  networkCategories: NetworkCategory[];

  brandList: ZionBrand[] = [];
  brands: Subject<ZionBrand[]> = new Subject();

  buyBoxDistances: Status[] = [];

  constructor(
    private supplierService: SuppliersService,
    private publicationService: PublicationService,
    private commonService: CommonsService,
    private storeService: StoreService
  ) {}

  ngOnInit() {
    this.isByStore = this.searchBy === 'store';

    this.loadSuppliers();
    if (!this.isByStore) {
      this.loadStores();
    }
    this.getEnums();
    this.getCategories();
    this.getBuyBoxDistances();

    if (this.onFocusControl) {
      this.onFocusControl.subscribe((s) => this.focusFilter(s));
    }
    if (this.onFocusControl) {
      this.onClearFilters.subscribe(() => this.reset());
    }
    if (this.onSearchStatus) {
      this.onSearchStatus.subscribe((s) => this.searchStatus(s));
    }
    if (this.onSearchOperation) {
      this.onSearchOperation.subscribe((o) => this.searchOperation(o));
    }

    if (this.isByStore && !isNilty(this.publicationService.byStoreFilters)) {
      this.filters = this.publicationService.byStoreFilters;
      this.search();
    }
    if (!this.isByStore && !isNilty(this.publicationService.byBarcodeFilters)) {
      this.filters = this.publicationService.byBarcodeFilters;
      this.search();
    }
  }

  search() {
    this.filterPublications.emit(this.filters);
  }

  reset() {
    this.filters = new PublicationFilters();
    this.filterPublications.emit(this.filters);
  }

  loadSuppliers() {
    this.supplierService.getAllSuppliers().subscribe((s) => (this.suppliers = s));
  }
  loadStores() {
    this.storeService.getPublishedStores().subscribe((s) => (this.stores = s.filter((it) => it.published).map((it) => it.store)));
  }

  getEnums() {
    this.publicationService.getPublicationsEnums().subscribe((r) => {
      this.publicationStatuses = r.publicationStatus;
      this.marketplaceOperations = r.marketplaceOperations;
      this.publicationErrors = r.publicationErrors;
    });
  }

  getCategories() {
    this.commonService.getNetworkCategories().subscribe((r) => {
      if (this.isByStore) {
        this.networkCategories = r.filter((it) => it.network === this.currentStore.marketplace.network);
      } else {
        this.networkCategories = r;
      }
    });
  }

  getBuyBoxDistances() {
    this.publicationService.getBuyBoxDistances().subscribe((r) => {
      this.buyBoxDistances = r;
    });
  }

  focusFilter(key: string) {
    let control = this.controls.find((it) => it.nativeElement?.id === `${key}FormControl` || it.id === `${key}FormControl`);

    if (!isNilty(control.nativeElement)) {
      control = control.nativeElement;
    }

    setTimeout(() => {
      if (control instanceof MatSelect) {
        (control as MatSelect).focus();
        (control as MatSelect).open();
      } else {
        control.focus();
      }
    }, 50);
  }

  selectedPendingFilters(o: any) {
    if (!isNilty(o)) {
      this.resetPublicationFilters();
    }
  }

  changedStatusFilter(s: string) {
    if (!isNilty(s)) {
      this.resetPendingFilters();
    }
  }

  changedSupplierFilter(s: number) {
    if (!isNilty(s)) {
      this.resetPendingFilters();
    }
  }

  searchOnlyPendingChanged(onlyPending: boolean) {
    if (onlyPending) {
      this.resetPublicationFilters();
    } else {
      this.resetPendingFilters();
    }
  }

  filterBrands(filterString: { filterText: string; filterKey: string }) {
    this.commonService.searchBrands(filterString.filterText).subscribe((resp: ZionBrand[]) => {
      this.brands.next(resp);
      this.brandList = resp;
    });
  }

  private resetPublicationFilters() {
    this.filters.supplierId = undefined;
    this.filters.status = undefined;
    this.filters.onlyPending = true;
  }
  private resetPendingFilters() {
    this.filters.marketplaceOperation = undefined;
    this.filters.onlyPending = false;
  }

  private searchStatus(s: string) {
    this.resetPendingFilters();
    this.filters.status = s;
    this.filterPublications.emit(this.filters);
  }
  private searchOperation(o: string) {
    this.resetPublicationFilters();
    this.filters.marketplaceOperation = o;
    this.filterPublications.emit(this.filters);
  }
}
