import { Component, Input, OnInit } from '@angular/core';
import { BlacklistTargetTypes } from '@models/configuration/policies/blacklist/blacklists-enums-model';
import { RestrictionTargetType } from '@models/configuration/policies/restrictions/restriction-target-type';

@Component({
  selector: 'app-policy-target-type-icon',
  template: ` <div class="policy-target-type-icon" [ngClass]="colorClass" [matTooltip]="targetType">{{ targetType[0] }}</div> `,
  styles: [
    `
      .orange {
        color: orange;
      }

      .red {
        color: red;
      }

      .green {
        color: green;
      }

      .dodgerblue {
        color: dodgerblue;
      }

      .purple {
        color: #781eff;
      }

      .policy-target-type-icon {
        display: inline;
        margin-right: 7px;
        font-weight: bold;
        font-size: 17px;
      }
    `,
  ],
})
export class PolicyTargetTypeIconComponent implements OnInit {
  @Input() targetType: string;

  colorClass: string;

  ngOnInit() {
    switch (this.targetType) {
      case BlacklistTargetTypes.STORE:
        this.colorClass = 'orange';
        break;
      case BlacklistTargetTypes.MARKETPLACE:
        this.colorClass = 'red';
        break;
      case BlacklistTargetTypes.NETWORK:
        this.colorClass = 'green';
        break;
      case BlacklistTargetTypes.GLOBAL:
        this.colorClass = 'dodgerblue';
        break;

      case RestrictionTargetType.POWER_SELLER:
        this.colorClass = 'purple';
        break;
      case RestrictionTargetType.GLOBAL:
        this.colorClass = 'dodgerblue';
        break;
    }
  }
}
