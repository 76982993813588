import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PublicationDto } from '../../../../../models/publications/publication-dto-model';
import { CatalogItemsService } from '../../../../catalog-items/catalog-items.service';
import { ZionCatalogItem } from '../../../../../models/zion-catalog-item-model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-catalog-recap-modal',
  templateUrl: './catalog-recap-modal.component.html',
  styleUrls: ['./catalog-recap-modal.component.scss'],
})
export class CatalogRecapModalComponent implements OnInit {
  ci: ZionCatalogItem;
  isBook: boolean;

  constructor(
    public dialogRef: MatDialogRef<CatalogRecapModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: PublicationDto,
    private catalogItemService: CatalogItemsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.catalogItemService
      .getCatalogItemBySupplierAndEan(this.data.publication.supplierCode, this.data.publication.barcode)
      .subscribe((it) => {
        this.ci = it;
        this.ci.supplier.initDescription();
      });

    this.isBook = this.data.publication.category.indexOf('BOOK') !== -1;
  }

  goToCatalogDetails() {
    this.router.navigate(['catalog-items', 'details', this.ci.id]);
    this.dialogRef.close();
  }
}
