import { Component, Input, OnInit } from '@angular/core';
import { BarcodeBlacklist, BlacklistTarget } from '@models/configuration/policies/blacklist/blacklist-model';
import { Observable, Subject } from 'rxjs';
import { BarcodeBlacklistFilters } from '@models/configuration/policies/blacklist/barcode-blacklist-filters-model';
import { ProductWithTitle } from '@models/product/product-with-title-model';
import { isNilty } from '@core/utils.service';
import { BlacklistService } from '../../blacklist.service';
import { PageEvent } from '@angular/material/paginator';
import { BlacklistReason } from '@models/configuration/policies/blacklist/blacklist-reason-model';

@Component({
  selector: 'app-barcode-blacklists-list',
  templateUrl: './barcode-blacklists-list.component.html',
  styleUrls: ['./barcode-blacklists-list.component.scss'],
})
export class BarcodeBlacklistsListComponent implements OnInit {
  @Input() targets: BlacklistTarget[];
  @Input() product: ProductWithTitle;
  @Input() refresh: Subject<BlacklistTarget[] | ProductWithTitle>;

  filters: BarcodeBlacklistFilters;
  barcodeBlacklists: BarcodeBlacklist[] = [];
  blacklistReasons: Observable<BlacklistReason[]>;

  resultsNumber = new Subject<number>();

  constructor(private blacklistService: BlacklistService) {}

  ngOnInit(): void {
    this.blacklistService.barcodeBlacklistsResultNumber.subscribe((r) => this.resultsNumber.next(r));
    this.blacklistReasons = this.blacklistService.getBlacklistReasons();

    if (!isNilty(this.refresh)) {
      this.refresh.subscribe((f) => {
        if (f instanceof ProductWithTitle) {
          this.product = f;
        } else {
          this.targets = f;
        }
        this.filters.pageNumber = 0;
        this.search();
      });
    }
    this.search();
  }

  changePage(event: PageEvent) {
    this.filters.pageNumber = event.pageIndex;
    this.search();
  }

  filter() {
    this.filters.pageNumber = 0;
    this.search();
  }

  clear() {
    this.filters = new BarcodeBlacklistFilters();
    this.search();
  }

  search() {
    if (isNilty(this.filters)) {
      this.filters = new BarcodeBlacklistFilters();
    }
    if (!isNilty(this.targets)) {
      this.filters.targets = this.targets;
    }
    if (!isNilty(this.product)) {
      this.filters.ean = this.product.barcode;
      this.filters.sku = this.product.sku;
      // TODO add marketplace barcode
    }
    this.blacklistService.findBarcodeBlacklistsByFilters(this.filters).subscribe((r) => {
      this.barcodeBlacklists = r;
    });
  }
}
