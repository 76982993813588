import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MarketplaceCategoryFee } from '../../../../../models/configuration/marketplace/marketplace-category-fee-model';
import { FeesConfigurationService } from '../../fees-configuration.service';
import { GenericConfirmationModalComponent } from '../../../../../shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { CommonsService } from '../../../../../shared/commons.service';
import { Currency } from '../../../../../models/currency-model';

@Component({
  selector: 'app-edit-marketplace-category-fee-modal-component',
  templateUrl: `edit-marketplace-category-fee-modal.component.html`,
  styles: [
    `
      .titles {
        font-size: 15px;
      }
      .titles b {
        color: #00275c;
      }
      .error {
        color: red;
      }
    `,
  ],
})
export class EditMarketplaceCategoryFeeModalComponent implements OnInit {
  error: string;
  currencies: Currency[];

  constructor(
    public dialogRef: MatDialogRef<EditMarketplaceCategoryFeeModalComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: MarketplaceCategoryFee,
    private service: FeesConfigurationService,
    private commonService: CommonsService
  ) {}

  ngOnInit() {
    this.commonService.getCurrencies().subscribe((c) => (this.currencies = c));
  }

  confirm() {
    this.error = undefined;
    if (this.data.percentageFee > 1 || this.data.percentageFee < 0) {
      this.error = 'Percentage Fee must be between 0 and 1';
      return;
    }
    if (this.data.closingFee === 0 && this.data.percentageFee === 0) {
      this.dialog
        .open(GenericConfirmationModalComponent, { data: 'You set both closing and percentage feed to 0.' })
        .afterClosed()
        .subscribe((r) => {
          if (r) {
            this.doConfirm();
          }
        });
    } else {
      this.doConfirm();
    }
  }

  private doConfirm() {
    this.service.saveMarketplaceCategoryFees(this.data).subscribe(() => this.dialogRef.close(true));
  }
}
