import { JsonObject, JsonProperty } from 'json2typescript';
import { Permission } from './security/permission-model';

@JsonObject('Role')
export class Role {
  @JsonProperty('id', String, false) id: string = undefined;
  @JsonProperty('name', String, false) name: string = undefined;
  @JsonProperty('application', String, false) application: string = undefined;
  @JsonProperty('permissions', [Permission], false) permissions: Permission[] = undefined;
}
