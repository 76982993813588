import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '../../../../core/converter.service';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../../../../core/environment/environment.service';
import { map } from 'rxjs/operators';
import { PublicationsJobs } from '../../../../models/publications-jobs-model';

@Injectable()
export class PublicationsJobsService {
  publicationsJobs: PublicationsJobs[];

  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getPublicationsJobsEndpoints(): Observable<PublicationsJobs[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('getPublicationsJobs'))
      .pipe(map((resp: PublicationsJobs[]) => resp.map((it) => this.converter.fromJSONtoObj(it, PublicationsJobs))));
  }
}
