import { JsonObject, JsonProperty } from 'json2typescript';
import { Publication } from './publication-model';
import { PendingPublication } from './pending-publication-model';

@JsonObject('PublicationDto')
export class PublicationDto {
  @JsonProperty('publication', Publication, true) publication: Publication = undefined;
  @JsonProperty('pending', PendingPublication, true) pending: PendingPublication = undefined;
}

@JsonObject('PublicationsByStoreKpi')
export class PublicationsByStoreKpi {}
