import { Injectable } from '@angular/core';

import { JsonConvert, OperationMode, ValueCheckingMode } from 'json2typescript';

@Injectable()
export class ConverterService {
  jsonConvert: JsonConvert;

  constructor() {
    this.jsonConvert = new JsonConvert();
    this.jsonConvert.operationMode = OperationMode.ENABLE;
    // this.jsonConvert.operationMode = OperationMode.LOGGING; // verbose
    this.jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
    this.jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL; // allow null
  }

  fromJSONtoObj(object: any, type: any): any {
    let deserializedObj = null;
    try {
      deserializedObj = this.jsonConvert.deserialize(object, type);
    } catch (e) {
      console.log('Could not deserialize object:' + object + ' di tipo:' + type);
      console.log(e);
      return null;
    }
    return deserializedObj;
  }

  fromObjtoJSON(object: any): any {
    let serializedObj = null;
    try {
      serializedObj = this.jsonConvert.serialize(object);
    } catch (e) {
      console.log('Could not serialize string:' + object);
      console.log(e);
      return null;
    }
    return serializedObj;
  }
}
