import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CustomActualShipmentCost,
  StoreFeesConfiguration,
} from '../../../../../models/configuration/marketplace/store-fees-configuration-model';
import { StoreSelectModalComponent } from '../../../markus-rules/publication-rules/publication-rule-edit/store-select-modal/store-select-modal.component';
import { PublicationRuleStore } from '../../../../../models/configuration/rules/publication-rule/publication-rule-store-model';
import { isNilty } from '../../../../../core/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { StoreFeesFilters } from '../../../../../models/configuration/marketplace/filters/store-fees-filters-model';
import { GenericConfirmationModalComponent } from '../../../../../shared/generic-confirmation-modal/generic-confirmation-modal.component';
import { Currency } from '../../../../../models/currency-model';
import { FeesConfigurationService } from '../../fees-configuration.service';
import { GenericErrorModalComponent } from '../../../../../shared/generic-error-modal/generic-error-modal.component';

@Component({
  selector: 'app-edit-store-fees',
  templateUrl: './edit-store-fees.component.html',
  styleUrls: ['./edit-store-fees.component.scss'],
})
export class EditStoreFeesComponent {
  @Input() storeFees: StoreFeesConfiguration;
  @Input() logisticPartners: string[];
  @Input() currencies: Currency[];

  @Output() save = new EventEmitter<StoreFeesConfiguration>();
  @Output() cancel = new EventEmitter<void>();

  constructor(private dialog: MatDialog, private storeFeesService: FeesConfigurationService) {}

  onSave() {
    if (!isNilty(this.storeFees.customActualShipmentCostList)) {
      // Code to execute when customActualShipmentCostList is not empty
      for (const it of this.storeFees.customActualShipmentCostList) {
        if (!this.isCustomShipmentCostValid(it)) {
          this.dialog.open(GenericErrorModalComponent, {
            data: `Custom shipping cost is invalid: min: ${it.minWeight}, max: ${it.maxWeight}, cost: ${it.actualShipmentCost}.`,
          });
          return;
        }
      }
      this.storeFees.customActualShipmentCostList = [...this.storeFees.customActualShipmentCostList].sort((a, b) =>
        a.minWeight > b.minWeight ? 1 : -1
      );
    }
    this.save.emit(this.storeFees);
  }

  onCancel() {
    this.cancel.emit();
  }

  selectStore() {
    this.dialog
      .open(StoreSelectModalComponent, { data: undefined })
      .afterClosed()
      .subscribe((s: PublicationRuleStore) => {
        if (!isNilty(s)) {
          this.storeFees.store = s;
          this.checkIfExisting();
          this.storeFees.currency = this.storeFees.store.currency;
        }
      });
  }

  selectedLogisticPartner(l: string) {
    this.storeFees.logisticPartner = l;
    this.checkIfExisting();
  }

  checkIfExisting() {
    if (!isNilty(this.storeFees.logisticPartner) && !isNilty(this.storeFees.store)) {
      const filters = new StoreFeesFilters();
      filters.storeCode = this.storeFees.store.code;
      filters.logisticPartner = this.storeFees.logisticPartner;
      this.storeFeesService.findByFilters(filters).subscribe((storeFeesConfig) => {
        if (storeFeesConfig.length > 0) {
          this.dialog
            .open(GenericConfirmationModalComponent, { data: '' })
            .afterClosed()
            .subscribe((resp: boolean) => {
              if (resp) {
                this.storeFees = storeFeesConfig[0];
              } else {
                this.storeFees.store = undefined;
                this.storeFees.logisticPartner = undefined;
              }
            });
        }
      });
    }
  }

  addRow() {
    if (isNilty(this.storeFees.customActualShipmentCostList)) {
      this.storeFees.customActualShipmentCostList = [];
    }
    this.storeFees.customActualShipmentCostList.push(new CustomActualShipmentCost());
  }

  removeRow(i) {
    this.storeFees.customActualShipmentCostList.splice(i, 1);
  }

  removeBtnOverHandle(el, type) {
    const inputArray = el.parentElement.parentElement;
    switch (type) {
      case 'over':
        inputArray.classList.add('remove-over');
        break;
      case 'out':
        inputArray.classList.remove('remove-over');
        break;
    }
  }
  removeBtnOver(e) {
    this.removeBtnOverHandle(e.currentTarget, 'over');
  }
  removeBtnOut(e) {
    this.removeBtnOverHandle(e.currentTarget, 'out');
  }

  checkVal(value, el, i) {
    const saveBtn = document.querySelector('#save-button') as HTMLInputElement;
    if (el.classList.contains(`min-weight-${i}`)) {
      const maxWeight = document.querySelector(`.max-weight-${i}`) as HTMLInputElement;
      if (isNilty(maxWeight.value) || value >= maxWeight.value) {
        el.parentElement.classList.add('bg-danger');
        maxWeight.parentElement.classList.add('bg-danger');
        saveBtn.disabled = true;
      } else if (value < maxWeight.value) {
        el.parentElement.classList.remove('bg-danger');
        maxWeight.parentElement.classList.remove('bg-danger');
        saveBtn.disabled = false;
      }
    } else if (el.classList.contains(`max-weight-${i}`)) {
      const minWeight = document.querySelector(`.min-weight-${i}`) as HTMLInputElement;
      if (isNilty(minWeight.value) || value <= minWeight.value) {
        el.parentElement.classList.add('bg-danger');
        minWeight.parentElement.classList.add('bg-danger');
        saveBtn.disabled = true;
      } else if (value > minWeight.value) {
        el.parentElement.classList.remove('bg-danger');
        minWeight.parentElement.classList.remove('bg-danger');
        saveBtn.disabled = false;
      }
    }
  }

  private isCustomShipmentCostValid(sc: CustomActualShipmentCost): boolean {
    if (isNilty(sc.maxWeight) || isNilty(sc.minWeight) || isNilty(sc.actualShipmentCost)) {
      return false;
    }

    if (sc.minWeight < 0 || sc.maxWeight <= 0 || sc.actualShipmentCost < 0) {
      return false;
    }

    return sc.minWeight < sc.maxWeight;
  }
}
