import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService } from '@core/converter.service';
import { EnvironmentService } from '@core/environment/environment.service';
import { Permission } from '@models/security/permission-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getPermissions(): Observable<Permission[]> {
    return this.http
      .get(this.environmentService.getPonchRestEndpoint('allPermissions'))
      .pipe(
        map((permissionsResp: Permission[]) => permissionsResp.map((permission) => this.converter.fromJSONtoObj(permission, Permission)))
      );
  }

  getPermission(application: string): Observable<Permission[]> {
    return this.http
      .get(this.environmentService.getPonchRestEndpoint('permissions') + '?application=' + application)
      .pipe(
        map((permissionsResp: Permission[]) => permissionsResp.map((permission) => this.converter.fromJSONtoObj(permission, Permission)))
      );
  }

  getApplications(): Observable<string[]> {
    return this.http.get<string[]>(this.environmentService.getPonchRestEndpoint('applications'));
  }

  savePermission(permission: Permission): Observable<boolean> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(permission);
    return this.http.post(this.environmentService.getPonchRestEndpoint('permissions'), body, { headers }).pipe(map(() => true));
  }

  deletePermission(id: string): Observable<any> {
    return this.http.delete(this.environmentService.getPonchRestEndpoint('permissions') + `/${id}`);
  }
}
