import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Status } from 'src/app/models/status-model';
import { ConverterService } from '../../core/converter.service';
import { EnvironmentService } from '../../core/environment/environment.service';
import { StockLocationFilters } from '../../models/filters/stock-location';
import { StockLocation } from '../../models/stock-location-model';
import { CreateNewStockLocation } from '@models/create-new-stock-location';
@Injectable()
export class StockLocationsService {
  resultsNumber = new Subject<number>();
  stockLocations: StockLocation[];
  productBarcodeTypes: Status[];

  private gettingAllStockLocations = false;
  private allStockLocationsSubject = new Subject<StockLocation[]>();

  constructor(private converter: ConverterService, private http: HttpClient, private environmentService: EnvironmentService) {}

  getFilteredStockLocations(filters: StockLocationFilters): Observable<StockLocation[]> {
    const code = filters.code ?? '';
    const pageIndex = filters.pageNumber ?? 0;
    const pageSize = filters.pageSize ?? 10;
    const sortBy = filters.sortBy ?? 'orderedOn';
    const sortDirection = filters.sortDirection ?? 'desc';

    const queryPath =
      '?code=' + code + '&page-index=' + pageIndex + '&page-size=' + pageSize + '&sort-by=' + sortBy + '&sort-direction=' + sortDirection;

    return this.http.get(this.environmentService.getRestEndpoint('stockLocations') + queryPath, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: StockLocation[]) => resp.map((r) => this.converter.fromJSONtoObj(r, StockLocation)))
    );
  }

  getAllStockLocations(): Observable<StockLocation[]> {
    if (this.stockLocations) {
      return of(this.stockLocations);
    }

    if (!this.gettingAllStockLocations) {
      this.gettingAllStockLocations = true;
      return this.http.get(this.environmentService.getRestEndpoint('stockLocations') + '/all', { observe: 'response' }).pipe(
        map((resp: HttpResponse<StockLocation[]>) => {
          this.stockLocations = resp.body.map((r) => {
            return this.converter.fromJSONtoObj(r, StockLocation);
          });
          this.allStockLocationsSubject.next(this.stockLocations);
          return this.stockLocations;
        })
      );
    } else {
      return this.allStockLocationsSubject;
    }
  }

  getAllReturnStockLocations(): Observable<StockLocation[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('stockLocations') + '/return', { observe: 'response' })
      .pipe(map((resp: HttpResponse<StockLocation[]>) => resp.body.map((r) => this.converter.fromJSONtoObj(r, StockLocation))));
  }

  getProductBarcodeTypes(): Observable<Status[]> {
    if (this.productBarcodeTypes) {
      return of(this.productBarcodeTypes);
    }

    return this.http
      .get(this.environmentService.getRestEndpoint('productBarcodes') + '/types', { observe: 'response' })
      .pipe(
        map((resp: HttpResponse<Status[]>) => (this.productBarcodeTypes = resp.body.map((r) => this.converter.fromJSONtoObj(r, Status))))
      );
  }

  addStockLocation(newStockLocation: CreateNewStockLocation): Observable<any> {
    const path = this.environmentService.getRestEndpoint('stockLocations');
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return this.http.post(path, newStockLocation).pipe(map(() => {}));
  }
}
