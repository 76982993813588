import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SellingPlatform } from '@models/configuration/marketplace/marketplace-model';
import { StoreService } from '../../../store-configuration/store.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-generic-confirmation-modal',
  templateUrl: './create-selling-platform-modal.component.html',
  styleUrls: ['./create-selling-platform-modal.component.scss'],
})
export class CreateSellingPlatformModalComponent implements OnInit {
  @ViewChild('newNameInput', { static: true }) newNameInput: ElementRef;

  newSellingPlatformName: string;
  sellingPlatforms: SellingPlatform[];
  isNewNameValid: boolean;

  constructor(public dialogRef: MatDialogRef<CreateSellingPlatformModalComponent>, private storeService: StoreService) {
    this.storeService.getSellingPlatforms().subscribe((r) => (this.sellingPlatforms = r));
  }

  ngOnInit() {
    fromEvent(this.newNameInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(50),
        distinctUntilChanged()
      )
      .subscribe((newName: string) => {
        this.isNewNameValid =
          !isNilty(newName) && this.sellingPlatforms.find((it) => it.name.toUpperCase() === newName.toUpperCase()) === undefined;
      });
  }

  save() {
    this.storeService.createSellingPlatform(this.newSellingPlatformName).subscribe(() => this.dialogRef.close(this.newSellingPlatformName));
  }
}
