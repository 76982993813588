import { JsonObject, JsonProperty } from 'json2typescript';
import { BlacklistReason } from './blacklist-reason-model';
import { RulePredicate } from '@models/configuration/rules/rule-predicate-model';
import { BlacklistTargetTypes } from './blacklists-enums-model';
import { BarcodePolicy, PredicatesPolicy, WordsPolicy } from '@models/configuration/policies/policies-model';

@JsonObject('BlacklistTarget')
export class BlacklistTarget {
  @JsonProperty('type', String, true) type: string = undefined;
  @JsonProperty('identifier', String, true) identifier: string = undefined;

  description: string;

  constructor(type: BlacklistTargetTypes, identifier: string) {
    this.type = type;
    this.identifier = identifier;
    this.description = this.buildDescription();
  }

  buildDescription() {
    return this.type + (this.type !== BlacklistTargetTypes.GLOBAL ? ` ${this.identifier}` : '');
  }
}

@JsonObject('Blacklist')
export class Blacklist {
  @JsonProperty('target', BlacklistTarget, true) target: BlacklistTarget = undefined;
  @JsonProperty('reason', BlacklistReason, true) reason: BlacklistReason = undefined;
  @JsonProperty('email', String, true) email: string = undefined;
  @JsonProperty('date', String, true) date: string = undefined;
  @JsonProperty('notes', String, true) notes: string = undefined;
  @JsonProperty('type', String, true) type: string = undefined;
  @JsonProperty('id', String, true) id: string = undefined;

  name: string;

  constructor(target: BlacklistTarget, reason: BlacklistReason, email: string, date: string, notes: string, type: string) {
    this.target = target;
    this.reason = reason;
    this.email = email;
    this.date = date;
    this.notes = notes;
    this.type = type;
  }

  buildName() {
    this.name = this.email.split('@')[0];
  }
}

@JsonObject('BarcodeBlacklist')
export class BarcodeBlacklist extends Blacklist implements BarcodePolicy {
  @JsonProperty('barcodeType', String, true) barcodeType: string = undefined;
  @JsonProperty('barcodeValue', String, true) barcodeValue: string = undefined;
}
export const buildBarcodeBlacklist = (bl: Blacklist, barcodeType: string, barcodeValue: string): BarcodeBlacklist => {
  const bb = new BarcodeBlacklist(bl.target, bl.reason, bl.email, bl.date, bl.notes, bl.type);
  bb.barcodeType = barcodeType;
  bb.barcodeValue = barcodeValue;
  return bb;
};

@JsonObject('WordsBlacklist')
export class WordsBlacklist extends Blacklist implements WordsPolicy {
  @JsonProperty('field', String, true) field: string = undefined;
  @JsonProperty('words', [String], true) words: string[] = undefined;
}
export const buildWordsBlacklist = (bl: Blacklist, field: string, words: string[]): WordsBlacklist => {
  const wb = new WordsBlacklist(bl.target, bl.reason, bl.email, bl.date, bl.notes, bl.type);
  wb.field = field;
  wb.words = words;
  return wb;
};

@JsonObject('PredicatesBlacklist')
export class PredicatesBlacklist extends Blacklist implements PredicatesPolicy {
  @JsonProperty('predicates', [RulePredicate], true) predicates: RulePredicate[] = undefined;
}
export const buildPredicatesBlacklist = (bl: Blacklist, predicates: RulePredicate[]): PredicatesBlacklist => {
  const pb = new PredicatesBlacklist(bl.target, bl.reason, bl.email, bl.date, bl.notes, bl.type);
  pb.predicates = predicates;
  return pb;
};

export class ImportBlacklistFile extends Blacklist {
  file: File;
  barcodeType: string;

  constructor(bl: Blacklist, file: File, barcodeType: string) {
    super(bl.target, bl.reason, bl.email, bl.date, bl.notes, bl.type);
    this.file = file;
    this.barcodeType = barcodeType;
  }
}
