import { Component, Input } from '@angular/core';
import { isNilty } from '@core/utils.service';
import { UpdateCriteria, UpdateCriterion } from '@models/configuration/rules/update-criteria-model';
import { PowerSellerRule } from '@models/configuration/rules/power-seller-rule/power-seller-rule-model';

@Component({
  selector: 'app-power-seller-rule-options-edit',
  templateUrl: './power-seller-rule-options-edit.component.html',
  styleUrls: ['./power-seller-rule-options-edit.component.scss'],
})
export class PowerSellerRuleOptionsEditComponent {
  rule: PowerSellerRule;

  updateCriteriaTypes = [
    { type: 'ABSOLUTE_VARIATION', description: 'Update if the change is greater than the thresholdValue' },
    { type: 'PERCENTAGE_VARIATION', description: 'Update if the percentage change is greater than the thresholdValue' },
    { type: 'THRESHOLD', description: 'Update only if the thresholdValue is crossed (in both directions)' },
  ];

  @Input() set _rule(r: PowerSellerRule) {
    this.rule = r;
  }

  addQuantityUpdateCriteria() {
    if (isNilty(this.rule.updateCriteria)) {
      this.rule.updateCriteria = new UpdateCriteria();
    }
    this.rule.updateCriteria.quantityUpdateCriterion = new UpdateCriterion();
  }
  removeQuantityCriteria() {
    this.rule.updateCriteria.quantityUpdateCriterion = null;
  }
  addPriceUpdateCriteria() {
    if (isNilty(this.rule.updateCriteria)) {
      this.rule.updateCriteria = new UpdateCriteria();
    }
    this.rule.updateCriteria.priceUpdateCriterion = new UpdateCriterion();
  }
  removePriceCriteria() {
    this.rule.updateCriteria.priceUpdateCriterion = null;
  }
}
