import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ProductsService } from '../../../../../products/products.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { isNilty } from '@core/utils.service';
import { MatTableDataSource } from '@angular/material/table';
import { ProductWithTitle } from '@models/product/product-with-title-model';

@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.scss'],
})
export class SearchProductComponent implements OnInit {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  @Output()
  selectedProduct = new EventEmitter<ProductWithTitle>();

  searchString = '';

  productsDataSource = new MatTableDataSource<ProductWithTitle>();
  columns = ['barcode', 'sku', 'brand', 'title'];

  constructor(private productsService: ProductsService) {}

  ngOnInit(): void {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((value: string) => {
        this.searchString = value;
        this.search(value);
      });
  }

  selectProduct(product: ProductWithTitle) {
    this.selectedProduct.next(product);
  }

  private search(value: string) {
    if (!isNilty(value)) {
      this.productsService.searchBarcode(value).subscribe((p) => (this.productsDataSource.data = p));
    } else {
      this.productsDataSource.data = [];
    }
  }
}
