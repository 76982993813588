import { DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { BooleanIconPipe } from './boolean-icon.pipe';
import { MyCurrencyCodePipe } from './currency-code.pipe';
import { MyCurrencyPipe } from './currency.pipe';
import { DatePipe } from './date.pipe';
import { FalseOrUndefinedPipe } from './false-or-undefined.pipe';
import { MonthYearTransform } from './month-year-transform.pipe';
import { NetworkCategoryPipe } from './network-category.pipe';
import { MyPercentPipe } from './percent.pipe';
import { StockLocationPipe } from './stockLocation.pipe';
import { SupplierPipe } from './supplier.pipe';
import { TruncateTextPipe } from './truncate-text.pipe';
import { ListingSellingPlatformDescriptionPipe } from '@shared/pipes/listing-selling-platform-description.pipe';

@NgModule({
  imports: [],
  declarations: [
    BooleanIconPipe,
    MyCurrencyPipe,
    MyPercentPipe,
    TruncateTextPipe,
    NetworkCategoryPipe,
    FalseOrUndefinedPipe,
    MyCurrencyCodePipe,
    DatePipe,
    SupplierPipe,
    StockLocationPipe,
    MonthYearTransform,
    ListingSellingPlatformDescriptionPipe,
  ],
  providers: [DecimalPipe, MyCurrencyPipe, MyCurrencyCodePipe, MyPercentPipe, ListingSellingPlatformDescriptionPipe],
  exports: [
    BooleanIconPipe,
    MyCurrencyPipe,
    MyPercentPipe,
    TruncateTextPipe,
    NetworkCategoryPipe,
    FalseOrUndefinedPipe,
    MyCurrencyCodePipe,
    DatePipe,
    SupplierPipe,
    StockLocationPipe,
    MonthYearTransform,
    ListingSellingPlatformDescriptionPipe,
  ],
})
export class MyPipes {}
