import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ImportStockLocation')
export class ImportStockLocation {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('importTimestamp', String, true) importTimestamp: string = undefined;
  @JsonProperty('importedItems', Number, true) importedItems: number = undefined;
  @JsonProperty('importedQuantities', Number, true) importedQuantities: number = undefined;
  @JsonProperty('invalidItems', Number, true) invalidItems: number = undefined;
  @JsonProperty('notFoundItems', Number, true) notFoundItems: number = undefined;
  @JsonProperty('fileUrl', String, true) fileUrl: string = undefined;
  @JsonProperty('errorRows', Number, true) errorRows: number = undefined;
  @JsonProperty('missingFieldsRows', Number, true) missingFieldsRows: number = undefined;
  @JsonProperty('missingFields', String, true) missingFields: string = undefined;
  @JsonProperty('availableItems', Number, true) availableItems: number = undefined;
  @JsonProperty('totalItems', Number, true) totalItems: number = undefined;
  @JsonProperty('skippedRows', Number, true) skippedRows: number = undefined;

  get errorPercentage(): number {
    const errorRows = this.invalidItems + this.notFoundItems + this.errorRows + this.missingFieldsRows;
    const feedItems = Math.max(this.importedItems + errorRows, 1);

    return errorRows / feedItems;
  }

  get availabilityPercentage(): number {
    return this.availableItems / this.totalItems;
  }

  get cssErrorColor(): string {
    const errorIntensity = (this.errorPercentage || 0) + (this.importedItems === 0 ? 0.75 : 0);
    return 'rgba(256, 0, 0, ' + errorIntensity + ')';
  }
}
