import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConverterService } from '@core/converter.service';
import { EnvironmentService } from '@core/environment/environment.service';
import { isNilty } from '@core/utils.service';
import { PromoPrice, PromoPriceFilters } from '@models/promo-price-model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PromoPriceService {
  resultsNumber = new Subject<number>();
  promoPriceFilters: PromoPriceFilters;

  constructor(
    private http: HttpClient,
    private converter: ConverterService,
    private environmentService: EnvironmentService,
    private snackBar: MatSnackBar
  ) {}

  getPromoPricesByFilters(): Observable<PromoPrice[]> {
    const path = this.environmentService.getMarkusRestEndpoint('promoPrices');
    const body = this.converter.fromObjtoJSON(this.promoPriceFilters);

    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: PromoPrice[]) => {
        if (!isNilty(resp)) {
          return resp.map((it) => this.converter.fromJSONtoObj(it, PromoPrice));
        } else {
          return [];
        }
      })
    );
  }

  uploadPromoPriceFile(file: File): Observable<void> {
    const path = this.environmentService.getMarkusRestEndpoint('promoPricesImport');
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(path, formData).pipe(
      map(() => {
        this.snackBar.open('File uploaded successfully', 'Close', { duration: 3000 });
      })
    );
  }

  deletePromoPrice(id: string): Observable<any> {
    return this.http.delete(this.environmentService.getMarkusRestEndpoint('promoPriceDelete') + '/' + id);
  }
}
