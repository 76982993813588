import { JsonObject, JsonProperty } from 'json2typescript';
import { PublicationRuleStore } from '../rules/publication-rule/publication-rule-store-model';

@JsonObject('CustomActualShipmentCost')
export class CustomActualShipmentCost {
  @JsonProperty('minWeight', Number, true) minWeight = 0.0;
  @JsonProperty('maxWeight', Number, true) maxWeight = 0.0;
  @JsonProperty('actualShipmentCost', Number, true) actualShipmentCost = 0.0;
  @JsonProperty('packagingWeightKg', Number, true) packagingWeightKg = 0.0;
}

@JsonObject('StoreFeesConfiguration')
export class StoreFeesConfiguration {
  @JsonProperty('store', PublicationRuleStore, true) store: PublicationRuleStore = undefined;
  @JsonProperty('logisticPartner', String, true) logisticPartner: string = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('id', String, true) id: string = undefined;
  @JsonProperty('packaging', Number, true) packaging = 0.0;
  @JsonProperty('poShipmentCost', Number, true) poShipmentCost = 0.0;
  @JsonProperty('handling', Number, true) handling = 0.0;
  @JsonProperty('defaultActualShipmentCost', Number, true) defaultActualShipmentCost = 0.0;
  @JsonProperty('shipmentClass', String, true) shipmentClass: string = undefined;
  @JsonProperty('customActualShipmentCostList', [CustomActualShipmentCost], true) customActualShipmentCostList: CustomActualShipmentCost[] =
    [];
}
