import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { isNilty } from '@core/utils.service';
import { BarChart, BarSeriesOption } from 'echarts/charts';
import {
  DataZoomComponent,
  DataZoomComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';

@Component({
  selector: 'app-pa-bar-chart',
  templateUrl: './pa-bar-chart.component.html',
  styleUrls: ['./pa-bar-chart.component.css'],
})
export class PaBarChartComponent implements AfterViewInit, OnChanges {
  @Input() data: any;
  @Input() suppliers: string[];

  @Output() detail = new EventEmitter<any>();

  @ViewChild('chartElem') chartElem: ElementRef;

  firstInit = false;

  ngAfterViewInit() {
    this.chartInit();
  }

  ngOnChanges() {
    if (this.firstInit && !isNilty(this.data)) this.chartInit();
  }

  chartInit() {
    this.firstInit = true;
    echarts.use([GridComponent, LegendComponent, BarChart, CanvasRenderer, DataZoomComponent]);

    type EChartsOption = echarts.ComposeOption<GridComponentOption | LegendComponentOption | BarSeriesOption | DataZoomComponentOption>;

    const chartDom = this.chartElem.nativeElement;
    chartDom.style.height = this.suppliers.length > 10 ? '500px' : '350px';
    const myChart = echarts.init(chartDom);

    const rawData = [];
    const xAxisDates = [];

    this.data.forEach((it) => {
      if (!xAxisDates.includes(it.date)) xAxisDates.push(it.date);
    });

    this.suppliers.forEach((supplier, sid) => {
      rawData[sid] = [];
      xAxisDates.forEach((date) => {
        rawData[sid].push(this.data.find((it) => it.date === date && it.supplierCode === supplier)?.publicationInfo?.published || 0);
      });
    });

    const grid = {
      left: 100,
      right: 100,
      top: 50,
      bottom: 50,
    };

    const series: BarSeriesOption[] = this.suppliers.map((name, sid) => {
      return {
        name,
        type: 'bar',
        stack: 'total',
        barWidth: '60%',
        label: {
          show: true,
          formatter: ({ value }) => (value > 0 ? `${value}` : ''),
        },
        data: rawData[sid].map((d) => d),
      };
    });

    const option: EChartsOption = {
      legend: {
        selectedMode: false,
      },
      grid,
      yAxis: {
        type: 'value',
      },
      xAxis: {
        type: 'category',
        data: xAxisDates,
      },
      dataZoom: [
        {
          show: true,
          start: 94,
          end: 100,
        },
        {
          type: 'inside',
          start: 94,
          end: 100,
        },
        {
          show: true,
          yAxisIndex: 0,
          filterMode: 'empty',
          width: 30,
          height: '80%',
          showDataShadow: false,
          left: '93%',
        },
      ],

      series,
    };

    if (option) {
      myChart.setOption(option, true);
      myChart.on('click', (params) => {
        this.detail.emit(this.data.filter((it) => it.date === params.name));
      });
    }
  }
}
