import { ITooltipAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { Publication } from '../../../../../models/publications/publication-model';
import { SuppliersService } from '../../../../suppliers/suppliers.service';
import { Supplier } from '../../../../../models/supplier-model';

@Component({
  selector: 'app-pending-tooltip-component',
  template: `
    <div class="custom-tooltip">
      <p>(click to open CatalogItem details)</p>
      <p><b>Supplier: </b> {{ supplier?.name }}</p>
      <p><b>Code: </b> {{ supplier?.code }}</p>
      <p><b>Last stock update: </b> {{ supplier?.lastStockUpdate }}</p>
    </div>
  `,
  styles: [
    `
      :host {
        font-family: 'Titillium Web', sans-serif;
        position: absolute;
        width: fit-content;
        max-width: 400px;
        height: 70px;
        pointer-events: none;
        transition: opacity 0.6s;
      }
      :host.ag-tooltip-hiding {
        opacity: 0;
      }
    `,
  ],
})
export class SupplierTooltipComponent implements ITooltipAngularComp {
  publication: Publication;
  supplier: Supplier;
  constructor(private supplierService: SuppliersService) {}

  agInit(params): void {
    this.publication = params.value as Publication;
    this.supplierService.getAllSuppliers().subscribe((s) => (this.supplier = s.find((it) => it.id === this.publication.supplierId)));
  }
}
