import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FtpEndpointConfiguration } from '@models/configuration/soup-configuration/ftp-destination-model';
import { FTP_SOURCE_TYPES } from '@models/configuration/soup-configuration/route-configurations/ftp-route-configuration-model';

@Component({
  selector: 'app-ftp-endpoints-modal',
  templateUrl: './ftp-endpoints-modal.component.html',
  styleUrls: ['./ftp-endpoints-modal.component.css'],
})
export class FtpEndpointsModalComponent {
  protocols = Object.keys(FTP_SOURCE_TYPES);

  endpoint: FtpEndpointConfiguration = this.data.endpoint;

  constructor(
    public dialogRef: MatDialogRef<FtpEndpointsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      endpoint: FtpEndpointConfiguration;
    }
  ) {}
}
