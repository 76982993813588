import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { isNilty } from '../../core/utils.service';
import { StockItemFilters } from '../../models/filters/stock-item';
import { StockItem } from '../../models/stock-item-model';
import { StockItemsService } from './stock-items.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-stock-items',
  templateUrl: './stock-items.component.html',
  styleUrls: ['./stock-items.component.scss'],
})
export class StockItemsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @ViewChild('stockFile', { static: false }) stockFile: ElementRef;
  @ViewChild('transferStockFile', { static: false }) transferStockFile: ElementRef;
  stockFileUpload: File;
  transferStockFileUpload: File;

  resultsNumber: Observable<number>;
  totalPhysicalQuantity: Observable<number>;
  totalAvailableQuantity: Observable<number>;
  dataSource = new MatTableDataSource<StockItem>([]);
  displayedColumns = [
    'productBarcodeValue',
    'productBarcodeType',
    'title',
    'stockLocation',
    'supplier',
    'physicalQuantity',
    'availableQuantity',
    'availabilityCode',
    'stockLocationSku',
    'stockLocationOwner',
    'internalSku',
    'updatedAt',
    'actualSupplierCode',
    'purchasePrice',
    'openCatalogItems',
  ];

  constructor(
    private stockItemsService: StockItemsService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    if (isNilty(this.stockItemsService.stockItemFilters)) {
      this.stockItemsService.stockItemFilters = new StockItemFilters();
    }

    this.route.queryParams.subscribe((params) => {
      if (params['stockLocation']) {
        this.stockItemsService.stockItemFilters = { stockLocation: params['stockLocation'] } as StockItemFilters;
      }
      if (params['ean']) {
        this.stockItemsService.stockItemFilters = { productBarcodeValue: params['ean'] } as StockItemFilters;
      }
    });

    this.resultsNumber = this.stockItemsService.resultsNumber;
    this.totalPhysicalQuantity = this.stockItemsService.totalPhysicalQuantity;
    this.totalAvailableQuantity = this.stockItemsService.totalAvailableQuantity;
    this.loadResults();
  }

  loadResults() {
    this.stockItemsService.getFilteredStockItems(this.stockItemsService.stockItemFilters).subscribe((resp: StockItem[]) => {
      this.dataSource.data = resp;
    });
  }

  loadPage() {
    this.stockItemsService.stockItemFilters.pageSize = this.paginator.pageSize;
    this.stockItemsService.stockItemFilters.pageNumber = this.paginator.pageIndex;
    this.loadResults();
  }

  onSort() {
    this.stockItemsService.stockItemFilters.sortBy = this.sort.active;
    this.stockItemsService.stockItemFilters.sortDirection = this.sort.direction;
    this.loadResults();
  }

  onFilter() {
    this.paginator.pageIndex = 0;
    this.loadResults();
  }

  hiddenQuantity() {
    return (
      this.stockItemsService.stockItemFilters &&
      (!isNilty(this.stockItemsService.stockItemFilters.supplier) ||
        !isNilty(this.stockItemsService.stockItemFilters.stockLocation) ||
        !isNilty(this.stockItemsService.stockItemFilters.productBarcodeValue) ||
        !isNilty(this.stockItemsService.stockItemFilters.productBarcodeType) ||
        !isNilty(this.stockItemsService.stockItemFilters.hasQuantity))
    );
  }

  openCatalogItems(stockItem: StockItem) {
    this.router.navigate(['catalog-items'], { queryParams: { ean: stockItem.productBarcode.value } });
  }

  uploadStockFile() {
    this.stockFile.nativeElement.click();
  }

  stockFileChanged(event) {
    this.stockFileUpload = event.target.files[0];
    this.stockItemsService.uploadStockItems(this.stockFileUpload).subscribe(() => {
      // Do nothing
    });
  }

  uploadTransferStockFile() {
    alert(
      '\n---------------------------- INSTRUCTIONS ----------------------------\n\n' +
        '                   Select a .CSV file containing following fields:\n\n' +
        'Ean; Quantity to Transfer; Origin Stock Location; Destination Stock Location; Actual Supplier Code'
    );
    this.transferStockFile.nativeElement.click();
  }

  transferFileChanged(event) {
    this.transferStockFileUpload = event.target.files[0];
    if (this.transferStockFileUpload.type !== 'text/csv') {
      this.snackbar.open('ERROR! Please, select an .csv file.', 'CLOSE')._dismissAfter(5000);
    } else {
      this.stockItemsService.uploadStockItemsTransfer(this.transferStockFileUpload).subscribe(() => {
        // Do nothing
      });
    }
  }
}
