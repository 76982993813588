import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNilty } from '@core/utils.service';
import { LoadPolicyWordsModalComponent } from './load-policy-words-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { BlacklistService } from '../../blacklists/blacklist.service';

@Component({
  selector: 'app-edit-words-policy',
  templateUrl: './edit-words-policy.component.html',
  styleUrls: ['./edit-words-policy.component.scss'],
})
export class EditWordsPolicyComponent implements OnInit {
  @Input() words: string[];
  @Output() wordsChange = new EventEmitter<string[]>();

  @Input() blacklistField: string;
  @Output() blacklistFieldChange = new EventEmitter<string>();

  @Output() completed = new EventEmitter<boolean>();

  @Input() editMode = true;

  // eslint-disable-next-line
  MAX_LENGTH = 30;

  displayedWords: string[] = [];

  resultsLength: number;

  searchString = '';

  blacklistFields: string[] = [];

  constructor(private dialog: MatDialog, private blacklistService: BlacklistService) {}

  ngOnInit() {
    if (isNilty(this.words)) {
      this.words = [];
      this.displayedWords = [];
    }
    this.filter('');

    this.blacklistService.getBlacklistWordsFields().subscribe((r) => (this.blacklistFields = r));
  }

  updateDisplayedStrings(stringsToShow: string[]) {
    this.displayedWords = stringsToShow.filter((_, index: number) => index <= this.MAX_LENGTH);
    this.resultsLength = stringsToShow.length;
  }

  removeFromList(value: string) {
    this.words.splice(this.words.indexOf(value), 1);
    this.filter(this.searchString);
    this.checkCompleted();
  }

  filter(searchString: string) {
    const filteredStrings = this.words.filter((it) => it.toUpperCase().indexOf(searchString.toUpperCase()) !== -1);
    this.updateDisplayedStrings(filteredStrings);
  }

  insert(value: string) {
    if (!this.editMode) {
      return;
    }
    if (this.displayedWords.length === 0) {
      this.doInsert(value);
    }
    this.checkCompleted();
  }

  pasteList() {
    this.dialog
      .open(LoadPolicyWordsModalComponent)
      .afterClosed()
      .subscribe((r: string[]) => {
        if (isNilty(r)) {
          return;
        }
        this.words = r;
        this.searchString = '';
        this.filter(this.searchString);
        this.checkCompleted();
      });
  }

  checkCompleted() {
    setTimeout(() => {
      this.wordsChange.emit(this.words);
      this.blacklistFieldChange.emit(this.blacklistField);
      this.completed.emit(this.words.length > 0 && !isNilty(this.blacklistField));
    }, 0);
  }

  private doInsert(value: string) {
    this.words.push(value);
    this.searchString = '';
    this.filter(this.searchString);
  }
}
