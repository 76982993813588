import { isNilty } from '@core/utils.service';

export enum PricingStrategy {
  Margin = 'Margin',
  Markup = 'Markup',
  RetailPrice = 'RetailPrice',
}

export class PricingParameters {
  pricingStrategy: PricingStrategy = PricingStrategy.Markup;
}

export class MarginParameters extends PricingParameters {
  target: number = undefined;
  lowest: number = undefined;
  highest: number = undefined;
  override pricingStrategy: PricingStrategy.Margin = PricingStrategy.Margin;

  constructor(p?: PricingParameters) {
    super();
    if (isNilty(p)) return;
    this.target = p['target'];
    this.lowest = p['lowest'];
    this.highest = p['highest'];
  }
}

export class MarkupParameters extends PricingParameters {
  target: number = undefined;
  lowest: number = undefined;
  highest: number = undefined;
  override pricingStrategy: PricingStrategy.Markup = PricingStrategy.Markup;

  constructor(p: PricingParameters) {
    super();
    if (isNilty(p)) return;
    this.target = p['target'];
    this.lowest = p['lowest'];
    this.highest = p['highest'];
  }
}

export class RetailPriceParameters extends PricingParameters {
  maxDiscount: number = undefined;
  override pricingStrategy: PricingStrategy.RetailPrice = PricingStrategy.RetailPrice;
}
