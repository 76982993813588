import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PublishedStore } from '../../../../models/publications/published-store-model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { isNilty, uniqueArray } from '../../../../core/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { PublicationService } from '../../publication.service';
import { marketplaceOperationColorMap, statusColorMap } from '../../shared/publications-list/publication-list-utils';
import { PendingOperationCount, PublicationStatusCount } from '../../../../models/publications/publications-counts-model';

@Component({
  selector: 'app-search-by-store',
  templateUrl: './search-by-store.component.html',
  styleUrls: ['./search-by-store.component.scss'],
})
export class SearchByStoreComponent implements OnInit {
  @Input() allStores: PublishedStore[];

  @Output() selectedStoreChange = new EventEmitter<PublishedStore>();

  @Output() onSearchStatus = new EventEmitter<string>();
  @Output() onSearchOperation = new EventEmitter<string>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  loadingCounts = false;

  selectedFilterIndex: 'STORES' | 'MARKETPLACES' | 'ACCOUNTS' = 'STORES';

  selectedStoreId: number = undefined;
  currentStore: PublishedStore = undefined;
  displayedStores: PublishedStore[];

  marketplaces: string[];
  selectedMarketplace: string;
  accounts: string[];
  selectedAccount: string;
  storeSearchString: string;

  storesDataSource: MatTableDataSource<PublishedStore>;
  columns = ['name', 'code', 'marketplace', 'account'];

  publicationStatusCount: PublicationStatusCount[] = [];
  pendingOperationCount: PendingOperationCount[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private publicationsService: PublicationService) {}

  ngOnInit() {
    this.marketplaces = uniqueArray(this.allStores.map((it) => it.store.marketplace.name)).sort();
    this.accounts = uniqueArray(this.allStores.map((it) => it.store.accountConnectionName)).sort();
    this.displayedStores = [...this.allStores];
    this.storesDataSource = new MatTableDataSource<PublishedStore>(this.displayedStores);
    this.storesDataSource.paginator = this.paginator;
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      if (!isNilty(params['storeCode'])) {
        this.selectedStore(this.allStores.find((it) => it.store.code.toUpperCase() === params['storeCode'].toUpperCase()));
      }
    });
  }

  selectedStore(s: PublishedStore) {
    if (s.published) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { storeCode: s.store.code },
        queryParamsHandling: 'merge',
      });
      this.selectedStoreId = s.store.id;
      this.currentStore = s;
      this.selectedStoreChange.next(s);
    }
  }

  changeSelectedStore() {
    this.selectedStoreId = undefined;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { storeCode: null },
      queryParamsHandling: 'merge',
    });
  }

  searchStore(searchString: string) {
    if (isNilty(searchString)) {
      this.storesDataSource.data = this.displayedStores;
      return;
    }
    const searchWith = searchString.toUpperCase();
    this.storesDataSource.data = this.displayedStores.filter(
      (it) =>
        it.store.code.toUpperCase().indexOf(searchWith) !== -1 ||
        it.store.name.toUpperCase().indexOf(searchWith) !== -1 ||
        (!isNilty(it.store.marketplace) && it.store.marketplace?.name?.toUpperCase()?.indexOf(searchWith) !== -1) ||
        (!isNilty(it.store.storeGroup) && it.store.storeGroup?.name?.toUpperCase()?.indexOf(searchWith) !== -1)
    );
  }

  selectedFilter(filter) {
    switch (filter) {
      case 'MARKETPLACES': {
        this.columns = ['name', 'code', 'account'];
        this.selectMarketplace();
        break;
      }
      case 'ACCOUNTS': {
        this.columns = ['name', 'code', 'marketplace'];
        this.selectAccount();
        break;
      }
      default: {
        this.columns = ['name', 'code', 'marketplace', 'account'];
        this.displayedStores = [...this.allStores];
        this.searchStore(this.storeSearchString);
        break;
      }
    }
  }

  selectMarketplace(selected?: string) {
    this.selectedMarketplace = selected;
    if (isNilty(this.selectedMarketplace)) {
      return;
    }
    this.displayedStores = [...this.allStores.filter((it) => it.store.marketplace.name === this.selectedMarketplace)];
    this.searchStore(this.storeSearchString);
  }
  selectAccount(selected?: string) {
    this.selectedAccount = selected;
    if (isNilty(this.selectedAccount)) {
      return;
    }
    this.displayedStores = [...this.allStores.filter((it) => it.store.accountConnectionName === this.selectedAccount)];
    this.searchStore(this.storeSearchString);
  }

  statusColorMap(status: string) {
    return statusColorMap(status);
  }
  marketplaceOperationColorMap(operation: string) {
    return marketplaceOperationColorMap(operation);
  }

  searchStatus(s: string) {
    this.onSearchStatus.emit(s);
  }
  searchOperation(o: string) {
    this.onSearchOperation.emit(o);
  }

  getPublicationsCount() {
    if (isNilty(this.currentStore.store.code)) {
      return;
    }
    this.loadingCounts = true;
    this.publicationStatusCount = undefined;
    this.pendingOperationCount = undefined;
    this.publicationsService.getPublicationsCountByStore(this.currentStore.store.code).subscribe((r) => {
      this.publicationsService.getPublicationsEnums().subscribe((enums) => {
        this.loadingCounts = false;
        this.publicationStatusCount = enums.publicationStatus.map(
          (it) => new PublicationStatusCount(it, r.publicationsByStatus.find((p) => p.status === it)?.count)
        );
        this.pendingOperationCount = enums.marketplaceOperations.map(
          (it) => new PendingOperationCount(it, r.pendingByOperation.find((p) => p.operation === it)?.count)
        );
      });
    });
  }
}
