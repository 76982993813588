import { Injectable } from '@angular/core';
import { cachedArrayGet, GenericArrayCache } from '@shared/service-cached-array';
import { BlacklistReason } from '@models/configuration/policies/blacklist/blacklist-reason-model';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '@core/converter.service';
import { EnvironmentService } from '@core/environment/environment.service';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { cachedGet, GenericCache } from '@shared/service-cached';
import {
  BarcodeBlacklist,
  BlacklistTarget,
  ImportBlacklistFile,
  PredicatesBlacklist,
  WordsBlacklist,
} from '@models/configuration/policies/blacklist/blacklist-model';
import { BarcodeBlacklistFilters } from '@models/configuration/policies/blacklist/barcode-blacklist-filters-model';
import { isNilty } from '@core/utils.service';
import { SolveBlacklistInput } from '@models/configuration/policies/blacklist/solve-blacklist-input-model';
import { PoliciesEnumsDto } from '@models/configuration/policies/policy-enums';

@Injectable()
export class BlacklistService {
  barcodeBlacklistsResultNumber = new Subject<number>();

  private blacklistEnumsCache = new GenericCache<PoliciesEnumsDto>(this.http, this.converter, PoliciesEnumsDto);
  private blacklistReasonsCache = new GenericArrayCache<BlacklistReason>(this.http, this.converter, BlacklistReason);

  constructor(private http: HttpClient, private converter: ConverterService, private environment: EnvironmentService) {}

  getBlacklistWordsFields(): Observable<string[]> {
    return this.getBlacklistsEnums().pipe(map((it) => it.wordPolicyFields));
  }

  getBlacklistReasons(): Observable<BlacklistReason[]> {
    return cachedArrayGet(this.blacklistReasonsCache, this.environment.getMarkusRestEndpoint('blacklistReasons')).pipe(
      map((r) => r.filter((it) => it.code !== 'OLD'))
    );
  }

  saveBlacklistReason(br: BlacklistReason): Observable<BlacklistReason> {
    return this.http.post(this.environment.getMarkusRestEndpoint('blacklistReasons'), this.converter.fromObjtoJSON(br)).pipe(
      map((r: BlacklistReason) => {
        this.blacklistReasonsCache.reset();
        return this.converter.fromJSONtoObj(r, BlacklistReason);
      })
    );
  }

  importBlacklistFile(b: ImportBlacklistFile): Observable<any> {
    const path =
      this.environment.getMarkusRestEndpoint('importBlacklistFile') +
      '?targetType=' +
      b.target.type +
      '&targetIdentifier=' +
      b.target.identifier +
      '&barcodeType=' +
      b.barcodeType;

    const formData = new FormData();
    formData.append('file', b.file);

    return this.http.post(path, formData);
  }

  saveSingleBarcodeBlacklist(b: BarcodeBlacklist): Observable<BarcodeBlacklist> {
    return this.saveSingleBlacklist(b, 'blacklistBarcode');
  }
  saveSinglePredicatesBlacklist(b: PredicatesBlacklist): Observable<PredicatesBlacklist> {
    return this.saveSingleBlacklist(b, 'blacklistPredicates');
  }
  saveSingleWordsBlacklist(b: WordsBlacklist): Observable<WordsBlacklist> {
    return this.saveSingleBlacklist(b, 'blacklistWords');
  }

  findBarcodeBlacklistsByFilters(filters: BarcodeBlacklistFilters): Observable<BarcodeBlacklist[]> {
    return this.http
      .post(this.environment.getMarkusRestEndpoint('blacklistBarcodeByFilters'), this.converter.fromObjtoJSON(filters), {
        observe: 'response',
      })
      .pipe(
        map((resp) => {
          this.barcodeBlacklistsResultNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        }),
        map((r: BarcodeBlacklist[]) => r.map((it) => this.converter.fromJSONtoObj(it, BarcodeBlacklist)))
      );
  }
  findWordsBlacklistsByTargets(targets: BlacklistTarget[]): Observable<WordsBlacklist[]> {
    return this.http
      .post(this.environment.getMarkusRestEndpoint('blacklistWordsByTargets'), targets)
      .pipe(map((r: WordsBlacklist[]) => r.map((it) => this.converter.fromJSONtoObj(it, WordsBlacklist))));
  }
  findPredicatesBlacklistsByTargets(targets: BlacklistTarget[]): Observable<PredicatesBlacklist[]> {
    return this.http
      .post(this.environment.getMarkusRestEndpoint('blacklistPredicatesByTargets'), targets)
      .pipe(map((r: PredicatesBlacklist[]) => r.map((it) => this.converter.fromJSONtoObj(it, PredicatesBlacklist))));
  }

  solveBlacklist(payload: SolveBlacklistInput): Observable<any> {
    if (isNilty(payload.id)) {
      return of();
    }

    return this.http.post(this.environment.getMarkusRestEndpoint('blacklistSolve'), this.converter.fromObjtoJSON(payload));
  }

  private saveSingleBlacklist = <T>(blacklist: T, endpointKey: string): Observable<T> =>
    this.http.post(this.environment.getMarkusRestEndpoint(endpointKey), this.converter.fromObjtoJSON(blacklist)).pipe(map((r: T) => r));

  private getBlacklistsEnums(): Observable<PoliciesEnumsDto> {
    return cachedGet(this.blacklistEnumsCache, this.environment.getMarkusRestEndpoint('blacklistEnums'));
  }
}
