import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { SalesOrderPollConfigurationEditComponent } from './sales-order-poll-configuration-edit/sales-order-poll-configuration-edit.component';
import { SalesOrderPollConfigurationListComponent } from './sales-order-poll-configuration-list/sales-order-poll-configuration-list.component';
import { SalesOrderPollConfigurationComponent } from './sales-order-poll-configuration.component';
import { SalesOrderPollConfigurationService } from './sales-order-poll-configuration.service';

@NgModule({
  declarations: [SalesOrderPollConfigurationComponent, SalesOrderPollConfigurationEditComponent, SalesOrderPollConfigurationListComponent],
  imports: [SharedModule],
  providers: [SalesOrderPollConfigurationService],
})
export class SalesOrderPollConfigurationModule {}
