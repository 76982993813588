import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService } from '@core/converter.service';
import { EnvironmentService } from '@core/environment/environment.service';
import { MetadataPriority } from '@models/configuration/metadata-priority-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MetadataPriorityService {
  constructor(private converterService: ConverterService, private http: HttpClient, private environmentService: EnvironmentService) {}

  getMetadataPriority(): Observable<MetadataPriority[]> {
    const path = this.environmentService.getRestEndpoint('metadataPriority') + 'find';
    return this.http.get(path).pipe(map((it: MetadataPriority) => this.converterService.fromJSONtoObj(it, MetadataPriority)));
  }

  getMetadataPriorityField(): Observable<string[]> {
    const path = this.environmentService.getRestEndpoint('metadataPriority') + 'product-metadata-field';
    return this.http.get<string[]>(path);
  }

  getMetadataPrioritySource(): Observable<string[]> {
    const path = this.environmentService.getRestEndpoint('sourceTypes') + 'product-metadata-source';
    return this.http.get<string[]>(path);
  }

  saveMetadataPriority(metadata: MetadataPriority[]): Observable<MetadataPriority[]> {
    const body = this.converterService.fromObjtoJSON(metadata);
    const path = this.environmentService.getRestEndpoint('metadataPriority') + 'save';

    return this.http.post(path, body).pipe(map((resp: MetadataPriority) => this.converterService.fromJSONtoObj(resp, MetadataPriority)));
  }
}
