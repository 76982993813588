import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CatalogItem } from '@models/catalog-item-model';
import { PriceHistoryEntry } from '@models/catalog-item-history-model';
import { isNilty } from '@core/utils.service';
import { CatalogItemsService } from '../../catalog-items.service';

@Component({
  selector: 'app-price-history',
  templateUrl: './price-history.component.html',
  styleUrls: ['./price-history.component.css'],
})
export class PriceHistoryComponent implements OnInit {
  @Input() catalogItem: CatalogItem;
  @Input() priceHistory: PriceHistoryEntry[];

  dataSource = new MatTableDataSource<PriceHistoryEntry>([]);
  displayedColumns = ['feed', 'importTimestamp', 'price', 'discountedPrice', 'discountPercentage', 'discountCode'];

  constructor(private catalogItemService: CatalogItemsService) {}

  ngOnInit(): void {
    this.loadResults();
  }

  loadResults() {
    this.dataSource.data = this.priceHistory;
  }

  getFeed(ph: PriceHistoryEntry) {
    if (!isNilty(ph.fileUrl)) {
      return this.openLink(ph.fileUrl);
    }

    this.catalogItemService.getFeed(ph.feedId).subscribe((r) => {
      if (isNilty(r.fileUrl)) return;
      this.openLink(r.fileUrl);
    });
  }
  private openLink(url: string) {
    window.open(url);
  }
}
