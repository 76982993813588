import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BlacklistTargetTypes } from '@models/configuration/policies/blacklist/blacklists-enums-model';
import { BlacklistTarget } from '@models/configuration/policies/blacklist/blacklist-model';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-select-blacklist-target',
  templateUrl: './select-blacklist-target.component.html',
  styleUrls: ['./select-blacklist-target.component.scss'],
})
export class SelectBlacklistTargetComponent {
  @Output()
  selectedBlacklistTarget = new EventEmitter<BlacklistTarget>();

  @Output()
  selectedTargetTypeChange = new EventEmitter<BlacklistTargetTypes>();
  @Output()
  selectedTargetIdentifierChange = new EventEmitter<string>();

  @Input()
  selectedTargetType: BlacklistTargetTypes;
  @Input()
  selectedTargetIdentifier: string;

  showTargetHelp = false;

  BlacklistTargetTypes = BlacklistTargetTypes;
  targetTypes = Object.keys(BlacklistTargetTypes);

  targetTypeChanged(type: string) {
    if (type === BlacklistTargetTypes.GLOBAL) {
      this.selectedTargetIdentifier = BlacklistTargetTypes.GLOBAL;
    } else {
      this.selectedTargetIdentifier = undefined;
    }
    this.selectedTargetTypeChange.emit(BlacklistTargetTypes[type]);
    this.selectedTargetIdentifierChange.emit(this.selectedTargetIdentifier);
    this.emitBlacklistTarget();
  }

  doSelectTarget(identifier: string) {
    this.selectedTargetIdentifier = identifier;
    this.selectedTargetIdentifierChange.emit(identifier);
    this.emitBlacklistTarget();
  }

  private emitBlacklistTarget() {
    if (!isNilty(this.selectedTargetType) && !isNilty(this.selectedTargetIdentifier)) {
      this.selectedBlacklistTarget.emit(new BlacklistTarget(this.selectedTargetType, this.selectedTargetIdentifier));
    }
  }
}
