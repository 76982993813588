import { Injectable } from '@angular/core';
import { ConverterService } from '@core/converter.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { EnvironmentService } from '@core/environment/environment.service';
import { StoreFilters } from '@models/configuration/marketplace/filters/store-filters-model';
import { FullStore } from '@models/configuration/marketplace/store-model';
import { map } from 'rxjs/operators';
import { MarketplaceConnection } from '@models/configuration/marketplace/marketplace-connection-model';
import { MarketplaceDto, SellingPlatform } from '@models/configuration/marketplace/marketplace-model';
import { cachedArrayGet, GenericArrayCache } from '@shared/service-cached-array';
import { PublishedStore, ZionStore } from '@models/publications/published-store-model';
import { isNilty } from '@core/utils.service';
import { CreateNewStore } from '@models/configuration/marketplace/create-new-store';
import { StoreGroup } from '@models/configuration/marketplace/store-group-model';
import { CreateNewMarket } from '@models/configuration/marketplace/create-new-market';
import { AccountView, OwnerView, StoreView } from '@models/configuration/marketplace/store-view-model';

@Injectable()
export class StoreService {
  resultsNumber = new Subject<number>();

  private publishedStoresCodes: string[];
  private allStores: ZionStore[];

  private marketplacesCache = new GenericArrayCache<MarketplaceDto>(this.http, this.converterService);
  private sellingPlatformsCache = new GenericArrayCache<SellingPlatform>(this.http, this.converterService);

  constructor(private converterService: ConverterService, private http: HttpClient, private environmentService: EnvironmentService) {}

  findByFilters(filters: StoreFilters): Observable<FullStore[]> {
    const body = this.converterService.fromObjtoJSON(filters);
    const path = this.environmentService.getRestEndpoint('findStoresByFilters');
    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: FullStore[]) => resp.map((it) => this.converterService.fromJSONtoObj(it, FullStore)))
    );
  }

  findAllOwners(): Observable<OwnerView[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('owners'))
      .pipe(map((r: OwnerView[]) => r.map((it) => this.converterService.fromJSONtoObj(it, OwnerView))));
  }

  findStoresForView(): Observable<StoreView[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('findStoresForView'))
      .pipe(map((r: StoreView[]) => r.map((it) => this.converterService.fromJSONtoObj(it, StoreView))));
  }

  findMarketplaceConnections(): Observable<MarketplaceConnection[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('connections'))
      .pipe(map((resp: MarketplaceConnection[]) => resp.map((it) => this.converterService.fromJSONtoObj(it, MarketplaceConnection))));
  }

  findConnectionCredentials(connectionId: number): Observable<any> {
    return this.http.get(this.environmentService.getRestEndpoint('credentials') + '/' + connectionId);
  }

  saveConnectionCredentials(connectionId: number, credentials: any): Observable<void> {
    const body = this.converterService.fromObjtoJSON(credentials);
    const path = this.environmentService.getRestEndpoint('saveCredentials') + '/' + connectionId;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return this.http.post(path, body).pipe(map(() => {}));
  }

  clearMarketplacesCache() {
    this.marketplacesCache.reset();
  }

  getMarketplaces(): Observable<MarketplaceDto[]> {
    return cachedArrayGet<MarketplaceDto>(this.marketplacesCache, this.environmentService.getRestEndpoint('getMarketplaces'));
  }

  getSellingPlatforms(): Observable<SellingPlatform[]> {
    return cachedArrayGet<SellingPlatform>(this.sellingPlatformsCache, this.environmentService.getRestEndpoint('sellingPlatforms'));
  }

  createSellingPlatform(spName: string): Observable<any> {
    this.sellingPlatformsCache.reset();
    return this.http.post(this.environmentService.getRestEndpoint('sellingPlatforms'), { sellingPlatformName: spName });
  }

  getAllStores(): Observable<ZionStore[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('findAll'))
      .pipe(map((r: ZionStore[]) => r.map((it) => this.converterService.fromJSONtoObj(it, ZionStore))));
  }

  getPublishedStores(returnOnlyPublished: boolean = true): Observable<PublishedStore[]> {
    if (!isNilty(this.allStores) && !isNilty(this.publishedStoresCodes)) {
      return of(this.returnPublishedStores(returnOnlyPublished));
    }

    const publishedCodesRequest = this.http
      .get(this.environmentService.getMarkusRestEndpoint('publishedStores'))
      .pipe(map((r: string[]) => r));
    const allStoresRequest: Observable<ZionStore[]> = this.getAllStores();

    return forkJoin([publishedCodesRequest, allStoresRequest]).pipe(
      map((results) => {
        this.publishedStoresCodes = results[0].map((it) => it.toUpperCase());
        this.allStores = results[1];
        return this.returnPublishedStores(returnOnlyPublished);
      })
    );
  }

  addStore(requestBody: CreateNewStore): Observable<any> {
    const path = this.environmentService.getRestEndpoint('addNewStore');
    const body = this.converterService.fromObjtoJSON(requestBody);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return this.http.post(path, body).pipe(map(() => {}));
  }

  addStoreGroup(requestBody: StoreGroup): Observable<any> {
    const path = this.environmentService.getRestEndpoint('addNewStoreGroup');
    const body = this.converterService.fromObjtoJSON(requestBody);
    return this.http.post(path, body);
  }

  addMarketplace(requestBody: CreateNewMarket): Observable<any> {
    const path = this.environmentService.getRestEndpoint('addNewMarketplace');
    const body = this.converterService.fromObjtoJSON(requestBody);
    return this.http.post(path, body);
  }

  getAllCountries(): Observable<string[]> {
    return this.http.get(this.environmentService.getRestEndpoint('countries')).pipe(map((countriesResp: string[]) => countriesResp));
  }

  getAllAccounts(): Observable<AccountView[]> {
    return this.http.get(this.environmentService.getRestEndpoint('accounts')).pipe(map((accountsResp: AccountView[]) => accountsResp));
  }

  getAllStoreGroups(): Observable<StoreGroup[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('storeGroups'))
      .pipe(map((storeGroupsResp: StoreGroup[]) => storeGroupsResp));
  }

  private returnPublishedStores(returnOnlyPublished: boolean): PublishedStore[] {
    const published = [];
    const unPublished = [];

    this.allStores.forEach((it) => {
      if (this.publishedStoresCodes.indexOf(it.code.toUpperCase()) !== -1) {
        published.push(new PublishedStore(it, true));
      } else {
        unPublished.push(new PublishedStore(it, false));
      }
    });

    if (returnOnlyPublished) {
      return published.sort((i, j) => (i.store.code > j.store.code ? 1 : -1));
    } else {
      return [...published, ...unPublished];
    }
  }
}
