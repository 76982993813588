import { JsonObject, JsonProperty } from 'json2typescript';

export enum PolicyTypes {
  BARCODE = 'BARCODE',
  PREDICATES = 'PREDICATES',
  WORDS = 'WORDS',
}

export enum BarcodeTypes {
  EAN = 'EAN',
  SKU = 'SKU',
  MARKETPLACE_BARCODE = 'MARKETPLACE_BARCODE',
}

@JsonObject('PoliciesEnumsDto')
export class PoliciesEnumsDto {
  @JsonProperty('wordPolicyFields', [String], true) wordPolicyFields: string[] = [];
}
