import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConverterService } from '@core/converter.service';
import { EnvironmentService } from '@core/environment/environment.service';
import { CatalogHistory } from '@models/catalog-item-history-model';
import { CatalogItem, CatalogItemStatus } from '@models/catalog-item-model';
import { CatalogItemFilters } from '@models/filters/catalog-item';
import { ZionCatalogItem } from '@models/zion-catalog-item-model';
import { ImportStockLocation } from '@models/import-stock-location-model';

@Injectable()
export class CatalogItemsService {
  resultsNumber = new Subject<number>();
  catalogItemFilters: CatalogItemFilters;
  priceHistoryResultsNumber = new Subject<number>();

  status: string[];

  constructor(
    private converter: ConverterService,
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private snackBar: MatSnackBar
  ) {}

  getFilteredCatalogItems(filters: CatalogItemFilters, skipCount: boolean = false): Observable<CatalogItem[]> {
    const productBarcodeValue = filters.productBarcodeValue ?? '';
    const productBarcodeType = filters.productBarcodeType ?? '';
    const supplier = filters.supplier ?? '';
    const supplierId = filters.supplierId ?? '';
    const sku = filters.sku ?? '';
    const internalSku = filters.internalSku ?? '';
    const status = filters.status ? filters.status : '';
    const quantityGreaterThan = filters.quantityGreaterThan ? filters.quantityGreaterThan : '';
    const isPod = filters.isPod ? filters.isPod : '';
    const pageIndex = filters.pageNumber ? filters.pageNumber : 0;
    const pageSize = filters.pageSize ? filters.pageSize : 10;
    const sortBy = filters.sortBy ? filters.sortBy : 'orderedOn';
    const sortDirection = filters.sortDirection ? filters.sortDirection : 'desc';

    const queryPath =
      '?productBarcodeValue=' +
      productBarcodeValue +
      '&productBarcodeType=' +
      productBarcodeType +
      '&supplier=' +
      supplier +
      '&supplierId=' +
      supplierId +
      '&sku=' +
      sku +
      '&internalSku=' +
      internalSku +
      '&status=' +
      status +
      '&quantityGreaterThan=' +
      quantityGreaterThan +
      '&isPod=' +
      isPod +
      '&page-index=' +
      pageIndex +
      '&page-size=' +
      pageSize +
      '&sort-by=' +
      sortBy +
      '&sort-direction=' +
      sortDirection +
      '&skip-count=' +
      skipCount;

    return this.http.get(this.environmentService.getRestEndpoint('catalogItems') + queryPath, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: CatalogItem[]) => resp.map((r) => this.converter.fromJSONtoObj(r, CatalogItem)))
    );
  }

  getCatalogItemBySupplierAndEan(supplierCode: string, barcode: string): Observable<ZionCatalogItem> {
    return this.http
      .get(this.environmentService.getRestEndpoint('catalogItems') + `/${supplierCode}/${barcode}`)
      .pipe(map((ci: ZionCatalogItem) => this.converter.fromJSONtoObj(ci, ZionCatalogItem)));
  }

  getCatalogItem(id: number): Observable<CatalogItem> {
    return this.http
      .get(this.environmentService.getRestEndpoint('catalogItems') + '/id/' + id)
      .pipe(map((resp) => this.converter.fromJSONtoObj(resp, CatalogItem)));
  }

  getCatalogHistory(catalogItemId: number): Observable<CatalogHistory> {
    return this.http
      .get(this.environmentService.getRestEndpoint('catalogHistory') + catalogItemId)
      .pipe(map((r: CatalogHistory) => this.converter.fromJSONtoObj(r, CatalogHistory)));
  }

  getFeed(feedId: number): Observable<ImportStockLocation> {
    return this.http
      .get(this.environmentService.getRestEndpoint('importStockLocationFeed') + feedId)
      .pipe(map((r: CatalogHistory) => this.converter.fromJSONtoObj(r, ImportStockLocation)));
  }

  getStatus(): Observable<string[]> {
    if (this.status) {
      return of(this.status);
    }

    return this.http
      .get(this.environmentService.getRestEndpoint('catalogItems') + '/status')
      .pipe(map((resp: any) => (this.status = resp.map((r) => r))));
  }

  disableCatalogItem(catalogItemId: number): Observable<any> {
    return this.changeCatalogItemStatus(catalogItemId, CatalogItemStatus.DISABLED);
  }
  activateCatalogItem(catalogItemId: number): Observable<any> {
    return this.changeCatalogItemStatus(catalogItemId, CatalogItemStatus.ACTIVE);
  }

  uploadCatalogItems(file: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(this.environmentService.getRestEndpoint('uploadCatalogItems'), formData).pipe(
      map(() => {
        this.snackBar.open('File uploaded. ', 'CLOSE')._dismissAfter(3000);
      })
    );
  }

  private changeCatalogItemStatus(catalogItemId: number, newStatus: string): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('changeCatalogItemStatus'), {
      catalogItemId,
      newStatus,
    });
  }
}
