import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonsService } from '../commons.service';
import { ProductsService } from '../../container/products/products.service';
import { NetworkCategory } from '../../models/network-category-model';
import { ProductNetworkCategoryModalInput } from '../../models/product/product-network-category-modal-input';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { isNilty } from '../../core/utils.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-publication-errors-modal',
  templateUrl: './product-network-category-modal.component.html',
  styleUrls: ['./product-network-category-modal.component.scss'],
})
export class ProductNetworkCategoryModalComponent implements OnInit {
  categoryMap: { network: string; productCategory: string; availableCategories: NetworkCategory[] }[] = [];

  private productCategories: NetworkCategory[];
  private allCategories: NetworkCategory[];
  private networks: string[];

  constructor(
    public dialogRef: MatDialogRef<ProductNetworkCategoryModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ProductNetworkCategoryModalInput,
    private commonsService: CommonsService,
    private productService: ProductsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    const networksObs = this.commonsService.getNetworks().pipe(
      map((it) => {
        this.networks = it;
        return;
      })
    );
    const ncObs = this.productService.getProductNetworkCategories(this.data.sku).pipe(
      map((nc: NetworkCategory[]) => {
        this.productCategories = nc;
        return;
      })
    );
    const categoriesObs = this.commonsService.getNetworkCategories().pipe(
      map((it) => {
        this.allCategories = it;
        return;
      })
    );

    forkJoin([networksObs, ncObs, categoriesObs]).subscribe(() => {
      this.networks.forEach((n) => {
        this.categoryMap.push({
          network: n,
          productCategory: this.productCategories.find((it) => it.network === n)?.serializedEnum,
          availableCategories: this.allCategories.filter((it) => it.network === n),
        });
      });
    });
  }

  save() {
    this.productService
      .saveProductNetworkCategories(
        this.data.sku,
        this.categoryMap.map((it) => it.productCategory).filter((it) => !isNilty(it))
      )
      .subscribe(() => {
        this.snackBar.open('Product Network Categories have been updated.')._dismissAfter(3000);
        this.dialogRef.close();
      });
  }
}
