import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { ProductsService } from '../products.service';
import { ProductFilters } from '@models/filters/product';
import { ProductEquivalence } from '@models/product-equivalence-model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-equivalence-products-list',
  templateUrl: './equivalence-products-list.component.html',
  styleUrls: ['./equivalence-products-list.component.css'],
})
export class EquivalenceProductsListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  resultsNumber: Observable<number>;
  dataSource = new MatTableDataSource<ProductEquivalence>([]);
  displayedColumns = ['ean', 'equivalentCode', 'equivalenceType'];
  productFilters = new ProductFilters();

  constructor(private productsService: ProductsService, private router: Router) {}

  ngOnInit(): void {
    this.resultsNumber = this.productsService.resultsNumber;
    this.loadResults();
  }

  loadResults() {
    this.productsService.getFilteredEquivalenceProducts(this.productFilters).subscribe((resp: ProductEquivalence[]) => {
      this.dataSource.data = resp;
    });
  }

  loadPage() {
    this.productFilters.pageSize = this.paginator.pageSize;
    this.productFilters.pageNumber = this.paginator.pageIndex;
    this.loadResults();
  }

  onSort() {
    this.productFilters.sortBy = this.sort.active;
    this.productFilters.sortDirection = this.productFilters.sortDirection === 'asc' ? 'desc' : 'asc';
    this.loadResults();
  }

  showProducts() {
    this.router.navigate(['products']);
  }

  filterProducts(filters: ProductFilters) {
    this.productFilters = filters;
    this.loadResults();
  }

  removeFilterKey(key: string) {
    delete this.productFilters[key];
    this.loadResults();
  }
}
