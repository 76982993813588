import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '@core/converter.service';
import { Observable } from 'rxjs';
import { FtpEndpointConfiguration } from '@models/configuration/soup-configuration/ftp-destination-model';
import { EnvironmentService } from '@core/environment/environment.service';
import { map } from 'rxjs/operators';

@Injectable()
export class FtpEndpointsService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getFtpEndpoints(): Observable<FtpEndpointConfiguration[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('ftpEndpoints'))
      .pipe(map((resp: FtpEndpointConfiguration[]) => resp.map((it) => this.converter.fromJSONtoObj(it, FtpEndpointConfiguration))));
  }

  save(ftp: FtpEndpointConfiguration): Observable<any> {
    const body = this.converter.fromObjtoJSON(ftp);
    return this.http.post(this.environmentService.getRestEndpoint('ftpEndpoints'), body);
  }
}
