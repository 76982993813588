import { Pipe, PipeTransform } from '@angular/core';
import { isNilty } from '@core/utils.service';

@Pipe({
  name: 'monthYearTransform',
})
export class MonthYearTransform implements PipeTransform {
  transform(monthYear: string): string {
    if (!isNilty(monthYear) && monthYear.length === 4) {
      const month = monthYear.substring(0, 2);
      const year = monthYear.substring(2);
      return `${month}/${year}`;
    }
    return;
  }
}
