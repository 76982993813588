import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('SoupParser')
export class SoupParser {
  @JsonProperty('parserName', String, true) parserName: string = undefined;
  @JsonProperty('beanName', String, false) beanName: string = undefined;
  @JsonProperty('description', String, true) description: string = undefined;
  @JsonProperty('fileTypes', [String], false) fileTypes: string[] = undefined;
  @JsonProperty('custom', Boolean, true) custom: boolean = undefined;
}
