import { JsonObject, JsonProperty } from 'json2typescript';
import { PublicationRule } from './publication-rule-model';
import { RuleStockLocationSource, RuleSupplierSource } from '../publication-rule-source-model';
import { PowerSellerRule } from '@models/configuration/rules/power-seller-rule/power-seller-rule-model';

@JsonObject('PartialRuleRefreshInput')
export class PartialRuleRefreshInput {
  @JsonProperty('ruleCode', String, true) ruleCode: string = undefined;
  @JsonProperty('suppliersToRefresh', [RuleSupplierSource], true) suppliersToRefresh: RuleSupplierSource[] = [];
  @JsonProperty('stockLocationsToRefresh', [RuleStockLocationSource], true) stockLocationsToRefresh: RuleStockLocationSource[] = [];

  private constructor(ruleCode: string, suppliers: RuleSupplierSource[], stockLocations: RuleStockLocationSource[]) {
    this.ruleCode = ruleCode;
    this.suppliersToRefresh = suppliers;
    this.stockLocationsToRefresh = stockLocations;
  }

  public static fromPublicationRule(
    rule: PublicationRule,
    suppliers: RuleSupplierSource[],
    stockLocations: RuleStockLocationSource[]
  ): PartialRuleRefreshInput {
    return new PartialRuleRefreshInput(rule.store.code, suppliers, stockLocations);
  }

  public static fromPowerSellerRule(
    rule: PowerSellerRule,
    suppliers: RuleSupplierSource[],
    stockLocations: RuleStockLocationSource[]
  ): PartialRuleRefreshInput {
    return new PartialRuleRefreshInput(rule.powerSeller.code, suppliers, stockLocations);
  }
}
