import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { StatusChangeLogEntry } from 'src/app/models/publications/status-changelog-entry-model';

@Component({
  selector: 'app-status-changelog-modal',
  templateUrl: './status-changelog-modal.component.html',
  styleUrls: ['./status-changelog-modal.component.scss'],
})
export class StatusChangelogModalComponent implements OnInit {
  @Input()
  statusChangeLog: StatusChangeLogEntry[];

  displayedColumns = ['timestamp', 'fromStatus', 'toStatus', 'forced'];
  dataSource: MatTableDataSource<StatusChangeLogEntry>;

  ngOnInit() {
    this.dataSource = new MatTableDataSource<StatusChangeLogEntry>(this.statusChangeLog);
  }
}
