import { JsonObject, JsonProperty } from 'json2typescript';
import { Pagination } from '../../shared/pagination-model';

@JsonObject('PublicationFilters')
export class PublicationFilters extends Pagination {
  @JsonProperty('storeCode', String, true) storeCode: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('barcode', String, true) barcode: string = undefined;
  @JsonProperty('category', String, true) category: string = undefined;

  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('supplierId', Number, true) supplierId: number = undefined;
  @JsonProperty('brand', String, true) brand: string = undefined;
  @JsonProperty('marketplaceBarcode', String, true) marketplaceBarcode: string = undefined;
  @JsonProperty('onlyOnline', Boolean, true) onlyOnline: boolean = undefined;
  @JsonProperty('errorCode', String, true) errorCode: string = undefined;
  @JsonProperty('isBuyBoxWinner', Boolean, true) isBuyBoxWinner: boolean = undefined;
  @JsonProperty('buyBoxDistances', [String], true) buyBoxDistances: string[] = undefined;

  @JsonProperty('marketplaceOperation', String, true) marketplaceOperation: string = undefined;
  @JsonProperty('onlyPending', Boolean, true) onlyPending = false;
  @JsonProperty('marketplaceFeedId', String, true) marketplaceFeedId: string = undefined;
  @JsonProperty('onlySubmitted', Boolean, true) onlySubmitted: boolean = undefined;

  constructor() {
    super();
    this.pageSize = 20;
  }
}

export class FilterChipModel {
  label: string;
  value: string;
  key: string;

  constructor(label: string, value: string, key: string) {
    this.label = label;
    this.value = value;
    this.key = key;
  }
}
