import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PublicationRulesService } from '../publication-rules.service';
import { PublicationRuleFilters } from '@models/configuration/rules/publication-rule/publication-rule-filters-model';
import { MatTableDataSource } from '@angular/material/table';
import { PublicationRule } from '@models/configuration/rules/publication-rule/publication-rule-model';
import { fromEvent, Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-publication-rules-list',
  templateUrl: './publication-rules-list.component.html',
  styleUrls: ['./publication-rules-list.component.css'],
})
export class PublicationRulesListComponent implements OnInit {
  @ViewChild('filterInput', { static: true }) filterInput: ElementRef;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  filters = new PublicationRuleFilters();
  allAccounts: string[] = [];

  dataSource = new MatTableDataSource<PublicationRule>();
  resultsNumber: Subject<number> = this.publicationRulesService.resultsNumber;
  displayedColumns = ['storeName', 'storeCode', 'marketplace', 'account', 'requireStoreFees', 'active', 'marketplaceSync', 'actions'];

  constructor(private publicationRulesService: PublicationRulesService) {}

  ngOnInit() {
    this.filters.sortBy = 'store.name';
    this.filters.sortDirection = 'asc';

    if (!isNilty(this.publicationRulesService.savedPublicationRuleFilters)) {
      this.filters.accountConnectionName = this.publicationRulesService.savedPublicationRuleFilters.accountConnectionName;
      this.filters.storeString = this.publicationRulesService.savedPublicationRuleFilters.storeString;
    }

    this.loadRules();

    this.publicationRulesService.getAllAccountNames().subscribe((r) => (this.allAccounts = r));

    fromEvent(this.filterInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.filters.storeString = text;
        this.loadRules();
      });
  }

  loadRules() {
    this.publicationRulesService.findRulesByFilters(this.filters).subscribe((it) => (this.dataSource.data = it));
  }

  loadRulesFromAccount(accountName?: string) {
    this.filters.accountConnectionName = accountName;
    this.loadRules();
  }

  loadPage() {
    this.filters.pageSize = this.paginator.pageSize;
    this.filters.pageNumber = this.paginator.pageIndex;
    this.loadRules();
  }
}
