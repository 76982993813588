import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Permission } from '@models/security/permission-model';
import { PermissionsService } from '../permissions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-permission-new',
  templateUrl: './permission-new.component.html',
  styleUrls: ['./permission-new.component.css'],
})
export class PermissionNewComponent implements OnInit {
  applications: string[] = [];

  permission = new Permission();
  permissionForm: FormGroup;
  constructor(
    private permissionsService: PermissionsService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.permissionForm = new FormGroup({
      name: new FormControl('', Validators.required),
      application: new FormControl('', Validators.required),
    });
    this.permissionsService.getApplications().subscribe((applications: string[]) => {
      this.applications = applications;
    });
  }

  onCancel() {
    this.router.navigate(['configuration', 'permissions']);
  }

  onSubmit() {
    this.permission = new Permission();
    this.permission.name = this.permissionForm.controls.name.value;
    this.permission.application = this.permissionForm.controls.application.value;

    this.permissionsService.savePermission(this.permission).subscribe(() => {
      this.router.navigate(['configuration', 'permissions']);
    });
  }
}
