import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-type-tag',
  template: `
    <div class="tag" [ngClass]="colorClass">
      {{ description }}
    </div>
  `,
  styles: [
    `
      .tag {
        font-weight: bold;
        border-radius: 16%;
        line-height: 12px;
        font-size: 12px;
        padding: 2px;
        text-align: center;
        color: white;
        display: inline-block;
        margin-right: 5px;
      }

      .drop {
        background-color: #062d64;
      }

      .bulk {
        background-color: #3864a4;
      }
    `,
  ],
})
export class OrderTypeTagComponent {
  tooltipOptions = {
    showDelay: 500,
  };
  description: string;
  colorClass: string;

  @Input() set orderType(o: string) {
    this.colorClass = o.toLowerCase();
    this.description = o;
  }
}
