import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericImportTemplate, TemplateColumnWithPosition } from '@models/import/import-templates-model';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-select-column-position',
  template: `
    <div class="dialog-container" (keyup.enter)="confirm()">
      <div mat-dialog-content>
        <div class="row">
          <div class="col center">
            <label for="name">Template name</label>
            <mat-form-field class="position-input">
              <input id="name" matInput [(ngModel)]="name" />
            </mat-form-field>
          </div>
        </div>
        <div class="row description">
          <div class="col center">(press ENTER to confirm)</div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .dialog-container {
        font-family: 'Titillium Web', sans-serif;
        width: 400px !important;
      }

      .position-input {
        margin-left: 50px;
      }

      .description {
        font-size: 13px;
      }
      .error {
        color: red;
      }
      .warn {
        color: orange;
      }
    `,
  ],
})
export class InsertTemplateNameModalComponent {
  name = '';

  constructor(
    private dialogRef: MatDialogRef<InsertTemplateNameModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { column: TemplateColumnWithPosition; template: GenericImportTemplate }
  ) {}

  confirm() {
    if (!isNilty(this.name)) this.dialogRef.close(this.name);
  }
}
